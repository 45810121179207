<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            color="primary"
            clearable
            label="Поиск"
            prepend-icon="mdi-cloud-search-outline"
            :append-outer-icon="advancedSearchIcon"
            @click:append-outer="search.advanced.show = !search.advanced.show"
            maxlength="64"
            v-model="search.fullName"
            @input="LoadGoods"
            dense
            outlined
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-expand-transition>
            <div v-show="search.advanced.show">
              <v-row class="py-1">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="search.advanced.INN"
                    label="МНН"
                    dense
                    clearable
                    @input="LoadGoods"
                    hide-details
                    outlined
                    prepend-icon="mdi-earth"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="search.advanced.brand"
                    label="Бренд"
                    dense
                    clearable
                    @input="LoadGoods"
                    hide-details
                    outlined
                    prepend-icon="mdi-watermark"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="search.advanced.ATC"
                    label="АТХ"
                    dense
                    clearable
                    @input="LoadGoods"
                    hide-details
                    outlined
                    prepend-icon="mdi-flask"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="search.advanced.producer"
                    label="Производитель"
                    dense
                    clearable
                    @input="LoadGoods"
                    hide-details
                    outlined
                    prepend-icon="mdi-domain"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-1" justify="center" align="center">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="goodsDateReg"
                    label="Дата регистрации"
                    type="number"
                    dense
                    clearable
                    hide-details
                    outlined
                    prepend-icon="mdi-update"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="goodsShelflife"
                    label="Срок годности"
                    type="number"
                    dense
                    clearable
                    hide-details
                    outlined
                    prepend-icon="mdi-update"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-row v-if="spellingCorrect">
        <v-col cols="12" class="py-1">
          <span class="red--text pr-1">вы имеете ввиду:</span>
          <a
            class="primary--text text-decoration-underline font-weight-medium"
            @click="
              search.fullName = spellingCorrect;
              LoadGoods();
            "
          >
            {{ this.spellingCorrect }}
          </a>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card>
      <v-form ref="listForm" lazy-validation>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading.goods"
          multi-sort
          class="elevation-3"
          dense
          show-expand
          @click:row="GetPriceByGoodsId"
          single-select
          hide-default-footer
          fixed-header
          disable-sort
          disable-pagination
          height="700"
          id="goodsList"
          :item-class="getRowColor"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <b>ID:</b>
              {{ item.id }}
              <v-spacer></v-spacer>
              <b>Название:</b>
              {{ item.name.name }}
              <v-spacer></v-spacer>
              <b>Лекарственная форма:</b>
              {{ item.dosageForm.name }}
              <v-spacer></v-spacer>
              <b>Доза:</b>
              {{ item.dose.name }}
              <v-spacer></v-spacer>
              <b>Номер:</b>
              {{ item.number }}
              <v-spacer></v-spacer>
              <b>Бренд:</b>
              {{ item.brand.name }}
              <v-spacer></v-spacer>
              <b>Производитель:</b>
              {{ item.producer.name }}
              <v-spacer></v-spacer>
              <b>Страна:</b>
              {{ item.country.name }}
              <v-spacer></v-spacer>
              <b>МНН:</b>
              {{ item.inn.name }}
              <v-spacer></v-spacer>
              <b>RX/OTC:</b>
              {{ item.rxOTC.name }}
            </td>
          </template>
          <template v-slot:item.info="{ item }">
            <div class="d-flex justify-center align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click.stop="AddInStorage(item)"
                    :loading="loading.addToStorageDialog"
                  >
                    <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Добавить в хранилище</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click.stop="GetAnnotationById(item)"
                    :loading="item.info"
                  >
                    <v-icon color="primary">mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Категория: {{ item.category.name }}</span>
                <v-spacer></v-spacer>
                <span>Брэнд: {{ item.brand.name }}</span>
                <v-spacer></v-spacer>
                <span>ATX: {{ item.atc.name }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.fullName="{ item }">
            <div>
              <b>{{ item.name.name }}</b>
              {{ item.dosageForm.name }}
              /
              {{ item.dose.name }}
              №{{ item.number }}
            </div>
            <div>
              {{ item.producer.name }}
              <v-chip
                v-if="getRegDateEndStatus(item)"
                x-small
                color="red"
                outlined
                >{{ getRegDateEndDaysCount(item) }}</v-chip
              >
              <v-chip
                v-if="getProducerDiscountStatus(item)"
                class="my-1"
                small
                color="primary"
                >Бонус от производителя</v-chip
              >
              <v-chip v-if="item.isPromotion" x-small color="green" outlined
                >Акция</v-chip
              >
            </div>
            <!-- <td :inner-html.prop="value | highlight(search.fullName)"></td> -->
          </template>
          <template v-slot:item.sticker="{ item }">
            <AdTemplate
              :adLocation="5"
              v-if="adGoods.includes(item.id)"
              :goodsId="item.id"
            ></AdTemplate>
          </template>
          <template v-slot:item.promoAd="{ item }">
            <PromoTemplate
              v-if="
                promoGoods.find((x) =>
                  x.adItems.find((x) => x.goodsId == item.id)
                ) != undefined
              "
              :adLocation="
                promoGoods.find((x) =>
                  x.adItems.find((x) => x.goodsId == item.id)
                ).id
              "
              :goodsId="item.id"
            ></PromoTemplate>
            <div v-else class="text-overline text-center primary--text">
              {{ item.rating != null ? item.rating.rank : "н/р" }}
            </div>
          </template>
        </v-data-table>
        <AddInStorageDialog />
        <AnnotationDialog />
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    goodsDateReg: 30,
    goodsShelflife: 30,
    expanded: [],
    loading: {
      addToStorageDialog: false,
      goods: false,
    },
    headers: [
      {
        text: "",
        value: "data-table-expand",
        show: true,
        align: "auto",
        class: "primary",
      },
      {
        text: "",
        align: "auto",
        value: "promoAd",
        show: true,
        class: "primary white--text",
      },
      {
        text: "Полное наименование товара",
        align: "auto",
        value: "fullName",
        show: true,
        class: "primary white--text",
      },
      {
        text: "",
        align: "auto",
        value: "sticker",
        show: true,
        class: "primary white--text",
      },
      {
        text: "",
        align: "auto",
        value: "info",
        show: true,
        class: "primary white--text",
      },
    ],
    options: {
      page: 1,
      itemsPerPage: 50,
    },
    items: [],
    total: 0,
    spellingCorrect: "",
    cseToken: "",
  }),
  watch: {
    options: {
      handler() {
        this.LoadGoods();
      },
      deep: true,
    },
    "options.itemsPerPage": function () {},
    search() {
      this.LoadGoods();
    },
    goodsShelflife: function () {
      this.$store
        .dispatch("COMMIT_PRICE_SHELFLIFE_END_STATUS", this.goodsShelflife)
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    },
  },
  created() {
    this.GetCseToken();
  },
  methods: {
    GetCseToken() {
      this.$store
        .dispatch("CSE_TOKEN")
        .then((response) => {
          this.cseToken = response;
        })
        .catch(() => {})
        .finally(() => {});
    },
    async LoadGoods() {
      this.spellingCorrect = "";
      this.loading.goods = true;
      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };
      let SearchProperty = {
        FullName: `${this.search.fullName ?? ""}`,
        Brand: `${this.search.advanced.brand ?? ""}`,
        INN: `${this.search.advanced.INN ?? ""}`,
        ATC: `${this.search.advanced.ATC ?? ""}`,
        Producer: `${this.search.advanced.producer ?? ""}`,
        CseToken: `${this.cseToken ?? ""}`,
        BeInThePrices: `${this.search.beInThePrices}`,
      };
      let GoodsSearch = {
        Pagination,
        SearchProperty,
      };
      this.$store
        .dispatch("ALL_GOODS_SEARCH_PAGING", GoodsSearch)
        .then((response) => {
          this.loading.goods = false;
          this.items = response;
          this.total = 50;
          if (!this.items.length) {
            this.$store
              .dispatch("SPELLING_CORRECT_GOOGLE", GoodsSearch)
              .then((response) => {
                this.spellingCorrect = response;
              })
              .catch((error) => {
                this.loading.goods = false;
                window.console.log(error);
              });
          }
        })
        .catch((error) => {
          this.loading.goods = false;
          window.console.log(error);
        });
    },
    async GetPriceByGoodsId(value, row) {
      if (this.userRoleId == 11) return;
      this.$store.commit("SET_LOADING_ORDER_PRICE", true);
      row.select(true);
      let payload = {
        goodsId: value.id,
        goods: value,
      };
      this.$store
        .dispatch("PRICE_BY_GOODS_ID", payload)
        .then(() => {
          this.$store.commit("SET_ORDER_RIGHT_TAB", "Цены");
        })
        .catch(() => {})
        .finally(() => {
          this.$store.commit("SET_LOADING_ORDER_PRICE", false);
        });

      this.$store
        .dispatch("GET_LAST_ORDER_INFO", value.id)
        .then(() => {})
        .catch(() => {});

      this.$store
        .dispatch("GET_PRODUCER_GOODS_GROWTH_DISCOUNTS", value.id)
        .then(() => {})
        .catch(() => {});
    },
    async GetAnnotationById(item) {
      item.info = true;
      this.$store
        .dispatch("ANNOTATION", item.annotationId)
        .then((hasAnnotation) => {
          if (hasAnnotation) {
            this.$store.commit("SET_ANNOTATION_DIALOG", true);
          } else {
            let Empty = {
              text: "Аннотация отсутствует",
              timeout: 5000,
              color: "warning",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Empty);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          item.info = false;
        });
    },
    getRowColor(item) {
      let currentIndex = this.items.map((x) => x.id).indexOf(item.id);
      if (
        this.promoGoods.find((x) =>
          x.adItems.find((x) => x.goodsId == item.id)
        ) != undefined
      ) {
        return "promoGood-row-color";
      }

      if (item.regDateEnd != null) {
        var currentDate = new Date();
        currentDate.setDate(
          currentDate.getDate() + parseInt(this.goodsDateReg, 10)
        );
        var parsedDate = Date.parse(item.regDateEnd);
        if (parsedDate < currentDate) {
          return "regDateEnd-row-color";
        }
      }

      if (currentIndex % 2 == 0) {
        return "gray-row-color";
      } else {
        return "white-row-color";
      }
    },
    getRegDateEndStatus(item) {
      if (item.regDateEnd != null) {
        var currentDate = new Date();
        currentDate.setDate(
          currentDate.getDate() + parseInt(this.goodsDateReg, 10)
        );
        var parsedDate = Date.parse(item.regDateEnd);
        if (parsedDate < currentDate) {
          return true;
        }
        return false;
      }
      return false;
    },
    getRegDateEndDaysCount(item) {
      var parsedDate = Date.parse(item.regDateEnd);
      var currentDate = new Date();
      const diffTime = Math.abs(parsedDate - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return parsedDate < currentDate
        ? "истек " + diffDays + " дн. назад"
        : "до окончания " + diffDays + " дн.";
    },
    getProducerDiscountStatus(item) {
      if (item.isProducerDiscount) return true;

      return false;
    },
    AddInStorage(item) {
      this.loading.addToStorageDialog = true;

      let payload = {
        goodsId: item.id,
        goods: item,
      };

      this.$store.commit("SET_STORAGE_DIALOG", true);
      this.$store.dispatch("PRICE_BY_GOODS_ID", payload);
      this.loading.addToStorageDialog = false;
    },
  },
  computed: {
    search: {
      get: function () {
        return this.$store.getters.GOODS_SEARCH;
      },
    },
    advancedSearchIcon: {
      get: function () {
        if (
          this.search.advanced.INN ||
          this.search.advanced.brand ||
          this.search.advanced.ATC ||
          this.search.advanced.producer
        )
          return "mdi-filter-menu-outline";
        else return "mdi-filter-outline";
      },
    },
    adGoods: {
      get: function () {
        return this.$store.getters.ADS?.find((x) => x.id == 5)?.adItems?.map(
          (x) => x.goodsId
        );
      },
    },
    promoGoods: {
      get: function () {
        return this.$store.getters.ADS?.filter((x) => [6, 7, 8].includes(x.id));
      },
    },
    userRoleId: {
      get: function () {
        return this.$store.getters.USER.userRoleId;
      },
    },
  },
};
</script>

<style lang="css">
.white-row-color {
  background-color: #ffffff;
}
.gray-row-color {
  background-color: rgba(0, 0, 0, 0.05);
}
.regDateEnd-row-color {
  background-color: rgba(255, 0, 0, 0.05);
}
.promoGood-row-color {
  background-color: lavenderblush;
}
</style>