<template>
  <div>
    <v-combobox
      v-model="headers"
      :items="selectedItem"
      label="Скрытие и отображение столбцов"
      multiple
      small-chips
      :clearable="true"
      dense
      outlined
      :counter="19"
      :hide-selected="true"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">{{ item.text }}</span>
          <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
        </v-chip>
      </template>
    </v-combobox>
    <v-card-title>
      Аннотация
      <v-spacer></v-spacer>
      <v-text-field
        clearable
        label="Поиск по названию"
        prepend-icon="mdi-cloud-search-outline"
        maxlength="64"
        v-model="search.Name"
        @input="loadAnnotation"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-text-field
        clearable
        label="Поиск по МНН"
        prepend-icon="mdi-search-web"
        maxlength="64"
        v-model="search.INN"
        @input="loadAnnotation"
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card>
      <v-data-table
        fixed-header
        height="500"
        :headers="headers"
        :items="items"
        class="elevation-3"
        multi-sort
        dense
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
          showFirstLastPage: true,
        }"
        :loading="loading"
      >
        <template v-slot:item.info="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                fab
                text
                v-on="on"
                @click="GetAnnotationsById(item)"
                :loading="item.info"
              >
                <v-icon color="primary">mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>Перейти к полному описанию</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <AnnotationDialog />
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [],
    loading: false,
    headers: [
      {
        text: "ID",
        sortable: true,
        value: "id",
        show: true,
        color: "blue",
      },
      {
        text: "Торговое название",
        sortable: true,
        value: "name",
        show: true,
        color: "green",
      },
      {
        text: "МНН",
        sortable: true,
        value: "inn",
        show: true,
        color: "red",
      },
      {
        text: "Фармакологические свойства",
        sortable: true,
        value: "pharmProperty",
        show: false,
        color: "cyan",
      },
      {
        text: "Лекарственная форма",
        sortable: true,
        value: "dosageFormInfo",
        show: false,
        color: "arctic",
      },
      {
        text: "Состав",
        sortable: true,
        value: "composition",
        show: false,
        color: "brown",
      },
      {
        text: "Описание",
        sortable: true,
        value: "description",
        show: false,
        color: "pink",
      },
      {
        text: "Фармакотерапевтическая группа",
        sortable: true,
        value: "pharmGroup",
        show: false,
        color: "purple",
      },
      {
        text: "Показания к применению",
        sortable: true,
        value: "indicationsForUse",
        show: false,
        color: "lime",
      },
      {
        text: "Способы применения",
        sortable: true,
        value: "modeOfUse",
        show: false,
        color: "deep orange",
      },
      {
        text: "Побочные действия",
        sortable: true,
        value: "sideEffects",
        show: false,
        color: "yellow",
      },
      {
        text: "Противопоказания",
        sortable: true,
        value: "contraindications",
        show: false,
        color: "grey",
      },
      {
        text: "Лекарственные взаимодействия",
        sortable: true,
        value: "interaction",
        show: false,
        color: "amber",
      },
      {
        text: "Особые указания",
        sortable: true,
        value: "specialInstructions",
        show: false,
        color: "purple",
      },
      {
        text: "Передозировка",
        sortable: true,
        value: "overDose",
        show: false,
        color: "orange",
      },

      {
        text: "Форма выпуска и упаковка",
        sortable: true,
        value: "releaseForm",
        show: false,
        color: "teal",
      },
      {
        text: "Условия хранения",
        sortable: true,
        value: "storageConditions",
        show: false,
        color: "peacock",
      },
      {
        text: "Срок хранения",
        sortable: true,
        value: "storageLife",
        show: false,
        color: "indigo",
      },
      {
        text: "Условия отпуска из аптек",
        sortable: true,
        value: "saleConditions",
        show: false,
        color: "denim",
      },
      {
        text: "Условия отпуска из аптек",
        sortable: true,
        value: "saleConditions",
        show: false,
        color: "denim",
      },
      {
        text: "Полное описание",
        value: "info",
        sortable: false,
        show: true,
        color: "sandstone",
      },
    ],
    selectedItem: [],
    options: {
      page: 1,
      itemsPerPage: 50,
    },
    total: 0,
    search: {
      Name: "",
      INN: "",
    },
  }),
  watch: {
    options: {
      handler() {
        this.loadAnnotation();
      },
    },
    search: function () {
      //this.loadAnnotation();
      window.console.log(this.search);
    },
  },
  methods: {
    async GetAnnotationsById(item) {
      item.info = true;
      this.$store
        .dispatch("ANNOTATION", item.id)
        .then(() => {
          item.info = false;
          //window.console.log("iteminfo",item.info);
          this.$store.commit("SET_ANNOTATION_DIALOG", true);
        })
        .catch(() => {
          item.info = false;
        });
    },
    async loadAnnotation() {
      this.loading = true;

      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };

      let SearchProperty = {
        Name: `${this.search.Name ?? ""}`,
        INN: `${this.search.INN ?? ""}`,
      };

      let AnnotationSearch = {
        Pagination,
        SearchProperty,
      };

      this.$store
        .dispatch("ALL_ANNOTATION_PAGING", AnnotationSearch)
        .then((response) => {
          this.loading = false;
          this.items = response;
          //this.total = 50;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });

      this.$store
        .dispatch("ALL_COUNT_ANNOTATION")
        .then((response) => {
          this.total = response;
        })
        .catch((error) => {
          window.console.log(error);
        });

     
    },
  },
  created: function () {
    this.loadAnnotation();
    this.selectedItem = this.headers;
    this.headers = this.headers.filter((x) => x.show == true);
  },
};
</script>