import axios from "axios";
// import store from "vuex";
import store from './../index'

export default {
    state: {
        annotation: {},
        dialog: false
    },
    getters: {
        ANNOTATION: state => {
            return state.annotation;
        },
        ANNOTATION_DIALOG: state => {
            return state.dialog;
        },
    },
    mutations: {
        SET_ANNOTATION: (state, payload) => {
            state.annotation = payload;
        },
        SET_ANNOTATION_DIALOG: (state, payload) => {
            state.dialog = payload;
        },
    },
    actions: {
        ANNOTATION: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get('/Annotation/GetById?id=' + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_SNACKBAR", { show: false });
                            store.commit('SET_ANNOTATION', data);
                            resolve(true);
                        }
                        if (status === 204) {
                            resolve(false);
                        }
                    })
                    .catch(error => {

                        reject(error);
                    });
            });
        },
        ALL_ANNOTATION_PAGING: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post("/Annotation/GetAllAnnotationPagingAsync", payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        ALL_COUNT_ANNOTATION: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Annotation/GetCountAnnotationAsync")
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        }
    }
};

