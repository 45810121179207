<template>
  <div>
    <v-combobox
      v-model="headers"
      :items="selectedItem"
      label="Скрытие и отображение столбцов"
      multiple
      small-chips
      :clearable="true"
      dense
      outlined
      :counter="6"
      :hide-selected="true"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">{{ item.text }}</span>
          <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
        </v-chip>
      </template>
    </v-combobox>
    <v-card-title>
      Новости
      <v-spacer></v-spacer>
      <v-text-field
        clearable
        label="Поиск по тексту"
        prepend-icon="mdi-cloud-search-outline"
        maxlength="64"
        v-model="search.Name"
        @input="loadAtricles"
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card>
      <v-data-table
        fixed-header
        :headers="headers"
        :items="items"
        class="elevation-3"
        dense
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
          showFirstLastPage: true,
        }"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="900px" persistent scrollable>
              <template v-slot:activator="{ on }">
                <v-btn class="mb-2" small v-on="on" color="primary"
                  >Создать</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="10" v-show="false">
                        <v-text-field
                          v-model="article.id"
                          label="ID"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="10">
                        <v-text-field
                          v-model="article.title"
                          label="Титул"
                          :error-messages="modelState['Title']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="10">
                        <v-textarea
                          outlined
                          v-model="article.text"
                          label="Текст"
                          :error-messages="modelState['Text']"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="Close()">Отмена</v-btn>
                  <v-btn text @click="Save()">Сохранить</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.dateInsert="{ item }">
          <span>{{ new Date(item.dateInsert).toLocaleString() }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="EditItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="DeleteById(item.id)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [],
    loading: false,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
        show: false,
      },
      {
        text: "Шапка",
        align: "center",
        value: "title",
        show: true,
      },
      {
        text: "Текст",
        align: "center",
        value: "text",
        show: true,
      },
      {
        text: "Менеджер",
        align: "center",
        value: "user.login",
        show: false,
      },
      {
        text: "Дата добавления",
        align: "center",
        value: "dateInsert",
        show: true,
      },
      {
        text: "Действия",
        value: "actions",
        sortable: false,
        exclude: false,
        show: true,
      },
    ],
    selectedItem: [],
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    search: {
      Name: "",
    },
    article: {
      id: 1,
      title: "Уважаемые пользователи!",
      text: "",
    },
    defaultArticle: {
      id: 1,
      title: "Уважаемые пользователи!",
      text: "",
    },
    dialog: false,
    editedIndex: -1,
    modelState: {}
  }),
  watch: {
    options: {
      handler() {
        this.loadAtricles();
      },
    },
    search: function() {
      this.loadAtricles();
    },
  },
  methods: {
    DeleteById(id) {
      if (confirm("Вы уверены, что хотите удалить новость?")) {
        this.$store
          .dispatch("DELETE_ARTICLE", id)
          .then(() => {
            this.Close();
            this.loadAtricles();
          })
          .catch();
      }
    },
    Close() {
      this.modelState = {};
      this.dialog = false;
      this.$nextTick(() => {
        this.article = Object.assign({}, this.defaultArticle);
        this.editedIndex = -1;
      });
    },
    Save() {
      window.console.log("SAVE INDEX", this.editedIndex);
      if (this.editedIndex === -1) {
        this.$store
          .dispatch("CREATE_ARTICLE", this.article)
          .then(() => {
            this.Close();
            this.loadAtricles();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
          });
      } else {
        this.$store
          .dispatch("EDIT_ARTICLE", this.article)
          .then((response) => {
            Object.assign(this.items[this.editedIndex], response);
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      }
    },
    EditItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedArticleIndex = this.items.indexOf(item);
      this.article = Object.assign({}, item);
      this.dialog = true;
    },
    async loadAtricles() {
      this.loading = true;
      let Search = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
        Search: `${this.search.Name ?? ""}`,
      };
      this.$store
        .dispatch("ALL_ARTICLE_PAGING", Search)
        .then((response) => {
          this.loading = false;
          this.items = response;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
      this.$store
        .dispatch("ALL_COUNT_ARTICLE")
        .then((response) => {
          this.total = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? "Создание" : "Изменение";
    },
  },
  created: function() {
    this.loadAtricles();
    this.selectedItem = this.headers;
    this.headers = this.headers.filter((x) => x.show == true);
  },
};
</script>
