<template>
  <div id="form">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Скидки и Договоры
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFilials"
            prepend-icon="mdi-cloud-search-outline"
            label="Поиск"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            label="Фильтр поставщиков"
            item-text="name"
            item-value="id"
            :items="distributors"
            @change="GetFilialsByDistributorId"
            persistent-hint
            hint="Выберите поставщика"
            return-object
            clearable
          ></v-autocomplete>
        </v-card-title>

        <v-sheet v-if="firstLoad" class="px-3 pt-3 pb-3">
          <v-skeleton-loader
            class="mx-auto"
            type="table-thead"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="table-row-divider@5"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="table-tfoot"
          ></v-skeleton-loader>
        </v-sheet>

        <v-card v-show="!firstLoad">
          <v-form ref="listForm" lazy-validation>
            <!-- <v-col class="text-left">
              <v-card elevation="0">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="UploadContractsByFilialsId(selectedFilials)"
                        :disabled="selectedFilials.length == 0"
                      >
                        <v-icon>mdi-cloud-upload-outline</v-icon> -
                        {{ selectedFilials.length }}
                      </v-btn>
                    </template>
                    <span>Выгрузить договоры выделенных филиалов</span>
                  </v-tooltip>
                </div>
              </v-card>
            </v-col> -->

            <v-data-table
              v-model="selectedFilials"
              :headers="filialHeaders"
              :items="filials"
              :items-per-page="5"
              class="elevation-2"
              :loading="loading"
              :search="searchFilials"
              
            >
              <template v-slot:item.contracts="{ item }">
                <v-btn
                  small
                  @click="
                    dialog = !dialog;
                    GetContractByFilialId(item);
                  "
                  :disabled="item.uploadingContract"
                  color="primary"
                  >Скидки и Договоры пользователей</v-btn
                >
              </template>

              <template v-slot:item.uploadContract="{ item }">
                <v-btn
                  fab
                  small
                  class="ma-2"
                  @click="UploadContractByFilialId(item)"
                  :loading="item.uploadingContract"
                  :disabled="item.uploadingContract"
                >
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  class="ma-2"
                  color="error"
                  :disabled="!item.uploadingContract"
                  @click="CancelRequestById(item)"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-form>
        </v-card>

        <v-dialog
          v-model="dialog"
          class="elevation-20"
          transition="scale-transition"
          persistent
          scrollable
        >
          <v-card tile>
            <v-toolbar dense>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ currentFilial }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn text @click="dialog = false">Закрыть</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-title>
              Скидки и Договоры
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchInContract"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                item-key="id"
                :headers="contractHeaders"
                :items="contracts"
                :search="searchInContract"
                :items-per-page="10"
                :loading="loadingContract"
                single-select
                show-group-by
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  data: () => ({
    selectedFilials: [],
    dialog: false,
    filials: [],
    contracts: [],
    distributors: [],
    expanded: [],
    loading: false,
    loadingContract: true,
    firstLoad: true,
    searchFilials: "",
    searchInContract: "",
    currentFilial: "",
    filialHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Поставщик", value: "name" },
      { text: "Скидки и Договоры", value: "contracts", sortable: false },
      // { text: "Выгрузка / Отмена", value: "uploadContract", sortable: false },
    ],
    contractHeaders: [
      { text: "Имя", sortable: true, value: "user.firstName" },
      { text: "Фамилия", sortable: true, value: "user.lastName" },
      { text: "Логин", sortable: true, value: "user.login" },
      { text: "БИН на момент выгрузки", sortable: true, value: "bin" },
      {
        text: "ID договора поставщика",
        sortable: true,
        value: "distributorContractId",
      },
      {
        text: "Название договора поставщика",
        sortable: true,
        value: "distributorContractName",
      },
      {
        text: "Скидка",
        sortable: true,
        value: "discountFromFilial",
      },
      {
        text: "Договор?",
        sortable: true,
        value: "isContract",
      },
    ],
  }),
  async mounted() {
    this.$store.dispatch("DISTRIBUTORS").then(() => {
      this.distributors = this.$store.getters.DISTRIBUTORS;
    });

    this.$store
      .dispatch("FILIALS")
      .then(() => {
        this.firstLoad = !this.firstLoad;
        this.filials = this.$store.getters.FILIALS;
      })
      .catch(() => {
        this.firstLoad = !this.firstLoad;
      });

    this.$store.dispatch("CANCEL_REQUESTS").then(() => {});
  },
  methods: {
    async GetContractByFilialId(filial) {
      this.contracts = [];
      this.searchInContract = "";
      this.currentFilial = "";
      this.loadingContract = true;
      this.$store
        .dispatch("CONTRACTS_BY_FILIAL_ID", filial.id)
        .then(() => {
          this.contracts = this.$store.getters.CONTRACTS_FILIAL;
          this.currentFilial = filial.name;
          this.loadingContract = false;
        })
        .catch(() => {
          this.loadingContract = false;
        });
    },

    async GetFilialsByDistributorId(distributor) {
      if (distributor == undefined) this.filials = this.$store.getters.FILIALS;
      else
        this.filials = this.$store.getters.FILIALS_BY_DISTRIBUTOR_ID(
          distributor.id
        ).filials;
    },
    async UploadContractByFilialId(item) {
      this.loading = !this.loading;
      item.uploadingContract = true;

      const axios = require("axios").default;

      let Request = {
        id: item.id,
        cancelTokenSource: axios.CancelToken.source(),
      };

      this.$store.commit("PUSH_REQUEST", Request);

      this.$store
        .dispatch("UNLOAD_CONTRACTS_BY_FILIAL_ID", item.id)
        .then(() => {
          this.loading = !this.loading;
          item.uploadingContract = false;
          let Success = {
            text: "Договоры загружены",
            timeout: 3000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          this.loading = !this.loading;
          item.uploadingContract = false;
          window.console.log(error);
        });
    },

    async UploadContractsByFilialsId() {
      this.loading = true;
      var filialsId = this.selectedFilials.map((x) => x.id);
      window.console.log(filialsId);
      await this.$store
        .dispatch("UNLOAD_CONTRACTS_BY_FILIALS_ID", filialsId)
        .then(() => {
          let Success = {
            text: "Выбранные договоры загружены",
            timeout: 3000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async CancelRequestById(item) {
      let Throw = {
        id: item.id,
        message:
          "Выгрузка была отменена пользователем. Поставщик: " + item.name,
      };
      this.$store.dispatch("CANCEL_REQUEST_BY_ID", Throw).then(() => {
        this.loading = !this.loading;
        item.uploadingContract = false;
      });
    },
  },
  created() {},
};
</script>