import axios from "axios";
import store from './../index'

export default {
    state: {
        currentUserModules: JSON.parse(localStorage.getItem('PHC_userM')),
        currentUserActions: JSON.parse(localStorage.getItem('PHC_userA')),
        accessDialog: {
            show: false,
            userId: '',
            login: '',
            firstName: '',
            lastName: ''
        },

        allDBModules: [],
        allAPIModules: [],
        anotherUserActions: [],
        accessTemplates: [],
        accessTemplateActions:[],
    },
    getters: {
        CURRENT_USER_MODULES: state => {
            return state.currentUserModules
        },
        CURRENT_USER_ACTIONS: state => {
            return state.currentUserActions
        },
        ACCESS_DIALOG: state => {
            return state.accessDialog
        },
        ALL_DB_MODULES: state => {
            return state.allDBModules
        },
        ALL_API_MODULES: state => {
            return state.allAPIModules
        },
        ANOTHER_USER_ACTIONS: state => {
            return state.anotherUserActions.filter(x => x.isAvailableToAll == false)
        },
        ACCESS_TEMPLATES: state => {
            return state.accessTemplates
        },
        ACCESS_TEMPLATE_ACTIONS: state => {
            return state.accessTemplateActions.filter(x => x.isAvailableToAll == false)
        },
    },
    mutations: {
        SET_CURRENT_USER_MODULES: (state, payload) => {
            state.currentUserModules = payload;
            localStorage.setItem('PHC_userM', JSON.stringify(payload));
        },
        SET_CURRENT_USER_ACTIONS: (state, payload) => {
            state.userActions = payload;
            localStorage.setItem('PHC_userA', JSON.stringify(payload));
        },
        SET_ACCESS_DIALOG: (state, payload) => {
            state.accessDialog = payload;
        },
        SET_ALL_DB_MODULES: (state, payload) => {
            let allModules = [];
            for (let m = 0; m < payload.length; m++) {
                let actions = payload[m].actions;
                let newActions = [];

                for (let a = 0; a < actions.length; a++) {
                    newActions.push({
                        id: payload[m].name + actions[a].name,
                        name: actions[a].description + ' [' + actions[a].name + ']',

                        moduleId: payload[m].id,

                        actionId: actions[a].id,
                        actionName: actions[a].name,

                        description: actions[a].description,
                        isAvailableToAll: actions[a].isAvailableToAll
                    });
                }

                allModules.push({
                    id: payload[m].name,
                    name: payload[m].description + ' [' + payload[m].name + ']',

                    moduleId: payload[m].id,
                    moduleName: payload[m].name,

                    description: payload[m].description,
                    children: newActions,
                });
            }
            state.allDBModules = allModules;
        },
        SET_ALL_API_MODULES: (state, payload) => {
            let modules = [];
            for (let m = 0; m < payload.length; m++) {
                let actions = payload[m].actions;
                let newActions = [];
                let foundModuleInDBModules = state.allDBModules.find(x => x.moduleName == payload[m].name)


                for (let a = 0; a < actions.length; a++) {

                    let foundActionInDBModules = foundModuleInDBModules?.children.find(c => c.id == payload[m].name + actions[a].name)

                    let newAction = {
                        id: payload[m].name + actions[a].name,
                        name: actions[a].description + ' [' + actions[a].name + ']',

                        moduleId: payload[m].id,
                        moduleName: foundModuleInDBModules?.moduleName,

                        actionId: foundActionInDBModules?.actionId,
                        actionName: actions[a].name,

                        description: actions[a].description,
                        isAvailableToAll: actions[a].isAvailableToAll,
                        isNewAction: foundActionInDBModules ? '' : 'new',
                        isDifferenceAction: foundActionInDBModules?.isAvailableToAll != actions[a].isAvailableToAll
                            || foundActionInDBModules?.description != actions[a].description ? 'update' : '',
                    }

                    newActions.push(newAction);
                }

                let module = {
                    id: payload[m].name,
                    name: payload[m].description + ' [' + payload[m].name + ']',

                    moduleId: foundModuleInDBModules?.moduleId,
                    moduleName: payload[m].name,
                    moduleDescription: payload[m].description,
                    children: newActions,
                    isNewModule: foundModuleInDBModules ? '' : 'new',
                    isDifferenceModule: foundModuleInDBModules?.description != payload[m].description ? 'update' : '',
                }

                modules.push(module);
            }
            state.allAPIModules = modules;
        },
        SET_ANOTHER_USER_ACTIONS: (state, payload) => {
            state.anotherUserActions = payload
        },
        SET_ACCESS_TEMPLATE_ACTIONS: (state, payload) => {
            state.accessTemplateActions = payload
        },
        SET_ACCESS_TEMPLATES: (state, payload) => {
            state.accessTemplates = payload
        },
        SET_NEW_ACCESS_TEMPLATE: (state, payload) => {
            state.accessTemplates.push(payload);
        },
        SET_DELETE_ACCESS_TEMPLATE: (state, payload) => {
            state.accessTemplates = state.accessTemplates.filter(
                x => x.id != payload
            );
        },
    },
    actions: {
        CURRENT_USER_ACTIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Access/GetUserActionsByContext`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_CURRENT_USER_ACTIONS", data);
                            store.commit("SET_CURRENT_USER_MODULES", data.map(x => x.action).map(y => y.module));
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        ALL_DB_MODULES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Access/GetDBModules`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_ALL_DB_MODULES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        ALL_API_MODULES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Access/GetAPIModules`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_ALL_API_MODULES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        ANOTHER_USER_ACTIONS_BY_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Access/GetUserActionsByUserId?userId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {

                            let userActions = [];

                            for (let i = 0; i < data.length; i++) {
                                userActions.push({
                                    actionId: data[i].actionId,
                                    id: data[i].action.module.name + data[i].action.name,
                                    moduleId: data[i].action.moduleId,
                                    moduleName: data[i].action.module.description + ' [' + data[i].action.module.name + ']',
                                    name: data[i].action.description + ' [' + data[i].action.name + ']',
                                    isAvailableToAll: data[i].action.isAvailableToAll,
                                    accessDateStart: data[i].accessDateStart,// new Date(data[i].accessDateStart).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }),
                                    accessDateEnd: data[i].accessDateEnd//new Date(data[i].accessDateEnd).toLocaleString(),
                                });
                            }

                            store.commit("SET_ANOTHER_USER_ACTIONS", userActions);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        CREATE_APPROVAL: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Access/CreateApproval`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        CHECK_USER_ACTION_ACCESS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Access/IsUserActionAccess?actionName=` + payload.actionName + '&moduleName=' + payload.moduleName)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        CREATE_ACTION: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Access/CreateAction`, payload)
                    .then(({

                        status
                    }) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        DELETE_ACTION: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Access/DeleteActionById?id=` + payload.actionId)
                    .then(({
                        status
                    }) => {
                        if (status === 200) {

                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_ACTION: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Access/UpdateAction`,
                        payload
                    )
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_MODULE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Access/CreateModule`, payload)
                    .then(({

                        status
                    }) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        DELETE_MODULE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Access/DeleteModuleById?id=` + payload.moduleId)
                    .then(({
                        status
                    }) => {
                        if (status === 200) {

                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_MODULE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Access/UpdateModule`,
                        payload
                    )
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_ACCESS_TEMPLATE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Access/CreateAccessTemplate`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            window.console.log(data)
                            store.commit('SET_NEW_ACCESS_TEMPLATE', data);
                            let Success = {
                                text: 'Создано шаблон доступа с именем ' + payload.name,
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        DELETE_ACCESS_TEMPLATE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Access/DeleteAccessTemplateById?id=` + payload)
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_DELETE_ACCESS_TEMPLATE', payload);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        ACCESS_TEMPLATES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Access/GetAccessTemplatesAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_ACCESS_TEMPLATES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        ACCESS_TEMPLATE_ACTIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Access/GetAccessTemplateActionsByIds`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_ACCESS_TEMPLATE_ACTIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Access/UpdateAccessTemplateActions`,
                        payload
                    )
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            let Success = {
                                text: 'Сохранены',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
}