<template>
  <v-container>
    <v-row>
      <v-card tile elevation="0">
        <v-card-title>
          <v-text-field
            v-model="search"
            label="Поиск"
            class="rounded-0"
            single-line
            hide-details
            append-icon="mdi-magnify"
            dense
            outlined
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="companyUserGroupUserPharms"
            item-key="id"
            class="elevation-0"
            :search="search"
            :loading="loading"
            loading-text="Идет загрузка..."
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" small outlined tile @click="apply()">
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "CompanyUserGroupUserPharms",
  computed: {
    selectedUserGroupUserId: function () {
      return this.$store.getters.SELECTED_COMPANY_USER_GROUP_USER;
    },
  },
  data() {
    return {
      headers: [
        { text: "Логин", value: "user.login", align: "center" },
        { text: "FirstName", value: "user.firstName", align: "center" },
        { text: "LastName", value: "user.lastName", align: "center" },
      ],
      search: "",
      loading: false,
      companyUserGroupUserPharms: []
    };
  },
  methods: {
    async getCompanyUserGroupUserPharms() {
      await this.$store
        .dispatch("GET_COMPANY_USER_GROUP_USER_PHARMS", this.selectedUserGroupUserId)
        .then(() => {
          this.companyUserGroupUserPharms =
            this.$store.getters.COMPANY_USER_GROUP_USER_PHARMS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  async mounted() {
    await this.getCompanyUserGroupUserPharms();
  },
};
</script>