<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 class="primary--text">Упс, 404</h1>
        <p>Страница, которую вы искали, не существует</p>
        <v-btn depressed color="primary" to="/">Вытащи меня отсюда!</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>