<template>
  <v-row>
    <v-window
      v-model="window"
      :vertical="vertical"
      :show-arrows="showArrows"
      :reverse="reverse"
      dark
      continuous
      style="max-width:825px"
    >
        <v-window-item>
            <v-btn fab text small v-if="adLocation == 6" @click="AdItemContentOpen(adItem.adItemContents[0].adItemContentOpens[0])">
                <v-icon color="primary">mdi-gift</v-icon>
            </v-btn>    
            <v-btn fab text small  v-else-if="adLocation == 7" @click="AdItemContentOpen(adItem.adItemContents[0].adItemContentOpens[0])">
                <v-icon color="primary">mdi-calendar-month</v-icon>
            </v-btn>
            <v-btn fab text small  v-else-if="adLocation == 8" @click="AdItemContentOpen(adItem.adItemContents[0].adItemContentOpens[0])">
                <v-icon color="primary">mdi-weather-sunny</v-icon>
            </v-btn> 
        </v-window-item>
    </v-window>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <iframe
                v-show="video.show"
                :src="video.src"
                frameborder="0"
                allowfullscreen
                width="600px"
                height="600px"
              ></iframe>
              <v-img v-show="image.show" :src="image.src"></v-img>
              <iframe
                width="100%"
                height="600px"
                frameborder="0"
                v-show="presentation.show"
                :src="presentation.src"
              ></iframe>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    adLocation: Number,
    goodsId: Number,
  },
  data: () => ({
    dialog: false,
    window: 0,
    showArrows: false,
    vertical: true,
    reverse: true,
    autorun: true,
    video: { show: false, src: "" },
    image: { show: false, src: "" },
    presentation: { show: false, src: "" },
  }),

  methods: {
    
    AdItemContentOpen(openType) {
      if(openType == undefined)
        return;
        
      this.presentation.src = this.video.src = this.image.src = "";
      this.presentation.show = this.video.show = this.image.show = false;

      if (openType.adItemContentOpenType.id == 0) {
        let Search = {
          fullName: openType.sourceLink,
          beInThePrices: false,
          advanced: {
            INN: "",
            brand: "",
            ATC: "",
            producer: "",
            show: false,
          },
        };
        this.$store.commit("SET_GOODS_SEARCH", Search);
      }

      if (openType.adItemContentOpenType.id == 1) {
        this.presentation.src =
          "https://docs.google.com/viewer?url=" +
          openType.sourceLink +
          "&embedded=true";
        this.presentation.show = true;
        this.dialog = true;
      }
      if (openType.adItemContentOpenType.id == 2) {
        this.video.src = openType.sourceLink;
        this.video.show = true;
        this.dialog = true;
      }
      if (openType.adItemContentOpenType.id == 3) {
        this.image.src = openType.sourceLink;
        this.image.show = true;
        this.dialog = true;
      }
    },
    GetDimension(col, isVertical) {
      var dimension = this.$store.getters.AD_ITEM_CONTENT_DIMENSIONS(
        this.adLocation,
        col,
        isVertical
      );
      return dimension;
    },
    IsVertical(contents) {
      var result =
        contents
          .map((x) => x.frontColumn)
          .reduce(function (previousValue, currentValue) {
            return previousValue + currentValue;
          }) == 12;

      return result;
    },
  },
  mounted() {},
  computed: {
    ad: {
      get: function () {
        var result = this.$store.getters.ADS?.find(
          (x) => x.id == this.adLocation
        );
        return result;
      },
    },
    adItem: {
      get: function () {
        var result = this.ad?.adItems?.find((x) => x.goodsId == this.goodsId);
        return result;
      },
    },
  },
  created() {
    setInterval(() => {
      if (!this.autorun) return;
      if (++this.window >= this.length) this.window = 0;
    }, 5000);
  },
};
</script>