<template>
  <div>
    <v-card-title>
      Города
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-sheet
      :loading="loading"
      v-if="firstLoad"
      class="px-3 pt-3 pb-3"
    >
      <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto" type="table-row-divider@5"></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
    </v-sheet>
    <v-data-table
      :headers="headers"
      v-show="!firstLoad"
      :items="cities"
      :items-per-page="5"
      class="elevation-1"
      :loading="loading"
      :search="search"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  data: () => ({
    cities: [],
    loading: true,
    firstLoad: true,
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        sortable: true,
        value: "id"
      },
      { text: "Название", value: "name" }
    ]
  }),
  async mounted() {
    this.$store
      .dispatch("USER_COUNTRY_CITIES")
      .then(() => {
        this.Loading();
        this.cities = this.$store.getters.USER_COUNTRY_CITIES;
      })
      .catch(error => {
        this.Loading();
        window.console.log(error);
      });
  },
  methods: {
    Loading() {
      this.firstLoad = !this.firstLoad;
      this.loading = !this.loading;
    }
  }
};
</script>