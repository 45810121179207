<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="cards"
      sort-by="calories"
      class="elevation-1"
      :loading="loading"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Карты халык банка</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Создать
            </v-btn> -->
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editedItem.filial"
                        :items="filials"
                        prepend-icon="mdi-domain"
                        label="Филиал"
                        item-text="name"
                        item-value="id"
                        clearable
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Отмена </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Сохранить </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Вы уверены, что хотите удалить этот элемент?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.transfer="{ item }">
        <v-btn small @click="GetTransfersByCardId(item)">
          <v-icon>mdi-transfer</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Сбросить </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogTransfers"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialogTransfers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Карта</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialogTransfers = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          Операций
          <v-spacer></v-spacer>
          <v-alert border="right" colored-border type="warning" elevation="2">
            Результаты ответа платежа: успех - <code>00</code> ошибка -
            <code>NO</code>
          </v-alert>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchTransfers"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="transferHeaders"
          :items="transfers"
          :search="searchTransfers"
          :items-per-page="5"
        >
          <template v-slot:item.halykTransferRequest.signedOrder="props">
            <v-textarea
              :value="props.item.halykTransferRequest.signedOrder"
              rows="2"
              row-height="20"
            ></v-textarea>
            <v-btn
              @click="FormatXML(props.item.halykTransferRequest.signedOrder)"
              x-small
              color="primary"
              >Формат</v-btn
            > </template
          ><template v-slot:item.response="props">
            <v-textarea :value="props.item.response" rows="2" row-height="20"></v-textarea
            ><v-btn @click="FormatXML(props.item.response)" x-small color="primary"
              >Формат</v-btn
            >
          </template>
          <template v-slot:item.responseDate="props">
            <span>{{ new Date(props.item.responseDate).toLocaleString() }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogFomatXML" scrollable>
      <v-card>
        <v-toolbar color="primary" dark
          >Форматированный вывод xml
          <v-spacer></v-spacer>
          <span v-show="copyTextLoading">Скопировано</span>

          <v-btn color="white" icon @click="СopyText()" :loading="copyTextLoading">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <pre ref="textToCopy">{{ formattedXml }}</pre>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialogFomatXML = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    copyTextLoading: false,
    dialogTransfers: false,
    dialogFomatXML: false,
    formattedXml: "",
    search: "",
    searchTransfers: "",
    loading: false,
    filials: [],
    transfers: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID карты (Халык)",
        align: "start",
        sortable: false,
        value: "cardId",
      },
      { text: "Логин пользователя", value: "user.login" },
      { text: "Магазин ID", value: "merchantId" },
      { text: "Карта", value: "cardMask" },
      { text: "MM", value: "cardExpiredMM" },
      { text: "YY", value: "cardExpiredYY" },
      { text: "Одобрить", value: "approve" },
      { text: "Код для подтверж.", value: "reference" },
      { text: "Рабочий", value: "working" },
      { text: "Филиал", value: "filial.name" },
      { text: "Дата создания", value: "createdDate" },
      { text: "Журнал операций", value: "transfer", sortable: false },
      { text: "Изменить", value: "actions", sortable: false },
    ],
    transferHeaders: [
      {
        text: "ID запроса",
        align: "start",
        sortable: false,
        value: "halykTransferRequest.id",
      },
      {
        text: "ID ответа",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Запрос Pharmcenter XML",
        value: "halykTransferRequest.signedOrder",
      },
      { text: "Ответ Халык XML", value: "response" },
      { text: "Результат ответа", value: "result" },
      { text: "Дата ответа", value: "responseDate" },
      { text: "С карты ID", value: "cardIdFrom" },
      { text: "На карту ID", value: "cardIdTo" },
      { text: "С пользователя", value: "halykTransferRequest.userFrom.login" },
      { text: "На пользователя", value: "halykTransferRequest.userTo.login" },
      { text: "Номер заказа", value: "halykTransferRequest.orderId" },
      { text: "Сумма", value: "amount" },
      { text: "Комиссия", value: "fee" },
    ],
    cards: [],
    editedIndex: -1,
    editedItem: {
      cardId: "",
      filial: {},
      filialId: 0,
    },
    defaultItem: {
      cardId: "",
      filial: {},
      filialId: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.GetFilials();
  },

  methods: {
    СopyText() {
      this.copyTextLoading = true;
      navigator.clipboard.writeText(this.formattedXml);

      setTimeout(() => (this.copyTextLoading = false), 200);
    },
    GetTransfersByCardId(item) {
      this.$store
        .dispatch("TRANSFERS_BY_CARDID", item.cardId)
        .then((response) => {
          this.transfers = response;
          this.dialogTransfers = true;
        })
        .catch(() => {});
    },
    GetFilials() {
      this.$store
        .dispatch("FILIALS")
        .then(() => {
          this.filials = this.$store.getters.FILIALS;
        })
        .catch(() => {});
    },

    initialize() {
      this.loading = true;
      this.$store
        .dispatch("HALYK_CARDS")
        .then((response) => {
          window.console.log("response", response);
          this.loading = false;
          this.cards = response;
          // this.totalUsers = 5000;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.cards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //   this.editedItem = JSON.parse(JSON.stringify(item));
      window.console.log(item, "editItem");
      window.console.log(this.editedItem, "editItem2");
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.cards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.cards.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        var edit = {
          index: this.cards[this.editedIndex],
          item: this.editedItem,
        };
        this.$store
          .dispatch("UPDATE_HALYK_CARD", this.editedItem)
          .then(() => {
            Object.assign(edit.index, edit.item);
            this.close();
          })
          .catch((error) => {
            window.console.log(error);
          });
      } else {
        this.cards.push(this.editedItem);
      }
      this.close();
    },

    FormatXML(xml) {
      var format = require("xml-formatter");
      var formattedXml = format(xml);
      this.dialogFomatXML = true;
      this.formattedXml = formattedXml;
    },
  },
};
</script>
