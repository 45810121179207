import axios from "axios";
import store from './../index'

export default {
    state: {
        cities: null,
        userCountryCities: null,
    },
    getters: {
        CITIES: state => {
            return state.cities;
        },
        USER_COUNTRY_CITIES: state =>{
            return state.userCountryCities;
        }
    },
    mutations: {
        SET_CITIES: (state, payload) => {
            state.cities = payload;
        },
        SET_USER_COUNTRY_CITIES: (state, payload) => {
            state.userCountryCities = payload;
        },
    },
    actions: {
        CITIES: (commit, payload) => {
            if (store.getters.CITIES == null)
                return new Promise((resolve, reject) => {
                    axios
                        .get(`/City/GetAll`, payload)
                        .then(({
                            data,
                            status
                        }) => {
                            if (status === 200) {
                                store.commit('SET_CITIES', data);
                                resolve(true);
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
        },
        USER_COUNTRY_CITIES: (commit, payload) => {
            if (store.getters.USER_COUNTRY_CITIES == null)
                return new Promise((resolve, reject) => {
                    axios
                        .get(`/City/GetByUserCountry`, payload)
                        .then(({
                            data,
                            status
                        }) => {
                            if (status === 200) {
                                store.commit('SET_USER_COUNTRY_CITIES', data);
                                resolve(true);
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
        }
    }
};