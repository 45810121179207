<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Выберите тип аптеки"
                  class="rounded-0"
                  :items="pharmTypes"
                  item-text="name"
                  item-value="id"
                  v-model="selectedPharmType"
                  outlined
                  dense
                  return-object
                  hide-details
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-for="(item, index) in items"
              :key="'it_' + item.growth + '_' + item.discount"
            >
              <v-col cols="5">
                <v-text-field
                  label="Прирост"
                  placeholder="Прирост"
                  outlined
                  class="rounded-0"
                  dense
                  hide-details=""
                  type="number"
                  v-model="item.growth"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  label="Скидка"
                  placeholder="Скидка"
                  outlined
                  class="rounded-0"
                  dense
                  hide-details=""
                  type="number"
                  v-model="item.discount"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn text x-small color="primary" @click="addItem()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn text x-small color="primary" @click="removeItem(index)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="green" small outlined tile @click="add()"
                  >Добавить</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DiscountConditions",
  data() {
    return {
      pharmTypes: [
        {
          id: 1,
          name: "Индивидуальные",
        },
        {
          id: 2,
          name: "Субдистры/опт",
        },
        {
          id: 3,
          name: "Сети без контракта",
        },
      ],

      items: [
        {
          growth: 0,
          discount: 0,
        },
      ],
    };
  },
  methods: {
    add() {
      let formatedItems = this.items.filter(
        (x) => x.growth != 0 || x.discount != 0
      );
      if (this.selectedPharmType == null || formatedItems.length == 0) return;

      formatedItems = formatedItems.map((x) => ({
        companyGoodsGroupId:
          this.$store.getters.SELECTED_COMPANY_GOODS_GROUP_ID,
        growth: x.growth,
        discount: x.discount,
        companyPharmTypeId: this.selectedPharmType.id,
      }));

      this.$store
        .dispatch("ADD_COMPANY_GOODS_GROUP_GROWTH_DISCOUNTS", {
          list: formatedItems,
        })
        .then((data) => {
          if (data.statusCode == 1) {
            let Success = {
              text: "Операция успешно выполнена!",
              timeout: 3000,
              color: "success",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Success);
          } else {
            let Error = {
              text: "Ошибка при выполнении операции!",
              timeout: 3000,
              color: "danger",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Error);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    addItem() {
      this.items.push({
        growth: 0,
        discount: 0,
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
  mounted() {},
};
</script>
