<template>
  <v-navigation-drawer
    clipped
    app
    :expand-on-hover="!drawer"
    v-model="drawer"
    :permanent="$vuetify.breakpoint.lgAndUp"
    @transitionend="temp = !temp"
  >
    <v-list dense>
      <template v-for="item in menu">
        <!--Шапка -->
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <v-subheader v-if="item.heading && (drawer || temp)">{{
              item.heading
            }}</v-subheader>
          </v-col>
          <!-- <v-col cols="6" class="text-center">
            <a href="#!" class="body-2">Редактировать</a>
          </v-col> -->
        </v-row>

        <!--Меню -->
        <v-list-group
          v-else-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon
          no-action
        >
          <!--Раскрыватель -->
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <!--Раскрывающиеся списки -->
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            link
            :to="child.link"
          >
            <!-- <template> -->
            <v-list-item-content>
              <v-list-item-title>{{ child.text }}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>-->
            <!-- </template> -->
          </v-list-item>
        </v-list-group>

        <!--Не раскрывающиеся списки -->
        <v-list-item v-else :key="item.text" link :to="item.link">
          <v-list-item-action>
            <v-badge
              v-if="item.badge"
              :content="item.badge"
              :value="item.badge"
              overlap
            ></v-badge>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <template v-if="!drawer && !temp && orderRoute">
        <span class="mx-3">БИН</span>
      </template>
      <OrderNavigation v-else-if="orderRoute"></OrderNavigation>
      <!-- <v-divider class="mb-2"></v-divider>
      <v-row align="center" justify="center">
        <div class="mx-4">
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon>mdi-tag-outline</v-icon>
                <template v-if="drawer || temp">v1.0.0</template>
              </v-btn>
            </template>

            <v-card class="mx-auto" max-width="300" tile>
              <v-list dense>
                <v-list-item-group>
                  <v-subheader>Документации</v-subheader>
                  <v-list-item
                    v-for="(documentation, i) in documentations"
                    :key="i.title"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="documentation.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="documentation.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="documentation.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider inset></v-divider>
                  <v-subheader>Версии</v-subheader>
                  <v-list-item v-for="(release, i) in releases" :key="i.title">
                    <v-list-item-icon>
                      <v-icon v-text="release.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="release.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="release.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
        </div>
        <div class="mx-4">
          <v-btn @click="$vuetify.theme.dark = !$vuetify.theme.dark" text fab>
            <v-icon v-if="$vuetify.theme.dark">mdi-brightness-4</v-icon>
            <v-icon v-else>mdi-brightness-5</v-icon>
          </v-btn>
        </div>
      </v-row> -->
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "Navigation",
  props: {},
  data() {
    return {
      dialog: false,
      expandFlag: true,
      timerInterval: "",
      menuFor: {
        Programmer: [
          { heading: "PROGRAMMER" },

          { icon: "mdi-home", text: "Главная", link: "/" },
          { icon: "mdi-account-clock", text: "Планировщик", link: "Scheduler" },
          {
            icon: "mdi-pill",
            text: "Потребность",
            link: "Order",
          },
          {
            id: "chat",
            icon: "mdi-chat",
            text: "Фарм-чат",
            link: "Chat",
            badge: this.$store.getters.COUNT_UNREAD_MESSAGES,
          },

          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Заказы",
            model: false,
            children: [
              {
                icon: "mdi-pill",
                text: "Потребность",
                link: "Order",
              },
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Архив",
                link: "ArchivePharmacy",
              },
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Скидки",
                link: "Discount",
              },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Справочник",
            model: false,
            children: [
              {
                icon: "mdi-city",
                text: "Города",
                link: "City",
              },
              {
                icon: "mdi-album",
                text: "Поставщики",
                link: "Distributor",
              },
              {
                icon: "mdi-pharmacy",
                text: "Товары",
                link: "Goods",
              },
              {
                icon: "mdi-google-analytics",
                text: "Аннотация",
                link: "Annotation",
              },
              {
                icon: "mdi-google-analytics",
                text: "Прайсы",
                link: "Price",
              },
              {
                icon: "mdi-city",
                text: "Пользователи",
                link: "User",
              },
            ],
          },
          // {
          //   icon: "mdi-chevron-up",
          //   "icon-alt": "mdi-chevron-down",
          //   text: "Отчеты",
          //   model: false,
          //   children: [
          //     {
          //       icon: "mdi-account-switch",
          //       text: "Ценовой анализ",
          //       link: "PriceAnalysisReport",
          //     },
          //   ],
          // },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Управление",
            model: false,
            children: [
              {
                icon: "mdi-account-switch",
                text: "Пользователи",
                link: "UserControl",
              },
              {
                icon: "mdi-domain",
                text: "Компании",
                link: "CompanyControl",
              },
              {
                icon: "mdi-domain",
                text: "Доступы",
                link: "AccessControl",
              },
              {
                icon: "mdi-domain",
                text: "Шаблоны доступов",
                link: "AccessTemplateControl",
              },
              {
                icon: "mdi-domain",
                text: "Реклама",
                link: "AdControl",
              },
              {
                icon: "mdi-domain",
                text: "Заказы",
                link: "OrderControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Скидки и Договоры",
                link: "ContractControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Адреса доставки",
                link: "ShippingAddressControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Карты халык банка",
                link: "HalykCardsControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Прайсы",
                link: "PriceControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Поставщики",
                link: "DistributorControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Hangfire",
                link: "Hangfire",
              },
              {
                icon: "mdi-google-analytics",
                text: "Журнал запросов",
                link: "apirequestcontrol",
              },
              {
                icon: "mdi-newspaper-variant-outline",
                text: "Новости",
                link: "Article",
              },
              {
                icon: "mdi-newspaper-variant-outline",
                text: "Контроллер",
                link: "UserAccessControl",
              },
            ],
          },
          //{ icon: "mdi-ab-testing", text: "Test Page", link: "Test" },
        ],
        Pharmacy: [
          // { heading: "LOGO" },

          { icon: "mdi-home", text: "Главная", link: "/" },
          { icon: "mdi-account-clock", text: "Планировщик", link: "Scheduler" },
          {
            icon: "mdi-pill",
            text: "Потребность",
            link: "Order",
          },
          {
            id: "chat",
            icon: "mdi-chat",
            text: "Фарм-чат",
            link: "Chat",
            badge: this.$store.getters.COUNT_UNREAD_MESSAGES,
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Заказы",
            model: false,
            children: [
              {
                icon: "mdi-pill",
                text: "Потребность",
                link: "Order",
              },
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Архив",
                link: "ArchivePharmacy",
              },
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Скидки",
                link: "Discount",
              },
              // { icon: "mdi-border-color", text: "Шаблоны", link: "Templates" },
              // { icon: "mdi-google-analytics", text: "Аналитика" },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Справочник",
            model: false,
            children: [
              {
                icon: "mdi-album",
                text: "Поставщики",
                link: "Distributor",
              },
              {
                icon: "mdi-pharmacy",
                text: "Товары",
                link: "Goods",
              },
              {
                icon: "mdi-google-analytics",
                text: "Аннотация",
                link: "Annotation",
              },
              {
                icon: "mdi-google-analytics",
                text: "Прайсы",
                link: "Price",
              },
              // {
              //   icon: "mdi-city",
              //   text: "Пользователи",
              //   link: "User",
              // },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Настройка",
            model: false,
            children: [
              {
                icon: "mdi-album",
                text: "Приоритеты",
                link: "FilialLine",
              },
            ],
          },
        ],
        Pharmacy_administrator: [],
        Filial: [
          { icon: "mdi-home", text: "Главная", link: "/" },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Заказы",
            model: false,
            children: [
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Архив",
                link: "ArchiveFilial",
              },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Справочник",
            model: false,
            children: [
              {
                icon: "mdi-google-analytics",
                text: "Прайсы",
                link: "Price",
              },
            ],
          },
        ],
        Filial_administrator: [],
        Producer: [
          { heading: "Производитель" },
          {
            icon: "mdi-format-list-numbered",
            text: "Аптеки",
            link: "MpPharms",
          },
        ],
        Producer_administrator: [
          { heading: "Производитель" },
          {
            icon: "mdi-format-list-numbered",
            text: "Позиции",
            link: "Positions",
          },
          { icon: "mdi-format-list-group", text: "Группы", link: "Groups" },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Скидочные системы",
            model: false,
            children: [
              {
                icon: "mdi-format-list-group",
                text: "Интеллект",
                link: "Intelligence",
              },
            ],
          },
        ],
        Manager: [
          { heading: "MANAGER" },

          { icon: "mdi-home", text: "Главная", link: "/" },
          { icon: "mdi-account-clock", text: "Планировщик", link: "Scheduler" },
          {
            icon: "mdi-pill",
            text: "Потребность",
            link: "Order",
          },
          {
            id: "chat",
            icon: "mdi-chat",
            text: "Фарм-чат",
            link: "Chat",
            badge: this.$store.getters.COUNT_UNREAD_MESSAGES,
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Заказы",
            model: false,
            children: [
              {
                icon: "mdi-pill",
                text: "Потребность",
                link: "Order",
              },
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Архив",
                link: "ArchivePharmacy",
              },
              {
                icon: "mdi-archive-arrow-down-outline",
                text: "Скидки",
                link: "Discount",
              },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Справочник",
            model: false,
            children: [
              {
                icon: "mdi-city",
                text: "Города",
                link: "City",
              },
              {
                icon: "mdi-album",
                text: "Поставщики",
                link: "Distributor",
              },
              {
                icon: "mdi-pharmacy",
                text: "Товары",
                link: "Goods",
              },
              {
                icon: "mdi-google-analytics",
                text: "Аннотация",
                link: "Annotation",
              },
              {
                icon: "mdi-google-analytics",
                text: "Прайсы",
                link: "Price",
              },
              {
                icon: "mdi-city",
                text: "Пользователи",
                link: "User",
              },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Управление",
            model: false,
            children: [
              {
                icon: "mdi-account-switch",
                text: "Пользователи",
                link: "UserControl",
              },
              {
                icon: "mdi-domain",
                text: "Компании",
                link: "CompanyControl",
              },
              {
                icon: "mdi-domain",
                text: "Шаблоны доступов",
                link: "AccessTemplateControl",
              },
              {
                icon: "mdi-domain",
                text: "Реклама",
                link: "AdControl",
              },
              {
                icon: "mdi-domain",
                text: "Заказы",
                link: "OrderControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Скидки и Договоры",
                link: "ContractControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Адреса доставки",
                link: "ShippingAddressControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Карты халык банка",
                link: "HalykCardsControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Прайсы",
                link: "PriceControl",
              },
              {
                icon: "mdi-google-analytics",
                text: "Поставщики",
                link: "DistributorControl",
              },
              {
                icon: "mdi-newspaper-variant-outline",
                text: "Новости",
                link: "Article",
              },
            ],
          },
        ],
        Accountant: [
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-chevron-down",
            text: "Управление",
            model: false,
            children: [
              {
                icon: "mdi-newspaper-variant-outline",
                text: "Контроллер",
                link: "UserAccessControl",
              },
            ],
          },
        ],
      },

      documentations: [
        {
          icon: "mdi-view-module",
          title: "Текущая версия",
          subtitle: "v2.0.0",
          link: "/",
        },
        {
          icon: "mdi-view-module",
          title: "Стабильная версия",
          subtitle: "v1.0.0",
          link: "/",
        },
      ],

      releases: [
        {
          icon: "mdi-view-module",
          title: "Текущая версия",
          subtitle: "v2.0.0",
          link: "/",
        },
      ],
    };
  },
  computed: {
    drawer: {
      get: function () {
        return this.$store.state.navDrawer;
      },
      set: function (newValue) {
        this.$store.state.navDrawer = newValue;
      },
    },
    userModules: {
      get: function () {
        return this.$store.getters.CURRENT_USER_MODULES;
      },
    },
    orderRoute() {
      if (this.$route.path == "/Order") {
        return true;
      } else {
        return false;
      }
    },
    countUnreadMessages: {
      get: function () {
        return this.$store.getters.COUNT_UNREAD_MESSAGES;
      },
    },
    menu: {
      get: function () {
        switch (this.$store.getters.USER.userRoleId) {
          case 1:
            return this.menuFor.Programmer;
          case 2:
          case 10:
          case 11:
            return this.menuFor.Pharmacy;
          case 3:
            return this.menuFor.Pharmacy_administrator;
          case 4:
            return this.menuFor.Filial;
          case 5:
            return this.menuFor.Filial_administrator;
          case 6:
            return this.menuFor.Producer;
          case 7:
            return this.menuFor.Producer_administrator;
          case 8:
            return this.menuFor.Manager;
          case 9:
            return this.menuFor.Accountant;
          default:
            return "";
        }
      },
    },
  },
  methods: {
    ChangeTitle(newCount) {
      const self = this;
      self.timerInterval = setInterval(function () {
        if (document.hasFocus()) {
          document.title = "Pharmcenter.kz";
          clearInterval(self.timerInterval);
        } else {
          document.title =
            document.title == "Pharmcenter.kz"
              ? newCount + " сообщение"
              : "Pharmcenter.kz";
        }
      }, 1000);
    },
  },
  created() {},
  watch: {
    countUnreadMessages(newCount) {
      this.menu.find((x) => x.id == "chat").badge = newCount;

      clearInterval(this.timerInterval);
      if (!document.hasFocus()) this.ChangeTitle(newCount);
    },
  },
};
</script>
