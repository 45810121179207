var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('br'),_c('h2',{staticClass:"title mb-2"},[_vm._v("Выберите БИН для настройки скидки и договоров")]),_c('v-chip-group',{attrs:{"column":"","active-class":"primary--text"},model:{value:(_vm.accessUserBINsSelection),callback:function ($$v) {_vm.accessUserBINsSelection=$$v},expression:"accessUserBINsSelection"}},_vm._l((_vm.accessUserBINs),function(accessUserBIN){return _c('v-chip',{key:accessUserBIN.bin,attrs:{"filter":"","outlined":""}},[_vm._v(" "+_vm._s(accessUserBIN.bin)+" "+_vm._s(accessUserBIN.companyBIN.description)+" ")])}),1)],1),(_vm.accessUserBINsSelection > -1)?_c('v-card-text',[_c('span',{staticClass:"text-h6 mr-10"},[_vm._v("Скидки")]),_c('span',{staticClass:"subheading"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Поставщики которые не указаны присвают скидки "),_c('code',[_vm._v("0%")]),_vm._v(" в заказах,")],1),_vm._v(" или "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":""}},'v-btn',attrs,false),on),[_vm._v("добавьте")])]}}],null,false,899401073),model:{value:(_vm.createDiscountMenu),callback:function ($$v) {_vm.createDiscountMenu=$$v},expression:"createDiscountMenu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-sheet',{staticClass:"pa-2 mr-1 mb-1",attrs:{"elevation":"2"}},[_c('v-subheader',[_c('v-autocomplete',{attrs:{"label":"Выберите поставщика","item-text":"name","item-value":"id","items":_vm.filials,"persistent-hint":"","return-object":"","clearable":"","error-messages":_vm.modelState['Filial']},model:{value:(_vm.discount.filial),callback:function ($$v) {_vm.$set(_vm.discount, "filial", $$v)},expression:"discount.filial"}})],1),_c('v-text-field',{attrs:{"label":"Скидка","value":_vm.discount.userDiscount,"prefix":"%","rules":_vm.discountRules,"required":"","error-messages":_vm.modelState['UserDiscount']},model:{value:(_vm.discount.userDiscount),callback:function ($$v) {_vm.$set(_vm.discount, "userDiscount", $$v)},expression:"discount.userDiscount"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","loading":_vm.loading,"text":""},on:{"click":function($event){_vm.createDiscountMenu = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"small":"","loading":_vm.loading,"text":""},on:{"click":function($event){return _vm.CreateDiscount()}}},[_vm._v("Добавьте")])],1)],1)],1),_vm._v(" новое "),_c('v-row',{staticClass:"pt-4"},_vm._l((_vm.discounts.filter(
          function (x) { return x.bin == _vm.accessUserBINs[_vm.accessUserBINsSelection].bin &&
            x.isContract == false; }
        )),function(discount,index){return _c('v-sheet',{key:index,staticClass:"pa-2 mr-1 mb-1",attrs:{"elevation":"4"}},[_c('v-subheader',[_vm._v(_vm._s(discount.filial.name)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","color":"warning","disabled":discount.isContract},on:{"click":function($event){return _vm.DeleteDiscount(discount)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1),_c('v-text-field',{attrs:{"label":"Скидка","value":discount.userDiscount,"prefix":"%","readonly":""}}),(discount.isContract)?_c('span',[_vm._v(" Наименование договора: "),_c('br'),_c('code',[_vm._v(" "+_vm._s(discount.distributorContractName)+" ")])]):_vm._e()],1)}),1)],1):_vm._e(),(_vm.accessUserBINsSelection > -1)?_c('v-card-text',[_c('span',{staticClass:"text-h6 mr-10"},[_vm._v("Договоры")]),_c('span',{staticClass:"subheading"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert-circle-outline")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":""}},'v-btn',attrs,false),on),[_vm._v("Выгрузить")])]}}],null,false,3728992219),model:{value:(_vm.createContractMenu),callback:function ($$v) {_vm.createContractMenu=$$v},expression:"createContractMenu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-sheet',{staticClass:"pa-2 mr-1 mb-1",attrs:{"elevation":"2"}},[_c('v-subheader',[_c('v-autocomplete',{attrs:{"label":"Выберите поставщика","item-text":"name","item-value":"id","items":_vm.filials,"persistent-hint":"","return-object":"","clearable":"","error-messages":_vm.modelState['Filial']},model:{value:(_vm.discount.filial),callback:function ($$v) {_vm.$set(_vm.discount, "filial", $$v)},expression:"discount.filial"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","loading":_vm.loading},on:{"click":function($event){_vm.createContractMenu = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"small":"","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.CreateContract()}}},[_vm._v("Выгрузить")])],1)],1)],1),_vm._v(" новый договор "),_c('v-row',{staticClass:"pt-4"},_vm._l((_vm.discounts.filter(
          function (x) { return x.bin == _vm.accessUserBINs[_vm.accessUserBINsSelection].bin &&
            x.isContract == true; }
        )),function(discount,index){return _c('v-sheet',{key:index,staticClass:"pa-2 mr-1 mb-1",attrs:{"elevation":"4"}},[_c('v-subheader',[_vm._v(_vm._s(discount.filial.name)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","color":"warning"},on:{"click":function($event){return _vm.DeleteDiscount(discount)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1),_c('v-text-field',{attrs:{"label":"Скидка","value":discount.userDiscount,"prefix":"%","readonly":""}}),(discount.isContract)?_c('span',[_vm._v(" Наименование договора: "),_c('br'),_c('code',[_vm._v(" "+_vm._s(discount.distributorContractName)+" ")])]):_vm._e(),_c('v-checkbox',{attrs:{"dense":"","disabled":discount.working,"label":"Рабочий договор"},on:{"click":function($event){return _vm.SetWorkingContract(discount)}},model:{value:(discount.working),callback:function ($$v) {_vm.$set(discount, "working", $$v)},expression:"discount.working"}})],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }