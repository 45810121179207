import axios from "axios";
import store from './../index'

export default {
  state: {
    reOrderDetail: {detailId: 0, orderId: 0},
    orderRightTab: "Новинки",
    orderLeftTab: "Товары",
    orderAnalogTab: "МНН",
    orderPriceLoading: false,
    orderDialog: {
      show: false,
      goodsQuantity: 1,
      price: {
        goodsId: 0,
        goods: {
          id: 0,
          fullName: ""
        },
        goodsPrice: 0,
        filialId: 0,
        filial: {
          name: ""
        },
        insertedDate: "",
        shelfLife: "",
        batch: "",
        isPromotion: false,
        promotionDescription: null,
        pack: 0,
        box: 0,
        multiplicity: 0,
        isExclusive: false,
        minOrder: 0,
        isntDiscount: true,
        stored: 0,
        distributorGoodsId: "",
        isInvalid: false
      }
    },
    orders: [],
    pharmacyPoints: [],
    archiveOrders: [],
    orderNavigation: 0,
    statuses: [],
    lastOrderInfo: null,
    activePoint: null,
    getOrderFromAPI: false,
  },
  getters: {
    GET_ORDER_FROM_API: state => {
      return state.getOrderFromAPI;
    },
    GET_PHARMACY_POINTS: state => {
      return state.pharmacyPoints;
    },
    RE_ORDER_DETAIL: state => {
      return state.reOrderDetail;
    },
    ORDER_RIGHT_TAB: state => {
      return state.orderRightTab;
    },
    ACTIVE_POINT: state => {
      return state.activePoint;
    },
    ORDER_LEFT_TAB: state => {
      return state.orderLeftTab;
    },
    ORDER_ANALOG_TAB: state => {
      return state.orderAnalogTab;
    },
    ORDER_PRICE_LOADING: state => {
      return state.orderPriceLoading;
    },
    ORDERS: state => {
      return state.orders.filter(
        x => x.orderDetails.length != 0
      );
    },
    ARCHIVE_ORDERS: state => {
      return state.archiveOrders.filter(
        x => x.orderDetails.length != 0
      );
    },
    ORDER_DIALOG: state => {
      return state.orderDialog
    },
    ORDER_NAVIGATION: state => {
      return state.orderNavigation;
    },
    STATUSES: state => {
      return state.statuses;
    },
    LAST_ORDER_INFO: state => {
      return state.lastOrderInfo;
    }
  },
  mutations: {
    SET_ORDER_FROM_API: (state, payload) => {
      state.getOrderFromAPI = payload;
    },
    SET_PHARMACY_POINTS: (state, payload) => {
      state.pharmacyPoints = payload;
    },
    SET_RE_ORDER_DETAIL: (state, payload) => {
      state.reOrderDetail = payload;
    },
    SET_ORDER_RIGHT_TAB: (state, payload) => {
      state.orderRightTab = payload;
    },
    SET_ACTIVE_POINT: (state, payload) => {
      state.activePoint = payload;
    },
    SET_ORDER_LEFT_TAB: (state, payload) => {
      state.orderLeftTab = payload;
    },
    SET_ORDER_ANALOG_TAB: (state, payload) => {
      state.orderAnalogTab = payload;
    },
    SET_LOADING_ORDER_PRICE: (state, payload) => {
      state.orderPriceLoading = payload;
    },
    SET_ORDERS: (state, payload) => {
      state.orders = payload;
    },
    SET_STATUSES: (state, payload) => {
      state.statuses = payload;
    },
    SET_ARCHIVE_ORDERS: (state, payload) => {
      state.archiveOrders = payload;
    },
    SET_NEW_ORDER: (state, payload) => {
      state.orders.push(payload);
    },
    SET_NEW_ORDERS: (state, payload) => {
      // state.orders = state.orders.concat(payload);
      state.orders.push(...payload);
    },
    SET_NEW_DETAIL: (state, payload) => {
      var orderId = payload[0].orderId;
      var orderDetails = payload;
      state.orders.find(x => x.id == orderId).orderDetails = orderDetails;
    },
    SET_UPDATE_DETAIL: (state, payload) => {
      var orderId = payload[0].orderId;
      var orderDetails = payload;
      state.orders.find(x => x.id == orderId).orderDetails = orderDetails;
    },
    SET_UPDATE_DETAILS: (state, payload) => {
      state.orders.find(
        x => x.id == payload.id
      ).orderDetails = payload.orderDetails

    },
    SET_DELETE_ORDER: (state, payload) => {
      state.orders = state.orders.filter(
        x => x.id != payload
      );
    },
    SET_CHANGED_ORDER: (state, payload) => {
      state.orders.find(
        x => x.id == payload.id
      ).orderDetails = payload.orderDetails

    },
    SET_SPLIT_DETAILS: (state, payload) => {
      var orderId = payload[0].orderId;
      let detailsId = payload.map(x => x.id);
      // state.orders.find(x => x.id == orderId).orderDetails = orderDetails;
      state.orders.find(x => x.id == orderId).orderDetails = state.orders.find(x => x.id == orderId).orderDetails.filter(x=> !detailsId.includes(x.id));
    },
    SET_DELETE_DETAILS: (state, payload) => {
      var orderId = payload[0].orderId;

      var orderDetails = payload;
      state.orders.find(x => x.id == orderId).orderDetails = orderDetails;
    },
    SET_MOVE_DETAILS_TO_ORDER: (state, payload) => {

      store.commit('SET_DELETE_DETAILS', payload.details);

      payload.details.forEach(function (detail) {
        detail.orderId = payload.toOrderId;
      })

      state.orders.filter(
        x => x.id == payload.toOrderId
      )[0].orderDetails.push(...payload.details)
    },
    SET_ORDER_DIALOG: (state, payload) => {
      state.orderDialog = payload;
    },
    SET_ORDER_NAVIGATION: (state, payload) => {
      state.orderNavigation = payload;
    },
    SET_LAST_ORDER_INFO: (state, payload) => {
      state.lastOrderInfo = payload;
    },
    SET_DELETE_DETAILS_FROM_STORAGE: (state, payload) => {
      var orderId = payload[0].orderId;
      let stateOrderDetails = state.orders.find(x=>x.id == orderId).orderDetails;
      if(stateOrderDetails.length == payload.length)
      {
        state.orders = state.orders.filter(
          x => x.id != orderId
        );
      }
      else 
      {
        let tempArr = stateOrderDetails.filter(
          (item) => !payload.map(x => x.id).includes(item.id)
        );

        state.orders.find(x=>x.id == orderId).orderDetails = tempArr;
      }
    },
  },
  actions: {
    
    CREATE_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/Create`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              store.commit('SET_NEW_ORDER', data.items);
              if(data.status != 6){
                let Success = {
                  text: 'Ваш заказ успешно создан с номером ' + data.items.id,
                  timeout: 5000,
                  color: 'success',
                  link: '#order' + data.items.id,
                  linkText: 'Перейти',
                  show: true
                }
                store.commit('SET_SNACKBAR', Success);
                resolve(true);
              }
              else{
                let Warning = {
                  text: 'Добавьте нагружаемый товар в заказ: ' + data.items.id,
                  timeout: 5000,
                  color: 'warning',
                  link: '#order' + data.id,
                  linkText: 'Перейти',
                  show: true
                }
                store.commit('SET_SNACKBAR', Warning);
                resolve(true);
              }
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    ORDERS: (commit, payload) => {
      store.commit('SET_ORDERS', []);
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/GetAllByContextAsyncAsNoTracking`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              store.commit('SET_ORDERS', data);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });
    },
    ORDERS_BY_USER: (commit, payload) => {
      store.commit('SET_ORDERS', []);
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/GetAllByUserIdAsyncAsNoTracking?userId=`+ payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              store.commit('SET_ORDERS', data);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })
      });

    },
    ALL_ORDERS: (commit, payload) => {
      store.commit('SET_ORDERS', []);
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/GetAllAsync`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              // store.commit('SET_ORDERS', data);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    STATUSES: (commit, payload) => {
      store.commit('SET_STATUSES', []);
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/GetStatuses`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    ARCHIVE_ORDERS: (commit, payload) => {
      store.commit('SET_ORDERS', []);
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/GetArchivesByContextAsync`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              // store.commit('SET_ORDERS', data);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    ARCHIVE_ORDERS_FILIAL: (commit, payload) => {
      store.commit('SET_ORDERS', []);
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/GetArchivesByFilialAsync`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              // store.commit('SET_ORDERS', data);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    CREATE_DETAIL: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/CreateDetail`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              if(data.type == 0){
                if (data.status == 0 || data.status == 2) {
                  store.commit('SET_NEW_DETAIL', data.items);
                  let Success = {
                    text: 'Ваш товар успешно добавлен к заказу с номером ' + data.items[0].orderId,
                    timeout: 5000,
                    color: 'success',
                    link: '#detail' + data.items[0].id,
                    linkText: 'Перейти'
                  }
                  store.commit('SET_SNACKBAR', Success);
                  resolve(true);
                }
                else {
                  let Warning = {
                    text: 'Товар не может быть добавлен к заказу(Акция) ',
                    timeout: 5000,
                    color: 'warning',
                    link: '',
                    linkText: '',
                    show: true
                  }
                  store.commit('SET_SNACKBAR', Warning);
                  resolve(true);
                }
              }
              else if(data.type == 1){
                if (data.status == 0 || data.status == 2) {
                  store.commit('SET_NEW_DETAIL', data.items);
                  let Success = {
                    text: 'Ваш товар успешно добавлен к заказу с номером ' + data.items[0].orderId,
                    timeout: 5000,
                    color: 'success',
                    link: '#detail' + data.items[0].id,
                    linkText: 'Перейти'
                  }
                  store.commit('SET_SNACKBAR', Success);
                  resolve(true);
                }
                else {
                  let Warning = {
                    text: 'Товар не может быть добавлен к заказу(Нагрузка) ',
                    timeout: 5000,
                    color: 'warning',
                    link: '',
                    linkText: '',
                    show: true
                  }
                  store.commit('SET_SNACKBAR', Warning);
                  resolve(true);
                }
              }
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    DELETE_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/Order/DeleteById?id=` + payload)
          .then(({
            status
          }) => {
            if (status === 200) {
              store.commit('SET_DELETE_ORDER', payload);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    DELETE_DETAILS: (commit, payload) => {
      let detailsId = payload.map(x => x.id);
      return new Promise((resolve, reject) => {
        axios
          .delete(`/Order/DeleteDetailsById`, {
            data: detailsId
          })
          .then(({
            data, status
          }) => {
            if (status === 200) {
              if (data.length > 0)
                store.commit('SET_DELETE_DETAILS', data);
              else
                store.commit('SET_DELETE_ORDER', payload[0].orderId);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    SPLIT_DETAILS: (commit, payload) => {
      let detailsId = payload.map(x => x.id);
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/SplitDetailsById`,
            detailsId
          )
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              store.commit('SET_SPLIT_DETAILS', payload);
              store.commit('SET_NEW_ORDER', data);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    UPDATE_DETAIL: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Order/UpdateDetail`,
            payload
          )
          .then(({
            status, data
          }) => {
            if (status === 200) {
              store.commit('SET_UPDATE_DETAIL', data.items);
              if(data.status != 6){
                let Success = {
                  text: 'Ваш заказ успешно изменен с номером ' + data.items[0].orderId,
                  timeout: 5000,
                  color: 'success',
                  link: '#order' + data.items[0].orderId,
                  linkText: 'Перейти',
                  show: true
                }
                store.commit('SET_SNACKBAR', Success);
                resolve(true);
              }
              else{
                let Warning = {
                  text: 'Добавьте нагружаемый товар в заказ: ' + data.items[0].orderId,
                  timeout: 5000,
                  color: 'warning',
                  link: '#order' + data.items[0].orderId,
                  linkText: 'Перейти',
                  show: true
                }
                store.commit('SET_SNACKBAR', Warning);
                resolve(true);
              }
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    MOVE_DETAILS_TO_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Order/MoveDetails?toOrderId=` + payload.toOrderId,
            payload.details.map(x => x.id),
          )
          .then(({
            status
          }) => {
            if (status === 200) {
              store.commit('SET_MOVE_DETAILS_TO_ORDER', payload);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    INITIALIZE_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/InitializeAsync?filialId=` + payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              window.console.log(data)
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    SEND_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/SendAsync`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    OPTIMIZED_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/Optimized`, payload)
          .then(({
            status
          }) => {

            if (status === 200) {
              let Success = {
                text: 'Отимизированный заказ выполнен. Выделенные позиции не найдены в прайсах.',
                timeout: 5000,
                color: 'success',
              }
              store.commit('SET_SNACKBAR', Success);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    ORDERS_GROUPED_BY: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/GetAllGroupedByAsync`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              // store.commit('SET_ORDERS', data);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/GetById?id=` + payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              store.commit('SET_UPDATE_DETAILS', data);
              resolve(data);
            }
            if (status === 204) {
              resolve(false);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    ORDER_IN_CHAT: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/GetById?id=` + payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              resolve(data);
            }
            if (status === 204) {
              resolve(false);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    CLONE_ORDER: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Order/Clone?existingId=` + payload)
          .then(({
            status
          }) => {

            if (status === 200) {
              let Success = {
                text: 'Заказ успешно создан',
                timeout: 5000,
                color: 'success',
                show: true
              }
              store.commit('SET_SNACKBAR', Success);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    UPDATE_DISCOUNTS_BY_USER_BIN_AND_ORDERID: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Order/UpdateDiscountsByBINAndOrderId?accessUserBIN=` + payload.accessUserBIN + `&orderId=` + payload.orderId,
            payload
          )
          .then(({
            status
          }) => {
            if (status === 200) {
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    GET_LAST_ORDER_INFO: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Order/GetLastOrderInfo?goodsId=` + payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              store.commit('SET_LAST_ORDER_INFO', data)
              resolve(data);
            }
            else{
              store.commit('SET_LAST_ORDER_INFO', null)
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },
    GET_RE_ORDER_DETAIL: (commit, payload) => {
      store.commit("SET_RE_ORDER_DETAIL", payload);
    },
    SET_DELETE_DETAILS_FROM_STORAGE: (commit, payload) => {
      store.commit("SET_DELETE_DETAILS_FROM_STORAGE", payload);
    },
    GET_PHARMACY_POINTS: (commit, payload) => {
      store.commit('SET_PHARMACY_POINTS', []);
      return new Promise((resolve, reject) => {
        axios
          .get(`/User/GetPharmacyPoints`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200) {
              store.commit('SET_PHARMACY_POINTS', data);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    },

  }
};