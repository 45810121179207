<template>
  <v-card :loading="loading">
    <v-card-title>
      <span>
        <code>Конфигурация выгрузки прайсов</code>
        {{ this.filial.name }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <!-- Конфигурация исходящего запроса -->
          <v-col>
            <span>Конфигурация исходящего запроса</span>
            <v-text-field
              label="[Url]"
              v-model="editPriceConfig.requestConfig.url"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[Urn]"
              v-model="editPriceConfig.requestConfig.urn"
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="[true, false]"
              v-model="editPriceConfig.requestConfig.isIndividualPrice"
              label="[IsIndividualPrice]"
              :rules="rules"
            ></v-select>
            <v-select
              :items="APIDataFormats"
              v-model="editPriceConfig.requestConfig.dataFormat"
              label="[DataFormat]"
              item-value="id"
              :rules="rules"
              item-text="format"
            ></v-select>
            <v-text-field
              label="[Encoding]"
              v-model="editPriceConfig.requestConfig.encoding"
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="APIRequestProtocols"
              v-model="editPriceConfig.requestConfig.requestProtocol"
              label="[RequestProtocol]"
              item-text="protocol"
              item-value="id"
              :rules="rules"
            ></v-select>
          </v-col>
          <!-- Конфигурация принятого ответа -->
          <v-col>
            <span>Конфигурация принятого ответа</span>
            <v-text-field
              label="[Encoding]"
              v-model="editPriceConfig.responseConfig.encoding"
              required
            ></v-text-field>
            <v-select
              :items="APIDataFormats"
              v-model="editPriceConfig.responseConfig.dataFormat"
              label="[DataFormat]"
              item-value="id"
              :rules="rules"
              item-text="format"
            ></v-select>
            <v-text-field
              label="[XPathLocalName]"
              v-model="editPriceConfig.responseConfig.xPathLocalName"
              required
            ></v-text-field>
            <v-text-field
              label="[DistributorGoodsIdParam]"
              v-model="editPriceConfig.responseConfig.distributorGoodsIdParam"
              required
            ></v-text-field>
            <v-text-field
              label="[DistributorGoodsNameParam]"
              v-model="editPriceConfig.responseConfig.distributorGoodsNameParam"
              required
            ></v-text-field>
            <v-text-field
              label="[DistributorProducerParam]"
              v-model="editPriceConfig.responseConfig.distributorProducerParam"
              required
            ></v-text-field>
            <v-text-field
              label="[DistributorCountryParam]"
              v-model="editPriceConfig.responseConfig.distributorCountryParam"
              required
            ></v-text-field>
            <v-text-field
              label="[GoodsPriceParam]"
              v-model="editPriceConfig.responseConfig.goodsPriceParam"
              required
            ></v-text-field>
            <v-text-field
              label="[ShelfLifeParam]"
              v-model="editPriceConfig.responseConfig.shelfLifeParam"
              required
            ></v-text-field>
            <v-text-field
              label="[ShelfLifeFormat]"
              v-model="editPriceConfig.responseConfig.shelfLifeFormat"
              required
            ></v-text-field>
            <v-text-field
              label="[StoredParam]"
              v-model="editPriceConfig.responseConfig.storedParam"
              required
            ></v-text-field>
            <v-text-field
              label="[BatchParam]"
              v-model="editPriceConfig.responseConfig.batchParam"
              required
            ></v-text-field>
            <v-text-field
              label="[PromoParam]"
              v-model="editPriceConfig.responseConfig.promoParam"
              required
            ></v-text-field>
            <v-text-field
              label="[PackParam]"
              v-model="editPriceConfig.responseConfig.packParam"
              required
            ></v-text-field>
            <v-text-field
              label="[BoxParam]"
              v-model="editPriceConfig.responseConfig.boxParam"
              required
            ></v-text-field>
            <v-text-field
              label="[MultiplicityParam]"
              v-model="editPriceConfig.responseConfig.multiplicityParam"
              required
            ></v-text-field>
            <v-text-field
              label="[IsExclusiveParam]"
              v-model="editPriceConfig.responseConfig.isExclusiveParam"
              required
            ></v-text-field>
            <v-text-field
              label="[MinOrderParam]"
              v-model="editPriceConfig.responseConfig.minOrderParam"
              required
            ></v-text-field>
            <v-text-field
              label="[IsntDiscountParam]"
              v-model="editPriceConfig.responseConfig.isntDiscountParam"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('on-price-config-dialog', false)"
        >Закрыть</v-btn
      >
      <v-btn color="primary" @click="UpdatePriceConfig()">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
  data: () => ({
    defaultPriceConfig: {
      requestConfig: {
        encoding: null,
        filial: null,
        filialId: null,
        isIndividualPrice: null,
        dataFormat: null,
        dataFormatId: null,
        requestProtocol: null,
        requestProtocolId: null,
        url: null,
        urn: null,
      },
      responseConfig: {
        batchParam: null,
        boxParam: null,
        dataFormat: null,
        dataFormatId: null,
        distributorGoodsIdParam: null,
        distributorGoodsNameParam: null,
        distributorProducerParam: null,
        distributorCountryParam: null,
        encoding: null,
        filial: null,
        filialId: null,
        goodsPriceParam: null,
        isExclusiveParam: null,
        isntDiscountParam: null,
        minOrderParam: null,
        multiplicityParam: null,
        packParam: null,
        promoParam: null,
        shelfLifeFormat: null,
        shelfLifeParam: null,
        storedParam: null,
        xPathLocalName: null,
      },
    },
    editPriceConfig: {
      requestConfig: {
        encoding: null,
        filial: null,
        filialId: null,
        isIndividualPrice: null,
        dataFormat: null,
        dataFormatId: null,
        requestProtocol: null,
        requestProtocolId: null,
        url: null,
        urn: null,
      },
      responseConfig: {
        batchParam: null,
        boxParam: null,
        dataFormat: null,
        dataFormatId: null,
        distributorGoodsIdParam: null,
        distributorGoodsNameParam: null,
        distributorProducerParam: null,
        distributorCountryParam: null,
        encoding: null,
        filial: null,
        filialId: null,
        goodsPriceParam: null,
        isExclusiveParam: null,
        isntDiscountParam: null,
        minOrderParam: null,
        multiplicityParam: null,
        packParam: null,
        promoParam: null,
        shelfLifeFormat: null,
        shelfLifeParam: null,
        storedParam: null,
        xPathLocalName: null,
      },
    },

    rules: [(value) => !!value || "Обязательное поле для заполнения"],
    loading: false,
  }),
  methods: {
    UpdatePriceConfig() {
      this.editPriceConfig.requestConfig.filialId = this.editPriceConfig.responseConfig.filialId = this.filial.id;

      var payload = {
        priceRequestConfigVM: this.editPriceConfig.requestConfig,
        priceResponseConfigVM: this.editPriceConfig.responseConfig,
      };

      this.$store
        .dispatch("UPDATE_PRICE_CONFIG", payload)
        .then(() => {
          this.$emit("on-price-config-dialog", false);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetPriceConfig() {
      this.loading = true;
      this.editPriceConfig = JSON.parse(
        JSON.stringify(this.defaultPriceConfig)
      );

      this.$store
        .dispatch("PRICE_CONFIG", this.filial.id)
        .then((response) => {
          if (response.requestConfig)
            this.editPriceConfig.requestConfig = response.requestConfig;

          if (response.responseConfig)
            this.editPriceConfig.responseConfig = response.responseConfig;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
  },
  async mounted() {},

  computed: {
    APIRequestTypes: {
      get: function () {
        return this.$store.getters.API_REQUEST_TYPES;
      },
    },
    APIRequestProtocols: {
      get: function () {
        return this.$store.getters.API_REQUEST_PROTOCOLS;
      },
    },
    APIDataFormats: {
      get: function () {
        return this.$store.getters.API_REQUEST_DATA_FORMATS;
      },
    },
  },
};
</script>

<style>
</style>