<template>
  <v-card class="mx-auto" :loading="loading.sendOrder">
    <v-card-title>
      <span class="subtitle-1">
        Номер заказа:
        <code>{{ order.id }}</code>
        {{ order.filial.name }}
        Дата создания заказа:
        {{ new Date(order.createdDate).toLocaleString() }}
      </span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-card-text
        >Выберите все варианты ниже и нажмите на кнопку отправить
        заказ</v-card-text
      >

      <v-divider class="mx-4"></v-divider>

      <!-- БИН -->
      <v-card-text>
        <span class="subheading mr-10">Выберите БИН</span>
        <span class="subheading">
          <v-icon color="warning">mdi-alert-circle-outline</v-icon> При выборе
          БИН поменяются скидки в текущем заказе.</span
        >

        <v-chip-group
          v-model="accessUserBINsSelection"
          column
          active-class="primary--text"
        >
          <v-chip
            :error-messages="modelState['AccessUserBIN']"
            @click.native="
              GetShippingAddresses();
              GetContracts();
              UpdateDiscountsByBINAndOrderId(accessUserBIN);
              UnloadClientBonus();
            "
            filter
            outlined
            v-for="accessUserBIN in accessUserBINs"
            :key="accessUserBIN.bin"
            :disabled="loading.shippingAddress"
            >{{ accessUserBIN.bin }}
            {{ accessUserBIN.companyBIN.description }}</v-chip
          >
        </v-chip-group>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <!-- адрес доставки -->
      <v-card-text v-if="show.shippingAddress">
        <span class="subheading">Выберите адрес доставки</span>

        <v-chip-group
          v-model="shippingAddressSelection"
          active-class="primary--text"
          mandatory
          column
        >
          <v-chip
            filter
            outlined
            v-for="address in shippingAddresses"
            :key="address.id"
            :disabled="loading.shippingAddress"
            >{{ address.distributorShippingAddressName }}</v-chip
          >
        </v-chip-group>
        <v-btn
          small
          @click="UnloadShippingAddresses()"
          :disabled="loading.shippingAddress"
          :loading="loading.shippingAddress"
          >Выгрузить</v-btn
        >
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <!-- почта -->
      <v-card-text v-if="show.emailToSendOrders">
        <span class="subheading">Выберите почту</span>

        <v-chip-group
          v-model="emailsToSendOrdersSelection"
          active-class="primary--text"
          mandatory
          column
        >
          <v-chip
            filter
            outlined
            v-for="emailToSendOrders in emailsToSendOrders"
            :key="emailToSendOrders.email"
            >{{ emailToSendOrders.email }}</v-chip
          >
        </v-chip-group>
        <v-menu
          v-model="createEmailToSendMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              :disabled="loading.emailToSendOrders"
              :loading="loading.emailToSendOrders"
              >Добавить</v-btn
            >
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="createEmailToSend"
                    :rules="emailToSendRules"
                    :counter="254"
                    maxlength="254"
                    label="Email"
                    required
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn small text @click="createEmailToSendMenu = false"
                >Отмена</v-btn
              >
              <v-btn small text @click="CreateEmailTosendOrders()"
                >Добавить</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <!-- договор -->
      <v-card-text v-if="show.contract">
        <span class="subheading">Выберите договор</span>
        <v-chip-group
          v-model="contractSelection"
          active-class="primary--text"
          mandatory
          column
        >
          <v-chip
            filter
            outlined
            v-for="contract in contracts"
            :key="contract.id"
            >{{ contract.distributorContractName }}</v-chip
          >
        </v-chip-group>
        <v-btn
          small
          @click="UnloadContracts()"
          :disabled="loading.contract"
          :loading="loading.contract"
          >Выгрузить</v-btn
        >
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <!-- дата и время доставки -->
      <v-card-text>
        <span class="subheading">Выберите дату и время доставки</span>
        <v-row>
          <v-col cols="12" lg="6">
            <v-menu
              ref="menuOfDeliveryDate"
              v-model="menuOfDeliveryDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Дата"
                  persistent-hint
                  prepend-icon="mdi-clock-time-eight-outline"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateOfDelivery"
                no-title
                @input="menuOfDeliveryDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <v-dialog
              ref="dialog"
              v-model="menuOfDeliveryTime"
              :return-value.sync="timeOfDelivery"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeOfDelivery"
                  label="Время"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuOfDeliveryTime"
                v-model="timeOfDelivery"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuOfDeliveryTime = false">
                  ОТМЕНА
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(timeOfDelivery)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <!-- комментария для поставщика -->
      <v-card-text>
        <span class="subheading">Комментария для поставщика</span>

        <v-textarea
          class="mx-2"
          label="Данное сообщение увидит обслуживающий Вас менеджер"
          rows="1"
          prepend-icon="mdi-comment"
          v-model="order.comment"
        ></v-textarea>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <!-- бонусы -->  
      <v-card-text v-if="availableBonus > 0">
        <span class="text-overline font-weight-bold">Бонусы клиента</span>
        <div class="subheading">Доступная бонусная сумма: <u class="green--text font-weight-bold">{{availableBonus}}</u></div>
        <div class="subheading">Сумма заказа: <u class="primary--text font-weight-bold">{{orderSum}}</u></div>
        <v-slider
            v-model="selectedClientBonus"
            class="align-center"
            :max="maxBonusSum"
            :min="0"
            hide-details
            dense
        >
           <template v-slot:append>
              <v-text-field
                append-icon="mdi-currency-usd"
                v-model="selectedClientBonus"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                
                dense
                outlined
              ></v-text-field>
            </template>
        </v-slider>
      </v-card-text>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="$emit('onCloseDialog')"
        :disabled="loading.sendOrder"
        color="primary"
        >Отмена</v-btn
      >
      <v-btn @click="SendOrder()" :disabled="loading.sendOrder" color="primary"
        >Отправить заказ</v-btn
      >
    </v-card-actions>
    <PayOrderDialog :order="payOrder" />
  </v-card>
</template>
<script>
export default {
  props: {
    order: Object,
  },
  data: (vm) => ({
    payOrder: {
      balance: 0,
      orderDetails: [{ quantity: 0, price: 0, discount: 0 }],
      filial: { name: "" },
    },

    createEmailToSendMenu: false,
    createEmailToSend: "",
    emailToSendRules: [
      (v) => !!v || "Заполните поле",
      (v) => /.+@.+\..+/.test(v) || "E-mail должен быть действительным",
    ],
    goodsPrices: [],
    quantityRules: (v) => v > 0 || "не должно быть меньше 1",
    modelState: {},

    loading: {
      orderSend: false,
      sendOrder: false,
      shippingAddress: false,
      contract: false,
      emailToSendOrders: false,
    },

    dialog: {
      orderSend: false,
    },

    show: {
      shippingAddress: false,
      email: false,
      contract: false,
      emailToSendOrders: false,
    },

    emailsToSendOrdersSelection: 0,
    emailsToSendOrders: [],

    accessUserBINsSelection: 0,
    accessUserBINs: [],

    shippingAddressSelection: 0,
    shippingAddresses: [],

    contractSelection: 0,
    contracts: [],

    dateOfDelivery: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.$options.filters.toDate(
      new Date().toISOString().substr(0, 10)
    ),
    menuOfDeliveryDate: false,
    menuOfDeliveryTime: false,
    timeOfDelivery: null,

    headers: [
      // { text: "", value: "id" },
      { text: "Название", value: "goods.fullName" },
      { text: "Форма", value: "goods.dosageForm.name" },
      { text: "Номер", value: "goods.number" },
      { text: "Кол-во", value: "quantity" },
      { text: "Цена /Мин.", value: "price" },
      { text: "Макс.розн.цена", value: "price2" },
      { text: "Сумма", value: "sum" },
      { text: "Скидка", value: "discount" },
      { text: "Срок годности", value: "shelfLife" },
    ],

    clientBonus: 0,
    selectedClientBonus: 0,
    availableBonus: 0,
    orderSum: 0,
    maxBonusSum: 0
  }),
  methods: {
    UpdateDiscountsByBINAndOrderId(setAccessUserBIN) {
      this.$store
        .dispatch("UPDATE_DISCOUNTS_BY_USER_BIN_AND_ORDERID", {
          accessUserBIN: setAccessUserBIN.bin,
          orderId: this.order.id,
        })
        .then(() => {
          this.$store.dispatch("ORDER", this.order.id);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    ParseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;
    },
    UnloadShippingAddresses() {
      this.shippingAddresses = [];
      this.loading.shippingAddress = true;
      let payload = {
        filialId: this.order.filialId,
        accessUserBIN: this.accessUserBINs[this.accessUserBINsSelection]?.bin,
        orderId: this.order.id,
      };
      if (payload.accessUserBIN) {
        this.$store
          .dispatch("UNLOAD_SHIPPING_ADDRESSES", payload)
          .then((response) => {
            this.loading.shippingAddress = false;

            this.show.shippingAddress = response.filialAbility;
            this.shippingAddresses = response.shippingAddresses;
          })
          .catch(() => {
            this.loading.shippingAddress = false;
          });
      }
    },
    GetShippingAddresses() {
      this.shippingAddresses = [];
      this.loading.shippingAddress = true;
      let payload = {
        filialId: this.order.filialId,
        accessUserBIN: this.accessUserBINs[this.accessUserBINsSelection]?.bin,
      };
      this.$store
        .dispatch("SHIPPING_ADDRESSES", payload)
        .then((response) => {
          this.loading.shippingAddress = false;

          this.show.shippingAddress = response.filialAbility;
          this.shippingAddresses = response.shippingAddresses;
          if (response.filialAbility && !response.shippingAddresses.length)
            this.UnloadShippingAddresses();
        })
        .finally(() => {
          this.loading.shippingAddress = false;
        });
    },
    UnloadContracts() {
      this.contracts = [];
      this.loading.contract = true;
      let payload = {
        filialId: this.order.filialId,
        accessUserBIN: this.accessUserBINs[this.accessUserBINsSelection]?.bin,
      };
      if (payload.accessUserBIN) {
        this.$store
          .dispatch("UNLOAD_CONTRACTS", payload)
          .then((response) => {
            this.loading.contract = false;

            this.show.contract = response.filialAbility;
            this.contracts = response.contracts;
          })
          .catch(() => {
            this.loading.contract = false;
          });
      }
    },
    GetContracts() {
      this.contracts = [];
      this.loading.contract = true;
      let payload = {
        filialId: this.order.filialId,
        accessUserBIN: this.accessUserBINs[this.accessUserBINsSelection]?.bin,
      };
      this.$store
        .dispatch("CONTRACTS", payload)
        .then((response) => {
          this.loading.contract = false;

          this.show.contract = response.filialAbility;
          this.contracts = response.contracts;
          if (response.filialAbility && !response.contracts.length)
            this.UnloadContracts();
        })
        .finally(() => {
          this.loading.contract = false;
        });
    },
    GetEmailsToSendOrders() {
      this.emailsToSendOrders = [];
      this.loading.emailToSendOrders = true;
      this.$store
        .dispatch("EMAILS_TO_SEND_ORDERS", this.order.filialId)
        .then((response) => {
          this.loading.emailToSendOrders = false;

          this.show.emailToSendOrders = response.filialAbility;
          this.emailsToSendOrders = response.emailsToSendOrders;
        })
        .catch(() => {});
    },
    InitializeOrderSend() {
      this.loading.orderSend = true;
      this.GetAccessUserBINs();
      this.loading.orderSend = false;
    },
    SendOrder() {
      if (!this.accessUserBINs[this.accessUserBINsSelection]?.bin) {
        let Snackbar = {
          text: "Выберите БИН для отправка заказа",
          timeout: 3000,
          color: "warning",
          show: true,
        };
        this.$store.commit("SET_SNACKBAR", Snackbar);
      }
      window.onbeforeunload = function () {
        return "Данные будут потеряны, если вы покинете страницу, вы уверены?";
      };
      this.loading.sendOrder = true;

      let payload = {
        orderId: this.order.id,
        distributorShippingAddressId: this.shippingAddresses[
          this.shippingAddressSelection
        ]?.distributorShippingAddressId,
        comment: this.order.comment,
        accessUserBIN: this.accessUserBINs[this.accessUserBINsSelection]?.bin,
        distributorContractId: this.contracts[this.contractSelection]
          ?.distributorContractId,
        emailToSend: this.emailsToSendOrders[this.emailsToSendOrdersSelection]
          ?.email,
        orderBonus: this.selectedClientBonus,
      };
      window.console.log('orderSend', payload);
      this.$store
        .dispatch("SEND_ORDER", payload)
        .then((response) => {
   
          var order = response.order;
          var orderIsPayable = response.orderIsPayable;
          this.loading.sendOrder = false;

          var Snackbar = {
            text: "",
            timeout: 7000,
            color: "",
            linkText: "",
            show: true,
          };

          switch (order.status) {
            // case "ОШИБКА":
            //   Snackbar.color = "error";
            //   Snackbar.text =
            //     "Ошибка отправки заказа. Пожалуйста свяжитесь с менеджерами Фармцентра. ";
            //   break;
            case "ОШИБКА":
              Snackbar.color = "error";
              Snackbar.text =
                "Ошибка. Ответ поставщика: " + order.filialOrderText;
              break;
            case "ИЗМЕНЕНЁН":
              Snackbar.color = "warning";
              Snackbar.text =
                "Заказ был изменён поставщиком. Посмотрите детали.";
              this.$emit("onCloseDialog");
              this.$store.commit("SET_CHANGED_ORDER", order);
              break;
            case "ОТМЕНЁН":
              Snackbar.color = "warning";
              Snackbar.text =
                "Заказ отменён поставщиком. Ответ поставщика: " +
                order.filialOrderText;
              break;
            case "ОТПРАВЛЕН":
              Snackbar.color = "success";
              Snackbar.text =
                "Ваш заказ отправлен.<br> Отправленнный заказ можете увидеть в архиве.";
              this.$emit("onCloseDialog");
              this.$store.commit("SET_DELETE_ORDER", payload.orderId);
              if (orderIsPayable) {
                this.payOrder = order;
                order.balance = response.balance;
                this.$store.commit("SET_PAY_ORDER_DIALOG", true);
              }
              break;
            case "ЗАБЛОКИРОВАН":
              Snackbar.color = "error";
              Snackbar.text =
                "Заказ заблокирован, обратитесь менеджерам Фармцентра";
              break;
            default:
              break;
          }
          this.$store.commit("SET_SNACKBAR", Snackbar);
          window.onbeforeunload = null;
        })
        .catch((error) => {
          window.console.log(error);
          this.loading.sendOrder = false;
          window.onbeforeunload = null;
          if (error.response?.data.errors) {
            this.modelState = error.response.data.errors;
          }
          this.error = true;
        });
    },
    GetAccessUserBINs() {
      this.loading.shippingAddress = true;
      this.$store
        .dispatch("ACCESS_USER_BINS_BY_CONTEXT")
        .then((response) => {
          if (response.length < 1) {
            let Snackbar = {
              text: "У вас нет активных БИН",
              timeout: 3000,
              color: "warning",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Snackbar);
          }
          this.accessUserBINs = response;
          this.accessUserBINsSelection = response.indexOf(
            response.find((x) => x.working == true)
          );
        
          this.GetShippingAddresses();

          this.GetEmailsToSendOrders();
          
          this.GetContracts();

          this.UnloadClientBonus();
        })
        .catch(() => {});
    },
    CreateEmailTosendOrders() {
      let payload = {
        filialId: this.order.filialId,
        email: this.createEmailToSend,
      };
      this.$store
        .dispatch("CREATE_EMAILS_TO_SEND_ORDERS", payload)
        .then(() => {
          this.createEmailToSendMenu = false;
          this.emailsToSendOrders.push(payload);
        })
        .catch(() => {
          this.loading.contract = false;
        });
    },
    UnloadClientBonus(){
      let payload = {
        filialId: this.order.filialId,
        accessUserBIN: this.accessUserBINs[this.accessUserBINsSelection]?.bin,
        orderId: this.order.id,
      };
      if (payload.accessUserBIN) {
         this.$store
          .dispatch("GET_CLIENT_BONUS_FROM_API", payload)
          .then((val) => {  
            var currentOrder = this.orders.find(x => x.id == this.order.id);
            var orderSumByDiscount = 0;
            currentOrder.orderDetails.forEach(element => {
                var elementSumByDiscount =  (
                      element.quantity * element.price - 
                      ((element.discount + element.discountPayment) * element.quantity * element.price) 
                      / 100
                  ).toFixed(2);
                orderSumByDiscount = parseFloat(orderSumByDiscount) + parseFloat(elementSumByDiscount);
            });
            this.clientBonus = val.clientBonus.bonus;
            this.orderSum = orderSumByDiscount;

            if(this.clientBonus < orderSumByDiscount){
                this.maxBonusSum = this.clientBonus
            }
            else{
                this.maxBonusSum = orderSumByDiscount;
            }
            
            this.selectedClientBonus = 0;
            this.availableBonus = val.clientBonus.bonus;
          })
          .catch(() => {
          });
      }
    }
  },
  async mounted() {
    this.InitializeOrderSend();
  },
  computed: {
    orders: {
      get: function () {
        return this.$store.getters.ORDERS;
      },
    },
  },
  watch: {
    dateOfDelivery() {
      this.dateFormatted = this.$options.filters.toDate(this.dateOfDelivery);
    },
    order() {
      this.InitializeOrderSend();
    },
    selectedClientBonus(val){
      this.availableBonus = this.clientBonus - val;
    }
  },
};
</script>
