<template>
  <span></span>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    GetExpiringTemplates(){
      this.$store
        .dispatch("GET_USER_EXPIRING_TEMPLATES")
        .then(()=>{})
        .catch((error)=> {
            window.console.log(error);
        })
    },
    ConnectAccessHub() {
      this.$store
        .dispatch("CONNECT_HUB")
        .then(() => {})
        .catch((error) => {
      
          window.console.error(error);
        });
    },
    SetCountUnreadMessages() {
      var countUnreadMessages = this.$store.getters.COUNT_UNREAD_MESSAGES + 1;
      this.$store.commit("SET_COUNT_UNREAD_MESSAGES", countUnreadMessages);
    },
    SetCountUnreadArticles(){
      if (this.$store.getters.COUNT_UNREAD_ARTICLES == 0){
        this.$store.commit("SET_COUNT_UNREAD_ARTICLES", 1);
        this.SetCountUnreadMessages();
      }
    },
    GetCountUnreadMessages() {
      this.$store
        .dispatch("COUNT_UNREAD_MESSAGES")
        .then()
        .catch((error) => {
          window.console.log(error);
        });
    },
    AddUnreadArticleCount() {
      this.$store
        .dispatch("ADD_UNREAD_ARTICLE_COUNT")
        .then()
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAds() {
      this.$store
        .dispatch("ADS")
        .then(() => {})
        .catch(() => {});
    },
    CurrentUserActions() {
      this.$store
        .dispatch("CURRENT_USER_ACTIONS")
        .then(() => {})
        .catch(() => {});
    },
  },
  created() {
    var expNoticeLoaded = this.$store.getters.GET_EXP_NOTICE;
    if (!expNoticeLoaded)
      this.GetExpiringTemplates();
    this.CurrentUserActions();
    this.GetCountUnreadMessages();
    this.AddUnreadArticleCount();
    this.ConnectAccessHub();
    this.$store.getters.HUB_CONNECTION.on("ReceivedSendToEveryone", this.SetCountUnreadArticles);
    this.$store.getters.HUB_CONNECTION.on("ReceivedToOne", this.SetCountUnreadMessages);
    this.GetAds();
  },
};
</script>
