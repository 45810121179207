<template>
  <iframe
    frameborder="0"
    style="overflow: hidden; height: 900px; width: 100%"
    :src="`${axiosBaseURL}/hangfire?access_token=${accToken}`"
  ></iframe>
</template>
<script>
export default {
  inject: ["theme"],
  data: () => ({
    axiosBaseURL: require("axios").default.defaults.baseURL,
    accToken: localStorage.getItem("PHC_accT"),
  }),
  async mounted() {},
  methods: {},
  created() {},
};
</script>
<style scoped></style>
