<template>
  <div>
    <v-card-text>
      <br />
      <h2 class="title mb-2">Выберите БИН для настройки скидки и договоров</h2>

      <v-chip-group
        v-model="accessUserBINsSelection"
        column
        active-class="primary--text"
      >
        <v-chip
          filter
          outlined
          v-for="accessUserBIN in accessUserBINs"
          :key="accessUserBIN.bin"
        >
          {{ accessUserBIN.bin }}
          {{ accessUserBIN.companyBIN.description }}
        </v-chip>
      </v-chip-group>
    </v-card-text>

    <!-- Discounts -->
    <v-card-text v-if="accessUserBINsSelection > -1">
      <span class="text-h6 mr-10">Скидки</span>
      <span class="subheading">
        <v-icon color="warning">mdi-alert-circle-outline</v-icon> Поставщики
        которые не указаны присвают скидки <code>0%</code> в заказах,</span
      >
      или
      <v-menu
        v-model="createDiscountMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" x-small>добавьте</v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-sheet elevation="2" class="pa-2 mr-1 mb-1">
                  <v-subheader
                    ><v-autocomplete
                      label="Выберите поставщика"
                      item-text="name"
                      item-value="id"
                      :items="filials"
                      persistent-hint
                      return-object
                      clearable
                      v-model="discount.filial"
                      :error-messages="modelState['Filial']"
                    ></v-autocomplete
                  ></v-subheader>
                  <v-text-field
                    label="Скидка"
                    :value="discount.userDiscount"
                    prefix="%"
                    :rules="discountRules"
                    required
                    v-model="discount.userDiscount"
                    :error-messages="modelState['UserDiscount']"
                  ></v-text-field>
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              small
              :loading="loading"
              text
              @click="createDiscountMenu = false"
              >Отмена</v-btn
            >
            <v-btn small :loading="loading" text @click="CreateDiscount()"
              >Добавьте</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
      новое

      <v-row class="pt-4">
        <v-sheet
          elevation="4"
          class="pa-2 mr-1 mb-1"
          v-for="(discount, index) in discounts.filter(
            (x) =>
              x.bin == accessUserBINs[accessUserBINsSelection].bin &&
              x.isContract == false
          )"
          :key="index"
        >
          <v-subheader
            >{{ discount.filial.name }}
            <v-btn
              class="ml-2"
              fab
              x-small
              color="warning"
              @click="DeleteDiscount(discount)"
              :disabled="discount.isContract"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn></v-subheader
          >

          <v-text-field
            label="Скидка"
            :value="discount.userDiscount"
            prefix="%"
            readonly
          ></v-text-field>
          <span v-if="discount.isContract">
            Наименование договора:
            <br />
            <code> {{ discount.distributorContractName }} </code>
          </span>
        </v-sheet>
      </v-row>
    </v-card-text>

    <!-- Contracts -->
    <v-card-text v-if="accessUserBINsSelection > -1">
      <span class="text-h6 mr-10">Договоры</span>
      <span class="subheading">
        <v-icon color="warning">mdi-alert-circle-outline</v-icon> </span
      >
      
      <v-menu
        v-model="createContractMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" x-small>Выгрузить</v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-sheet elevation="2" class="pa-2 mr-1 mb-1">
                  <v-subheader
                    ><v-autocomplete
                      label="Выберите поставщика"
                      item-text="name"
                      item-value="id"
                      :items="filials"
                      persistent-hint
                      return-object
                      clearable
                      v-model="discount.filial"
                      :error-messages="modelState['Filial']"
                    ></v-autocomplete
                  ></v-subheader>
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              small
              text
              :loading="loading"
              @click="createContractMenu = false"
              >Отмена</v-btn
            >
            <v-btn small text :loading="loading" @click="CreateContract()"
              >Выгрузить</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
      новый договор

      <v-row class="pt-4">
        <v-sheet
          elevation="4"
          class="pa-2 mr-1 mb-1"
          v-for="(discount, index) in discounts.filter(
            (x) =>
              x.bin == accessUserBINs[accessUserBINsSelection].bin &&
              x.isContract == true
          )"
          :key="index"
        >
          <v-subheader
            >{{ discount.filial.name }}
            <v-btn
              class="ml-2"
              fab
              x-small
              color="warning"
              @click="DeleteDiscount(discount)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn></v-subheader
          >

          <v-text-field
            label="Скидка"
            :value="discount.userDiscount"
            prefix="%"
            readonly
          ></v-text-field>
          <span v-if="discount.isContract">
            Наименование договора:
            <br />
            <code> {{ discount.distributorContractName }} </code>
          </span>
          <v-checkbox
            dense
            :disabled="discount.working"
            v-model="discount.working"
            :label="`Рабочий договор`"
            @click="SetWorkingContract(discount)"
          ></v-checkbox>
        </v-sheet>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data: () => ({
    discountRules: [(v) => !!v || "Скидка обязательно"],
    modelState: {},
    discounts: [],
    accessUserBINs: [],
    accessUserBINsSelection: 0,
    createDiscountMenu: false,
    createContractMenu: false,
    discount: {
      filial: null,
      userDiscount: null,
      bin: null,
    },
    filials: [],
    filial: {},
    loading: false,
  }),
  methods: {
    GetDiscounts() {
      this.$store
        .dispatch("DISCOUNTS")
        .then((response) => {
          this.discounts = response;
        })
        .catch(() => {});
    },
    GetAccessUserBINs() {
      this.$store
        .dispatch("ACCESS_USER_BINS_BY_CONTEXT")
        .then((response) => {
          this.accessUserBINs = response;

          this.accessUserBINsSelection = response.indexOf(
            response.find((x) => x.working == true)
          );
        })
        .catch(() => {});
    },
    GetDistributors() {
      this.$store.dispatch("FILIALS").then(() => {
        this.filials = this.$store.getters.FILIALS;
      });
    },
    CreateDiscount() {
      this.loading = true;
      this.modelState = {};
      this.discount.bin = this.accessUserBINs[this.accessUserBINsSelection]?.bin;

      this.$store
        .dispatch("CREATE_DISCOUNT", this.discount)
        .then((response) => {
          this.discounts.push(response);
          this.createDiscountMenu = false;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.modelState = error.response.data.errors;
          }
          this.loading = false;
        });
    },
    CreateContract() {
      this.loading = true;
      this.modelState = {};
      let payload = {
        filialId: this.discount.filial.id,
        accessUserBIN: (this.discount.bin = this.accessUserBINs[
          this.accessUserBINsSelection
        ]?.bin),
      };

      this.$store
        .dispatch("UNLOAD_CONTRACTS", payload)
        .then(() => {
          this.createContractMenu = false;
          this.loading = false;
          this.GetDiscounts();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.modelState = error.response.data.errors;
          }
          this.loading = false;
        });
    },
    SetWorkingContract(discount) {
      this.loading = true;
      this.modelState = {};

      this.$store
        .dispatch("SET_WORKING_CONTRACT", discount)
        .then(() => {
          this.loading = false;
          this.GetDiscounts();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    DeleteDiscount(discount) {
      discount.bin = this.accessUserBINs[this.accessUserBINsSelection]?.bin;
      this.$store
        .dispatch("DELETE_DISCOUNT", discount)
        .then(() => {
          this.GetDiscounts();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    UpdateWorkingAccessUserBIN(setAccessUserBIN) {
      this.$store
        .dispatch("UPDATE_WORKING_ACCESS_USER_BIN", setAccessUserBIN?.bin)
        .then(() => {
          this.accessUserBINs.forEach(function (accessUserBIN) {
            if (accessUserBIN.bin == setAccessUserBIN.bin)
              accessUserBIN.working = true;
            else accessUserBIN.working = false;
          });
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  mounted() {
    this.GetAccessUserBINs();
    this.GetDiscounts();
    this.GetDistributors();
  },
  computed: {
    accessUserBINWorking: {
      get: function () {
        return this.accessUserBINs.find((x) => x.working == true);
      },
      set: function (setAccessUserBIN) {
        this.UpdateWorkingAccessUserBIN(setAccessUserBIN);
      },
    },
    accessUserBINWorkingDescription: {
      get: function () {
        return this.accessUserBINWorking?.companyBIN?.description;
      },
    },
  },
};
</script>

<style>
</style>