import axios from "axios";
import store from './../index'

export default {
  state: {
    analyzes: null,
  },
  getters: {
    ANALYZES: state => {
      return state.analyzes;
    },
  },
  mutations: {
    SET_ANALYZES: (state, payload) => {
      state.analyzes = payload;
    },
  },
  actions: {
    ANALYZES: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Report/GetPriceAnalysisAsync`, payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              store.commit('SET_ANALYZES', data);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    DOWNLOAD_EXCEL_FILE: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Report/DownloadExcelFileAsync`, payload, {
            responseType: 'blob'
          })
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              let url = window.URL.createObjectURL(new Blob([data]));
              let link = document.createElement('a');
              link.href = url;

              let today = new Date();
              let month = today.getMonth() + 1;
              let day = today.getDate();
              let hours = today.getHours();
              let minutes = today.getMinutes();
              let seconds = today.getSeconds();
              month = month < 10 ? '0' + month : month;
              day = day < 10 ? '0' + day : day;
              hours = hours < 10 ? '0' + hours : hours;
              minutes = minutes < 10 ? '0' + minutes : minutes;
              seconds = seconds < 10 ? '0' + seconds : seconds;

              let dateAndTime = day + '-' + month + '-' + today.getFullYear() + '_' +
                hours + '-' + minutes + '-' + seconds;

              let filename = 'Ценевой_анализ_' + dateAndTime + '.xlsx';

              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              
              resolve(true);
            }
            if (status === 204) {
              resolve(false);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};