import axios from "axios";
import mrcAxios from "../../config/mrcAxios"
import store from './../index'

export default {
    state: {
        companyCorporations: [],
        corporations: []
    },
    getters: {
        COMPANY_CORPORATIONS: state => {
            return state.companyCorporations;
        },
        CORPORATIONS: state => {
            return state.corporations;
        },
    },
    mutations: {
        SET_COMPANY_CORPORATIONS: (state, payload) => {
            state.companyCorporations = payload;
        },
        SET_CORPORATIONS: (state, payload) => {
            state.corporations = payload;
        }
    },
    actions: {
        GET_COMPANY_CORPORATIONS: () => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/CompanyGoodsCorporation/Get`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_CORPORATIONS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GET_CORPORATIONS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Corporation/GetByIdList?` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_CORPORATIONS', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};