import Vue from 'vue'
import App from './App.vue'
import router from './config/router'
import store from './store'
import vuetify from './config/vuetify'
import {
  setupComponents
} from './config/components'
import axios from './config/axios'
import signalr from './config/signalr'
import filters from './config/filters'
import jsonExcel from "vue-json-excel";
import './registerServiceWorker'


Vue.component("downloadExcel", jsonExcel);
Vue.config.productionTip = false

setupComponents(Vue)

new Vue({
  router,
  store,
  signalr,
  vuetify,
  filters,
  axios,
  render: h => h(App)
}).$mount('#app')