import axios from "axios";
import store from './../index'

export default {
    state: {
        countunreadArticles: 0
    },
    getters: {
        COUNT_UNREAD_ARTICLES: state => {
            return state.countunreadArticles;
        }
    },
    mutations: {
        SET_COUNT_UNREAD_ARTICLES: (state, payload) => {
            state.countunreadArticles = payload;
        }
    },
    actions: {
        CREATE_ARTICLE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Article/Create`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            let Success = {
                                text: 'Новость успешно создана',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            new Promise( async () => {
                                await store.getters.HUB_CONNECTION.invoke("SendArticleToEveryone");
                            })
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        ALL_ARTICLE_PAGING: (commit, payload) => {
            window.console.log('ALL_ARTICLE_PAGING');
            let take = `take=${payload.Take}`;
            let skip = `skip=${payload.Skip}`;
            let search = `search=${payload.Search}`
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Article/GetAllPagingAsync?${take}&${skip}&${search}`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        ALL_COUNT_ARTICLE: () => {
            window.console.log('ALL_COUNT_ARTICLE');
            return new Promise((resolve, reject) => {
                axios
                    .get("/Article/GetCount")
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        EDIT_ARTICLE: (commit, payload) => {
            window.console.log('EDIT_ARTICLE');
            return new Promise((resolve, reject) => {
                axios
                    .put(`Article/Update`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                        reject(error);
                    })
            })
        },
        DELETE_ARTICLE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`Article/DeleteById?id=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            let Success = {
                                text: 'Новость успешно удалена',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }

    }
}