import axios from "axios";
import store from './../index'

// ADS
// Id	Location
// 0	TOP_LEFT
// 1	TOP_CENTER
// 2	TOP_RIGHT
// 3	NEW_GOODS
// 4	ALTERNATIVE_GOODS
// 5	STICKERS

export default {
    state: {
        ads: null
    },
    getters: {
        ADS: state => {
            return state.ads;
        },
        AD_ITEM_CONTENT_DIMENSIONS: () => (adId, col, isVertical) => {
            if (adId == 3) {
                if (col == 4) {
                    return {
                        width: 260,
                        height: isVertical ? 525 : 250,
                    };
                }
                if (col == 6) {
                    return {
                        width: 400,
                        height: isVertical ? 525 : 250,
                    };
                }
                if (col == 8) {
                    return {
                        width: 545,
                        height: isVertical ? 525 : 250,
                    };
                }
                if (col == 12) {
                    return {
                        width: 825,
                        height: isVertical ? 525 : 250,
                    };
                }
            }
            if (adId == 0 || adId == 2) {
                if (col == 4) {
                    return {
                        width: 152,
                        height: isVertical ? 75 : 250,
                    };
                }
                if (col == 6) {
                    return {
                        width: 240,
                        height: isVertical ? 75 : 250,
                    };
                }
                if (col == 12) {
                    return {
                        width: 505,
                        height: isVertical ? 75 : 250,
                    };
                }
            }

            if (adId == 4) {
                if (col == 6) {
                    return {
                        width: 390,
                        height: isVertical ? 140 : 250,
                    };
                }
                if (col == 12) {
                    return {
                        width: 805,
                        height: isVertical ? 140 : 250,
                    };
                }
            }
            if (adId == 5) {
                if (col == 12) {
                    return {
                        width: 250,
                        height: isVertical ? 50 : 50,
                    };
                }
            }
            
            if (adId == 6 || adId == 7 || adId == 8) {
                if (col == 12) {
                    return {
                        width: 250,
                        height: isVertical ? 50 : 50,
                    };
                }
            }
        },
        ADS_CONSTRUCTOR: () => (adId) => {
            return {
                adItems: [
                    // 12, 12
                    {
                        adIdContains: [3],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 12,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 12,
                            frontOrder: 2,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        ],
                        goodsId: null,
                        goods: null,
                    },

                    // 4, 4, 4, 4, 4, 4
                    {
                        adIdContains: [3],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 4,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 2,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 3,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 4,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 5,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 6,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        ],
                        goodsId: null,
                        goods: null,
                    },

                    // 4, 4, 4
                    {
                        adIdContains: [0, 2, 3],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 4,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 2,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 3,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        ],
                        goodsId: null,
                        goods: null,
                    },

                    // 6, 6
                    {
                        adIdContains: [0, 2, 3, 4],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 6,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 6,
                            frontOrder: 2,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        ],
                        goodsId: null,
                        goods: null,
                    },

                    // 12
                    {
                        adIdContains: [0, 2, 3, 4, 5, 6, 7, 8],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 12,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },],
                        goodsId: null,
                        goods: null,
                    },

                    // 8, 4
                    {
                        adIdContains: [3],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 8,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 4,
                            frontOrder: 2,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        ],
                        goodsId: null,
                        goods: null,
                    },

                    // 4, 8
                    {
                        adIdContains: [3],
                        adId: adId,
                        adItemContents: [{
                            frontColumn: 4,
                            frontOrder: 1,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        {
                            frontColumn: 8,
                            frontOrder: 2,
                            dateStart: "",
                            dateFinish: "",
                            description: "",
                            tooltip: "",
                            sourceLink: "",
                            adItemContentOpens: [],
                        },
                        ],
                        goodsId: null,
                        goods: null,
                    },
                ],
            };
        }
    },
    mutations: {
        SET_ADS: (state, payload) => {
            state.ads = payload;
        },
        SET_NEW_AD_ITEM: (state, payload) => {
            state.ads.find(x => x.id == payload.adId).adItems.push(payload);
        },
        SET_AD_ITEM: (state, payload) => {
            var adItems = state.ads.find(x => x.id == payload.adId).adItems;
            var foundIndex = adItems.findIndex(x => x.id == payload.id);
            adItems[foundIndex] = payload;
        },
        SET_DELETE_AD_ITEM: (state, payload) => {
            state.ads.find(x => x.id == payload.adId).adItems = state.ads.find(x => x.id == payload.adId).adItems.filter(
                x => x.id != payload.id
            );
        },
    },
    actions: {
        ADS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Ad/GetAll`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_ADS', data)
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_AD_ITEM_CONTENT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Ad/UpdateAdItemContent`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        CREATE_AD_ITEM: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Ad/CreateAdItem`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            window.console.log(data)
                            store.commit('SET_NEW_AD_ITEM', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        AD_ITEM_CONTENT_OPEN_TYPES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Ad/GetAdItemContentOpenTypes`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });

        },
        UPDATE_AD_ITEM: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Ad/UpdateAdItem`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_AD_ITEM', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        DELETE_AD_ITEM: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Ad/DeltetAdItemById?id=` + payload.id)
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_DELETE_AD_ITEM', payload);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};