<template>
  <v-dialog
    class="elevation-20"
    transition="scale-transition"
    max-width="450px"
    persistent
    scrollable
    v-model="payOrderInvoiceDialog"
  >
    <v-card class="mx-auto" max-width="450" :loading="payInvoiceLoading">
      <v-toolbar dense>
        <v-btn text color="success" icon @click="payOrderInvoiceDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Информация по оплате</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn @click="payOrderInvoiceDialog = false" text color="success"
            >Закрыть</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title> </v-card-title>
      <v-card-text>
        <div class="overline">Сумма: {{ transferResponse.amount }} т</div>
        <div class="overline">Комиссия: {{ transferResponse.fee }} т</div>
        <div class="overline">Код оплаты: {{ transferResponse.reference }}</div>
        <div class="overline">
          Дата: {{ new Date(transferResponse.actionTime).toLocaleString() }}
        </div>
        <div class="overline">Оплачено с карты: {{ transferResponse.cardHashFrom }}</div>
      </v-card-text>

      <v-card-actions>
        <v-sheet class="pa-1 success text-lg-h6 white--text" width="100%">
          <center>Платеж успешно совершен</center>
        </v-sheet>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    transferResponse: Object,
  },
  data: () => ({
    payInvoiceLoading: false,
  }),
  computed: {
    payOrderInvoiceDialog: {
      get: function () {
        return this.$store.getters.PAY_ORDER_INVOICE_DIALOG;
      },
      set: function () {
        this.$store.commit("SET_PAY_ORDER_INVOICE_DIALOG", false);
      },
    },
  },
  methods: {},
};
</script>

<style></style>
