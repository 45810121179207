<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-2 font-weight-medium">
              <v-icon>mdi-list-box-outline</v-icon>
              Список групп позиций
            </span>
          </v-card-title>
          <v-card-text>
            <PositionList />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PositionList from "../../positions/List.vue";
export default {
  name: "Intelligence",
  components: {
    PositionList,
  },
  data() {
    return {
    };
  },
};
</script>