<template>
  <v-row class="pa-4">
    <v-chip-group
      v-model="orderNav"
      column
      style="position: -webkit-sticky; position: sticky; top: 10px; z-index: 1"
      class="mx-2"
    >
      <v-chip small color="primary" filter>
        Все заказы ({{ orders.length }})
      </v-chip>
      <v-chip
        small
        color="primary"
        filter
        v-for="order in orders"
        :key="order.id"
      >
        <code>{{ order.id }}</code>
        {{ order.filial.name }}
      </v-chip>
    </v-chip-group>

    <v-col cols="12">
      <!-- orders -->
      <v-card
        class="mb-5"
        v-for="item in ordersNav"
        :key="item.id"
        :id="`order${item.id}`"
        elevation="5"
      >
        <!-- header -->
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="9" class="text-truncate">
                <v-list-item>
                  <v-list-item-avatar> </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Номер заказа:
                      <code class="subtitle-2">{{ item.id }}</code>
                      Товаров:
                      <code class="subtitle-2">{{
                        item.orderDetails.length
                      }}</code>
                      Дата создания:
                      <code class="subtitle-2">
                        {{
                          new Date(item.createdDate).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}
                      </code>
                      Тип оплаты:
                      <code>
                        {{
                          paymentTypes.find(
                            (x) => x.id == item.orderDetails[0].paymentType
                          ) != null
                            ? paymentTypes.find(
                                (x) => x.id == item.orderDetails[0].paymentType
                              ).type
                            : ""
                        }}
                      </code>
                    </v-list-item-subtitle>
                    <v-list-item-title>{{
                      item.filial.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3" class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="
                        currentOrder = item;
                        dialog.orderFullScreen = true;
                      "
                    >
                      <v-icon>mdi-fullscreen</v-icon>
                    </v-btn>
                  </template>
                  <span>Увидеть в полном экране</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="
                        currentOrder = item;
                        dialog.shareOnChat = true;
                      "
                    >
                      <v-icon>mdi-share-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Поделиться в чате</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- body -->
        <v-row>
          <!-- details operation -->
          <v-col cols="1" class="text-right">
            <v-card
              elevation="0"
              style="position: -webkit-sticky; position: sticky; top: 60px"
              class="pt-4"
            >
              <div class="mb-1">
                <v-menu top offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-btn v-on="{ ...onTooltip, ...onMenu }" fab x-small>
                          <v-icon>mdi-content-cut</v-icon>
                        </v-btn>
                      </template>
                      <span>Переместить в хранилище</span>
                    </v-tooltip>
                  </template>
                  <v-list dense max-width="230px">
                    <v-subheader v-if="storages.length == 0"
                      >У вас нет активных хранилище</v-subheader
                    >
                    <template v-for="(accessStorage, index) in storages">
                      <v-divider :key="index" v-if="index !== 0"></v-divider>
                      <v-list-item :key="-index" @click="0">
                        <v-list-item-content
                          @click="
                            MoveDetailsToStorage(
                              item.selectedDetails,
                              accessStorage.storage.id
                            )
                          "
                        >
                          <code class="subtitle-2">{{
                            accessStorage.storage.name
                          }}</code>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>
              <div class="mb-1">
                <v-menu top offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-btn v-on="{ ...onTooltip, ...onMenu }" fab x-small>
                          <v-icon>mdi-box-cutter</v-icon>
                        </v-btn>
                      </template>
                      <span>Переместить к заказам у того же поставщика</span>
                    </v-tooltip>
                  </template>
                  <v-list dense two-line max-width="200px">
                    <template
                      v-if="
                        orders.filter(
                          (x) => x.filialId == item.filialId && x.id !== item.id
                        ).length < 1
                      "
                    >
                      <v-subheader>Нет других заказов у поставщика</v-subheader>
                    </template>
                    <template
                      v-for="(orderItem, index) in orders.filter(
                        (x) => x.filialId == item.filialId && x.id !== item.id
                      )"
                    >
                      <v-divider :key="index" v-if="index !== 0"></v-divider>
                      <v-list-item :key="-index" @click="0">
                        <v-list-item-content
                          @click="
                            MoveDetailsToOrder(
                              item.selectedDetails,
                              orderItem.id
                            )
                          "
                        >
                          <v-list-item-title>
                            К заказу
                            <code class="subtitle-2">{{ orderItem.id }}</code>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-html="orderItem.createdDate"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>
              <div class="mb-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      @click="SplitDetails(item.selectedDetails)"
                    >
                      <v-icon>mdi-call-split</v-icon>
                    </v-btn>
                  </template>
                  <span>Разделить заказ</span>
                </v-tooltip>
              </div>
              <div class="mb-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      @click="DeleteDetails(item.selectedDetails)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить позиции</span>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>

          <!-- details -->
          <v-col cols="10">
            <v-data-table
              filterable
              v-model="item.selectedDetails"
              :headers="headers"
              :items="item.orderDetails"
              dense
              show-select
              item-key="id"
              :items-per-page="100"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.id="{ item }">
                <v-chip>
                  {{
                    item.orderDetails
                      .map(function(x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </v-chip>
              </template>

              <!-- TODO переписать  -->
              <template v-slot:item.quantity="props">
                <span style="float: left">
                  {{ props.item.quantity }}
                </span>
                <v-edit-dialog
                  :return-value.sync="props.item.quantity"
                  large
                  lazy
                  persistent
                  @save="SaveQuantity(props.item)"
                  @cancel="CancelQuantity"
                  @open="OpenQuantity"
                  @close="CloseQuantity"
                  saveText="Сохранить"
                  cancelText="Отмена"
                  v-if="
                    props.item.isMainWorkloadGroup != false ||
                      props.item.isMainPromoGroup != false
                  "
                >
                  <div class="text-no-wrap">
                    <v-icon small>mdi-pencil</v-icon>
                  </div>
                  <v-badge
                    v-if="props.item.quantityChanged"
                    color="warning"
                    bordered
                    :content="`${props.item.oldQuantity}`"
                  ></v-badge>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.quantity"
                      single-line
                      counter
                      autofocus
                      type="number"
                      :rules="[quantityRules]"
                      label="Введите количество"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.priceWithDiscount="{ item }">
                <div class="text-no-wrap">
                  <span
                    v-bind:style="[IsMinPrice(item) ? {} : { color: 'red' }]"
                  >
                    {{
                      (
                        item.price *
                        (1 - (item.discount + item.discountPayment) / 100)
                      ).toFixed(2)
                    }}
                  </span>
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        @click="GetPriceByGoodsIdAndCityId(item)"
                        >mdi-redo</v-icon
                      >
                    </template>
                    <v-simple-table fixed-header max-height="300px" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Поставщик</th>
                            <th class="text-left">Цена</th>
                            <th class="text-left">Срок годности</th>
                            <th class="text-left">Остатки</th>
                          </tr>
                        </thead>
                        <tbody v-if="goodsPrices.length">
                          <tr
                            v-for="price in goodsPrices"
                            :key="price.id"
                            @click="SelectFilial(price, item)"
                          >
                            <td>
                              {{ price.filial.name }}
                              <code v-if="price.userDiscount !== 0"
                                >{{ price.userDiscount }}%</code
                              >
                              <v-spacer></v-spacer>
                              <code v-if="price.isntDiscount">без скидки</code>
                              <v-tooltip top v-if="price.isPromotion">
                                <template v-slot:activator="{ on }">
                                  <code v-on="on">акция</code>
                                </template>
                                <span> {{ price.promotionDescription }}</span>
                              </v-tooltip>
                            </td>

                            <td>
                              <code v-if="price.userDiscount !== 0">{{
                                (
                                  price.goodsPrice -
                                  (price.userDiscount * price.goodsPrice) / 100
                                ).toFixed(2)
                              }}</code>
                              {{ price.goodsPrice }}
                            </td>
                            <td>
                              <span>{{
                                price.shelfLife.substr(0, 10) | toDate
                              }}</span>
                            </td>
                            <td>
                              <span v-if="price.stored > 0">{{
                                price.stored
                              }}</span>
                              <span v-else>н/д</span>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="4">Нет прайсов</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-menu>
                </div>
              </template>

              <template v-slot:item.price="{ item }">
                <div class="text-no-wrap">
                  {{ item.price }}
                </div>
                <v-badge
                  v-if="item.priceChanged"
                  color="warning"
                  bordered
                  :content="`${item.oldPrice}`"
                ></v-badge>
              </template>

              <template v-slot:item.goods.priceLimitation.retail="{ item }">
                <span v-if="item.goods.priceLimitation != null">{{
                  item.goods.priceLimitation.retail
                }}</span>
                <code v-else>н/д</code>
              </template>

              <template v-slot:item.goods.fullName="{ item }">
                {{ item.goods.fullName }}
                <v-chip
                  v-if="
                    item.promoGroup != null && item.isMainPromoGroup == true
                  "
                  color="#6DBFB9"
                  dark
                  x-small
                  >A</v-chip
                >
                <v-chip
                  v-if="
                    item.promoGroup != null && item.isMainPromoGroup == false
                  "
                  color="teal"
                  dark
                  x-small
                  >G</v-chip
                >

                <v-chip
                  v-if="
                    item.workloadGroup != null &&
                      item.isMainWorkloadGroup == true
                  "
                  color="#559EBF"
                  dark
                  x-small
                  >L</v-chip
                >

                <v-chip
                  v-if="
                    item.workloadGroup != null &&
                      item.isMainWorkloadGroup == false
                  "
                  color="#2B5666"
                  dark
                  x-small
                  >W</v-chip
                >
              </template>

              <template v-slot:item.shelfLife="{ item }">{{
                item.shelfLife.substr(0, 10) | toDate
              }}</template>

              <template v-slot:item.sumWithDiscount="{ item }">{{
                (
                  item.quantity *
                  item.price *
                  (1 - (item.discount + item.discountPayment) / 100)
                ).toFixed(2)
              }}</template>

              <template v-slot:item.sum="{ item }">{{
                (item.quantity * item.price).toFixed(2)
              }}</template>

              <template v-slot:item.discount="{ item }"
                >{{ item.discount }}

                <v-spacer></v-spacer>
                <v-badge
                  v-if="item.discountChanged"
                  color="warning"
                  bordered
                  :content="`${item.oldDiscount}`"
                ></v-badge>
                <v-spacer></v-spacer>
                <code v-if="item.isntDiscount">без скидки</code></template
              >

              <template v-slot:footer>
                <v-divider></v-divider>
                <v-layout justify-end>
                  <v-chip class="ma-2" label justify-end>
                    Сумма заказа со скидкой:
                    {{
                      item.orderDetails
                        .map(
                          (x) => x.quantity * x.price * (1 - x.discount / 100)
                        )
                        .reduce(function(previousValue, currentValue) {
                          return previousValue + currentValue;
                        })
                        .toFixed(2)
                    }}
                  </v-chip>

                  <v-chip class="ma-2" label justify-end>
                    Сумма заказа без скидки:
                    {{
                      item.orderDetails
                        .map((x) => x.quantity * x.price)
                        .reduce(function(previousValue, currentValue) {
                          return previousValue + currentValue;
                        })
                        .toFixed(2)
                    }}
                  </v-chip>
                </v-layout>
              </template>
            </v-data-table>
          </v-col>

          <!-- order operation -->
          <v-col cols="1" class="text-left">
            <v-card
              elevation="0"
              style="position: -webkit-sticky; position: sticky; top: 60px"
              class="pt-4"
            >
              <div class="mb-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      @click="
                        currentOrder = item;
                        dialog.orderSend = true;
                      "
                      :loading="loading.orderSend && item.id == currentOrder.id"
                      :disabled="
                        loading.orderSend && item.id == currentOrder.id
                      "
                    >
                      <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </template>
                  <span>Отправить заказ</span>
                </v-tooltip>
              </div>
              <div class="mb-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      @click="
                        DeleteOrder(item.id);
                        currentOrder.id = item.id;
                        loading.deleteOrder = true;
                      "
                      :loading="
                        loading.deleteOrder && item.id == currentOrder.id
                      "
                      :disabled="
                        loading.deleteOrder && item.id == currentOrder.id
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить заказ</span>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="12" md="4" offset-md="4">
          <v-card
            v-show="getOrderFromAPI"
            width="500"
            elevation="0"
            class="text-center"
          >
            <v-card-text class="text-center">
              Подождите, пожалуйста пока загружаются заказы
              <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
                height="10"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Окно отправки заказов -->
    <v-dialog v-model="dialog.orderSend" persistent max-width="1000" scrollable>
      <OrderSend
        :order="currentOrder"
        @onCloseDialog="dialog.orderSend = false"
      ></OrderSend>
    </v-dialog>

    <!-- Окно поделится заказом в чате -->
    <v-dialog v-model="dialog.shareOnChat" persistent max-width="800">
      <Share
        :order="currentOrder"
        @onCloseDialog="dialog.shareOnChat = false"
      ></Share>
    </v-dialog>

    <!-- Окно просмотра заказа в полном экране -->
    <v-dialog v-model="dialog.orderFullScreen" hide-overlay fullscreen>
      <OrderFullScreen
        :orderFullScreen="currentOrder"
        @onCloseDialog="dialog.orderFullScreen = false"
      ></OrderFullScreen>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
    data: () => ({
        goodsPrices: [],
        quantityRules: (v) => v > 0 || "не должно быть меньше 1",
        modelState: {},
        dialog: {
            orderFullScreen: false,
            orderSend: false,
            shareOnChat: false,
        },
        currentOrder: {
        },
        headers: [
        // { text: "", value: "id" },
        { text: "Название", value: "goods.fullName" },
        { text: "Форма", value: "goods.dosageForm.name" },
        { text: "Номер", value: "goods.number" },
        { text: "Кол-во", value: "quantity" },
        { text: "Цена со скидкой", value: "priceWithDiscount" },
        { text: "Цена без скидки", value: "price" },
        { text: "Макс. розн. цена", value: "goods.priceLimitation.retail" },
        { text: "Сумма со скидкой", value: "sumWithDiscount" },
        { text: "Сумма без скидки", value: "sum" },
        { text: "Скидка", value: "discount" },
        //{ text: "Скидка от типа оплаты", value: "discountPayment" },
        { text: "Срок годности", value: "shelfLife" },
        ],
        paymentTypes: [],
        loading: {
            deleteOrder: false,
            orderSend: false,
        },
    }),
    methods: {
        IsMinPrice(item) {
            if (item.minPrice) {
            return (item.price - (item.price * item.discount) / 100).toFixed(2) >
                item.minPrice
                ? false
                : true;
            }
            return true;
        },
        ParseDate(date) {
            if (!date) return null;

            const [month, day, year] = date.split("/");
            return `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;
        },
        NotSelectedDetails(details) {
            if (details.length == 0) {
            let Snackbar = {
                text: "Выберите позиции",
                timeout: 3000,
                color: "warning",
                show: true,
            };
            this.$store.commit("SET_SNACKBAR", Snackbar);
            return true;
            }
        },
        MoveDetailsToOrder(details, toOrderId) {
            if (this.NotSelectedDetails(details)) return;

            let payload = {
            toOrderId,
            details,
            };
            this.$store
            .dispatch("MOVE_DETAILS_TO_ORDER", payload)
            .then(() => {})
            .catch((error) => {
                window.console.log(error);
            });
        },
        MoveDetailsToStorage(details, toStorageId) {
            if (this.NotSelectedDetails(details)) return;
            let payload = {
            toStorageId,
            details,
            };

            this.$store
            .dispatch("MOVE_DETAILS_TO_STORAGE", payload)
            .then(() => {
                let Snackbar = {
                text: "Товар добавлен в хранилище!",
                timeout: 3000,
                color: "success",
                show: true,
                };

                this.$store.dispatch(
                "SET_DELETE_DETAILS_FROM_STORAGE",
                payload.details
                );
                this.$store.commit("SET_SNACKBAR", Snackbar);
            })
            .catch((error) => {
                window.console.log(error);
                let Snackbar = {
                text: "Ошибка при добавлении товара в хранилище!",
                timeout: 3000,
                color: "danger",
                show: true,
                };
                this.$store.commit("SET_SNACKBAR", Snackbar);
            });
        },
        SaveQuantity(detail) {
            let UpdatedDetail = {
            Id: detail.id,
            OrderId: detail.orderId,
            GoodsId: detail.goodsId,
            Batch: detail.batch,
            Quantity: detail.quantity,
            Discount: detail.discount,
            Price: detail.price,
            DistributorGoodsId: detail.distributorGoodsId,
            };

            this.$store
            .dispatch("UPDATE_DETAIL", UpdatedDetail)
            .then(() => {})
            .catch((error) => {
                window.console.log(error);
                // if (error.response.data.errors) {
                //   this.modelState = error.response.data.errors;
                // }
                // this.error = true;
            });
        },
        CancelQuantity() {
            window.console.log("cancel");
        },
        OpenQuantity() {
            // this.modelState = {};
            window.console.log("open");
        },
        CloseQuantity() {
            window.console.log("close");
        },
        DeleteOrder(id) {
            this.$store
            .dispatch("DELETE_ORDER", id)
            .then(() => {})
            .catch(() => {
                this.loading.deleteOrder = false;
            });
        },
        DeleteDetails(details) {
            if (this.NotSelectedDetails(details)) return;
            var tempDetails = details.filter(
            (x) => x.isMainWorkloadGroup == false || x.isMainPromoGroup == false
            );
            if (tempDetails.length > 0) {
            let Snackbar = {
                text: "Нельзя удалять акционные/нагрузочные позиции!",
                timeout: 3000,
                color: "warning",
                show: true,
            };
            this.$store.commit("SET_SNACKBAR", Snackbar);
            } else {
            this.$store
                .dispatch("DELETE_DETAILS", details)
                .then(() => {})
                .catch(() => {});
            }
        },
        SplitDetails(details) {
            if (this.NotSelectedDetails(details)) return;

            this.$store
            .dispatch("SPLIT_DETAILS", details)
            .then(() => {})
            .catch(() => {});
        },
        GetPriceByGoodsId(goods, filialId) {
            if (this.userRoleId == 11)
              return;
            this.goodsPrices = [];
            let payload = {
            goodsId: goods.goodsId,
            goods: goods,
            };

            this.$store
            .dispatch("PRICE_BY_GOODS_ID", payload)
            .then(() => {
                this.goodsPrices = this.$store.getters.PRICE_BY_GOODS_ID.prices
                .filter((x) => x.filialId !== filialId)
                .sort(function(a, b) {
                    return (
                    a.goodsPriceWithUserDiscount - b.goodsPriceWithUserDiscount
                    );
                });
            })
            .catch(() => {});
        },
        GetPriceByGoodsIdAndCityId(item) {
            window.console.log("getpricebygoodsid", item);
            this.goodsPrices = [];
            let cityId = this.$store.getters.ORDERS.filter(
            (x) => x.id == item.orderId
            )[0]?.filial?.cityId;
            let payload = {
            goodsId: item.goodsId,
            goods: item,
            };
            if (cityId) {
            this.$store
                .dispatch("PRICE_BY_GOODS_ID", payload)
                .then(() => {
                this.goodsPrices = this.$store.getters.PRICE_BY_GOODS_ID.prices
                    .filter((x) => x.filial.cityId == cityId)
                    .sort(function(a, b) {
                    return (
                        a.goodsPriceWithUserDiscount - b.goodsPriceWithUserDiscount
                    );
                    });
                })
                .catch(() => {});
            }
        },
        SelectFilial(value, item) {
            this.$store.dispatch("GET_RE_ORDER_DETAIL", {
              detailId: item.id,
              orderId: item.orderId,
            });
            let OrderDialog = {
            goodsQuantity: item.quantity,
            show: true,
            price: Object.assign({}, value),
            };
            this.$store.commit("SET_ORDER_DIALOG", OrderDialog);
        },
        async GetPaymentTypes() {
            await this.$store
            .dispatch("GET_ALL_PAYMENT_TYPES")
            .then((response) => {
                this.paymentTypes = response;
            })
            .catch(() => {});
        },
    },
    async mounted() {
        await this.GetPaymentTypes();
    },
    computed: {
        getOrderFromAPI: {
            get: function () {
                return this.$store.getters.GET_ORDER_FROM_API;
            },
            set: function (value) {
                this.$store.commit("SET_ORDER_FROM_API", value);
            }
        },
        orders: {
            get: function() {
                return this.$store.getters.ORDERS;
            },
        },
        orderNav: {
            get: function() {
                return this.$store.getters.ORDER_NAVIGATION;
            },
            set: function(orderIndex) {
                this.$store.commit("SET_ORDER_NAVIGATION", orderIndex);
            },
        },
        ordersNav: {
            get: function() {
            let orderNav = this.$store.getters.ORDER_NAVIGATION;
            if (orderNav == 0) return this.$store.getters.ORDERS;
            return this.$store.getters.ORDERS.slice(orderNav - 1, orderNav);
            },
        },
        storages: {
            get: function() {
            return this.$store.getters.ACCESS_STORAGES;
            },
        },
    },
};
</script>
