var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"form"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.selectedItem,"label":"Скрытие и отображение столбцов","multiple":"","small-chips":"","clearable":true,"dense":"","outlined":"","counter":13,"hide-selected":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.text))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-close")])],1):_vm._e()]}}]),model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-card-title',[_vm._v(" Товары "),_c('v-spacer'),_c('v-text-field',{attrs:{"clearable":"","label":"Поиск","prepend-icon":"mdi-cloud-search-outline","maxlength":"64"},on:{"input":_vm.loadGoods},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{attrs:{"label":"Скрытые / Видимые","name":"isHide","item-text":"label","item-value":"value","items":[
                      { label: 'Все товары', value: null },
                      { label: 'Скрытые', value: true },
                      { label: 'Видимые', value: false } ]},on:{"change":_vm.loadGoods},model:{value:(_vm.model.isHide),callback:function ($$v) {_vm.$set(_vm.model, "isHide", $$v)},expression:"model.isHide"}})],1),_c('v-card',[_c('v-form',{ref:"listForm",attrs:{"lazy-validation":""}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
                'items-per-page-options': [5, 10, 20, 50, 100, 200, 300, 400],
                showFirstLastPage: true,
              },"loading":_vm.loading,"multi-sort":"","dense":""},on:{"update:options":function($event){_vm.options=$event}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }