<template>
  <v-card :loading="loading">
    <v-card-title>
      <span>
        <code>Конфигурация выгрузки договоров</code>
        {{ this.filial.name }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <!-- Конфигурация исходящего запроса -->
          <v-col>
            <span>Конфигурация исходящего запроса</span>
            <v-text-field
              label="[Url]"
              v-model="editContractConfig.requestConfig.url"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[Urn]"
              v-model="editContractConfig.requestConfig.urn"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[BINParam]"
              v-model="editContractConfig.requestConfig.binParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[Encoding]"
              v-model="editContractConfig.requestConfig.encoding"
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="APIRequestProtocols"
              v-model="editContractConfig.requestConfig.requestProtocol"
              label="[RequestProtocol]"
              item-text="protocol"
              item-value="id"
              :rules="rules"
            ></v-select>
          </v-col>
          <!-- Конфигурация принятого ответа -->
          <v-col>
            <span>Конфигурация принятого ответа</span>
            <v-text-field
              label="[XPathLocalName]"
              v-model="editContractConfig.responseConfig.xPathLocalName"
              required
            ></v-text-field>
            <v-text-field
              label="[ContractIdParam]"
              v-model="editContractConfig.responseConfig.contractIdParam"
              required
            ></v-text-field>
            <v-text-field
              label="[ContractNameParam]"
              v-model="editContractConfig.responseConfig.contractNameParam"
              required
            ></v-text-field>
            <v-text-field
              label="[ContractDiscountParam]"
              v-model="editContractConfig.responseConfig.contractDiscountParam"
              required
            ></v-text-field>
            <v-text-field
              label="[Encoding]"
              v-model="editContractConfig.responseConfig.encoding"
              required
            ></v-text-field>
            <v-select
              :items="APIDataFormats"
              v-model="editContractConfig.responseConfig.dataFormat"
              label="[DataFormat]"
              :rules="rules"
              item-value="id"
              item-text="format"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('on-contract-config-dialog', false)"
        >Закрыть</v-btn
      >
      <v-btn color="primary" @click="UpdateContractConfig()">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
  data: () => ({
    defaultContractConfig: {
      requestConfig: {
        binParam: null,
        encoding: null,
        filial: null,
        filialId: null,
        requestProtocol: null,
        url: null,
        urn: null,
      },
      responseConfig: {
        contractDiscountParam: null,
        contractIdParam: null,
        contractNameParam: null,
        dataFormat: null,
        dataFormatId: null,
        encoding: null,
        filial: null,
        filialId: null,
        xPathLocalName: null,
      },
    },
    editContractConfig: {
      requestConfig: {
        binParam: null,
        encoding: null,
        filial: null,
        filialId: null,
        requestProtocol: null,
        requestProtocolId: null,
        url: null,
        urn: null,
      },
      responseConfig: {
        contractDiscountParam: null,
        contractIdParam: null,
        contractNameParam: null,
        dataFormat: null,
        dataFormatId: null,
        encoding: null,
        filial: null,
        filialId: null,
        xPathLocalName: null,
      },
    },

    rules: [(value) => !!value || "Обязательное поле для заполнения"],
    loading: false,
  }),
  methods: {
    UpdateContractConfig() {
      this.editContractConfig.requestConfig.filialId = this.editContractConfig.responseConfig.filialId = this.filial.id;

      var payload = {
        contractRequestConfigVM: this.editContractConfig.requestConfig,
        contractResponseConfigVM: this.editContractConfig.responseConfig,
      };
      this.$store
        .dispatch("UPDATE_CONTRACT_CONFIG", payload)
        .then(() => {
          this.$emit("on-contract-config-dialog", false);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetContractConfig() {
      this.loading = true;
      this.editContractConfig = JSON.parse(
        JSON.stringify(this.defaultContractConfig)
      );

      this.$store
        .dispatch("CONTRACT_CONFIG", this.filial.id)
        .then((response) => {
          if (response.requestConfig)
            this.editContractConfig.requestConfig = response.requestConfig;

          if (response.responseConfig)
            this.editContractConfig.responseConfig = response.responseConfig;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
  },
  async mounted() {},

  computed: {
    APIRequestTypes: {
      get: function () {
        return this.$store.getters.API_REQUEST_TYPES;
      },
    },
    APIRequestProtocols: {
      get: function () {
        return this.$store.getters.API_REQUEST_PROTOCOLS;
      },
    },
    APIDataFormats: {
      get: function () {
        return this.$store.getters.API_REQUEST_DATA_FORMATS;
      },
    },
  },
};
</script>

<style>
</style>