export const CitiesUzb = {
    Tashkent : 101
};

export const PhoneNumberFormat = {
    Kazakhstan: [3,3,4],
    Uzbekistan: [2,3,4]
};

export default {
    CitiesUzb,
    PhoneNumberFormat
};