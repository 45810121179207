<template>
  <v-app>
    <template v-if="!$route.meta.allowAnonymous">
      <Global />
      <Navigation />
      <Toolbar />
      <v-main>
        <v-container fluid>
          <Breadcrumbs />
          <router-view></router-view>
        </v-container>
        <v-fab-transition>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            @click="toTop"
            absolute
            fab
            fixed
            right
            bottom
            class="mb-10"
            color="primary"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
        <Footer />
      </v-main>
    </template>
    <template v-if="$route.meta.allowAnonymous">
      
      <Login />
    </template>
    <Snackbar />
    <ExpiringNotification></ExpiringNotification>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style scoped></style>
