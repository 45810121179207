<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Количество"
                  placeholder="Количество"
                  outlined
                  class="rounded-0"
                  dense
                  hide-details=""
                  type="number"
                  v-model="quantity"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="green" small outlined tile @click="add()"
                  >Добавить</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DiscountConditions",
  data() {
    return {
      quantity: null,
    };
  },
  methods: {
    add() {
      if (this.quantity == null) return;

      this.$store
        .dispatch("ADD_COMPANY_GOODS_GROUP_GROWTH_DEFAULT_DISCOUNTS", {
          list: [
            {
              companyGoodsGroupId:
                this.$store.getters.SELECTED_COMPANY_GOODS_GROUP_ID,
              quantity: this.quantity,
            },
          ],
        })
        .then((data) => {
          if (data.statusCode == 1) {
            let Success = {
              text: "Операция успешно выполнена!",
              timeout: 3000,
              color: "success",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Success);
          } else {
            let Error = {
              text: "Ошибка при выполнении операции!",
              timeout: 3000,
              color: "danger",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Error);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  mounted() {},
};
</script>
