<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="name"
          placeholder="Введите наименование группы"
          label="Наименование группы"
          outlined
          hide-details
          class="rounded-0"
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="green" outlined tile @click="add()"> Добавить </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "CompanyUserGroup",
  data() {
    return {
      name: null,
      userGroup: null
    };
  },
  methods: {
    async add() {
      this.$store
        .dispatch("ADD_COMPANY_USER_GROUP", {name: this.name})
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
};
</script>
