import axios from "axios";
import store from './../index'

export default {
    state: {
        contractsFilial: [],
    },
    getters: {
        CONTRACTS_FILIAL: state => {
            return state.contractsFilial;
        },
    },
    mutations: {
        SET_CONTRACTS_FILIAL: (state, payload) => {
            state.contractsFilial = payload;
        },
    },
    actions: {
        CONTRACTS_BY_FILIAL_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Contract/GetByFilialId?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_CONTRACTS_FILIAL', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UNLOAD_CONTRACTS_BY_FILIAL_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Contract/UnloadByFilialId?filialId=` + payload, {
                        cancelToken: store.getters.CANCEL_TOKEN(payload)
                    })
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);

                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UNLOAD_CONTRACTS_BY_FILIALS_ID: (commit, payload) => {

            return new Promise((resolve, reject) => {
                axios
                    .post(`/Contract/UnloadByFilialsId`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);

                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UNLOAD_CONTRACTS: (commit, payload) => {
            window.console.log(payload)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Contract/UnloadAsync?filialId=` + payload.filialId + "&accessUserBIN=" + payload.accessUserBIN)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CONTRACTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Contract/GetByFilialIdAndBIN?filialId=` + payload.filialId + "&accessUserBIN=" + payload.accessUserBIN)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        SET_WORKING_CONTRACT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Contract/SetWorkingContract`, payload)
                    .then(({

                        status
                    }) => {

                        if (status === 200) {
                            let Success = {
                                text: 'Рабочий договор изменён',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);

                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
};