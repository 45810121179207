<template>
  <div id="form">
    <v-row justify="center">
      <v-col>
        <v-combobox
          v-model="headers"
          :items="selectedItem"
          label="Скрытие и отображение столбцов"
          multiple
          small-chips
          :clearable="true"
          dense
          outlined
          :counter="13"
          :hide-selected="true"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
              <span class="pr-2">{{ item.text }}</span>
              <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
            </v-chip>
          </template>
        </v-combobox>

        <!-- {{headers}} -->
        <v-card-title>
          Товары
          <v-spacer></v-spacer>
          <v-text-field
            clearable
            label="Поиск"
            prepend-icon="mdi-cloud-search-outline"
            maxlength="64"
            v-model="search"
            @input="loadGoods"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select
            label="Скрытые / Видимые"
            name="isHide"
            item-text="label"
            item-value="value"
            :items="[
                        { label: 'Все товары', value: null },
                        { label: 'Скрытые', value: true },
                        { label: 'Видимые', value: false },
                      ]"
            v-model="model.isHide"
            @change="loadGoods"
          ></v-select>
        </v-card-title>
        <v-card>
          <v-form ref="listForm" lazy-validation>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{
                  'items-per-page-options': [5, 10, 20, 50, 100, 200, 300, 400],
                  showFirstLastPage: true,
                }"
              :loading="loading"
              multi-sort
              class="elevation-3"
              dense
            >
              <!-- <template v-slot:item.type="{ item }">{{ selectionItems.type[item.type] }}</template> -->
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    headers: [
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
        show: true
      },
      {
        text: "Категория",
        align: "center",
        sortable: true,
        value: "category.name",
        show: true
      },
      {
        text: "Лекарственная форма",
        align: "center",
        sortable: true,
        value: "dosageForm.name"
      },
      {
        text: "Доза",
        align: "center",
        sortable: true,
        value: "dose.name"
      },
      {
        text: "Патент",
        align: "center",
        sortable: true,
        value: "generic.name"
      },
      {
        text: "АТХ",
        align: "center",
        sortable: true,
        value: "atc.name"
      },
      {
        text: "Бренд",
        align: "center",
        sortable: true,
        value: "brand.name",
        show: true
      },
      {
        text: "Производитель",
        align: "center",
        sortable: true,
        value: "producer.name",
        show: true
      },
      {
        text: "Корпорация",
        align: "center",
        sortable: true,
        value: "corporation.name",
        show: true
      },
      {
        text: "Страна",
        align: "center",
        sortable: true,
        value: "country.name"
      },
      {
        text: "МНН",
        align: "center",
        sortable: true,
        value: "inn.name"
      },
      {
        text: "Rx / OTC",
        align: "center",
        sortable: true,
        value: "rxOTC.name"
      },
      {
        text: "Полное название",
        align: "center",
        sortable: true,
        value: "fullName",
        show: true
      }
    ],
    selectedItem: [],
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: ["id"],
      sortDesc: [false]
    },
    items: [],
    total: 0,
    // selectionItems: {
    //   form_type: ["Вид 1", "Вид 2"]
    // },
    search: "",
    model: {
      // type: null,
      isHide: null
    }
  }),
  watch: {
    options: {
      handler() {
        this.loadGoods();
      },
      deep: true
    },
    search: function() {
    },
    "options.itemsPerPage": function() {
    }
  },
  methods: {
    async loadGoods() {
      this.loading = true;

      let sorts = [];
      if (this.options.sortBy !== null) {
        this.options.sortBy.forEach((value, index) => {
          sorts.push(value + (this.options.sortDesc[index] ? " desc" : " asc"));
        });
      }

      let take = `take=${this.options.itemsPerPage}`;
      let skip = `skip=${(this.options.page - 1) * this.options.itemsPerPage}`;
      if (this.search == null) this.search = "";
      let search = `search=${this.search.trim()}`;
      let sort = `sort=${sorts.join(" ")}`;
      let url = `/Goods/GetAllInPricesPagingAsync?${take}&${skip}&${search}&${sort}`;


      const axios = require("axios").default;
      await axios
        .post(url)
        .then(response => {
          this.loading = false;
          this.items = response.data;
          // this.total = res.data.total
          this.total = 50000;
        })
        .catch(error => {
          this.loading = false;
          window.console.log(error);
        });
    }
  },
  created: function() {
    this.loadGoods();
    this.selectedItem = this.headers;
    this.headers = this.headers.filter(x => x.show == true);
  },
  computed: {
    //  computedHeaders () {
    //     return this.headers.filter(x=>x.show == true)
    //  }
  }
};
</script>