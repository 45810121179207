<template>
  <v-card tile>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-sheet>
              <v-text-field
                v-model="search"
                label="Поиск по доступам"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </v-sheet>
            <v-treeview
              open-all
              item-disabled="isAvailableToAll"
              dense
              v-model="accessTemplateActions"
              :items="allActions"
              selectable
              return-object
              open-on-click
              expand-icon="mdi-chevron-down"
              on-icon="mdi-bookmark"
              off-icon="mdi-bookmark-outline"
              indeterminate-icon="mdi-bookmark-minus"
              :search="search"
              item-key="id"
            ></v-treeview>
          </v-col>

          <v-divider vertical></v-divider>
          <v-col class="pa-4" cols="6">
            <div class="pa-3">
              <v-menu
                v-model="createAccessTemplateMenu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" x-small color="primary">Создать</v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-sheet elevation="2" class="pa-2 mr-1 mb-1">
                          <v-text-field
                            v-model="newAccessTemplateName"
                            label="Название"
                            required
                          ></v-text-field>
                        </v-sheet>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn small text @click="createAccessTemplateMenu = false"
                      >Отмена</v-btn
                    >
                    <v-btn small text @click="CreateAccessTemplate()" color="primary"
                      >Создать</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
              шаблон доступа

              <v-divider></v-divider>
              <v-chip-group
                v-model="accessTemplateSelect"
                active-class="primary--text"
                mandatory
                column
              >
                <v-chip
                  filter
                  outlined
                  v-for="accessTemplate in accessTemplates"
                  :key="accessTemplate.id"
                  close
                  close-icon="mdi-delete"
                  @click:close="DeleteAccessTemplate(accessTemplate.id)"
                  @click.native="GetAccessTemplateActions()"
                  >{{ accessTemplate.name }}</v-chip
                >
              </v-chip-group>
            </div>

            <v-divider></v-divider>

            <code>Доступы выбранного шаблона</code>
            <v-btn
              color="primary"
              elevation="2"
              rounded
              x-small
              @click="UpdateAccessTemplateActions()"
              :loading="loading"
              :disabled="loading"
              >Сохранить изменения</v-btn
            >

            <v-divider></v-divider>

            <template v-if="accessTemplateActions.length">
              <v-scroll-x-transition group hide-on-leave>
                <v-expansion-panels
                  :key="accessTemplateActions.length"
                  focusable
                  v-model="panel"
                >
                  <v-expansion-panel
                    :key="`name-${m}-${m}`"
                    v-for="(module, m) in modules"
                    v-show="
                      accessTemplateActions.filter((x) => x.moduleId == module.moduleId)
                        .length
                    "
                  >
                    <v-expansion-panel-header
                      ><h4 :key="`moduleName-${m}`">
                        {{ module.description + "[" + module.moduleName + "]" }}
                      </h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template
                        v-for="(action, a) in accessTemplateActions.filter(
                          (x) => x.moduleId == module.moduleId
                        )"
                      >
                        <code :key="`action-${m}-${a}`">{{ action.actionId }}</code>
                        <span :key="`name-${m}-${a}`">{{ action.name }}</span>
                        <code
                          v-if="action.isAvailableToAll"
                          :key="`isAvailableToAll-${m}-${a}`"
                          >Доступен для всех</code
                        >
                        <v-spacer :key="`spacer-name-${m}-${a}`"></v-spacer>
                        <v-spacer :key="`spacer-datetime-${m}-${a}`"></v-spacer>
                        <v-divider :key="`spacer-divider-${m}-${a}`"></v-divider>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-scroll-x-transition>
            </template>
            <template v-else>Пусто</template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <div style="flex: 1 1 auto"></div>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    newAccessTemplateName: "",
    panel: [],
    createAccessTemplateMenu: false,
    accessTemplateSelect: [],
    allActions: [{ id: 0, name: "Pharmcenter.kz (Все доступы)", children: [] }],
    search: null,
    loading: false,
  }),
  mounted() {
    this.$store
      .dispatch("ALL_DB_MODULES")
      .then(() => {
        this.allActions[0].children = this.$store.getters.ALL_DB_MODULES;
      })
      .catch((error) => {
        window.console.log(error);
      });

    this.$store
      .dispatch("ACCESS_TEMPLATES")
      .then(() => {
        this.GetAccessTemplateActions();
      })
      .catch((error) => {
        window.console.log(error);
      });
  },
  methods: {
    CreateAccessTemplate() {
      let AccessTemplate = {
        name: this.newAccessTemplateName,
      };
      this.$store
        .dispatch("CREATE_ACCESS_TEMPLATE", AccessTemplate)
        .then(() => {
          this.createAccessTemplateMenu = false;
        })
        .catch(() => {
          this.createAccessTemplateMenu = false;
        });
    },

    DeleteAccessTemplate(id) {
      this.$store
        .dispatch("DELETE_ACCESS_TEMPLATE", id)
        .then(() => {
          this.GetAccessTemplateActions();
        })
        .catch(() => {});
    },

    GetAccessTemplateActions() {
      var accessTemplatesIds = [];

      accessTemplatesIds.push(this.accessTemplates[this.accessTemplateSelect].id);

      this.$store
        .dispatch("ACCESS_TEMPLATE_ACTIONS", accessTemplatesIds)
        .then((response) => {
          var accessTemplateActions = [];

          response.forEach((templateAction) => {
            accessTemplateActions.push({
              id: templateAction.action.module.name + templateAction.action.name,
              name:
                templateAction.action.description +
                "[" +
                templateAction.action.name +
                "]",
              moduleId: templateAction.action.module.id,
              actionId: templateAction.action.id,
              actionName: templateAction.action.name,
              description: templateAction.action.description,
              isAvailableToAll: templateAction.action.isAvailableToAll,
            });
          });
          this.accessTemplateActions = accessTemplateActions;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    UpdateAccessTemplateActions() {
      this.loading = true;
      let AccessTemplateActions = {
        accessTemplateId: this.accessTemplates[this.accessTemplateSelect].id,
        accessTemplateActionsId: this.accessTemplateActions.map((x) => x.actionId),
      };

      this.$store
        .dispatch("UPDATE_ACCESS_TEMPLATE_ACTIONS", AccessTemplateActions)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    accessDialog: {
      get: function () {
        return this.$store.getters.ACCESS_DIALOG;
      },
    },
    accessTemplateActions: {
      get: function () {
        var accessTemplateActions = this.$store.getters.ACCESS_TEMPLATE_ACTIONS;
        return accessTemplateActions;
      },
      set: function (val) {
        this.$store.commit("SET_ACCESS_TEMPLATE_ACTIONS", val);
      },
    },
    modules: {
      get: function () {
        return this.allActions[0].children.map((x) => {
          return {
            moduleId: x.moduleId,
            moduleName: x.moduleName,
            description: x.description,
          };
        });
      },
    },
    accessTemplates: {
      get: function () {
        return this.$store.getters.ACCESS_TEMPLATES;
      },
    },
  },
  created: function () {},
};
</script>
<style scoped>
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
