<template>
  <v-dialog
    v-model="annotationDialog"
    hide-overlay
    class="elevation-20"
    transition="scale-transition"
    width="1200px"
    persistent
    scrollable
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn icon @click="annotationDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Аннотация</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom left max-height="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              Навигация
              <v-icon right>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(annotationLink, i) in annotationLinks"
              :key="i"
              :href="annotationLink.link"
              @click="() => {}"
            >
              <v-list-item-title>{{ annotationLink.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn text @click="annotationDialog = false">Закрыть</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-timeline :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item right id="producerInfo">
            <v-card>
              <v-card-title class="lime darken-1 justify-end">
                <h2 class="mr-4 white--text font-weight-light">Производитель</h2>
                <v-icon dark size="42">mdi-factory</v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.producerInfo}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="red lighten-1" small left id="name">
            <v-card>
              <v-card-title class="red lighten-1 justify-end">
                <h2 class="mr-4 white--text font-weight-light">Торговое название</h2>
                <v-icon dark size="42">mdi-account-multiple-outline</v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">{{annotation.name}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="purple accent-1" right id="pharmProperty">
            <v-card>
              <v-card-title class="purple accent-1">
                <v-icon class="mr-4" dark size="42">mdi-receipt</v-icon>
                <h2 class="white--text font-weight-light">Фармакологические свойства</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.pharmProperty}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="green lighten-1" small left id="inn">
            <v-card>
              <v-card-title class="green lighten-1">
                <v-icon class="mr-4" dark size="42">mdi-rename-box</v-icon>
                <h2 class="white--text font-weight-light">Международное непатентованное название</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.inn}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="amber lighten-1" right id="dosageFormInfo">
            <v-card>
              <v-card-title class="amber lighten-1 justify-end">
                <h2 class="mr-4 white--text font-weight-light">Лекарственная форма</h2>
                <v-icon dark size="25">mdi-home-outline</v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="8">{{annotation.dosageFormInfo}}</v-col>
                  <v-col class="hidden-sm-and-down text-left" md="2">
                    <v-icon size="64">mdi-beaker-empty-outline</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="purple lighten-2" small left id="composition">
            <v-card>
              <v-card-title class="purple lighten-2">
                <v-icon class="mr-4" dark size="42">mdi-script</v-icon>
                <h2 class="white--text font-weight-light">Состав</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">{{annotation.composition}}</v-col>
                  <v-col class="hidden-sm-and-down text-left" md="2">
                    <v-icon size="42">mdi-beaker-empty-outline</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="cyan lighten-1" right id="description">
            <v-card>
              <v-card-title class="cyan lighten-1">
                <v-icon class="mr-4" dark size="42">mdi-hospital</v-icon>
                <h2 class="white--text font-weight-light">Описание</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">{{annotation.description}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="pink accent-2" left small id="pharmGroup">
            <v-card>
              <v-card-title class="pink accent-2">
                <v-icon class="mr-4" dark size="42">mdi-google-circles-group</v-icon>
                <h2 class="white--text font-weight-light">Фармакотерапевтическая группа</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.pharmGroup}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="blue lighten-2" right id="indicationsForUse">
            <v-card>
              <v-card-title class="blue lighten-2">
                <v-icon class="mr-4" dark size="42">mdi-transcribe</v-icon>
                <h2 class="white--text font-weight-light">Показания к применению</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.indicationsForUse}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="deep-orange darken-1" small left id="modeOfUse">
            <v-card>
              <v-card-title class="deep-orange darken-1">
                <v-icon class="mr-4" dark size="42">mdi-message</v-icon>
                <h2 class="white--text font-weight-light">Способы применения</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.modeOfUse}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="cyan darken-2" right id="sideEffects">
            <v-card>
              <v-card-title class="cyan darken-2">
                <v-icon class="mr-4" dark size="42">mdi-message-alert</v-icon>
                <h2 class="white--text font-weight-light">Побочные действия</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.sideEffects}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="grey darken-1" small left id="contraindications">
            <v-card>
              <v-card-title class="grey darken-1">
                <v-icon class="mr-4" dark size="42">mdi-message-text</v-icon>
                <h2 class="white--text font-weight-light">Противопоказания</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.contraindications}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="green lighten-1" right id="interaction">
            <v-card>
              <v-card-title class="green lighten-1">
                <v-icon class="mr-4" dark size="42">mdi-pharmacy</v-icon>
                <h2 class="white--text font-weight-light">Лекарственные взаимодействия</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.interaction}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="lime darken-1" small left id="specialInstructions">
            <v-card>
              <v-card-title class="lime darken-1">
                <v-icon class="mr-4" dark size="42">mdi-phone-in-talk</v-icon>
                <h2 class="white--text font-weight-light">Особые указания</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.specialInstructions}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="cyan darken-1" right id="overDose">
            <v-card>
              <v-card-title class="cyan darken-1">
                <v-icon class="mr-4" dark size="42">mdi-pill</v-icon>
                <h2 class="white--text font-weight-light">Передозировка</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.overDose}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="amber darken-3" small left id="releaseForm">
            <v-card>
              <v-card-title class="amber darken-3">
                <v-icon class="mr-4" dark size="42">mdi-phone-in-talk</v-icon>
                <h2 class="white--text font-weight-light">Форма выпуска и упаковка</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.releaseForm}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="deep-purple darken-1" right id="storageConditions">
            <v-card>
              <v-card-title class="deep-purple darken-1">
                <v-icon class="mr-4" dark size="42">mdi-briefcase-download</v-icon>
                <h2 class="white--text font-weight-light">Условия хранения</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.storageConditions}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="blue darken-2" small left id="storageLife">
            <v-card>
              <v-card-title class="blue darken-2">
                <v-icon class="mr-4" dark size="42">mdi-backup-restore</v-icon>
                <h2 class="white--text font-weight-light">Срок хранения</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.storageLife}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
          <v-timeline-item color="green lighten-1" right id="saleConditions">
            <v-card>
              <v-card-title class="green lighten-1">
                <v-icon class="mr-4" dark size="42">mdi-script</v-icon>
                <h2 class="white--text font-weight-light">Условия отпуска из аптек</h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>{{annotation.saleConditions}}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    goodName: "",
    annotationLinks: [
      {
        title: "Производитель",
        link: "#producerInfo",
      },
      {
        title: "Торговое название",
        link: "#name",
      },
      {
        title: "Фармакологические свойства",
        link: "#pharmProperty",
      },
      {
        title: "Международное непатентованное название",
        link: "#inn",
      },
      {
        title: "Лекарственная форма",
        link: "#dosageFormInfo",
      },
      {
        title: "Состав",
        link: "#composition",
      },
      {
        title: "Описание",
        link: "#description",
      },
      {
        title: "Фармакотерапевтическая группа",
        link: "#pharmGroup",
      },
      {
        title: "Показания к применению",
        link: "#indicationsForUse",
      },
      {
        title: "Способы применения",
        link: "#modeOfUse",
      },
      {
        title: "Побочные действия",
        link: "#sideEffects",
      },
      {
        title: "Противопоказания",
        link: "#contraindications",
      },
      {
        title: "Лекарственные взаимодействия",
        link: "#interaction",
      },
      {
        title: "Особые указания",
        link: "#specialInstructions",
      },
      {
        title: "Передозировка",
        link: "#overDose",
      },
      {
        title: "Форма выпуска и упаковка",
        link: "#releaseForm",
      },
      {
        title: "Условия хранения",
        link: "#storageConditions",
      },
      {
        title: "Срок хранения",
        link: "#storageLife",
      },
      {
        title: "Условия отпуска из аптек",
        link: "#saleConditions",
      },
    ],
  }),
  methods: {},
  computed: {
    annotationDialog: {
      get: function () {
        return this.$store.getters.ANNOTATION_DIALOG;
      },
      set: function (dialog) {
        this.$store.commit("SET_ANNOTATION_DIALOG", dialog);
      },
    },
    annotation: {
      get: function () {
        let ann = this.$store.getters.ANNOTATION;
        return ann;
      },
    },
  },
};
</script>