import axios from "axios";
import store from './../index'

export default {
    state: {
        shippingAddressesFilial: [],
    },
    getters: {
        SHIPPING_ADDRESSES_FILIAL: state => {
            return state.shippingAddressesFilial;
        },
    },
    mutations: {
        SET_SHIPPING_ADDRESSES_FILIAL: (state, payload) => {
            state.shippingAddressesFilial = payload;
        },
    },
    actions: {

        UNLOAD_SHIPPING_ADDRESSES: (commit, payload) => {
            window.console.log(payload)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ShippingAddress/UnloadAsync?filialId=` + payload.filialId + "&accessUserBIN=" + payload.accessUserBIN + "&orderId=" + payload.orderId)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        SHIPPING_ADDRESSES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ShippingAddress/GetByFilialIdAndBINAsync?filialId=` + payload.filialId + "&accessUserBIN=" + payload.accessUserBIN)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        SHIPPING_ADDRESSES_BY_FILIAL_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ShippingAddress/GetByFilialId?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_SHIPPING_ADDRESSES_FILIAL', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};