<template>
  <v-dialog
    class="elevation-20"
    transition="scale-transition"
    max-width="1200px"
    persistent
    scrollable
    v-model="bankCardsDialog"
  >
    <v-card :loading="loading">
      <v-toolbar dense>
        <v-btn text color="primary" icon @click="bankCardsDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Мои карты от Halyk Bank</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="CardRegRequest" outlined color="primary">Добавить карту</v-btn>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon href="https://halykbank.kz/cards/debetovye-karty" target="_blank">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-alert-circle-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Как получить карту?</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn @click="bankCardsDialog = false" text color="primary">Закрыть</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-item-group mandatory>
          <v-container>
            <v-row>
              <v-col cols="12" md="4" v-if="!bankCards.length"> Пусто </v-col>
              <v-col v-for="card in bankCards" :key="card.cardID" cols="12" md="4">
                <v-item v-slot="{}">
                  <v-card
                    :color="card.working ? 'primary' : 'grey'"
                    class="d-flex align-center"
                    dark
                    height="100"
                    @click="SetWorkingCard(card.cardId)"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="headline mb-1">
                          {{ card.cardMask }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ card.cardExpiredMM }}/{{ card.cardExpiredYY
                          }}<v-scroll-y-transition>
                            <span class="ml-5" v-if="card.working">[Рабочая карта]</span>
                          </v-scroll-y-transition>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="bankCardsDialog = false" outlined>
          Сохранить
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  mounted() {},
  computed: {
    bankCardsDialog: {
      get: function () {
        return this.$store.getters.HALYK_CARDS_DIALOG;
      },
      set: function () {
        this.$store.commit("SET_HALYK_CARDS_DIALOG", false);
      },
    },
    bankCards: {
      get: function () {
        return this.$store.getters.HALYK_CARDS;
      },
    },
  },
  methods: {
    SetWorkingCard(val) {
      this.loading = true;
      this.$store
        .dispatch("SET_WORKING_CARD", { cardId: val })
        .then(() => {
          this.$store
            .dispatch("HALYK_CARDS_CONTEXT")
            .then(() => {
              this.loading = false;
            })
            .catch((error) => {
              window.console.log(error);
            });
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    CardRegRequest() {
      this.$store
        .dispatch("CARD_REG_REQUEST")
        .then((response) => {
          
          let form = document.createElement("form");
          form.action = response.actionUrl;
          form.method = "POST";

          let orderBase64 =
            '<input name="Signed_Order_B64" value="' + response.signedOrderB64 + '">';
          let email = '<input name="email" value="' + response.email + '">';
          let language = '<input name="Language" value="' + response.language + '">';
          let backLink = '<input name="BackLink" value="' + response.backLink + '">';
          let postLink = '<input name="PostLink" value="' + response.postLink + '">';
          let failureBackLink =
            '<input name="FailureBackLink" value="' + response.failureBackLink + '">';
          let template = '<input name="template" value="' + response.template + '">';

          form.innerHTML =
            orderBase64 +
            email +
            language +
            backLink +
            postLink +
            failureBackLink +
            template;

          document.body.append(form);

          form.submit();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
};
</script>
