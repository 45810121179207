import axios from "axios";
// import store from './../index'

export default {
    state: {
        APIRequestTypes: [],
        APIRequestProtocols: [],
        APIDataFormats: [],
    },
    getters: {
        API_REQUEST_TYPES: state => {
            return state.APIRequestTypes;
        },
        API_REQUEST_PROTOCOLS: state => {
            return state.APIRequestProtocols;
        },
        API_REQUEST_DATA_FORMATS: state => {
            return state.APIDataFormats;
        },
    },
    mutations: {
        SET_API_REQUEST_TYPES: (state, payload) => {
            state.APIRequestTypes = payload;
        },
        SET_API_REQUEST_PROTOCOLS: (state, payload) => {
            state.APIRequestProtocols = payload;
        },
        SET_API_REQUEST_DATA_FORMATS: (state, payload) => {
            state.APIDataFormats = payload;
        },
    },
    actions: {
        API_REQUEST_TYPES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/APIRequest/GetAPIRequestTypes`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        API_REQUEST_PROTOCOLS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/APIRequest/GetAPIRequestProtocols`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        API_REQUEST_DATA_FORMATS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/APIRequest/GetAPIDataFormats`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        API_REQUEST: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/APIRequest/GetAPIRequestOutgoing?orderId=` + payload.orderId + `&requestType=` + payload.requestType + `&filialId=` + payload.filialId)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                        if (status === 204) {
                            resolve(false);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        API_REQUESTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/APIRequest/GetAPIRequestsOutgoing`, {
                        params: payload
                    })
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                        if (status === 204) {
                            resolve(false);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        API_REQUEST_DOWNLOAD_RESPONSE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/APIRequest/DownloadAPIRequestsOutgoingResponse?id=` + payload, {
                        responseType: 'blob'
                    })
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            const url = window.URL.createObjectURL(new Blob([data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'response' + '.zip');
                            document.body.appendChild(link);
                            link.click()
                        }
                        if (status === 204) {
                            resolve(false);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};