import axios from "axios";
import store from './../index'

export default {
    state: {
        countunreadMessages: 0
    },
    getters: {
        COUNT_UNREAD_MESSAGES: state => {
            return state.countunreadMessages;
        }
    },
    mutations: {
        SET_COUNT_UNREAD_MESSAGES: (state, payload) => {
            state.countunreadMessages = payload;
        },
        ADD_COUNT_UNREAD_MESSAGES: (state, payload) => {
            state.countunreadMessages += payload;
        }
    },
    actions: {
        CHAT_BY_RECIPIENT_USER: (commit, payload) => {

            return new Promise((resolve, reject) => {
                axios
                    .get(`/Chat/GetByRecipientUserIdAsync?recipientUserId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {

                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COUNT_UNREAD_MESSAGES: (commit, payload) => {

            return new Promise((resolve, reject) => {
                axios
                    .get(`/Chat/GetCountUnreadMessagesAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_COUNT_UNREAD_MESSAGES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        ALL_CHATS: (commit, payload) => {

            return new Promise((resolve, reject) => {
                axios
                    .get(`/Chat/GetAllAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        SEND_TO_ONE_CHAT: (commit, payload) => {
            return new Promise((resolve) => {
                store.getters.HUB_CONNECTION.invoke("SendToOneChat", payload.userId, payload.message);
                resolve(true)
            });
        },
        GET_ARTICLES: () => {
            window.console.log('GET_ARTICLES');
            return new Promise((resolve, reject) => {
                axios
                    .get('Article/GetAllAsync')
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            });

        },
        LOAD_ARTICLE_INFO: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Article/GetInformation`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        },
        ADD_UNREAD_ARTICLE_COUNT: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Article/GetInformation`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.haveUnreadArticles) {
                                store.commit("ADD_COUNT_UNREAD_MESSAGES", 1);
                                store.commit("SET_COUNT_UNREAD_ARTICLES", 1);
                                resolve(true);                                
                            }
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        },
        READ_ARTICLES: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Article/Read`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        }
    },
};