<template>
  <v-container>
    <v-row>
      <v-card tile elevation="0">
        <v-card-title>
          <v-text-field
            v-model="search"
            label="Поиск"
            class="rounded-0"
            single-line
            hide-details
            append-icon="mdi-magnify"
            dense
            outlined
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selectedGoods"
            :headers="headers"
            :items="companyGoodsGroups"
            item-key="id"
            class="elevation-0"
            :single-select="singleSelect"
            :search="search"
            show-select
            :loading="loading"
            loading-text="Идет загрузка..."
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" small outlined tile @click="apply()">
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "CompanyUserGroupCompanyGoodsGroup",
  computed: {
    selectedUserGroupId: function(){
        return this.$store.getters.SELECTED_COMPANY_USER_GROUP;
    }
  },
  data() {
    return {
      headers: [
        { text: "Идентификатор", value: "id", align: "center" },
        { text: "Наименование", value: "name", align: "center" },
      ],
      companyGoodsGroups: [],
      tempCompanyGoodsGroups: [],
      companyUserGroup: null,
      search: "",
      singleSelect: false,
      selectedGoods: [],
      loading: false,
    };
  },
  methods: {
    async getCompanyGoodsGroups() {
      await this.$store
        .dispatch("GET_COMPANY_GOODS_GROUPS")
        .then(() => {
          this.tempCompanyGoodsGroups = this.$store.getters.COMPANY_GOODS_GROUPS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    async getCompanyUserGroup() {
      await this.$store
        .dispatch("GET_COMPANY_USER_GROUPS")
        .then(() => {
          let companyUserGroups = this.$store.getters.COMPANY_USER_GROUPS;
          let companyUserGroup = companyUserGroups.find(
            (x) => x.id == this.selectedUserGroupId
          );
          if (companyUserGroup != undefined) {
            let tempCompanyUserGroupGoodsGroups =
              companyUserGroup.companyUserGroupGoodsGroups.map(
                (x) => x.companyGoodGroupId
              );
            this.companyGoodsGroups = this.tempCompanyGoodsGroups.filter(
              (x) => !tempCompanyUserGroupGoodsGroups.includes(x.id)
            );
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    apply() {
      let param = {
        list: this.selectedGoods.map((x) => ({
          companyUserGroupId: this.selectedUserGroupId,
          companyGoodGroupId: x.id,
        })),
      };
      this.$store
        .dispatch("ADD_RANGE_COMPANY_USER_GROUP_COMPANY_GOODS_GROUPS", param)
        .then(() => {
          let addedItems =
            this.$store.getters.COMPANY_USER_GROUP_COMPANY_GOODS_GROUPS;

          let companyUserGroups = this.$store.getters.COMPANY_USER_GROUPS;
          let companyUserGroup = companyUserGroups.find(
            (x) => x.id == this.selectedUserGroupId
          );

          if (companyUserGroup != undefined) {
            companyUserGroup.companyUserGroupGoodsGroups = [
              ...new Set([
                ...companyUserGroup.companyUserGroupGoodsGroups,
                ...addedItems,
              ]),
            ];

            for (var i = 0; i < companyUserGroups.length; i++) {
              if (companyUserGroups[i].id == this.selectedUserGroupId) {
                companyUserGroups[i].companyUserGroupGoodsGroups =
                  companyUserGroup.companyUserGroupGoodsGroups;
              }
            }

            this.$store.commit("SET_COMPANY_USER_GROUPS", companyUserGroups);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    async initialize() {
      this.loading = true;
      await this.getCompanyGoodsGroups();
      await this.getCompanyUserGroup();
      this.loading = false;
    },
  },
  async mounted(){
    await this.initialize();
  }
};
</script>