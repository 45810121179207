import axios from "axios";
import store from './../index'

export default {
  state: {
    promos: null,
  },
  getters: {
    PROMOS: state => {
      return state.promos;
    },
  },
  mutations: {
    SET_PROMOS: (state, payload) => {
      state.promos = payload;
    },
  },
  actions: {
    GetPromoDescription: (commit, payload) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`/Price/GetPromoByPriceId?priceId=` + payload)
                .then(({
                    data,
                    status
                }) => {
                    if (status === 200) {
                        window.console.log('promos',data);
                        store.commit('SET_PROMOS', data);
                        resolve(data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
  },
};