import axios from 'axios';
import store from '../store'

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ?
  'https://testapi.provisor.kz' :
  'http://localhost:65186'

axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${store.getters.ACCESS_TOKEN}`;
  return config;
});

let subscribers = []
let isRefreshing = false

function onAccessTokenFetched() {
  subscribers = subscribers.filter(callback => callback())
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

axios.interceptors.response.use(function (response) {
  return response
}, (error) => {
  window.console.log(error)

  let Snackbar = {
    text: 'Пожалуйста проверьте соединение с интернетом',
    timeout: 3000,
    color: "error",
    show: true
  }

  if (typeof error.response === 'undefined') {
    store.commit('SET_SNACKBAR', Snackbar);
    return Promise.reject(error);
  }

  switch (error.response.status) {

    case 401: {


      let Token = {
        Access: store.getters.ACCESS_TOKEN,
        Refresh: store.getters.REFRESH_TOKEN
      }

      if (!isRefreshing) {
        isRefreshing = true
        store.dispatch("UPDATE_TOKENS", Token).then(() => {
          isRefreshing = false
          onAccessTokenFetched()
        }).catch(() => {
          isRefreshing = false
          store.dispatch("CLEAR_TOKENS")
        })
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(() => {
          resolve(axios(error.response.config))
        })
      })
      return retryOriginalRequest
    }

    case 400: {
 
      if (error.response.data.errors)
        return Promise.reject(error);
      Snackbar.text = error.response.data.error;
      store.commit('SET_SNACKBAR', Snackbar);
      return Promise.reject(error);
    }

    case 403: {
      Snackbar.text = 'У вас нет доступа к этому ресурсу.';
      store.commit('SET_SNACKBAR', Snackbar);
      return Promise.reject(error);
    }

    default: {
      Snackbar.text = error.response.status;
      store.commit('SET_SNACKBAR', Snackbar);
      return Promise.reject(error);
    }
  }
})

export default 'axios';