<template>
  <v-card :loading="loading">
    <v-card-title>
      <span>
        <code>Конфигурация выгрузки адресов</code>
        {{this.filial.name}}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <v-col>
            <span>Конфигурация исходящего запроса</span>
            <v-text-field
              label="[Url]"
              v-model="editShippingAddressConfig.requestConfig.url"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[Urn]"
              v-model="editShippingAddressConfig.requestConfig.urn"
              required
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[BINParam]"
              v-model="editShippingAddressConfig.requestConfig.binParam"
              required
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[HeaderAuthorizationValue]"
              v-model="editShippingAddressConfig.requestConfig.headerAuthorizationValue"
              required
            ></v-text-field>
            <v-text-field
              label="[Encoding]"
              v-model="editShippingAddressConfig.requestConfig.encoding"
              required
            ></v-text-field>
            <v-select
              :items="APIRequestProtocols"
              v-model="editShippingAddressConfig.requestConfig.requestProtocol"
              label="[RequestProtocol]"
              item-text="protocol"
              item-value="id"
              :rules="rules"
            ></v-select>
          </v-col>
          <v-col>
            <span>Конфигурация принятого ответа</span>
            <v-text-field
              label="[XPathLocalName]"
              v-model="editShippingAddressConfig.responseConfig.xPathLocalName"
              required
            ></v-text-field>
            <v-text-field
              label="[AddressIdParam]"
              v-model="editShippingAddressConfig.responseConfig.addressIdParam"
              required
            ></v-text-field>
            <v-text-field
              label="[BINParam]"
              v-model="editShippingAddressConfig.responseConfig.binParam"
              required
            ></v-text-field>
            <v-text-field
              label="[AddressNameParam]"
              v-model="editShippingAddressConfig.responseConfig.addressNameParam"
              required
            ></v-text-field>
            <v-text-field
              label="[Encoding]"
              v-model="editShippingAddressConfig.responseConfig.encoding"
              required
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="APIDataFormats"
              v-model="editShippingAddressConfig.responseConfig.dataFormat"
              label="[DataFormat]"
              :rules="rules"
              item-value="id"
              item-text="format"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('on-shipping-address-config-dialog', false)">Закрыть</v-btn>
      <v-btn color="primary" @click="UpdateShippingAddressConfig(); ">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
  data: () => ({
    defaultShippingAddressConfig: {
      requestConfig: {
        filialId: null,
        url: null,
        urn: null,
        binParam: null,
        headerAuthorizationValue: null,
        encoding: null,
        requestProtocol: null,
      },
      responseConfig: {
        filialId: null,
        xPathLocalName: null,
        addressIdParam: null,
        binParam: null,
        addressNameParam: null,
        encoding: null,
        dataFormat: null,
      },
    },
    editShippingAddressConfig: {
      requestConfig: {
        filialId: null,
        url: null,
        urn: null,
        binParam: null,
        headerAuthorizationValue: null,
        encoding: null,
        requestProtocol: null,
      },
      responseConfig: {
        filialId: null,
        xPathLocalName: null,
        addressIdParam: null,
        binParam: null,
        addressNameParam: null,
        encoding: null,
        dataFormat: null,
      },
    },

    rules: [(value) => !!value || "Обязательное поле для заполнения"],
    loading: false,
  }),
  methods: {
    UpdateShippingAddressConfig() {
      this.editShippingAddressConfig.requestConfig.filialId = this.editShippingAddressConfig.responseConfig.filialId = this.filial.id;

      var payload = {
        shippingAddressRequestConfigVM: this.editShippingAddressConfig
          .requestConfig,
        shippingAddressResponseConfigVM: this.editShippingAddressConfig
          .responseConfig,
      };

      this.$store
        .dispatch("UPDATE_SHIPPING_ADDRESS_CONFIG", payload)
        .then(() => {
          this.$emit("on-shipping-address-config-dialog", false);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetShippingAddressConfig() {
      this.loading = true;
      this.editShippingAddressConfig = JSON.parse(
        JSON.stringify(this.defaultShippingAddressConfig)
      );

      this.$store
        .dispatch("SHIPPING_ADDRESS_CONFIG", this.filial.id)
        .then((response) => {

          if (response.requestConfig)
            this.editShippingAddressConfig.requestConfig =
              response.requestConfig;

          if (response.responseConfig)
            this.editShippingAddressConfig.responseConfig =
              response.responseConfig;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
  },
  async mounted() {},
  computed: {
    APIRequestTypes: {
      get: function () {
        return this.$store.getters.API_REQUEST_TYPES;
      },
    },
    APIRequestProtocols: {
      get: function () {
        return this.$store.getters.API_REQUEST_PROTOCOLS;
      },
    },
    APIDataFormats: {
      get: function () {
        return this.$store.getters.API_REQUEST_DATA_FORMATS;
      },
    },
  },
};
</script>

<style>
</style>