<template>
  <div>
    <v-card-title>
      Реклама
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table :headers="adHeaders" :items="adItems" class="elevation-1">
      <template v-slot:item.edit="{ item }">
        <v-btn small @click="EditAd(item)" color="primary">Редактировать</v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Реклама</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialog = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>{{ ad.location }}</v-card-title>
        <v-card-text>
          <AdTemplateEdit :adLocation="ad.id"></AdTemplateEdit>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    ad: {},
    dialog: false,
    adHeaders: [
      {
        text: "Идентификатор",
        align: "left",
        sortable: true,
        value: "id",
      },
      { text: "Расположение", value: "location" },
      { text: "", value: "edit", sortable: false },
    ],
    adItems: [],
    locations: [],
    itemContentOpenTypes: [],
  }),

  methods: {
    EditAd(ad) {
      this.dialog = !this.dialog;
      this.ad = ad;
    },
    GetAds() {
      this.$store
        .dispatch("ADS")
        .then(() => {
          this.adItems = this.$store.getters.ADS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.GetAds();
  },
};
</script>>
