<template>
  <v-dialog
    v-model="accessDialog.show"
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn icon @click="Close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Доступы пользователя {{ this.accessDialog.login }}
          {{ this.accessDialog.firstName }}
          {{ this.accessDialog.lastName }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn v-if="selectedTemplates.length" text @click="CreateApproval()" :disabled="!valid">Сохранить</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-sheet>
                <v-text-field
                  v-model="search"
                  label="Поиск по шаблонам"
                  flat
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                v-model="selectedTemplates"
                :items="allTemplates"
                selectable
                return-object
                open-on-click
                expand-icon="mdi-chevron-down"
                on-icon="mdi-bookmark"
                off-icon="mdi-bookmark-outline"
                indeterminate-icon="mdi-bookmark-minus"
                :search="search"
                item-key="id"
                item-disabled="isDisabled"
                dense
                :active.sync = "active"
                :open.sync = "open"
              >
              </v-treeview>
            </v-col>
            <v-divider vertical></v-divider>

            <v-col class="pa-4" cols="8"
            >
              <template
                v-if="userTemplates.length"
              >
                <h4>Шаблоны пользователя</h4>
                <v-list
                  >
                  <v-list-item
                    v-for="(template, index) in userTemplates"
                    :key="`usertemplates-${index}`"
                  >
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            v-model="template.accessTemplate.name"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                          label="Дата начала"
                          v-model="template.dateStart"
                          type="date"
                          readonly
                        >
                            <template v-slot:append>        
                              <v-icon> 
                                mdi-calendar
                              </v-icon> 
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                          label="Дата окончания"
                          v-model="template.dateEnd"
                          type="date"
                          readonly
                        >
                            <template v-slot:append>        
                              <v-icon
                                :color="template.iconColor"
                              > 
                                  mdi-calendar
                              </v-icon> 
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
           
              <template
                v-if="selectedTemplates.length"
              >
                <v-divider></v-divider>
                <h4>Новые доступы</h4>
                <v-form v-model="valid" ref="userDialogform" >
                  <v-list
                  >
                    <v-list-item
                      v-for="(template, index) in selectedTemplates"
                      :key="`seltemplate-${index}-${accessDialog.userId}`"
                    >
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="3">
                            <v-text-field
                              label="Название"
                              v-model="template.name"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              label="Дата начала"
                              v-model="template.dateStart"
                              :rules="[v => !!v || 'Выберите дату']"
                              type="date"               
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              label="Дата окончания"
                              v-model="template.dateEnd"
                              type="date"
                              :rules="[v => !!v || 'Выберите дату']"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-textarea
                    v-model="comment"
                    outlined
                    label="Комментарий"
                    rows="1"
                    class="mt-4"
                  ></v-textarea>
                </v-form>
              </template>
              <template
                v-if="pendingTemplates.length"
              >
                <v-divider></v-divider>
                <h4>На рассмотрении</h4>
                <v-list>
                  <v-list-item
                    v-for="(template, index) in pendingTemplates"
                    :key="`pendtemplate-${index}`"
                  >
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            v-model="template.name"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          class="ml-1"
                        >
                          <v-text-field
                            label="Дата начала"
                            v-model="template.dateStart"
                            type="date"
                            readonly
                          >
                            <template v-slot:append>        
                              <v-icon
                                color="orange"
                              > 
                                mdi-calendar
                              </v-icon> 
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          class="ml-2"
                        >
                          <v-text-field
                            label="Дата окончания"
                            v-model="template.dateEnd"
                            type="date"
                            readonly
                          >
                            <template v-slot:append>        
                              <v-icon
                                color="orange"
                              > 
                                mdi-calendar
                              </v-icon> 
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <div style="flex: 1 1 auto"></div>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  data: () => ({
    rules: {
      emailRules: [
        (v) => !!v || "Заполните поле",
        (v) => /.+@.+\..+/.test(v) || "E-mail должен быть действительным",
      ],
    },
    active: [],
    open: [],
    search: null,
    selectedTemplates: [],
    default: [],
    comment: "",
    modelState: {},
    valid: true
  }),
  mounted() {
  },
  methods: {
    Close(){
      this.active = [];
      this.open = [];
      this.selectedTemplates.filter(x =>{
        x.dateStart = null;
        x.dateEnd = null;
      });
      this.selectedTemplates = [];
      this.comment = "";
      this.accessDialog.show = false;
    },
    CreateApproval(){
      var isValid = this.$refs.userDialogform.validate();
      if (isValid){
          let payload = {
          userId: this.$store.getters.ACCESS_DIALOG.userId,
          selectedTemplates: this.selectedTemplates,
          comment: this.comment
        }
        this.$store
          .dispatch("CREATE_APPROVAL", payload)
          .then(()=>{
            this.Close();
          })
          .catch((error)=> {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          })
      }
      else {
        confirm('Заполните все поля');
      }
      
    }
  },
  computed: {
    allTemplates: {
      get: function(){
        let menu = [{ id: 0, name: "Pharmcenter.kz (Все шаблоны)", children: [] }]
        var tmpAllTemplates = this.$store.getters.ACCESS_TEMPLATES;
        tmpAllTemplates.forEach((item)=> {
          item.isDisabled = this.pendingTemplates.filter(x => x.id == item.id).length == 0 ? false: true;
        })
        menu[0].children = tmpAllTemplates;
        return menu;
      }
    },
    pendingTemplates: {
      get: function(){
        var tmpPendingTemplates =  this.$store.getters.GET_PENDING_TEMPLATES;
        tmpPendingTemplates.forEach((template)=> {
          var dateEnd = new Date(template.dateEnd);
          var datEndStr = dateEnd.getFullYear() + "-" + ("0" + (dateEnd.getMonth() + 1)).slice(-2) + "-" + ("0" + dateEnd.getDate()).slice(-2);
          var dateStart = new Date(template.dateStart);
          var datStartStr = dateStart.getFullYear() + "-" + ("0" + (dateStart.getMonth() + 1)).slice(-2) + "-" + ("0" + dateStart.getDate()).slice(-2);
          template.dateEnd = datEndStr;
          template.dateStart = datStartStr;
        })
        return tmpPendingTemplates;
      }
    },
    userTemplates: {
      get: function(){
        var tmpUserTemplates = this.$store.getters.GET_USER_TEMPLATES;
        tmpUserTemplates.forEach((template)=> {
          template.iconColor = (new Date() > new Date(template.dateEnd)) ? "red" : "green";
          var dateEnd = new Date(template.dateEnd);
          var datEndStr = dateEnd.getFullYear() + "-" + ("0" + (dateEnd.getMonth() + 1)).slice(-2) + "-" + ("0" + dateEnd.getDate()).slice(-2);
          var dateStart = new Date(template.dateStart);
          var datStartStr = dateStart.getFullYear() + "-" + ("0" + (dateStart.getMonth() + 1)).slice(-2) + "-" + ("0" + dateStart.getDate()).slice(-2);
          template.dateEnd = datEndStr;
          template.dateStart = datStartStr;
        })
        return tmpUserTemplates;
      }
    },
    accessDialog: {
      get: function () {
        return this.$store.getters.ACCESS_DIALOG;
      },
    },
  },
  watch: {
  },
  created: function () {
    this.$store
      .dispatch("ACCESS_TEMPLATES")
      .then(() => {})
  },
};
</script>
