import {
  Line,
  mixins
} from "vue-chartjs";
const {
  reactiveProp
} = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    type: Object,
    default: null
  },
  options: {
    type: Object,
    default: null
  },
  mounted() {
    // this.chartData создаётся внутри миксина.
    // Если вы хотите передать опции, создайте локальный объект options

    this.renderChart(this.chartData, this.options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: value => this.$options.filters.toCurrency(value)
          }
        }]
      },

      responsive: true,
      maintainAspectRatio: false
    });
  }
};