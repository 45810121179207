import axios from "axios";
import store from './../index'

export default {
    state: {
        accessStorages: [],
        storageGoods: [],
        deleteStorageGoodsStatus: null,
        dialog: false
    },
    getters: {
        ACCESS_STORAGES: state => {
            return state.accessStorages;
        },
        STORAGE_GOODS: state => {
            return state.storageGoods;
        },
        DELETE_STORAGE_GOODS_STATUS: state => {
            return state.deleteStorageGoodsStatus;
        },
        STORAGE_DIALOG: state => {
            return state.dialog;
        }
    },
    mutations: {
        SET_ACCESS_STORAGES: (state, payload) => {
            state.accessStorages = payload;
        },
        SET_STORAGE_GOODS: (state, payload) => {
            state.storageGoods = payload;
        },
        SET_NEW_STORAGE: (state, payload) => {
            state.accessStorages.push(payload);
        },
        SET_DELETE_STORAGE: (state, payload) => {
            state.accessStorages = state.accessStorages.filter(
                x => x.storage.id != payload
            );
        },
        SET_NEW_STORAGE_SINGLE_GOODS: (state, payload) => {
            state.storageGoods.push(payload);
        },
        SET_NEW_STORAGE_MULTIPLE_GOODS: (state, payload) => {
            state.storageGoods.push(...payload);
        },
        SET_DELETE_STORAGE_GOODS_STATUS: (state, payload) => {
            state.deleteStorageGoodsStatus = payload;
        },
        SET_STORAGE_DIALOG: (state, payload) => {
            state.dialog = payload
        }
    },
    actions: {
        ACCESS_STORAGES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Storage/GetAccessStoragesAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_ACCESS_STORAGES', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        STORAGE_GOODS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Storage/GetStorageGoodsByIdAsync?storageId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_STORAGE_GOODS', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        CREATE_STORAGE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Storage/Create`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            payload.id = data
                            store.commit('SET_NEW_STORAGE', {
                                storage: payload
                            });
                            let Success = {
                                text: 'Создано хранилище с именем ' + payload.name,
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        DELETE_STORAGE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Storage/DeleteById?id=` + payload)
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_DELETE_STORAGE', payload);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_STORAGE_GOODS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Storage/CreateStorageGoodsAsync`, payload)
                    .then(({
                        status
                    }) => {

                        if (status === 200) {
                            let NewStorageGoods = {
                                goodsId: payload.goodsId,
                                goods: {
                                    fullName: payload.goodsFullName
                                },
                                quantity: payload.goodsQuantity,
                                userCreated: {
                                    firstName: store.getters.USER.firstName
                                },
                                storageId: payload.storageId,
                                userCreatedId: store.getters.USER.id
                            }
                            store.commit('SET_NEW_STORAGE_SINGLE_GOODS', NewStorageGoods);
                            let Success = {
                                text: 'Ваш товар успешно добавлен к хранилище ',
                                timeout: 5000,
                                color: 'success',
                                show: true,
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        DELETE_STORAGE_GOODS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Storage/DeleteStorageGoodsAsync`, {data : payload})
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_DELETE_STORAGE_GOODS_STATUS', true);               
                        }
                        else{
                            store.commit('SET_DELETE_STORAGE_GOODS_STATUS', false);
                        }
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        ACCESS_USERS_BY_STORAGE_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Storage/GetAccessUsersByStorageIdAsync?storageId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_ACCESS_USERS_STORAGE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Storage/CreateAccessUsersStorageAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            store.commit('SET_NEW_STORAGE_SINGLE_GOODS', data);
                            let Success = {
                                text: 'Доступ предоставлен',
                                timeout: 5000,
                                color: 'success',
                                show:true
                                
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        MOVE_DETAILS_TO_STORAGE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Storage/MoveDetails?toStorageId=` + payload.toStorageId,
                        payload.details.map(x => x.id),
                    )
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            // store.commit('SET_DELETE_DETAILS', payload.details);

                            payload.details.forEach(function (element) {
                                element.userCreated = {
                                    firstName: store.getters.USER.firstName
                                };
                                element.storageId = payload.toStorageId;
                                element.userCreatedId = store.getters.USER.id
                            });

                            store.commit('SET_NEW_STORAGE_MULTIPLE_GOODS', payload.details);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        COPY_DETAILS_TO_STORAGE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Storage/CopyDetails?toStorageId=` + payload.toStorageId,
                        payload.details.map(x => x.id),
                    )
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
};