<template>
    <div id="form">
        <v-row justify="center">
            <v-col>
                <v-card-title>
                    Приоритеты
                    <v-spacer></v-spacer>
                    <v-text-field v-model="searchFilials" prepend-icon="mdi-cloud-search-outline"
                        label="Поиск"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-autocomplete 
                        label="Линия приоритетности" 
                        item-text="name" 
                        item-value="number" 
                        :items="lines.filter(element => element.filter == true)"
                        @change="GetFilialsByLine" 
                        persistent-hint 
                        hint="Выберите линию" 
                        clearable
                        v-model="filterLine"
                        return-object
                    ></v-autocomplete>
                </v-card-title>

                <v-sheet v-if="firstLoad" class="px-3 pt-3 pb-3">
                    <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
                    <v-skeleton-loader class="mx-auto" type="table-row-divider@5"></v-skeleton-loader>
                    <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
                </v-sheet>

                <v-card>
                    <v-form ref="listForm" lazy-validation>
                        <v-data-table 
                            :headers="filialHeaders" 
                            v-show="!firstLoad" 
                            :items="filials" 
                            :items-per-page="15"
                            class="elevation-2" 
                            :loading="loading"
                            :search="searchFilials"
                            >

                            <template v-slot:item.line ="{ item }">
                                <v-chip @click="EditFilialLine(item)"
                                    :color="`${item.color}`"
                                    outlined
                                >
                                    {{ lines.find(({ number }) => number === item.line).name }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
        
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field disabled v-model="editedItem.name" label="Поставщик"></v-text-field>
                            </v-col>
                            <v-select
                                v-model="editedItem.line"
                                prepend-icon="mdi-city-variant-outline"
                                :items="lines"
                                menu-props="auto"
                                label="Линия"
                                single-line
                                item-text="name"
                                item-value="number"
                            ></v-select>
                        </v-row>
                    </v-container>
                </v-card-text>
        
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save" :disabled='isDisabled'>
                        Изменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    inject: ["theme"],
    data: () => ({
        filterLine: null,
        formTitle: 'Изменить приоритет',
        dialog: false,
        filials: [],
        distributors: [],
        loading: false,
        firstLoad: true,
        searchFilials: "",
        filialHeaders: [
            { text: "ID", sortable: true, value: "id", align: "center"},
            { text: "Поставщик", value: "name", align: "center" },
            { text: "Линия", sortable: false, value: "line", align: "center" },
        ],
        editedItem: {
            name: "",
            line: { number: 0, name: "Не выбрана", color: "grey" }
        },
        lines: [
            { number: 0, name: "Не выбрана", color: "grey", filter: false },
            { number: 1, name: "Первая линия", color: "green", filter: true },
            { number: 2, name: "Вторая линия", color: "primary", filter: true },
            { number: 3, name: "Третья линия", color: "secondary", filter: true },
        ],
        oldline: null,
        editFilialId: null,
    }),
    async mounted() {
        this.GetFilialLines();
    },
    methods: {
        GetFilialLines() {
            this.firstLoad = true;
            this.$store
                .dispatch("FILIAL_WITH_LINES_BY_CONTEXT")
                .then(() => {
                    this.firstLoad = false;
                    this.filials = this.$store.getters.FILIALS;
                    this.filials.forEach(element => {
                        element.line = element.filialLines[0]?.line ?? 0;
                        element.color = this.lines.find(({ number }) => number === element.line).color;
                    });
                    this.GetFilialsByLine(this.filterLine)
                })
                .catch(() => {
                });  
        },
        save() {
            if (this.editedItem.line === 0) {
                this.$store
                    .dispatch("DELETE_FILIAL_LINE", this.editedItem.filialLineId)
                    .then(() => {
                        this.GetFilialLines();
                        this.dialog = false;
                    })
                    .catch(() => {
                        let Snackbar = {
                            text: "Произошла ошибка",
                            timeout: 3000,
                            color: "error",
                            show: true,
                        };
                        this.$store.commit("SET_SNACKBAR", Snackbar);
                    })
            }
            else {

                let payload = {
                    filialId: this.editFilialId,
                    line: this.editedItem.line
                }

                this.$store
                    .dispatch("UPDATE_FILIAL_LINE", payload)
                    .then(() => {
                        this.GetFilialLines();
                        this.dialog = false;
                    })
                    .catch(() => {
                        let Snackbar = {
                            text: "Произошла ошибка",
                            timeout: 3000,
                            color: "error",
                            show: true,
                        };
                        this.$store.commit("SET_SNACKBAR", Snackbar);
                    })
                
            }
        },
        close() {
            this.dialog = false;  
        },
        EditFilialLine(value) {
            let newObject = Object.assign({}, value);
            this.editedItem.name = newObject.name;
            this.editedItem.line = newObject.line;
            this.editedItem.filialLineId = newObject.filialLines[0]?.id;

            this.editFilialId = newObject.id;
            this.oldline = newObject.line;
            this.dialog = true;
        },
        GetFilialsByLine(line) {
            if (line == null) {
                this.filials = this.$store.getters.FILIALS;
            }
            else {
                this.filials = this.$store.getters.FILIALS
                    .filter(element => element.line == line.number);
            }
        }, 
    },
    created() {
    },
    computed: {
        isDisabled: {
            get() {
                return this.oldline == this.editedItem.line;
            },
        }
    }
};
</script>

<style >
</style>