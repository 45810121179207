var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('onCloseDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title'),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.$emit('onCloseDialog')}}},[_vm._v(" Закрыть ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-subheader'),_c('v-list-item',[_c('v-card',{key:_vm.orderFullScreen.id,staticClass:"mb-3",attrs:{"id":("order" + (_vm.orderFullScreen.id))}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-truncate",attrs:{"cols":"9"}},[_c('v-list-item',[_c('v-list-item-avatar'),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Номер заказа: "),_c('code',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.orderFullScreen.id))]),_vm._v(" Дата создания: "+_vm._s(new Date(_vm.orderFullScreen.createdDate).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit", }))+" ")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.orderFullScreen.filial.name))])],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.$emit('onCloseDialog')}}},on),[_c('v-icon',[_vm._v("mdi-fullscreen")])],1)]}}])},[_c('span',[_vm._v("Увидеть в полном экране")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.currentOrder = _vm.orderFullScreen;
                          _vm.dialog.shareOnChat = true;}}},on),[_c('v-icon',[_vm._v("mdi-share-variant")])],1)]}}])},[_c('span',[_vm._v("Поделиться в чате")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-card',{staticClass:"pt-4",staticStyle:{"position":"sticky","top":"60px"},attrs:{"elevation":"0"}},[_c('div',{staticClass:"mb-1"},[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""}},Object.assign({}, onTooltip, onMenu)),[_c('v-icon',[_vm._v("mdi-content-cut")])],1)]}}],null,true)},[_c('span',[_vm._v("Переместить в хранилище")])])]}}])},[_c('v-list',{attrs:{"dense":"","max-width":"230px"}},[(_vm.storages.length == 0)?_c('v-subheader',[_vm._v("У вас нет активных хранилище")]):_vm._e(),_vm._l((_vm.storages),function(accessStorage,index){return [(index !== 0)?_c('v-divider',{key:index}):_vm._e(),_c('v-list-item',{key:-index,on:{"click":function($event){0}}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.MoveDetailsToStorage(
                                _vm.orderFullScreen.selectedDetails,
                                accessStorage.storage.id
                              )}}},[_c('code',{staticClass:"subtitle-2"},[_vm._v(_vm._s(accessStorage.storage.name))])])],1)]})],2)],1)],1),_c('div',{staticClass:"mb-1"},[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""}},Object.assign({}, onTooltip, onMenu)),[_c('v-icon',[_vm._v("mdi-box-cutter")])],1)]}}],null,true)},[_c('span',[_vm._v("Переместить к заказам у того же поставщика")])])]}}])},[_c('v-list',{attrs:{"dense":"","two-line":"","max-width":"200px"}},[(
                          _vm.orders.filter(
                            function (x) { return x.filialId == _vm.orderFullScreen.filialId &&
                              x.id !== _vm.orderFullScreen.id; }
                          ).length < 1
                        )?[_c('v-subheader',[_vm._v("Нет других заказов у поставщика")])]:_vm._e(),_vm._l((_vm.orders.filter(
                          function (x) { return x.filialId == _vm.orderFullScreen.filialId &&
                            x.id !== _vm.orderFullScreen.id; }
                        )),function(orderItem,index){return [(index !== 0)?_c('v-divider',{key:index}):_vm._e(),_c('v-list-item',{key:-index,on:{"click":function($event){0}}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.MoveDetailsToOrder(
                                _vm.orderFullScreen.selectedDetails,
                                orderItem.id
                              )}}},[_c('v-list-item-title',[_vm._v(" К заказу "),_c('code',{staticClass:"subtitle-2"},[_vm._v(_vm._s(orderItem.id))])]),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(orderItem.createdDate)}})],1)],1)]})],2)],1)],1),_c('div',{staticClass:"mb-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.SplitDetails(_vm.orderFullScreen.selectedDetails)}}},on),[_c('v-icon',[_vm._v("mdi-call-split")])],1)]}}])},[_c('span',[_vm._v("Разделить заказ")])])],1),_c('div',{staticClass:"mb-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.DeleteDetails(_vm.orderFullScreen.selectedDetails)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Удалить позиции")])])],1)])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"filterable":"","headers":_vm.headers,"items":_vm.orderFullScreen.orderDetails,"dense":"","show-select":"","item-key":"id","items-per-page":100,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                              var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.orderFullScreen.orderDetails .map(function (x) { return x.id; }) .indexOf(item.id) + 1)+" ")])]}},{key:"item.quantity",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.quantity,"large":"","lazy":"","persistent":"","saveText":"Сохранить","cancelText":"Отмена"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "quantity", $event)},"update:return-value":function($event){return _vm.$set(props.item, "quantity", $event)},"save":function($event){return _vm.SaveQuantity(props.item)},"cancel":_vm.CancelQuantity,"open":_vm.OpenQuantity,"close":_vm.CloseQuantity},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","counter":"","autofocus":"","type":"number","rules":[_vm.quantityRules],"label":"Введите количество"},model:{value:(props.item.quantity),callback:function ($$v) {_vm.$set(props.item, "quantity", $$v)},expression:"props.item.quantity"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(props.item.quantity)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),(props.item.quantityChanged)?_c('v-badge',{attrs:{"color":"warning","bordered":"","content":("" + (props.item.oldQuantity))}}):_vm._e()],1)]}},{key:"item.price",fn:function(ref){
                              var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('span',{style:([ _vm.IsMinPrice(item) ? {}: {'color':'red'}])},[_vm._v(" "+_vm._s((item.price * (1 - item.discount / 100)).toFixed(2))+" ")]),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.GetPriceByGoodsIdAndCityId(item)}}},on),[_vm._v("mdi-redo")])]}}],null,true)},[_c('v-simple-table',{attrs:{"fixed-header":"","max-height":"300px","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Поставщик")]),_c('th',{staticClass:"text-left"},[_vm._v("Цена")]),_c('th',{staticClass:"text-left"},[_vm._v("Срок годности")]),_c('th',{staticClass:"text-left"},[_vm._v("Остатки")])])]),_c('tbody',_vm._l((_vm.goodsPrices),function(price){return _c('tr',{key:price.id,on:{"click":function($event){return _vm.SelectFilial(price, item.quantity)}}},[_c('td',[_vm._v(" "+_vm._s(price.filial.name)+" "),(price.userDiscount !== 0)?_c('code',[_vm._v(_vm._s(price.userDiscount)+"%")]):_vm._e(),_c('v-spacer'),(price.isntDiscount)?_c('code',[_vm._v("без скидки")]):_vm._e(),(price.isPromotion)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('code',_vm._g({},on),[_vm._v("акция")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(price.promotionDescription))])]):_vm._e()],1),_c('td',[(price.userDiscount !== 0)?_c('code',[_vm._v(_vm._s(( price.goodsPrice - (price.userDiscount * price.goodsPrice) / 100 ).toFixed(2)))]):_vm._e(),_vm._v(" "+_vm._s(price.goodsPrice)+" ")]),_c('td',[_c('span',[_vm._v(_vm._s(_vm._f("toDate")(price.shelfLife.substr(0, 10))))])]),_c('td',[(price.stored > 0)?_c('span',[_vm._v(_vm._s(price.stored))]):_c('span',[_vm._v("н/д")])])])}),0)]},proxy:true}],null,true)})],1)],1),(item.priceChanged)?_c('v-badge',{attrs:{"color":"warning","bordered":"","content":("" + (item.oldPrice))}}):_vm._e()]}},{key:"item.shelfLife",fn:function(ref){
                              var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toDate")(item.shelfLife.substr(0, 10))))]}},{key:"item.sum",fn:function(ref){
                              var item = ref.item;
return [_vm._v(_vm._s((item.quantity * item.price).toFixed(2)))]}},{key:"item.discount",fn:function(ref){
                              var item = ref.item;
return [_vm._v(_vm._s(item.discount)+" "),_c('v-spacer'),(item.discountChanged)?_c('v-badge',{attrs:{"color":"warning","bordered":"","content":("" + (item.oldDiscount))}}):_vm._e(),_c('v-spacer'),(item.isntDiscount)?_c('code',[_vm._v("без скидки")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","justify-end":""}},[_vm._v(" Общая сумма: "+_vm._s(_vm.orderFullScreen.orderDetails .map(function (x) { return x.quantity * x.price; }) .reduce(function (previousValue, currentValue) { return previousValue + currentValue; }) .toFixed(2))+" ")])],1)]},proxy:true}]),model:{value:(_vm.orderFullScreen.selectedDetails),callback:function ($$v) {_vm.$set(_vm.orderFullScreen, "selectedDetails", $$v)},expression:"orderFullScreen.selectedDetails"}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"1"}},[_c('v-card',{staticClass:"pt-4",staticStyle:{"position":"sticky","top":"60px"},attrs:{"elevation":"0"}},[_c('div',{staticClass:"mb-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","loading":_vm.loading.orderSend && _vm.orderFullScreen.id == _vm.currentOrder.id,"disabled":_vm.loading.orderSend && _vm.orderFullScreen.id == _vm.currentOrder.id},on:{"click":function($event){_vm.currentOrder = _vm.orderFullScreen;
                          _vm.dialog.orderSend = true;}}},on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}])},[_c('span',[_vm._v("Отправить заказ")])])],1),_c('div',{staticClass:"mb-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","loading":_vm.loading.deleteOrder && _vm.orderFullScreen.id == _vm.currentOrder.id,"disabled":_vm.loading.deleteOrder && _vm.orderFullScreen.id == _vm.currentOrder.id},on:{"click":function($event){_vm.DeleteOrder(_vm.orderFullScreen.id);
                          _vm.currentOrder.id = _vm.orderFullScreen.id;
                          _vm.loading.deleteOrder = true;}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Удалить заказ")])])],1)])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1000","scrollable":""},model:{value:(_vm.dialog.orderSend),callback:function ($$v) {_vm.$set(_vm.dialog, "orderSend", $$v)},expression:"dialog.orderSend"}},[_c('OrderSend',{attrs:{"order":_vm.currentOrder},on:{"onCloseDialog":function($event){_vm.dialog.orderSend = false}}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog.shareOnChat),callback:function ($$v) {_vm.$set(_vm.dialog, "shareOnChat", $$v)},expression:"dialog.shareOnChat"}},[_c('Share',{attrs:{"order":_vm.currentOrder},on:{"onCloseDialog":function($event){_vm.dialog.shareOnChat = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }