import mrcAxios from "../../config/mrcAxios"
import store from './../index'

export default {
    state: {
        producerGoodsDiscounts: [],
    },
    getters: {
        PRODUCER_GOODS_DISCOUNTS: state => {
            return state.producerGoodsDiscounts;
        },
    },
    mutations: {
        SET_PRODUCER_GOODS_DISCOUNTS: (state, payload) => {
            state.producerGoodsDiscounts = payload;
        }
    },
    actions: {
        GET_PRODUCER_GOODS_GROWTH_DISCOUNTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/GrowthDiscount/GetListByGoodsId?goodsId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_PRODUCER_GOODS_DISCOUNTS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
}