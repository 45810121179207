<template>
  <v-card :loading="loading">
    <v-card-title>
      <span>
        <code>Конфигурация отправка заказов</code>
        {{ this.filial.name }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <!-- Конфигурация исходящего запроса -->
          <v-col>
            <span>Конфигурация исходящего запроса</span>
            <v-text-field
              label="[Url]"
              v-model="editSendOrderConfig.requestConfig.url"
              :rules="rules"
            ></v-text-field>

            <v-text-field
              label="[Urn]"
              v-model="editSendOrderConfig.requestConfig.urn"
            ></v-text-field>

            <v-text-field
              label="[PostPodyParam]"
              v-model="editSendOrderConfig.requestConfig.postPodyParam"
            ></v-text-field>

            <v-text-field
              label="[NodeDistributorRootParam]"
              v-model="editSendOrderConfig.requestConfig.nodeDistributorRootParam"
            ></v-text-field>
            <v-text-field
              label="[NodeDistributorNamespaceRootParam]"
              v-model="editSendOrderConfig.requestConfig.nodeDistributorNamespaceRootParam"
            ></v-text-field>
            <v-text-field
              label="[NodeDistributorNamespaceParam]"
              v-model="editSendOrderConfig.requestConfig.nodeDistributorNamespaceParam"
            ></v-text-field>
            <v-text-field
              label="[NodeRootParam]"
              v-model="editSendOrderConfig.requestConfig.nodeRootParam"
            ></v-text-field>
            <v-text-field
              label="[NodeDetailsParam]"
              v-model="editSendOrderConfig.requestConfig.nodeDetailsParam"
            ></v-text-field>
            <v-text-field
              label="[NodeDetailParam]"
              v-model="editSendOrderConfig.requestConfig.nodeDetailParam"
            ></v-text-field>
            <v-text-field
              label="[OrderIdParam]"
              v-model="editSendOrderConfig.requestConfig.orderIdParam"
            ></v-text-field>
            <v-text-field
              label="[ContractIdParam]"
              v-model="editSendOrderConfig.requestConfig.contractIdParam"
            ></v-text-field>
            <v-text-field
              label="[AddressIdParam]"
              v-model="editSendOrderConfig.requestConfig.addressIdParam"
            ></v-text-field>
            <v-text-field
              label="[ContentType]"
              v-model="editSendOrderConfig.requestConfig.contentType"
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="APIDataFormats"
              v-model="editSendOrderConfig.requestConfig.dataFormat"
              label="[DataFormat]"
              item-value="id"
              :rules="rules"
              item-text="format"
            ></v-select>
            <v-text-field
              label="[DistributorGoodsIdParam]"
              v-model="
                editSendOrderConfig.requestConfig.distributorGoodsIdParam
              "
            ></v-text-field>
            <v-text-field
              label="[QuantityParam]"
              v-model="editSendOrderConfig.requestConfig.quantityParam"
            ></v-text-field>
            <v-text-field
              label="[GoodsPriceParam]"
              v-model="editSendOrderConfig.requestConfig.goodsPriceParam"
            ></v-text-field>
            <v-text-field
              label="[DiscountParam]"
              v-model="editSendOrderConfig.requestConfig.discountParam"
            ></v-text-field>
            <v-text-field
              label="[ShelfLifeParam]"
              v-model="editSendOrderConfig.requestConfig.shelfLifeParam"
            ></v-text-field>
            <v-text-field
              label="[ShelfLifeFormat]"
              v-model="editSendOrderConfig.requestConfig.shelfLifeFormat"
            ></v-text-field>
            <v-text-field
              label="[CommentParam]"
              v-model="editSendOrderConfig.requestConfig.commentParam"
            ></v-text-field>
            <v-text-field
              label="[Encoding]"
              v-model="editSendOrderConfig.requestConfig.encoding"
              required
            ></v-text-field>
            <v-select
              :items="APIRequestProtocols"
              v-model="editSendOrderConfig.requestConfig.requestProtocol"
              label="[RequestProtocol]"
              item-text="protocol"
              item-value="id"
              :rules="rules"
            ></v-select>
          </v-col>
          <!-- Конфигурация принятого ответа -->
          <v-col>
            <span>Конфигурация принятого ответа</span>
            <v-select
              :items="APIDataFormats"
              v-model="editSendOrderConfig.responseConfig.dataFormat"
              label="[DataFormat]"
              item-text="format"
              item-value="id"
              :rules="rules"
            ></v-select>
            <v-text-field
              label="[Encoding]"
              v-model="editSendOrderConfig.responseConfig.encoding"
              required
            ></v-text-field>
            <v-text-field
              label="[StatusParam]"
              v-model="editSendOrderConfig.responseConfig.statusParam"
              required
            ></v-text-field>
            <v-text-field
              label="[ErrorTextParam]"
              v-model="editSendOrderConfig.responseConfig.errorTextParam"
              required
            ></v-text-field>
            <v-text-field
              label="[StatusOkValue]"
              v-model="editSendOrderConfig.responseConfig.statusOkValue"
              required
            ></v-text-field>
            <v-text-field
              label="[StatusCancelValue]"
              v-model="editSendOrderConfig.responseConfig.statusCancelValue"
              required
            ></v-text-field>
            <v-text-field
              label="[StatusErrorValue]"
              v-model="editSendOrderConfig.responseConfig.statusErrorValue"
              required
            ></v-text-field>
            <v-text-field
              label="[StatusChangedValue]"
              v-model="editSendOrderConfig.responseConfig.statusChangedValue"
              required
            ></v-text-field>
            <v-text-field
              label="[FilialOrderIdParam]"
              v-model="editSendOrderConfig.responseConfig.filialOrderIdParam"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="$emit('on-send-order-config-dialog', false)"
        >Закрыть</v-btn
      >
      <v-btn color="primary" @click="UpdateOrderConfig()">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
  data: () => ({
    defaultSendOrderConfig: {
      requestConfig: {
        addressIdParam: null,
        commentParam: null,
        contractIdParam: null,
        discountParam: null,
        distributorGoodsIdParam: null,
        encoding: null,
        filial: null,
        filialId: null,
        goodsPriceParam: null,
        nodeDetailParam: null,
        nodeDetailsParam: null,
        postPodyParam:null,
        nodeDistributorRootParam:null,
        nodeDistributorNamespaceRootParam: null,
        nodeDistributorNamespaceParam: null,
        nodeRootParam: null,
        orderIdParam: null,
        dataFormat: null,
        contentType: null,
        quantityParam: null,
        requestProtocol: null,
        shelfLifeFormat: null,
        shelfLifeParam: null,
        url: null,
        urn: null,
      },
      responseConfig: {
        dataFormat: null,
        encoding: null,
        errorTextParam: null,
        filial: null,
        filialId: null,
        filialOrderIdParam: null,
        statusCancelValue: null,
        statusChangedValue: null,
        statusErrorValue: null,
        statusOkValue: null,
        statusParam: null,
      },
    },
    editSendOrderConfig: {
      requestConfig: {
        addressIdParam: null,
        commentParam: null,
        contractIdParam: null,
        discountParam: null,
        distributorGoodsIdParam: null,
        encoding: null,
        filial: null,
        filialId: null,
        goodsPriceParam: null,
        nodeDetailParam: null,
        nodeDetailsParam: null,
        postPodyParam:null,
        nodeDistributorRootParam:null,
        nodeDistributorNamespaceRootParam: null,
        nodeDistributorNamespaceParam: null,
        nodeRootParam: null,
        orderIdParam: null,
        dataFormat: null,
        contentType: null,
        quantityParam: null,
        requestProtocol: null,
        shelfLifeFormat: null,
        shelfLifeParam: null,
        url: null,
        urn: null,
      },
      responseConfig: {
        dataFormat: null,
        encoding: null,
        errorTextParam: null,
        filial: null,
        filialId: null,
        filialOrderIdParam: null,
        statusCancelValue: null,
        statusChangedValue: null,
        statusErrorValue: null,
        statusOkValue: null,
        statusParam: null,
      },
    },

    rules: [(value) => !!value || "Обязательное поле для заполнения"],
    loading: false,
  }),
  methods: {
    UpdateOrderConfig() {
      this.editSendOrderConfig.requestConfig.filialId = this.editSendOrderConfig.responseConfig.filialId = this.filial.id;

      var payload = {
        orderRequestConfigVM: this.editSendOrderConfig.requestConfig,
        orderResponseConfigVM: this.editSendOrderConfig.responseConfig,
      };

      this.$store
        .dispatch("UPDATE_ORDER_CONFIG", payload)
        .then(() => {
          this.$emit("on-send-order-config-dialog", false);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetOrderConfig() {
      this.loading = true;
      this.editSendOrderConfig = JSON.parse(
        JSON.stringify(this.defaultSendOrderConfig)
      );

      this.$store
        .dispatch("ORDER_CONFIG", this.filial.id)
        .then((response) => {
          if (response.requestConfig)
            this.editSendOrderConfig.requestConfig = response.requestConfig;

          if (response.responseConfig)
            this.editSendOrderConfig.responseConfig = response.responseConfig;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
  },
  async mounted() {},

  computed: {
    APIRequestTypes: {
      get: function () {
        return this.$store.getters.API_REQUEST_TYPES;
      },
    },
    APIRequestProtocols: {
      get: function () {
        return this.$store.getters.API_REQUEST_PROTOCOLS;
      },
    },
    APIDataFormats: {
      get: function () {
        return this.$store.getters.API_REQUEST_DATA_FORMATS;
      },
    },
  },
};
</script>

<style>
</style>