<template>
  <div>
    <v-card-title>
      Поставщики
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchDistributors"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-sheet :loading="loading" v-if="firstLoad" class="px-3 pt-3 pb-3">
      <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        type="table-row-divider@5"
      ></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
    </v-sheet>

    <v-data-table
      :headers="distributorHeaders"
      v-show="!firstLoad"
      :items="distributors"
      :items-per-page="10"
      class="elevation-2"
      :loading="loading"
      :search="searchDistributors"
    >
      <template v-slot:item.filial="{ item }">
        <v-btn
          small
          @click="
            dialog = !dialog;
            GetFilialsByDistributorId(item);
          "
          color="primary"
          >Филиалы</v-btn
        >
      </template>
    </v-data-table>

    <!-- Поставщики -->
    <v-dialog
      v-model="dialog"
      hide-overlay
      class="elevation-20"
      transition="scale-transition"
      width="1800px"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialog = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          Поставщики
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFilials"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="filialHeaders"
            :items="filials"
            :search="searchFilials"
            :items-per-page="500"
          >
            <!-- Настройка возможностей исходящих запросов -->
            <template v-slot:item.filialRequestAbilities="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click="GetFilialRequestAbilities(item)"
                    :loading="item.requestAbilities"
                  >
                    <v-icon>mdi-power-settings</v-icon>
                  </v-btn>
                </template>
                <span>Настройка возможностей исходящих запросов</span>
              </v-tooltip>
            </template>

            <!-- Конфигурация выгрузки адресов -->
            <template v-slot:item.shippingAddressConfig="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click="GetShippingAddressConfig(item)"
                    :loading="item.shippingAddressConfig"
                  >
                    <v-icon>mdi-ambulance</v-icon>
                  </v-btn>
                </template>
                <span>Конфигурация выгрузки адресов</span>
              </v-tooltip>
            </template>

            <!-- Конфигурация отправка заказов -->
            <template v-slot:item.sendOrderConfig="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click="GetSendOrderConfig(item)"
                    :loading="item.sendOrderConfig"
                  >
                    <v-icon>mdi-order-bool-descending-variant</v-icon>
                  </v-btn>
                </template>
                <span>Конфигурация отправка заказов</span>
              </v-tooltip>
            </template>

            <!-- Конфигурация выгрузки договоров -->
            <template v-slot:item.contractConfig="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click="GetContractConfig(item)"
                    :loading="item.contractConfig"
                  >
                    <v-icon>mdi-animation</v-icon>
                  </v-btn>
                </template>
                <span>Конфигурация выгрузки договоров</span>
              </v-tooltip>
            </template>

            <!-- Конфигурация выгрузки прайсов -->
            <template v-slot:item.priceConfig="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click="GetPriceConfig(item)"
                    :loading="item.priceConfig"
                  >
                    <v-icon>mdi-file-cloud-outline</v-icon>
                  </v-btn>
                </template>
                <span>Конфигурация выгрузки прайсов</span>
              </v-tooltip>
            </template>

            <!-- Конфигурация выгрузки прайсов -->
            <template v-slot:item.invoiceConfig="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    text
                    v-on="on"
                    @click="GetInvoiceConfig(item)"
                    :loading="item.invoiceConfig"
                  >
                    <v-icon>mdi-book-open</v-icon>
                  </v-btn>
                </template>
                <span>Конфигурация выгрузки накладных</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Настройка возможностей исходящих запросов [DIALOG]-->
    <v-dialog
      v-model="filialRequestAbilitiesDialog"
      persistent
      max-width="650px"
      scrollable
    >
      <FilialRequestAbilities
        :filial="currentFilial"
        @on-filial-request-abilities-dialog="FilialRequestAbilitiesDialog"
        ref="refFilialRequestAbilities"
      ></FilialRequestAbilities>
    </v-dialog>

    <!-- Конфигурация выгрузки адресов [DIALOG]-->
    <v-dialog
      v-model="shippingAddressConfigDialog"
      persistent
      max-width="1200px"
      scrollable
    >
      <ShippingAddressConfig
        :filial="currentFilial"
        @on-shipping-address-config-dialog="ShippingAddressConfigDialog"
        ref="refShippingAddressConfig"
      ></ShippingAddressConfig>
    </v-dialog>

    <!-- Конфигурация отправка заказов [DIALOG]-->
    <v-dialog
      v-model="sendOrderConfigDialog"
      persistent
      max-width="1200px"
      scrollable
    >
      <SendOrderConfig
        :filial="currentFilial"
        @on-send-order-config-dialog="SendOrderConfigDialog"
        ref="refSendOrderConfig"
      ></SendOrderConfig>
    </v-dialog>

    <!-- Конфигурация выгрузки договоров [DIALOG]-->
    <v-dialog
      v-model="contractConfigDialog"
      persistent
      max-width="1200px"
      scrollable
    >
      <ContractConfig
        :filial="currentFilial"
        @on-contract-config-dialog="ContractConfigDialog"
        ref="refContractConfig"
      ></ContractConfig>
    </v-dialog>

    <!-- Конфигурация выгрузки прайсов [DIALOG]-->
    <v-dialog
      v-model="priceConfigDialog"
      persistent
      max-width="1200px"
      scrollable
    >
      <PriceConfig
        :filial="currentFilial"
        @on-price-config-dialog="PriceConfigDialog"
        ref="refPriceConfig"
      ></PriceConfig>
    </v-dialog>

    <!-- Конфигурация накладных [DIALOG]-->
    <v-dialog
      v-model="invoiceConfigDialog"
      persistent
      max-width="1200px"
      scrollable
    >
      <InvoiceConfig
        :filial="currentFilial"
        @on-invoice-config-dialog="InvoiceConfigDialog"
        ref="refInvoiceConfig"
      ></InvoiceConfig>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,

    APIRequestTypes: [],
    APIRequestProtocols: [],
    APIDataFormats: [],
    InvoiceUnloadTypes:[],

    filialRequestAbilitiesDialog: false,
    shippingAddressConfigDialog: false,
    sendOrderConfigDialog: false,
    priceConfigDialog: false,
    contractConfigDialog: false,
    invoiceConfigDialog: false,

    distributors: [],
    filials: [],
    currentFilial: {},
    expanded: [],
    loading: true,
    firstLoad: true,
    searchDistributors: "",
    searchFilials: "",
    distributorHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Название", value: "name" },
      { text: "", value: "filial", sortable: false },
    ],
    filialHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Название", value: "name" },
      { text: "Телефон", value: "phone" },
      { text: "Почта", value: "email" },
      { text: "", value: "filialRequestAbilities", sortable: false },
      { text: "", value: "shippingAddressConfig", sortable: false },
      { text: "", value: "sendOrderConfig", sortable: false },
      { text: "", value: "contractConfig", sortable: false },
      { text: "", value: "priceConfig", sortable: false },
      { text: "", value: "invoiceConfig", sortable: false },
    ],
  }),
  async mounted() {
    this.GetDistributors();
    this.GetAPIRequestTypes();
    this.GetAPIRequestProtocols();
    this.GetAPIDataFormats();
    this.GetInvoiceUnloadTypes();
  },
  methods: {
    FilialRequestAbilitiesDialog(val) {
      this.filialRequestAbilitiesDialog = val;
    },
    GetFilialRequestAbilities(filial) {
      this.currentFilial = filial;
      this.filialRequestAbilitiesDialog = true;
      this.$nextTick(() => {
        this.$refs.refFilialRequestAbilities.GetFilialRequestAbilities();
      });
    },
    ShippingAddressConfigDialog(val) {
      this.shippingAddressConfigDialog = val;
    },
    GetShippingAddressConfig(filial) {
      this.currentFilial = filial;
      this.shippingAddressConfigDialog = true;
      this.$nextTick(() => {
        this.$refs.refShippingAddressConfig.GetShippingAddressConfig();
      });
    },
    SendOrderConfigDialog(val) {
      this.sendOrderConfigDialog = val;
    },
    GetSendOrderConfig(filial) {
      this.currentFilial = filial;
      this.sendOrderConfigDialog = true;
      this.$nextTick(() => {
        this.$refs.refSendOrderConfig.GetOrderConfig();
      });
    },
    ContractConfigDialog(val) {
      this.contractConfigDialog = val;
    },
    GetContractConfig(filial) {
      this.currentFilial = filial;
      this.contractConfigDialog = true;
      this.$nextTick(() => {
        this.$refs.refContractConfig.GetContractConfig();
      });
    },
    PriceConfigDialog(val) {
      this.priceConfigDialog = val;
    },
    GetPriceConfig(filial) {
      this.currentFilial = filial;
      this.priceConfigDialog = true;
      this.$nextTick(() => {
        this.$refs.refPriceConfig.GetPriceConfig();
      });
    },
    InvoiceConfigDialog(val) {
      this.invoiceConfigDialog = val;
    },
    GetInvoiceConfig(filial) {
      this.currentFilial = filial;
      this.invoiceConfigDialog = true;
      this.$nextTick(() => {
        this.$refs.refInvoiceConfig.GetInvoiceConfig();
      });
    },

    GetDistributors() {
      this.$store
        .dispatch("DISTRIBUTORS")
        .then(() => {
          this.Loading();
          this.distributors = this.$store.getters.DISTRIBUTORS;
        })
        .catch((error) => {
          this.Loading();
          window.console.log(error);
        });
    },
    GetFilialsByDistributorId(filial) {
      this.filials = this.$store.getters.FILIALS_BY_DISTRIBUTOR_ID(
        filial.id
      ).filials;
    },
    Loading() {
      this.firstLoad = !this.firstLoad;
      this.loading = !this.loading;
    },

    GetAPIRequestTypes() {
      this.$store
        .dispatch("API_REQUEST_TYPES")
        .then((response) => {
          this.$store.commit("SET_API_REQUEST_TYPES", response);
          this.APIRequestTypes = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAPIRequestProtocols() {
      this.$store
        .dispatch("API_REQUEST_PROTOCOLS")
        .then((response) => {
          this.$store.commit("SET_API_REQUEST_PROTOCOLS", response);
          this.APIRequestProtocols = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAPIDataFormats() {
      this.$store
        .dispatch("API_REQUEST_DATA_FORMATS")
        .then((response) => {
          this.$store.commit("SET_API_REQUEST_DATA_FORMATS", response);
          this.APIDataFormats = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetInvoiceUnloadTypes() {
      this.$store
        .dispatch("INVOICE_UNLOAD_TYPES")
        .then((response) => {
          this.$store.commit("SET_INVOICE_UNLOAD_TYPES", response);
          this.InvoiceUnloadTypes = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  computed: {
    userActions: {
      get: function () {
        return this.$store.getters.CURRENT_USER_ACTIONS;
      },
    },
  },
};
</script>