<template>
  <v-row justify="center">
    <v-responsive :aspect-ratio="16/9">
        <v-card-text>
         <h1>Страница сброса пароля</h1>
        </v-card-text>
      </v-responsive>
    <v-dialog v-model="dialog" persistent max-width="440">
      <v-card>
        <v-card-title class="text-h5"> Создайте пароль </v-card-title>
        <v-card-text>Пароль должен быть надежным.</v-card-text>
        <v-card-text>
          <v-text-field
            v-model="password"
            label="Пароль"
            name="password"
            prepend-icon="mdi-lock"
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
          />

          <v-text-field
            v-model="confirmPassword"
            label="Подтвердить Пароль"
            name="confirmPassword"
            prepend-icon="mdi-lock"
            :rules="confirmPasswordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="ResetPassword()" :loading="loading">
            Создать новый пароль
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      loading:false,
      showPassword: false,
      dialog: true,
      password: "",
      confirmPassword: "",
      passwordRules: [
        (value) => !!value || "Пожалуйста, введите пароль.",
        (value) => (value && value.length >= 6) || "минимум 6 символов",
      ],
      confirmPasswordRules: [
        (value) => !!value || "Подтвердить пароль",
        (value) =>
          value === this.password || "Подтверждение пароля не совпадает.",
      ],
    };
  },
  methods: {
    ResetPassword() {
      if (
        this.password === this.confirmPassword &&
        this.password.length >= 6 &&
        this.confirmPassword.length >= 6
      ) {
        this.$store
          .dispatch("RESET_PASSWORD", this.password)
          .then(() => {
            this.loading = false;
            this.$router.push("/");

              let Message = {
            text: "Ваш пароль был успешно изменен. ",
            timeout: 7000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Message);

          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            this.error = true;
          });
      }
    },
  },
};
</script>