<template >
  <div>
    <v-combobox
      v-model="headersComputed"
      :items="selectedHeaders"
      label="Скрытие и отображение столбцов"
      multiple
      small-chips
      :clearable="true"
      dense
      outlined
      :counter="headers.length"
      :hide-selected="true"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">{{ item.text }}</span>
          <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
        </v-chip>
      </template>
    </v-combobox>
    <v-card-title>
      Пользователи
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchLogin"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск по логину"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headersComputed"
      :items="users"
      :options.sync="options"
      class="elevation-2"
      :server-items-length="usersCount"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="900px" persistent scrollable>
            <template v-slot:activator="{ on }">
              <v-btn class="mb-2" small v-on="on" color="primary"
                >Создать</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" v-show="false">
                      <v-text-field
                        v-model="editedUser.id"
                        label="ID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedUser.login"
                        label="Логин"
                        :error-messages="modelState['Login']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedUser.firstName"
                        label="Имя"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedUser.lastName"
                        label="Фамилия"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.userRoleId"
                        :items="userRoles"
                        prepend-icon="mdi-shield-account-outline"
                        menu-props="auto"
                        hide-details
                        label="Роль"
                        single-line
                        item-text="name"
                        item-value="id"
                        :error-messages="modelState['userRoleId']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        v-model="editedUser.company"
                        :items="companies"
                        cache-items
                        :search-input.sync="searchCompanies"
                        :loading="loadingSearchCompany"
                        prepend-icon="mdi-domain"
                        label="Компания"
                        item-text="name"
                        item-value="id"
                        return-object
                        clearable
                        :error-messages="modelState['CompanyId']"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.accessUserCities"
                        prepend-icon="mdi-city-variant-outline"
                        :items="accessUserCities"
                        menu-props="auto"
                        label="Города"
                        single-line
                        item-text="name"
                        item-value="id"
                        return-object
                        multiple
                        :error-messages="modelState['AccessUserCities']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.accessUserBINs"
                        prepend-icon="mdi-city-variant-outline"
                        :items="accessUserBINs"
                        menu-props="auto"
                        label="БИНы"
                        single-line
                        item-text="bin"
                        item-value="bin"
                        return-object
                        multiple
                        :error-messages="modelState['AccessUserBINs']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-show="editedIndex === -1">
                      <v-text-field
                        v-model="editedUser.password"
                        label="Пароль"
                        :error-messages="modelState['Password']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-show="editedIndex === -1">
                      <v-text-field
                        v-model="editedUser.confirmPassword"
                        label="Подтвердите пароль"
                        :error-messages="modelState['ConfirmPassword']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="Close">Отмена</v-btn>
                <v-btn text @click="Save">Сохранить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <AccessDialog></AccessDialog>
        </v-toolbar>
      </template>

      <template v-slot:item.userPhones="props">
        <div
          v-for="item in props.item.userPhones"
          :key="item.phone"
          class="text-no-wrap"
        >
          <v-tooltip left >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Home'"
                >mdi-phone-classic</v-icon
              >
            </template>
            <span>Домашний</span>
          </v-tooltip>

          <v-tooltip left >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Mobile'"
                >mdi-cellphone</v-icon
              >
            </template>
            <span>Мобильный</span>
          </v-tooltip>

          <v-tooltip left >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Work'"
                >mdi-phone-outgoing</v-icon
              >
            </template>
            <span>Рабочий</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Fax'"
                >mdi-printer</v-icon
              >
            </template>
            <span>Факс</span>
          </v-tooltip>
          {{ item.phone }}
        </div>
      </template>

      <template v-slot:item.userEmails="props">
        <div
          v-for="item in props.item.userEmails"
          :key="item.email"
          class="text-no-wrap"
        >
          <v-icon x-small>mdi-email</v-icon>
          {{ item.email }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)">mdi-pencil</v-icon>
      </template>

      <template v-slot:item.accessUserCities="props">
        <div
          v-for="item in props.item.accessUserCities"
          :key="item.id"
          class="text-no-wrap"
        >
          <span v-if="item.companyCity">
            <v-icon x-small>mdi-city</v-icon>
            {{ item.companyCity.city.name }}
          </span>
        </div>
      </template>

      <template v-slot:item.accessUserBINs="props">
        <div
          v-for="item in props.item.accessUserBINs"
          :key="item.id"
          class="text-no-wrap"
        >
          <span v-if="item.bin">
            <v-icon x-small>mdi-city</v-icon>
            {{ item.bin }}
          </span>
        </div>
      </template>

      <template v-slot:item.userAccesses="{ item }">
        <v-icon small class="mr-2" @click="EditUserAccesses(item)"
          >mdi-pencil</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    loadingSearchCompany: false,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    usersCount: 0,
    modelState: {},
    userRoles: [],
    companies: [],
    editedUser: {
      id: "",
      login: "",
      firstName: "",
      lastName: "",
      userRoleId: 0,
      company: {},
      companyId: 0,
      password: "",
      confirmPassword: "",
      accessUserCitiesId: [],
      accessUserBINsId: [],
    },
    defaultUser: {
      id: "",
      login: "",
      firstName: "",
      lastName: "",
      userRoleId: 0,
      company: {},
      companyId: 0,
      accessUserCitiesId: [],
      accessUserBINsId: [],
    },
    editedIndex: -1,
    users: [],
    dialog: false,
    searchCompanies: "",
    searchLogin: "temir",
    headers: [
      {
        text: "Id",
        align: "left",
        sortable: true,
        value: "id",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Логин",
        align: "left",
        sortable: true,
        value: "login",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Имя",
        align: "left",
        sortable: true,
        value: "firstName",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Фамилия",
        align: "left",
        sortable: true,
        value: "lastName",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Роль",
        align: "left",
        sortable: true,
        value: "userRole.name",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Дата создания",
        align: "left",
        sortable: true,
        value: "dateCreated",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Кем создано (Логин)",
        align: "left",
        sortable: true,
        value: "userCreated.login",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Дата изменения",
        align: "left",
        sortable: true,
        value: "changedDate",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Кем изменено (Логин)",
        align: "left",
        sortable: true,
        value: "changedUser.login",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Телефоны",
        align: "center",
        sortable: true,
        value: "userPhones",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Почты",
        align: "center",
        sortable: true,
        value: "userEmails",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Компания",
        align: "center",
        sortable: true,
        value: "company.name",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Город",
        align: "left",
        sortable: true,
        value: "accessUserCities",
        showHeader: true,
        exclude: false,
      },
      {
        text: "БИН",
        align: "left",
        sortable: true,
        value: "accessUserBINs",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Изменение",
        value: "actions",
        sortable: false,
        showHeader: true,
        exclude: false,
      },
      {
        text: "Доступы",
        value: "userAccesses",
        sortable: false,
        showHeader: true,
        exclude: false,
      },
    ],
    accessUserCities: [],
    accessUserBINs: [],
  }),
  async mounted() {
    this.GetUserRoles();
  },
  methods: {
    GetCompanies(val) {
      this.loadingSearchCompany = true;

      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };

      let CompaniesSearchProperty = {
        Name: `${val ?? ""}`,
      };

      let CompaniesSearch = {
        Pagination,
        CompaniesSearchProperty,
      };

      this.$store
        .dispatch("COMPANIES", CompaniesSearch)
        .then((response) => {
          this.companies = response.result;
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loadingSearchCompany = false;
        });
    },
    GetUserRoles() {
      this.$store
        .dispatch("USER_ROLES")
        .then(() => {
          this.userRoles = this.$store.getters.USER_ROLES;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetUsers() {
      this.loading = true;

      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };

      let UsersSearchProperty = {
        Login: `${this.searchLogin ?? ""}`,
      };

      let UsersSearch = {
        Pagination,
        UsersSearchProperty,
      };

      this.$store
        .dispatch("USERS", UsersSearch)
        .then((response) => {
          this.loading = false;
          this.users = response.result;
          this.usersCount = response.count;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
    EditItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.companies.push(this.editedUser.company);
      this.editedUser.accessUserCities = this.editedUser.accessUserCities.map(x=>x.city);

      window.console.log(
        "editedUser.accessUserCities",
        this.editedUser.accessUserCities
      );
      this.dialog = true;
    },

    EditUserAccesses(item) {
      this.$store
        .dispatch("ANOTHER_USER_ACTIONS_BY_ID", item.id)
        .then(() => {
          let AccessDialog = {
            show: true,
            userId: item.id,
            login: item.login,
            firstName: item.firstName,
            lastName: item.lastName,
          };
          this.$store.commit("SET_ACCESS_DIALOG", AccessDialog);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    Close() {
      this.modelState = {};
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },
    Save() {
      this.editedUser.accessUserCitiesId = this.editedUser.accessUserCities.map(
        (x) => x?.id
      );

      this.editedUser.accessUserBINsId = this.editedUser.accessUserBINs.map(
        (x) => x.bin
      );

      this.editedUser.companyId = this.editedUser.company?.id;

      if (this.editedIndex > -1) {
        this.$store
          .dispatch("UPDATE_USER", this.editedUser)
          .then((response) => {
            Object.assign(this.users[this.editedIndex], response);
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      } else {
        this.$store
          .dispatch("CREATE_USER", this.editedUser)
          .then(() => {
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.GetUsers();
      },
      deep: true,
    },
    searchLogin() {
      this.GetUsers();
    },
    dialog(val) {
      val || this.Close();
    },
    contentUpdate() {
      this.GetUsers();
    },
    "editedUser.company": function (val) {
      if (val) {

        this.accessUserCities = val.companyCities.map(x=>x.city);
        this.accessUserBINs = val.companyBINs;

      }
    },
    searchCompanies(val) {
      val && val !== this.editedUser.companyId && this.GetCompanies(val);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    contentUpdate() {
      return this.$store.getters.CONTENT_UPDATE;
    },
    selectedHeaders: {
      get: function () {
        return this.headers.filter((x) => x.exclude == false);
      },
    },
    headersComputed: {
      get: function () {
        return this.headers
          .filter((x) => x.showHeader == true)
          .filter((x) => x.exclude == false);
      },
      set: function (val) {
        val.forEach(function (header) {
          header.showHeader = true;
        });

        this.headers.forEach(function (header) {
          if (!val.map((x) => x.text).includes(header.text))
            header.showHeader = false;
        });
      },
    },
  },
};
</script>