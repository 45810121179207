import mrcAxios from "../../config/mrcAxios"
import store from './../index'

export default {
    state: {
        companyGoodsGroups: [],
        companyGoodsGroupPharm: null,
        selectedGoodsGroupId: null,
        companyGoodsGroupGrowthDiscounts: [],
        companyGoodsGroupGrowthDefaultDiscounts: []
    },
    getters: {
        COMPANY_GOODS_GROUPS: state => {
            return state.companyGoodsGroups;
        },
        COMPANY_GOODS_GROUP_PHARM: state => {
            return state.companyGoodsGroupPharm;
        },
        SELECTED_COMPANY_GOODS_GROUP_ID: state => {
            return state.selectedGoodsGroupId;
        },
        COMPANY_GOODS_GROUP_GROWTH_DISCOUNTS: state => {
            return state.companyGoodsGroupGrowthDiscounts;
        },
        COMPANY_GOODS_GROUP_GROWTH_DEFAULT_DISCOUNTS: state => {
            return state.companyGoodsGroupGrowthDefaultDiscounts;
        },
    },
    mutations: {
        SET_COMPANY_GOODS_GROUPS: (state, payload) => {
            state.companyGoodsGroups = payload;
        },
        SET_COMPANY_GOODS_GROUP_PHARM: (state, payload) => {
            state.companyGoodsGroupPharm = payload;
        },
        SET_SELECTED_COMPANY_GOODS_GROUP_ID: (state, payload) => {
            state.selectedGoodsGroupId = payload;
        },
        SET_COMPANY_GOODS_GROUP_GROWTH_DISCOUNTS: (state, payload) => {
            state.companyGoodsGroupGrowthDiscounts = payload;
        },
        SET_COMPANY_GOODS_GROUP_GROWTH_DEFAULT_DISCOUNTS: (state, payload) => {
            state.companyGoodsGroupGrowthDefaultDiscounts = payload;
        },
    },
    actions: {
        ADD_COMPANY_GOODS_GROUPS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .post(`/CompanyGoodsGroup/AddRange`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_GOODS_GROUPS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GET_COMPANY_GOODS_GROUPS: () => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/CompanyGoodsGroup/Get`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_GOODS_GROUPS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GET_COMPANY_GOODS_GROUP_PHARM: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .post(`/CompanyGoodsGroupPharm/Add`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_GOODS_GROUP_PHARM', data.data);
                                resolve(data);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMMIT_SELECTED_COMPANY_GOODS_GROUP_ID: (commit, payload) => {
            store.commit('SET_SELECTED_COMPANY_GOODS_GROUP_ID', payload);
        },
        ADD_COMPANY_GOODS_GROUP_GROWTH_DISCOUNTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .post(`/GrowthDiscount/AddRange`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_GOODS_GROUP_GROWTH_DISCOUNTS', data.data);
                                resolve(data);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        ADD_COMPANY_GOODS_GROUP_GROWTH_DEFAULT_DISCOUNTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .post(`/GrowthDiscount/AddRangeDefault`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_GOODS_GROUP_GROWTH_DEFAULT_DISCOUNTS', data.data);
                                resolve(data);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};