import axios from "axios";
import store from "..";


export default {
    state: {
        expiringTemplates: [],
        EXPNotice: sessionStorage.getItem('EXP_notice'),
        showExpNotice: false
    },
    getters: {
        GET_EXPIRING_TEMPLATES: state => {
            return state.expiringTemplates;
        },
        GET_EXP_NOTICE: state => {
            return state.EXPNotice;
        },
        GET_SHOW_EXP_NOTICE: state => {
            return state.showExpNotice;
        }
    },
    mutations: {
        SET_EXPIRING_TEMPLATES: (state, payload) => {
            state.expiringTemplates = payload;
        },
        SET_EXP_NOTICE: (state, payload) => {
            state.EXPNotice = payload;
            sessionStorage.setItem('EXP_notice', payload);
        },
        SET_SHOW_EXP_NOTICE: (state, payload) => {
            state.showExpNotice = payload;
        },
        CLEAR_EXP_NOTICE: (state) => {
            state.EXPNotice = null;
            sessionStorage.removeItem('EXP_notice');
        }
    },
    actions: {
        GET_MANAGERS: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`User/GetManagers`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                      }  
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        GET_USER_EXPIRING_TEMPLATES: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Access/GetExpiringTemplates`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.length)
                                store.commit('SET_SHOW_EXP_NOTICE', true);
                            store.commit('SET_EXPIRING_TEMPLATES', data);
                            store.commit('SET_EXP_NOTICE', true);
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        CREATE_NOTIFICATION: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Notification/Create`, payload)
                    .then(({

                        status
                    }) => {

                        if (status === 200) {

                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        NOTIFICATION_BY_GOODS_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Notification/GetSingleByGoodsIdAsync?goodsId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
};