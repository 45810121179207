<template>
  <v-treeview dense open-on-click :items="allAPIActions">
    <template v-slot:prepend="{ item }">
      <v-icon
        color="primary"
        v-if="item.isDifferenceAction && !item.isNewAction"
      >{{ icons[item.isDifferenceAction] }}</v-icon>
      <v-icon
        color="primary"
        v-if="item.isDifferenceModule && !item.isNewModule"
      >{{ icons[item.isDifferenceModule] }}</v-icon>
      <v-icon color="primary" v-if="item.isNewAction">{{ icons[item.isNewAction] }}</v-icon>
      <v-icon color="primary" v-if="item.isNewModule">{{ icons[item.isNewModule] }}</v-icon>
    </template>

    <template v-slot:append="{ item }">
      <template v-if="!item.children">
        <v-btn x-small text color="primary" v-if="item.isAvailableToAll">Доступен для всех</v-btn>
        <v-btn x-small color="primary" v-if="item.isNewAction" @click="CreateAction(item)">Создать</v-btn>
        <v-btn x-small color="warning" v-else @click="DeleteAction(item)">Удалить</v-btn>
        <v-btn
          x-small
          color="success"
          v-if="item.isDifferenceAction && !item.isNewAction"
          @click="UpdateAction(item)"
        >Обновить</v-btn>
      </template>
      <template v-else>
        <v-btn x-small color="primary" v-if="item.isNewModule" @click="CreateModule(item)">Создать</v-btn>
        <v-btn x-small color="warning" v-else @click="DeleteModule(item)">Удалить</v-btn>
        <v-btn
          x-small
          color="success"
          v-if="item.isDifferenceModule && !item.isNewModule"
          @click="UpdateModule(item)"
        >Обновить</v-btn>
      </template>
    </template>
  </v-treeview>
</template>
<script>
export default {
  data: () => ({
    allAPIActions: [],
    icons: {
      new: "mdi-new-box",
      update: "mdi-update",
    },
  }),
  methods: {
    CreateModule(newModule) {
      window.console.log("CreateModule", newModule);
      this.$store
        .dispatch("CREATE_MODULE", newModule)
        .then(() => {
          this.GetDBModules();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    CreateAction(newAction) {
      window.console.log("CreateAction", newAction);
      this.$store
        .dispatch("CREATE_ACTION", newAction)
        .then(() => {
          this.GetDBModules();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    UpdateAction(action) {
      window.console.log("UpdateAction", action);
      this.$store
        .dispatch("UPDATE_ACTION", action)
        .then(() => {
          this.GetDBModules();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    DeleteAction(action) {
      window.console.log("DeleteAction", action);
      this.$store
        .dispatch("DELETE_ACTION", action)
        .then(() => {
          this.GetDBModules();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    DeleteModule(module) {
      this.$store
        .dispatch("DELETE_MODULE", module)
        .then(() => {
          this.GetDBModules();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetDBModules() {
      this.$store
        .dispatch("ALL_DB_MODULES")
        .then(() => {
          this.$store
            .dispatch("ALL_API_MODULES")
            .then(() => {
              this.allAPIActions = this.$store.getters.ALL_API_MODULES;
            })
            .catch((error) => {
              window.console.log(error);
            });
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    UpdateModule(module) {
      this.$store
        .dispatch("UPDATE_MODULE", module)
        .then(() => {
          this.GetDBModules();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  mounted() {
    this.GetDBModules();
  },
};
</script>