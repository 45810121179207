<template>
  <div>
    <v-row justify="center">
      <v-card>
        <v-card-actions>
          <v-btn
            class="mx-2"
            dark
            small
            color="primary"
            @click="adTemplateNewDialog = true"
          >
            Добавить новый слайд
            <v-icon right dark>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
        <v-window
          v-model="window"
          :vertical="vertical"
          :reverse="reverse"
          dark
          continuous
          style="max-width: 825px"
        >
          <template v-for="adItem in adItems">
            <v-window-item :key="adItem.id">
              <v-row>
                <template v-for="(content, index) in adItem.adItemContents">
                  <v-col :cols="content.frontColumn" :key="index">
                    <v-menu top offset-y absolute style="max-width: 600px">
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip left :disabled="!content.tooltip">
                          <template v-slot:activator="{ on: tooltip }">
                            <v-card
                              color="grey"
                              :height="
                                GetDimension(
                                  content.frontColumn,
                                  IsVertical(adItem.adItemContents)
                                ).height
                              "
                              :width="
                                GetDimension(
                                  content.frontColumn,
                                  IsVertical(adItem.adItemContents)
                                ).width
                              "
                              :img="content.sourceLink"
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                            >
                              {{ content.description }}
                              <v-card-text style="position: relative">
                                <v-btn
                                  small
                                  absolute
                                  fab
                                  top
                                  right
                                  color="red"
                                  @click="
                                    adItemContent = content;
                                    adTemplateEditDialog = true;
                                  "
                                >
                                  <v-icon>mdi-image-edit-outline</v-icon>
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </template>
                          <span>{{ content.tooltip }}</span>
                        </v-tooltip>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(openType,
                          index) in content.adItemContentOpens"
                          :key="index"
                          @click="AdItemContentOpen(openType)"
                        >
                          <v-list-item-title>{{
                            openType.adItemContentOpenType.type
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </template>
              </v-row>
            </v-window-item>
          </template>
        </v-window>

        <v-card-actions class="justify-space-between" v-if="adItems.length">
          <v-btn text @click="PrevWindowItem">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group v-model="window" class="text-center" mandatory>
            <v-item
              v-for="n in adItems.length"
              :key="`btn-${n}`"
              v-slot:default="{ active, toggle }"
            >
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn text @click="NextWindowItem">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>

        <!-- Изменение и удаление слайда -->
        <v-card-actions v-if="adItems.length">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="warning"
            @click="DeleteAdItem(adItems[window])"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="warning"
            @click="
              currentAdItem = adItems[window];
              adItemEditDialog = true;
            "
          >
            <v-icon dark>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline"></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <iframe
                  v-show="video.show"
                  :src="video.src"
                  frameborder="0"
                  allowfullscreen
                  width="600px"
                  height="600px"
                ></iframe>
                <v-img v-show="image.show" :src="image.src"></v-img>
                <iframe
                  width="100%"
                  height="600px"
                  frameborder="0"
                  v-show="presentation.show"
                  :src="presentation.src"
                ></iframe>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Закрыть</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Добавить новый слайд -->
      <v-dialog v-model="adTemplateNewDialog" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Выберите слайд</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-4"
              @click="adTemplateNewDialog = false"
              >Отмена</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <AdTemplateNew
                  :adLocation="adLocation"
                  @ad-template-new-dialog-close="adTemplateNewDialog = false"
                ></AdTemplateNew>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Редактирование слайда -->
      <v-dialog v-model="adTemplateEditDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline"></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="center">
                <v-col>
                  <span>Редактор изображения</span>

                  <!-- Загрузка изображение -->
                  <v-text-field
                    prepend-icon="mdi-link-box-variant-outline"
                    v-model="adItemContent.sourceLink"
                    label="Вставьте ссылку на изображение"
                    dense
                    clearable
                  ></v-text-field>

                  <!-- Описание рекламы когда нет изображение -->
                  <v-text-field
                    prepend-icon=" mdi-tag-text-outline"
                    label="Описание (будет показываться когда нет изображения)"
                    dense
                    clearable
                    v-model="adItemContent.description"
                  ></v-text-field>

                  <!-- Подсказка при навидении мыши на рекламу -->
                  <v-text-field
                    prepend-icon="mdi-tooltip-text-outline"
                    label="Подсказка (всплывает при наведении)"
                    v-model="adItemContent.tooltip"
                    dense
                    clearable
                  ></v-text-field>

                  <!-- Дата начала рекламы -->
                  <v-menu
                    v-model="dateStartMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Дата с"
                        prepend-icon="mdi-clock-time-eight-outline"
                        readonly
                        :value="adItemContent.dateStart"
                        v-on="on"
                        hint="Выберите дату начала рекламы"
                        clearable
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="adItemContent.dateStart"
                      no-title
                      @input="dateStartMenu = false"
                    ></v-date-picker>
                  </v-menu>

                  <!-- Дата конца рекламы -->
                  <v-menu
                    v-model="dateFinishMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Дата по (включительно)"
                        prepend-icon="mdi-clock-time-eight-outline"
                        readonly
                        :value="adItemContent.dateFinish"
                        v-on="on"
                        hint="Выберите дату конца рекламы"
                        clearable
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="adItemContent.dateFinish"
                      no-title
                      @input="dateFinishMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <br />
                  <v-divider></v-divider>
                  <span>Редактор действий на изображения</span>

                  <v-select
                    v-model="adItemContent.adItemContentOpens"
                    :items="adItemContentOpenTypes"
                    item-text="adItemContentOpenType.type"
                    item-value="adItemContentOpenTypeId"
                    label="Выбрать"
                    persistent-hint
                    return-object
                    multiple
                  ></v-select>
                  <div
                    v-for="openType in adItemContent.adItemContentOpens"
                    :key="openType.adItemContentOpenTypeId"
                  >
                    <v-text-field
                      prepend-icon="mdi-step-forward-2"
                      :label="
                        openType.adItemContentOpenType.type + ' (ОПИСАНИЕ)'
                      "
                      dense
                      clearable
                      v-model="openType.description"
                    ></v-text-field>
                    <v-text-field
                      prepend-icon="mdi-step-forward-2"
                      :label="
                        openType.adItemContentOpenType.type +
                        ' (ССЫЛКА ИЛИ ТЕКСТ)'
                      "
                      dense
                      clearable
                      v-model="openType.sourceLink"
                    ></v-text-field>
                    <v-divider></v-divider>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="adTemplateEditDialog = false"
              >Закрыть</v-btn
            >
            <v-btn color="primary" @click="UpdateAdItemContent(adItemContent)"
              >Сохранить</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Привязка товара -->
      <v-dialog v-model="adItemEditDialog" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Выберите товар</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-spacer></v-spacer>
            <v-autocomplete
              v-model="currentAdItem.goodsId"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              chips
              clearable
              hide-details
              hide-selected
              item-text="fullName"
              item-value="id"
              label="Искать товар..."
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Найдите товар для привязки к
                    <strong>слайду</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="primary"
                  class="white--text"
                  v-on="on"
                >
                  <v-icon left>mdi-coin</v-icon>
                  <span v-text="item.fullName"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="primary"
                  class="headline font-weight-light white--text"
                  >{{ item.fullName.charAt(0) }}</v-list-item-avatar
                >
                <v-list-item-content>
                  <v-list-item-title v-text="item.fullName"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.atc.name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-waves</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-4"
              @click="adItemEditDialog = false"
              >Отмена</v-btn
            >
            <v-btn color="primary" @click="UpdateAdItem()">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-alert
        v-if="adItems[window] && adItems[window].goods"
        border="top"
        colored-border
        type="info"
        elevation="2"
        >Привязанный товар к слайду:
        {{ adItems[window].goods.fullName }}</v-alert
      >
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    adLocation: Number,
    goodsId: Number,
  },
  data: () => ({
    isLoading: false,
    items: [],

    search: null,
    tab: null,

    showMenu: false,
    adItemContent: {},
    currentAdItem: {},
    x: 0,
    y: 0,
    dialog: false,
    adTemplateNewDialog: false,
    adTemplateEditDialog: false,
    adItemEditDialog: false,
    window: 0,
    adItemContentOpenTypes: [],
    dateStartMenu: false,
    dateFinishMenu: false,

    showArrows: true,
    vertical: true,
    reverse: true,
    autorun: true,
    video: { show: false, src: "" },
    image: { show: false, src: "" },
    presentation: { show: false, src: "" },
  }),

  methods: {
    AdItemContentOpen(openType) {
      this.presentation.src = this.video.src = this.image.src = "";
      this.presentation.show = this.video.show = this.image.show = false;

      if (openType.adItemContentOpenType.id == 0) {
        let Search = {
          fullName: openType.sourceLink,
          beInThePrices: false,
          advanced: {
            INN: "",
            brand: "",
            ATC: "",
            producer: "",
            show: false,
          },
        };
        this.$store.commit("SET_GOODS_SEARCH", Search);
      }

      if (openType.adItemContentOpenType.id == 1) {
        this.presentation.src =
          "https://docs.google.com/viewer?url=" +
          openType.sourceLink +
          "&embedded=true";
        this.presentation.show = true;
        this.dialog = true;
      }
      if (openType.adItemContentOpenType.id == 2) {
        this.video.src = openType.sourceLink;
        this.video.show = true;
        this.dialog = true;
      }
      if (openType.adItemContentOpenType.id == 3) {
        this.image.src = openType.sourceLink;
        this.image.show = true;
        this.dialog = true;
      }
    },
    GetDimension(col, isVertical) {
      var dimension = this.$store.getters.AD_ITEM_CONTENT_DIMENSIONS(
        this.adLocation,
        col,
        isVertical
      );
      return dimension;
    },
    IsVertical(contents) {
      var result =
        contents
          .map((x) => x.frontColumn)
          .reduce(function (previousValue, currentValue) {
            return previousValue + currentValue;
          }) == 12;

      return result;
    },

    UpdateAdItemContent(content) {
      this.$store
        .dispatch("UPDATE_AD_ITEM_CONTENT", content)
        .then(() => {
          this.adTemplateEditDialog = false;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    UpdateAdItem() {
      this.$store
        .dispatch("UPDATE_AD_ITEM", this.currentAdItem)
        .then(() => {
          this.adItemEditDialog = false;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAdItemContentOpenTypes() {
      this.$store
        .dispatch("AD_ITEM_CONTENT_OPEN_TYPES")
        .then((response) => {
          var selectAdItemContentOpenTypes = [];
          response.forEach((adItemContentOpenType) => {
            selectAdItemContentOpenTypes.push({
              adItemContentId: 98,
              adItemContentOpenTypeId: adItemContentOpenType.id,
              adItemContentOpenType: adItemContentOpenType,
              sourceLink: "",
              description: "",
            });
          });
          this.adItemContentOpenTypes = selectAdItemContentOpenTypes;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    NextWindowItem() {
      this.window = this.window + 1 === this.length ? 0 : this.window + 1;
    },
    PrevWindowItem() {
      this.window = this.window - 1 < 0 ? this.length - 1 : this.window - 1;
    },
    DeleteAdItem(adItem) {
      this.$store
        .dispatch("DELETE_AD_ITEM", adItem)
        .then(() => {})
        .catch(() => {});
    },
  },

  computed: {
    ad: {
      get: function () {
        return this.$store.getters.ADS?.find((x) => x.id == this.adLocation);
      },
    },
    adItems: {
      get: function () {
        var result = this.ad?.adItems;

        return result;
      },
    },
  },
  mounted() {
    this.GetAdItemContentOpenTypes();
  },
  watch: {
    search() {
     
      this.isLoading = true;

      let Pagination = {
        Take: `${50}`,
        Skip: `${0}`,
      };

      let SearchProperty = {
        FullName: `${this.search ?? ""}`,
        Brand: "",
        INN: "",
        ATC: "",
        Producer: "",
      };

      let GoodsSearch = {
        Pagination,
        SearchProperty,
      };

      this.$store
        .dispatch("ALL_GOODS_SEARCH_PAGING", GoodsSearch)
        .then((response) => {
          this.loading = false;
          this.items = response;
          this.total = 50;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

