<template>
  <div>
    <v-card-title>
      Компании
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchName"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск по названию"
        single-line
        hide-details
      ></v-text-field>
       <v-text-field
        v-model="searchBIN"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск по БИН"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="companyHeaders"
      :items="companies"
      :options.sync="options"
      class="elevation-2"
      :server-items-length="companiesCount"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialogCompany"
            max-width="900px"
            persistent
            scrollable
          >
            <template v-slot:activator="{ on }">
              <v-btn class="mb-2" small v-on="on" color="primary"
                >Создать</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" v-show="false">
                      <v-text-field
                        v-model="editedCompany.id"
                        label="ID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedCompany.name"
                        label="Название"
                        :error-messages="modelState['Name']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedCompany.lawName"
                        label="Юр. Название"
                        :error-messages="modelState['LawName']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedCompany.address"
                        :error-messages="modelState['Address']"
                        label="Адрес"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedCompany.website"
                        label="Веб-сайт"
                        :error-messages="modelState['Website']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedCompany.companyCitiesId"
                        prepend-icon="mdi-city-variant-outline"
                        :items="cities"
                        menu-props="auto"
                        label="Города"
                        single-line
                        item-text="name"
                        item-value="id"
                        multiple
                        :error-messages="modelState['CompanyCitiesId']"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedCompany.companyTypeId"
                        :items="companyTypes"
                        prepend-icon="mdi-format-list-bulleted-type"
                        menu-props="auto"
                        label="Тип"
                        single-line
                        item-text="name"
                        item-value="id"
                        :error-messages="modelState['CompanyTypeId']"
                      ></v-select>
                    </v-col>

                    <v-row
                      v-for="(item, index) in editedCompany.companyBINs"
                      v-bind:key="index"
                    >
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          @click:append="AddBIN"
                          @click:prepend="
                            editedCompany.companyBINs.splice(index, 1)
                          "
                          :prepend-icon="
                            editedCompany.companyBINs.length > 1
                              ? 'mdi-minus'
                              : 'mdi-undefined'
                          "
                          :label="identifier"
                          required
                          maxlength="12"
                          v-model="item.bin"
                          :error-messages="
                            modelState['CompanyBINs[' + index + '].BIN']
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          @click:append="AddBIN"
                          append-icon="mdi-plus"
                          @click:prepend="
                            editedCompany.companyBINs.splice(index, 1)
                          "
                          label="Описание"
                          required
                          v-model="item.description"
                          :error-messages="modelState['CompanyBINs']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="Close" color="primary">Отмена</v-btn>
                <v-btn text @click="Save" color="primary">Сохранить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.companyCities="props">
        <div
          v-for="item in props.item.companyCities"
          :key="item.id"
          class="text-no-wrap"
        >
          <span v-if="item.city">
            <v-icon x-small>mdi-city</v-icon>
            {{ item.city.name }}
          </span>
        </div>
      </template>

      <template v-slot:item.companyBINs="props">
        <div
          v-for="item in props.item.companyBINs"
          :key="item.id"
          class="text-no-wrap"
        >
          <v-icon x-small>mdi-cube</v-icon>
          {{ item.bin }}
          <span class="overline">{{ item.description }}</span>
        </div>
      </template>

      <template v-slot:item.website="props">
        <div class="text-no-wrap">
          {{ props.item.website }}
          <v-btn small icon :href="props.item.website" target="_blank">
            <v-icon small>mdi-call-made</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.companyPhones="props">
        <div
          v-for="item in props.item.companyPhones"
          :key="item.phone"
          class="text-no-wrap"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Home'"
                >mdi-phone-classic</v-icon
              >
            </template>
            <span>Домашний</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Mobile'"
                >mdi-cellphone</v-icon
              >
            </template>
            <span>Мобильный</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Work'"
                >mdi-phone-outgoing</v-icon
              >
            </template>
            <span>Рабочий</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Fax'"
                >mdi-printer</v-icon
              >
            </template>
            <span>Факс</span>
          </v-tooltip>
          {{ item.phone }}
        </div>
      </template>

      <template v-slot:item.companyEmails="props">
        <div
          v-for="item in props.item.companyEmails"
          :key="item.email"
          class="text-no-wrap"
        >
          <v-icon x-small>mdi-email</v-icon>
          {{ item.email }}
        </div>
      </template>

      <template v-slot:item.user="{ item }">
        <v-btn
          small
          @click="
            dialogUsers = !dialogUsers;
            GetUsersByCompanyId(item);
          "
        >
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="DeleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogUsers"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialogUsers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Имя компании</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialogUsers = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          Пользователи
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchUsers"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="userHeaders"
          :items="users"
          :search="searchUsers"
          :items-per-page="5"
        ></v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CitiesUzb } from "../../config";
export default {
 
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    identifier: "БИН",
    companyTypes: [],
    cities: [],
    modelState: {},
    editedCompany: {
      address: "",
      companyBINs: [{ bin: "", description: "" }],
      companyCitiesId: [],
      companyEmails: "",
      companyPhones: "",
      companyType: { id: 0, name: "0" },
      companyTypeId: 0,
      id: 0,
      lawName: "",
      name: "",
      website: "",
    },
    defaultCompany: {
      address: "",
      companyBINs: [{ bin: "", description: "" }],
      companyCitiesId: [],
      companyEmails: "",
      companyPhones: "",
      companyType: { id: 0, name: "0" },
      companyTypeId: 0,
      id: 0,
      lawName: "",
      name: "",
      website: "",
    },
    editedIndex: -1,
    users: [],
    dialogUsers: false,
    dialogCompany: false,
    companies: [],
    companiesCount: 0,
    loading: false,
    searchUsers: "",
    searchName: "",
    searchBIN: "",
    companyHeaders: [
      {
        text: "ID",
        align: "left",
        sortable: true,
        value: "id",
      },
      { text: "Название", value: "name" },
      { text: "Юр. Название", value: "lawName" },
      { text: "Город", value: "companyCities" },
      { text: "БИН", value: "companyBINs" },
      { text: "Адрес", value: "address" },
      { text: "Веб сайт", value: "website" },
      { text: "Тип компании", value: "companyType.name" },
      {
        text: "Телефоны",
        align: "center",
        sortable: true,
        value: "companyPhones",
        show: true,
      },
      {
        text: "Почты",
        align: "center",
        sortable: true,
        value: "companyEmails",
        show: true,
      },
      { text: "Пользователи", value: "user", sortable: false },
      { text: "Действия", value: "actions", sortable: false, show: true },
    ],
    userHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Логин", value: "login" },
      { text: "Имя", value: "firstName" },
      { text: "Фамилия", value: "lastName" },
      { text: "Компания", value: "company.name" },
      { text: "Роль", value: "userRole.name" },
    ],
  }),
  async mounted() {
    this.GetCompanies();
    this.GetCompanyTypes();
    this.GetCities();
  },
  methods: {
    GetCompanies() {
      this.loading = true;

      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };

      let CompaniesSearchProperty = {
        Name: `${this.searchName ?? ""}`,
        BIN: `${this.searchBIN ?? ""}`,
      };

      let CompaniesSearch = {
        Pagination,
        CompaniesSearchProperty,
      };

      this.$store
        .dispatch("COMPANIES", CompaniesSearch)
        .then((response) => {
          this.companies = response.result;
          this.companiesCount = response.count;
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    GetCompanyTypes() {
      this.$store
        .dispatch("COMPANY_TYPES")
        .then(() => {
          this.companyTypes = this.$store.getters.COMPANY_TYPES;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetCities() {
      this.$store
        .dispatch("CITIES")
        .then(() => {
          this.cities = this.$store.getters.CITIES;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    AddBIN() {
      this.editedCompany.companyBINs.push({ bin: "", description: "" });
    },
    GetUsersByCompanyId(company) {
      this.$store
        .dispatch("USERS_BY_COMPANY_ID", company.id)
        .then((response) => {
          this.users = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    EditItem(item) {
      this.editedIndex = this.companies.indexOf(item);
      this.editedCompany = Object.assign({}, item);
      this.editedCompany.companyCitiesId = item.companyCities.map(
        (x) => x.cityId
      );
      if (item.companyBINs.length == 0)
        this.editedCompany.companyBINs.push({ bin: "", description: "" });

      window.console.log("  this.editedCompany", this.editedCompany);
      this.dialogCompany = true;
    },
    DeleteItem(item) {
      const index = this.companies.indexOf(item);
      confirm("Вы уверены, что хотите удалить этого пользователя?") &&
        this.companies.splice(index, 1);
    },
    Close() {
      this.modelState = {};
      this.dialogCompany = false;
      this.$nextTick(() => {
        this.editedCompany = Object.assign({}, this.defaultCompany);
        this.editedIndex = -1;
      });
    },
    Save() {
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("UPDATE_COMPANY", this.editedCompany)
          .then((response) => {
            Object.assign(this.companies[this.editedIndex], response);
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      } else {
        this.$store
          .dispatch("CREATE_COMPANY", this.editedCompany)
          .then(() => {
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.GetCompanies();
      },
      deep: true,
    },
    searchName() {
      this.GetCompanies();
    },
    searchBIN() {
      this.GetCompanies();
    },
    dialogCompany(val) {
      val || this.Close();
    },
    "editedCompany.companyCitiesId": function (val) {
      let UzbCitiesId = Object.values(CitiesUzb);
      window.console.log(UzbCitiesId);
      if (val.some((x) => UzbCitiesId.includes(x))) this.identifier = "ИНН";
      else this.identifier = "БИН";
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
  },
};
</script>