import axios from "axios";
import mrcAxios from "../../config/mrcAxios"
import store from './../index'

export default {
    state: {
        company: null,
        companies: null,
        companyTypes: [],
        medicalRepesentativeCabinetAvailable: null
    },
    getters: {
        COMPANY: state => {
            return state.company;
        },
        COMPANIES: state => {
            return state.companies;
        },
        COMPANY_TYPES: state => {
            return state.companyTypes
        },
        MEDICAL_REPRESENTATIVE_CABINET_AVAILABLE: state => {
            return state.medicalRepesentativeCabinetAvailable
        }
    },
    mutations: {
        SET_COMPANY: (state, payload) => {
            state.company = payload;
        },
        SET_COMPANIES: (state, payload) => {
            state.companies = payload;
        },
        SET_COMPANY_TYPES: (state, payload) => {
            state.companyTypes = payload;
        },
        SET_NEW_COMPANY: (state, payload) => {
            state.companies.push(payload);
        },
        SET_MEDICAL_REPRESENTATIVE_CABINET_AVAILABLE: (state, payload) => {
            state.medicalRepesentativeCabinetAvailable = payload;
        }
    },
    actions: {
        COMPANY: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Company/GetById?id=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_COMPANY', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMPANIES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Company/GetAll`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMPANY_TYPES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Company/GetTypes`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_COMPANY_TYPES", data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        CREATE_COMPANY: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Company/Create`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            store.commit('SET_NEW_COMPANY', data);
                            let Success = {
                                text: 'Компания успешно создана ' + data.name,
                                timeout: 5000,
                                color: 'success',
                            }
                            store.commit('SET_SNACKBAR', Success);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_COMPANY: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Company/Update`,
                        payload
                    )
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        GET_MEDICAL_REPRESENTATIVE_CABINET_AVAILABLE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/AvailableCompany/GetById?id=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_MEDICAL_REPRESENTATIVE_CABINET_AVAILABLE', data);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};