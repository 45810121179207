import axios from "axios";
import store from './../index'

export default {
    state: {
        search: {
            fullName: "",
            beInThePrices: false,
            advanced: {
                INN: "",
                brand: "",
                ATC: "",
                producer: "",
                show: false
            }
        },
        corporationGoods: []
    },
    getters: {
        GOODS_SEARCH: state => {
            return state.search;
        },
        CORPORATION_GOODS: state => {
            return state.corporationGoods;
        },
    },
    mutations: {
        SET_GOODS_SEARCH: (state, payload) => {
            state.search = payload;
        },
        SET_CORPORATION_GOODS: (state, payload) => {
            state.corporationGoods = payload;
        },
    },
    actions: {
        ALL_GOODS_SEARCH_PAGING: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Goods/GetAllSearchPagingAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        SPELLING_CORRECT_GOOGLE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Goods/SpellingCorrectGoogle`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CSE_TOKEN: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Goods/GetCseTokenGoogle`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        GET_GOODS_BY_CORPORATION_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Goods/GetByCorporationId?corporationId=${payload}`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_CORPORATION_GOODS', data);
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        }
    }
};