// Core Components
import Toolbar from '../components/core/Toolbar.vue';
import Navigation from '../components/core/Navigation.vue';
import Breadcrumbs from '../components/core/Breadcrumbs.vue';
import Footer from '../components/core/Footer.vue';
import Global from '../components/core/Global.vue';
import Login from '../components/core/Login.vue';
import ResetPassword from '../components/core/ResetPassword.vue';

// Order Components
import OrderGoods from '../components/order/OrderGoods.vue';
import OrderPrices from '../components/order/OrderPrices.vue';
import OrderAnalogs from '../components/order/OrderAnalogs.vue';
import OrderList from '../components/order/OrderList.vue';
import OrderListCenter from '../components/order/OrderListCenter.vue';
import OrderListPoint from '../components/order/OrderListPoint.vue';
import OrderLeftTabs from '../components/order/OrderLeftTabs.vue';
import OrderRightTabs from '../components/order/OrderRightTabs.vue';
import OrderTemplates from '../components/order/OrderTemplates.vue';
import OrderDialog from '../components/order/OrderDialog.vue';
import OrderFullScreen from '../components/order/OrderFullScreen.vue';
import NotInPrice from '../components/order/NotInPrice.vue';
import Storage from '../components/order/Storage.vue';
import OrderNavigation from '../components/order/OrderNavigation.vue';
import OrderSend from '../components/order/OrderSend.vue';

// Chat Components
import Chat from '../components/chat/Chat.vue';
import Share from '../components/chat/Share.vue';

//Annotation Components
import AnnotationDialog from '../components/annotation/AnnotationDialog.vue';

// User Components
import UserDialog from '../components/user/UserDialog.vue';

// Pay Components
import BankCardsDialog from '../components/pay/BankCardsDialog.vue';
import PayOrderDialog from '../components/pay/PayOrderDialog.vue';
import PayOrderInvoiceDialog from '../components/pay/PayOrderInvoiceDialog.vue';

// Management Components
import AccessDialog from '../components/management/AccessDialog.vue';

// Additional Components
import Snackbar from '../components/Snackbar.vue';

//AdTemplate Components
import AdTemplate from '../components/ad/AdTemplate.vue';
import AdTemplateEdit from '../components/ad/AdTemplateEdit.vue';
import AdTemplateNew from '../components/ad/AdTemplateNew.vue';
import PromoTemplate from '../components/ad/PromoTemplate.vue';

//Catalog Components
import ShippingAddressConfig from '../components/catalog/distributor/ShippingAddressConfig.vue';
import FilialRequestAbilities from '../components/catalog/distributor/FilialRequestAbilities.vue';
import SendOrderConfig from '../components/catalog/distributor/SendOrderConfig.vue';
import ContractConfig from '../components/catalog/distributor/ContractConfig.vue';
import PriceConfig from '../components/catalog/distributor/PriceConfig.vue';
import InvoiceConfig from '../components/catalog/distributor/InvoiceConfig.vue';

import ExpiringNotification from '../components/core/ExpiringNotification.vue'

//
import AddInStorageDialog from '../components/storage/AddInStorageDialog.vue';

function setupComponents(Vue) {
  Vue.component('Toolbar', Toolbar);
  Vue.component('Navigation', Navigation);
  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.component('Footer', Footer);
  Vue.component('Login', Login);
  Vue.component('ResetPassword', ResetPassword);
  Vue.component('Snackbar', Snackbar);
  Vue.component('OrderGoods', OrderGoods);
  Vue.component('OrderPrices', OrderPrices);
  Vue.component('OrderAnalogs', OrderAnalogs);
  Vue.component('OrderList', OrderList);
  Vue.component('OrderListCenter', OrderListCenter);
  Vue.component('OrderListPoint', OrderListPoint);
  Vue.component('OrderFullScreen', OrderFullScreen);
  Vue.component('OrderLeftTabs', OrderLeftTabs);
  Vue.component('OrderRightTabs', OrderRightTabs);
  Vue.component('OrderSend', OrderSend);
  Vue.component('OrderTemplates', OrderTemplates);
  Vue.component('OrderDialog', OrderDialog);
  Vue.component('Storage', Storage);
  Vue.component('OrderNavigation', OrderNavigation);
  Vue.component('UserDialog', UserDialog);
  Vue.component('BankCardsDialog', BankCardsDialog);
  Vue.component('AccessDialog', AccessDialog);
  Vue.component('NotInPrice', NotInPrice);
  Vue.component('Global', Global);
  Vue.component('AnnotationDialog', AnnotationDialog);
  Vue.component('Share', Share);
  Vue.component('Chat', Chat);
  Vue.component('AdTemplate', AdTemplate);
  Vue.component('AdTemplateEdit', AdTemplateEdit);
  Vue.component('AdTemplateNew', AdTemplateNew);
  Vue.component('PromoTemplate', PromoTemplate);
  Vue.component('ShippingAddressConfig', ShippingAddressConfig);
  Vue.component('InvoiceConfig', InvoiceConfig);
  Vue.component('FilialRequestAbilities', FilialRequestAbilities);
  Vue.component('SendOrderConfig', SendOrderConfig);
  Vue.component('ContractConfig', ContractConfig);
  Vue.component('PriceConfig', PriceConfig);
  Vue.component('PayOrderDialog', PayOrderDialog)
  Vue.component('PayOrderInvoiceDialog', PayOrderInvoiceDialog)
  Vue.component('ExpiringNotification', ExpiringNotification)
  Vue.component('AddInStorageDialog', AddInStorageDialog)

}

export {
  setupComponents
}
