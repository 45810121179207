<template>
  <v-dialog v-model="userDialog" persistent max-width="650px">
    <v-card>
      <v-card-text>
        <v-form v-model="valid" ref="userDialogform" lazy-validation>
          <v-container>
            <v-row>
              <!-- Имя -->
              <v-col cols="6">
                <v-text-field
                  label="Имя*"
                  required
                  hint="пример: Иван"
                  :counter="50"
                  v-model="user.firstName"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>

              <!-- Фамилия -->
              <v-col cols="6">
                <v-text-field
                  label="Фамилия*"
                  hint="пример: Иванов"
                  :counter="50"
                  required
                  :rules="nameRules"
                  v-model="user.lastName"
                ></v-text-field>
              </v-col>

              <!-- Почта -->
              <v-col
                cols="12"
                v-for="(item, index) in user.userEmails"
                v-bind:key="item.index"
              >
                <v-text-field
                  @click:append="AddUserEmail"
                  @click:prepend="user.userEmails.splice(index, 1)"
                  :prepend-icon="
                    user.userEmails.length > 1 ? 'mdi-minus' : 'mdi-undefined'
                  "
                  :append-icon="'mdi-plus'"
                  label="Почта*"
                  :rules="emailRules"
                  hint="пример: ivanov@pharmit.kz"
                  required
                  maxlength="50"
                  v-model="item.email"
                  :error-messages="
                    modelState['UserEmails[' + index + '].email']
                  "
                ></v-text-field>
              </v-col>

              <!-- Телефон -->
              <v-row
                v-for="(item, index) in user.userPhones"
                v-bind:key="index"
              >
                <v-col cols="6">
                  <v-text-field
                    @click:prepend="user.userPhones.splice(index, 1)"
                    :prepend-icon="
                      user.userPhones.length > 1 ? 'mdi-minus' : 'mdi-undefined'
                    "
                    label="Телефоны*"
                    :rules="phoneRules"
                    :hint="item.phoneTypeId != 5 ? hint : 'пример: +7(777) 777 7777, вн.: 444'"
                    :maxlength="item.phoneTypeId != 5 ? '14' : '24'"
                    type="phone"
                    required
                    :prefix="prefix"
                    v-model="item.phone"
                    :error-messages="
                      modelState['UserPhones[' + index + '].phone']
                    "
                    @input="AcceptPhoneNumber(index)"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    @click:append="AddUserPhone"
                    :append-icon="'mdi-plus'"
                    v-model="item.phoneTypeId"
                    :items="phoneTypes"
                    menu-props="auto"
                    hide-details
                    required
                    label="Тип телефона"
                    single-line
                    item-text="phoneTypeName"
                    :rules="phoneTypeRules"
                    item-value="id"
                    :error-messages="
                      modelState['UserPhones[' + index + '].type']
                    "
                  ></v-select>
                </v-col>
              </v-row>

              <!-- Согласие -->
              <v-col cols="12">
                <v-checkbox v-model="checkbox" :rules="agreementRules" required>
                  <template v-slot:label>
                    <div @click.stop>
                      Даю согласие
                      <a
                        href="javascript:;"
                        @click.stop="agreementDialog = true"
                        >на обработку персональных данных</a
                      >
                    </div>
                  </template>
                </v-checkbox>
              </v-col>

              <v-dialog
                v-model="agreementDialog"
                max-width="700"
                persistent
                scrollable
              >
                <v-card>
                  <v-card-title class="headline"
                    >Согласие на сбор, обработку персональных
                    данных</v-card-title
                  >
                  <v-card-text>
                    В соответствии с Законом Республики Казахстан от 21 мая 2013
                    года № 94-V «О персональных данных и их защите» и иными
                    нормативными правовыми актами Республики Казахстан (далее
                    совместно - Закон), Я, [Ф.И.О] (далее - Субъект), гражданин
                    Республики Казахстан, [наименование, номер, дату и кем выдан
                    документ, удостоверяющий его личность], индивидуальный
                    идентификационный номер [значение], адрес фактического места
                    жительства: [вписать нужное], адрес места регистрации:
                    [вписать нужное], даю безусловное согласие Работодателю и
                    Третьим лицам, т.е. лицам связанным, как в настоящее время,
                    так и в будущем, с Работодателем обстоятельствами или
                    правоотношениями по сбору, обработке и защите моих (обо мне)
                    персональных данных, на сбор, обработку и трансграничную
                    передачу, не противоречащими законодательству способами, в
                    целях, регламентированных настоящим согласием, и в
                    источниках, в том числе общедоступных, по усмотрению
                    Работодателя, в связи с возникновением с Работодателем, уже
                    существующих или возникающих в будущем любых трудовых,
                    гражданско-правовых и (или) иных правоотношений,
                    нижеследующих моих (обо мне) персональных данных, а также
                    происходящих в них будущем изменений и (или) дополнений,
                    зафиксированных на электронном, бумажном и (или) ином
                    материальном носителе, включая, но, не ограничиваясь: 1)
                    сведения, необходимые для оформления трудовых,
                    гражданско-правовых и (или) иных правоотношений, а также для
                    осуществления взаимодействия в процессе исполнения таковых,
                    и документы, подтверждающие указанные сведения: фамилия,
                    имя, отчество (при наличии); гражданство; индивидуальный
                    идентификационный номер (ИИН); данные документа,
                    удостоверяющего личность; дата и данные о рождении; наличие
                    и группа инвалидности и степени ограничения к трудовой
                    деятельности; данные о социальных льготах и социальном
                    статусе; сведения о дееспособности; портретное изображение
                    (фотография); пол; подпись; банковские реквизиты (номер
                    банковского счета, наименование и БИК банка); биографические
                    данные; любые анкетные данные; данные водительского
                    удостоверения; данные личного листка по учету кадров и
                    личной карточки форма Т-2; документы, подтверждающие право
                    собственности или иное законное право на имущество, в том
                    числе фотографии в любом формате имущества,
                    идентификационные данные, данные о регистрации, обременении,
                    общие характеристики имущества; стоимость, адрес (место
                    нахождения) имущества, данные государственной регистрации и
                    т.п.; 2) сведения для поддержания связи, и документы,
                    подтверждающие указанные сведения: данные о фактическом
                    месте жительства; данные об адресе места регистрации;
                    контактная информация (номера телефонов, адрес электронной
                    почты и т.п.); документы, подтверждающие законность
                    нахождения на территории Республики Казахстан и т.п.;
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="agreementDialog = false"
                      >Согласен</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-container>
        </v-form>

        <code>*Поля обязательны для заполнения</code>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="Validate" :disabled="!valid" :loading="loading"
          >Сохранить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {  PhoneNumberFormat } from '../../config';
export default {
  data: () => ({
    hint: 'пример: +7(777) 777 7777',
    prefix: '+7',
    loading: false,
    phoneTypes: [],
    modelState: {},
    agreementDialog: false,
    valid: true,
    checkbox: true,
    nameRules: [
      (v) => !!v || "Заполните поле",
      (v) => (v && v.length) <= 50 || "Должно быть не более 50 символов",
    ],
    emailRules: [
      (v) => !!v || "Заполните поле",
      (v) => /.+@.+\..+/.test(v) || "E-mail должен быть действительным",
    ],
    phoneRules: [
      (v) => !!v || "Заполните поле",
      (v) => (v && v.length) >= 13 || "Телефон не действительный",
    ],
    phoneTypeRules: [(v) => !!v || "Заполните поле"],
    agreementRules: [(v) => !!v || "Чтобы сохранить, вы должны дать согласие!"],
  }),
  async mounted() {
    this.GetPhoneTypes();
  },
  computed: {
    userDialog: {
      get: function () {
        return this.$store.getters.USER_DIALOG;
      },
      set: function(payload){
        this.$store.commit("SET_USER_DIALOG", payload);
      }
    },
    user: {
      get: function () {
        return this.$store.getters.USER;
      },
    },
  },
  methods: {
    AcceptPhoneNumber(index) {
      var x = null; 
      x = this.AcceptKzPhoneNumber(index);
      this.user.userPhones[index].phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "") + 
        (this.user.userPhones[index].phoneTypeId == 5 ? (this.user.userPhones[index].phone.length >= 14? ", вн.: " + x[4] : '') : '');
    },
    AcceptKzPhoneNumber(index){
      var regex = new RegExp('(\\d{0,' + PhoneNumberFormat.Kazakhstan[0] + '})(\\d{0,' + PhoneNumberFormat.Kazakhstan[1] + '})(\\d{0,' + PhoneNumberFormat.Kazakhstan[2] + '})(\\d{0,3})');
      var x = this.user.userPhones[index].phone
        .replace(/\D/g, "")
        .match(regex);   
      return x;
    },
    AddUserEmail() {
      this.user.userEmails.push({ email: "" });
    },
    AddUserPhone() {
      this.user.userPhones.push({ phone: "" });
    },
    Validate() {
      var isValid = this.$refs.userDialogform.validate();
      if (isValid) {
        this.loading = true;
        this.user["accessUserBINsId"] = this.user.accessUserBINs.map((x) => x.bin);
        this.user["accessUserCitiesId"] = this.user.accessUserCities.map(
          (x) => x.cityId
        );

        this.$store
          .dispatch("UPDATE_USER_CONTEXT", this.user)
          .then(() => {
            this.loading = false;
            this.$store.commit("SET_USER_DIALOG", false);
          })
          .catch((error) => {
            this.loading = false;
            window.console.log(error);
          });
      }
    },
    Clear() {
      this.$refs.userDialogform.resetValidation();
      // this.$refs.userDialogform.reset();
      this.userDialog = false;
    },
    GetPhoneTypes() {
      this.$store
        .dispatch("PHONE_TYPES")
        .then(() => {
          this.phoneTypes = this.$store.getters.PHONE_TYPES;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
};
</script>

<style>
</style>