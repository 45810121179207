<template>
  <v-col lg="6" md="6" sm="12" cols="12">
    <v-tabs v-model="activeOrderTab">
      <v-tab v-for="item in items" :key="item.tab" :href="`#${item.tab}`">{{
        item.tab
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeOrderTab">
      <v-tab-item v-for="item in items" :key="item.tab" :value="item.tab">
        <div v-if="item.tab == 'Товары'">
          <OrderGoods></OrderGoods>
        </div>
        <div v-if="item.tab == 'Хранилище'">
          <Storage></Storage>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    items: [
      { tab: "Товары", content: "" },
      { tab: "Хранилище", content: "" },
    ],
  }),
  computed: {
    activeOrderTab: {
      get: function () {
        return this.$store.getters.ORDER_LEFT_TAB;
      },
      set: function (newValue) {
        this.$store.commit("SET_ORDER_LEFT_TAB", newValue);
      },
    },
  },
};
</script>