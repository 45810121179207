import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

import ru from 'vuetify/es5/locale/ru'



export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#055a81'
      }
    },
    // options: {
    //   themeCache: {
    //     get: key => localStorage.getItem(key),
    //     set: (key, value) => localStorage.setItem(key, value),
    //   },
    //   variations: false
    // },

  },
  lang: {
    locales: {
      ru
    },
    current: 'ru',
  },
});