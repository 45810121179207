<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels accordion class="rounded-0">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="text-subtitle-2 font-weight-medium">
                <v-icon>mdi-format-list-group-plus</v-icon>
                Добавление позиций
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AddPositions />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="text-subtitle-2 font-weight-medium">
                <v-icon>mdi-list-box-outline</v-icon>
                Список групп позиций
              </span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <List />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AddPositions from "./AddPositions.vue";
import List from "./List.vue";
export default {
  name: "Positions",
  components: {
    AddPositions,
    List,
  },
  data() {
    return {};
  },
};
</script>