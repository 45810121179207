import axios from "axios";
import store from "..";

export default {
    state: {
        unconfirmedUsers: [],
        userTemplates: [],
        pendingTemplates: []
    },
    getters: {
        GET_UNCONFIRMED_USERS: state => {
            return state.unconfirmedUsers;
        },
        GET_USER_TEMPLATES: state => {
            return state.userTemplates;
        },
        GET_PENDING_TEMPLATES: state => {
            return state.pendingTemplates;
        }
    },
    mutations: {
        SET_UNCONFIRMED_USERS: (state, payload) => {
            state.unconfirmedUsers = payload;
        },
        SET_USER_TEMPLATES: (state, payload) => {
            state.userTemplates = payload;
        },
        SET_PENDING_TEMPLATES: (state, payload) => {
            state.pendingTemplates = payload;
        }
    },
    actions: {
        GET_USER_PENDING_TEMPLATES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Access/GetPendingTemplates?userId=`+ payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_PENDING_TEMPLATES', data);
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        CONFIRM_USER_TEMPLATES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`Access/ConfirmAccessApproval`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        GET_USER_APPROVALS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Access/GetApprovalsByUserId?userId=`+ payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        GET_USER_TEMPLATES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Access/GetTemplatesByUserId?userId=`+ payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_USER_TEMPLATES', data);
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        GET_UNCONFIRMED_USERS: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`Access/GetUnconfirmedUsers`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_UNCONFIRMED_USERS', data);
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        }
    }
}