import axios from "axios";
// import store from './../index'

export default {
    state: {
        InvoiceUnloadTypes: []
    },
    getters: {
        INVOICE_UNLOAD_TYPES: state => {
            return state.InvoiceUnloadTypes;
        },
    },
    mutations: {
        SET_INVOICE_UNLOAD_TYPES: (state, payload) => {
            state.InvoiceUnloadTypes = payload;
        }
    },
    actions: {
        INVOICE_UNLOAD_TYPES: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Invoice/GetInvoiceUnloadTypes`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};