import axios from "axios";
import store from './../index'

export default{
    state: {
        paymentTypes: [],
        selectedPaymentTypes: []
    },
    getters: {
        PAYMENT_TYPES: state => {
            return state.paymentTypes;
        },
        SELECTED_PAYMENT_TYPES: state => {
            return state.selectedPaymentTypes;
        },
    },
    mutations: {
        SET_PAYMENT_TYPES: (state, payload) => {
            state.paymentTypes = payload;
        },
        SET_SELECTED_PAYMENT_TYPES: (state, payload) => {
            state.selectedPaymentTypes = payload;
        }
    },
    actions:{
        GET_ALL_PAYMENT_TYPES: () => {
            return new Promise(async (resolve, reject) => {
               await axios
                    .get('/Price/GetPaymentTypes')
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_SELECTED_PAYMENT_TYPES', data.map(x => x.id));
                            store.commit('SET_PAYMENT_TYPES', data);
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMMIT_SELECTED_PAYMENT_TYPES: (commit, payload) => {
            store.commit('SET_SELECTED_PAYMENT_TYPES', payload);
        }
    }
}