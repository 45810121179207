<template>
  <v-card :loading="loading">
    <v-card-title>
      <span>
        <code>Конфигурация выгрузки накладных</code>
        {{ this.filial.name }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <!-- Конфигурация исходящего запроса -->
          <v-col>
            <span>Конфигурация исходящего запроса</span>
            <v-text-field
              label="[Url]"
              v-model="editInvoiceConfig.requestConfig.url"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[Urn]"
              v-model="editInvoiceConfig.requestConfig.urn"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[PeriodDateFormat]"
              v-model="editInvoiceConfig.requestConfig.periodDateFormat"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[FromDateParam]"
              v-model="editInvoiceConfig.requestConfig.fromDateParam"
              :rules="rules"
            ></v-text-field>
             <v-text-field
              label="[ToDateParam]"
              v-model="editInvoiceConfig.requestConfig.toDateParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[Encoding]"
              v-model="editInvoiceConfig.requestConfig.encoding"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[DistributorOrderIdParam]"
              v-model="editInvoiceConfig.requestConfig.distributorOrderIdParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[PhOrderIdParam]"
              v-model="editInvoiceConfig.requestConfig.phOrderIdParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[ClientBINParam]"
              v-model="editInvoiceConfig.requestConfig.clientBINParam"
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="InvoiceUnloadTypes"
              v-model="editInvoiceConfig.requestConfig.invoiceUnloadType"
              label="[InvoiceUnloadType]"
              item-value="id"
              :rules="rules"
              item-text="type"
            ></v-select>
            <v-select
              :items="APIRequestProtocols"
              v-model="editInvoiceConfig.requestConfig.requestProtocol"
              label="[RequestProtocol]"
              item-text="protocol"
              item-value="id"
              :rules="rules"
            ></v-select>
            <v-text-field
              label="[authorizationLogin]"
              v-model="editInvoiceConfig.requestConfig.authorizationLogin"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[authorizationPassword]"
              v-model="editInvoiceConfig.requestConfig.authorizationPassword"
              :rules="rules"
            ></v-text-field>
            <!-- <v-text-field
              label="[]"
              v-model="editInvoiceConfig.requestConfig."
              :rules="rules"
            ></v-text-field>
            <v-select
              :items=""
              v-model="editInvoiceConfig.responseConfig."
              label="[]"
              item-value=""
              :rules="rules"
              item-text=""
            ></v-select> -->
          </v-col>
          <!-- Конфигурация принятого ответа -->
          <v-col>
            <span>Конфигурация принятого ответа</span>
            <v-text-field
              label="[certificateNumberParam]"
              v-model="editInvoiceConfig.responseConfig.certificateNumberParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[clientAddressParam]"
              v-model="editInvoiceConfig.responseConfig.clientAddressParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[clientBINParam]"
              v-model="editInvoiceConfig.responseConfig.clientBINParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[clientNameParam]"
              v-model="editInvoiceConfig.responseConfig.clientNameParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[detailSumParam]"
              v-model="editInvoiceConfig.responseConfig.detailSumParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[discountParam]"
              v-model="editInvoiceConfig.responseConfig.discountParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[distributorGoodsCountryParam]"
              v-model="editInvoiceConfig.responseConfig.distributorGoodsCountryParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[distributorGoodsIdParam]"
              v-model="editInvoiceConfig.responseConfig.distributorGoodsIdParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[distributorGoodsNameParam]"
              v-model="editInvoiceConfig.responseConfig.distributorGoodsNameParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[distributorGoodsProducerParam]"
              v-model="editInvoiceConfig.responseConfig.distributorGoodsProducerParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[encoding]"
              v-model="editInvoiceConfig.responseConfig.encoding"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[goodsPriceParam]"
              v-model="editInvoiceConfig.responseConfig.goodsPriceParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[numberBillParam]"
              v-model="editInvoiceConfig.responseConfig.numberBillParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[numberInvoiceParam]"
              v-model="editInvoiceConfig.responseConfig.numberInvoiceParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[orderDetailParam]"
              v-model="editInvoiceConfig.responseConfig.orderDetailParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[orderIdParam]"
              v-model="editInvoiceConfig.responseConfig.orderIdParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[orderParam]"
              v-model="editInvoiceConfig.responseConfig.orderParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[quantityParam]"
              v-model="editInvoiceConfig.responseConfig.quantityParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[receiveDateFormat]"
              v-model="editInvoiceConfig.responseConfig.receiveDateFormat"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[receivedDateParam]"
              v-model="editInvoiceConfig.responseConfig.receivedDateParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[serialNumberParam]"
              v-model="editInvoiceConfig.responseConfig.serialNumberParam"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[shelfLifeFormat]"
              v-model="editInvoiceConfig.responseConfig.shelfLifeFormat"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              label="[shelfLifeParam]"
              v-model="editInvoiceConfig.responseConfig.shelfLifeParam"
              :rules="rules"
            ></v-text-field>
            <v-select
              :items="APIDataFormats"
              v-model="editInvoiceConfig.responseConfig.dataFormat"
              label="[DataFormat]"
              item-value="id"
              :rules="rules"
              item-text="format"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('on-invoice-config-dialog', false)"
        >Закрыть</v-btn
      >
      <v-btn color="primary" @click="UpdateInvoiceConfig()">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
  data: () => ({
    defaultInvoiceConfig: {
      requestConfig: {
        clientBINParam:null,
        filialId: null,
        filial: null,
        url: null,
        urn: null,
        distributorOrderIdParam: null,
        phOrderIdParam: null,
        encoding: null,
        requestProtocol: null,
        invoiceUnloadType: null,
        fromDateParam: null,
        toDateParam: null,
        periodDateFormat: null,
        authorizationLogin: null,
        authorizationPassword: null
      },
      responseConfig: {
        filialId: null,
        filial: null,
        dataFormat: null,
        encoding: null,
        orderParam: null,
        orderDetailParam: null,
        shelfLifeParam: null,
        shelfLifeFormat: null,
        distributorGoodsNameParam: null,
        distributorGoodsProducerParam: null,
        distributorGoodsCountryParam: null,
        goodsPriceParam: null,
        distributorGoodsIdParam: null,
        numberInvoiceParam: null,
        numberBillParam: null,
        clientNameParam: null,
        clientBINParam: null,
        clientAddressParam: null,
        receivedDateParam: null,
        receiveDateFormat: null,
        discountParam: null,
        detailSumParam: null,
        quantityParam: null,
        certificateNumberParam: null,
        serialNumberParam: null,
        orderIdParam: null,
      },
    },
    editInvoiceConfig: {
      requestConfig: {
        clientBINParam:null,
        filialId: null,
        filial: null,
        url: null,
        urn: null,
        distributorOrderIdParam: null,
        phOrderIdParam: null,
        encoding: null,
        requestProtocol: null,
        invoiceUnloadType: null,
        fromDateParam: null,
        toDateParam: null,
        periodDateFormat: null,
        authorizationLogin: null,
        authorizationPassword: null
      },
      responseConfig: {
        filialId: null,
        filial: null,
        dataFormat: null,
        encoding: null,
        orderParam: null,
        orderDetailParam: null,
        shelfLifeParam: null,
        shelfLifeFormat: null,
        distributorGoodsNameParam: null,
        distributorGoodsProducerParam: null,
        distributorGoodsCountryParam: null,
        goodsPriceParam: null,
        distributorGoodsIdParam: null,
        numberInvoiceParam: null,
        numberBillParam: null,
        clientNameParam: null,
        clientBINParam: null,
        clientAddressParam: null,
        receivedDateParam: null,
        receiveDateFormat: null,
        discountParam: null,
        detailSumParam: null,
        quantityParam: null,
        certificateNumberParam: null,
        serialNumberParam: null,
        orderIdParam: null,
      },
    },

    rules: [(value) => !!value || "Обязательное поле для заполнения"],
    loading: false,
  }),
  methods: {
    UpdateInvoiceConfig() {
      this.editInvoiceConfig.requestConfig.filialId = this.editInvoiceConfig.responseConfig.filialId = this.filial.id;

      var payload = {
        invoiceRequestConfigVM: this.editInvoiceConfig.requestConfig,
        invoiceResponseConfigVM: this.editInvoiceConfig.responseConfig,
      };

      this.$store
        .dispatch("UPDATE_INVOICE_CONFIG", payload)
        .then(() => {
          this.$emit("on-invoice-config-dialog", false);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetInvoiceConfig() {
      this.loading = true;
      this.editInvoiceConfig = JSON.parse(
        JSON.stringify(this.defaultInvoiceConfig)
      );

      this.$store
        .dispatch("INVOICE_CONFIG", this.filial.id)
        .then((response) => {
          if (response.requestConfig)
            this.editInvoiceConfig.requestConfig = response.requestConfig;

          if (response.responseConfig)
            this.editInvoiceConfig.responseConfig = response.responseConfig;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
  },
  async mounted() {},

  computed: {
    APIRequestTypes: {
      get: function () {
        return this.$store.getters.API_REQUEST_TYPES;
      },
    },
    APIRequestProtocols: {
      get: function () {
        return this.$store.getters.API_REQUEST_PROTOCOLS;
      },
    },
    APIDataFormats: {
      get: function () {
        return this.$store.getters.API_REQUEST_DATA_FORMATS;
      },
    },
    InvoiceUnloadTypes:{
        get: function () {
        return this.$store.getters.INVOICE_UNLOAD_TYPES;
      },
    }
  },
};
</script>

<style>
</style>