<template>
  <div id="form">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Адреса Доставки
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFilials"
            prepend-icon="mdi-cloud-search-outline"
            label="Поиск"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            label="Фильтр поставщиков"
            item-text="name"
            item-value="id"
            :items="distributors"
            @change="GetFilialsByDistributorId"
            persistent-hint
            hint="Выберите поставщика"
            return-object
            clearable
          ></v-autocomplete>
        </v-card-title>

        <v-sheet v-if="firstLoad" class="px-3 pt-3 pb-3">
          <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="table-row-divider@5"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
        </v-sheet>

        <v-card>
          <v-form ref="listForm" lazy-validation>
            <v-data-table
              :headers="filialHeaders"
              v-show="!firstLoad"
              :items="filials"
              :items-per-page="5"
              class="elevation-2"
              :loading="loading"
              :search="searchFilials"
            >
              <template v-slot:item.shippingAddresses="{ item }">
                <v-btn
                  small
                  @click="dialog = !dialog; GetShippingAddressByFilialId(item)"
                  :disabled="item.uploadingShippingAddress"
                  color="primary"
                >Адреса доставки пользователей</v-btn>
              </template>

              <template v-slot:item.uploadShippingAddress="{ item }">
                <v-btn
                  fab
                  small
                  class="ma-2"
                  @click="UploadShippingAddressByFilialId(item)"
                  :loading="item.uploadingShippingAddress"
                  :disabled="item.uploadingShippingAddress"
                >
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  class="ma-2"
                  color="error"
                  :disabled="!item.uploadingShippingAddress"
                  @click="CancelRequestById(item)"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-form>
        </v-card>

        <v-dialog
          v-model="dialog"
          class="elevation-20"
          transition="scale-transition"
          persistent
          scrollable
        >
          <v-card tile>
            <v-toolbar dense>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{currentFilial}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn text @click="dialog = false">Закрыть</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-title>
              Адреса Доставки
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchInShippingAddress"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                item-key="id"
                :headers="shippingAddressHeaders"
                :items="shippingAddresses"
                :search="searchInShippingAddress"
                :items-per-page="10"
                :loading="loadingShippingAddress"
                single-select
                show-group-by
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  data: () => ({
    dialog: false,
    filials: [],
    shippingAddresses: [],
    distributors: [],
    expanded: [],
    loading: false,
    loadingShippingAddress: true,
    firstLoad: true,
    searchFilials: "",
    searchInShippingAddress: "",
    currentFilial: "",
    filialHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Поставщик", value: "name" },
      { text: "Адреса Доставки", value: "shippingAddresses", sortable: false },
      // {
      //   text: "Выгрузка / Отмена",
      //   value: "uploadShippingAddress",
      //   sortable: false,
      // },
    ],
    shippingAddressHeaders: [
      { text: "Имя", sortable: true, value: "user.firstName" },
      { text: "Фамилия", sortable: true, value: "user.lastName" },
      { text: "Логин", sortable: true, value: "user.login" },
      {
        text: "БИН на момент выгрузки",
        sortable: true,
        value: "binFromFilial",
      },
      {
        text: "ID адресов доставки",
        sortable: true,
        value: "distributorShippingAddressId",
      },
      {
        text: "Название адресов доставки",
        sortable: true,
        value: "distributorShippingAddressName",
      },
    ],
  }),
  async mounted() {
    this.$store.dispatch("DISTRIBUTORS").then(() => {
      this.distributors = this.$store.getters.DISTRIBUTORS;
    });

    this.$store
      .dispatch("FILIALS")
      .then(() => {
        this.firstLoad = !this.firstLoad;
        this.filials = this.$store.getters.FILIALS;
      })
      .catch(() => {
        this.firstLoad = !this.firstLoad;
      });

    this.$store.dispatch("CANCEL_REQUESTS").then(() => {});
  },
  methods: {
    async GetShippingAddressByFilialId(filial) {
      this.shippingAddresses = [];
      this.searchInShippingAddress = "";
      this.currentFilial = "";
      this.loadingShippingAddress = true;
      this.$store
        .dispatch("SHIPPING_ADDRESSES_BY_FILIAL_ID", filial.id)
        .then(() => {
          this.shippingAddresses = this.$store.getters.SHIPPING_ADDRESSES_FILIAL;
          this.currentFilial = filial.name;
          this.loadingShippingAddress = false;
        })
        .catch(() => {
          this.loadingShippingAddress = false;
        });
    },
    async GetFilialsByDistributorId(distributor) {
      if (distributor == undefined) this.filials = this.$store.getters.FILIALS;
      else
        this.filials = this.$store.getters.FILIALS_BY_DISTRIBUTOR_ID(
          distributor.id
        ).filials;
    },
    async UploadShippingAddressByFilialId(item) {
      this.loading = !this.loading;
      item.uploadingShippingAddress = true;

      const axios = require("axios").default;

      let Request = {
        id: item.id,
        cancelTokenSource: axios.CancelToken.source(),
      };

      this.$store.commit("PUSH_REQUEST", Request);

      this.$store
        .dispatch("UNLOAD_SHIPPING_ADDRESSES_BY_FILIAL_ID", item.id)
        .then(() => {
          this.loading = !this.loading;
          item.uploadingShippingAddress = false;
          let Success = {
            text: "Адреса Доставки загружены",
            timeout: 3000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          this.loading = !this.loading;
          item.uploadingShippingAddress = false;
          window.console.log(error);
        });
    },
    async CancelRequestById(item) {
      let Throw = {
        id: item.id,
        message:
          "Выгрузка была отменена пользователем. Поставщик: " + item.name,
      };
      this.$store.dispatch("CANCEL_REQUEST_BY_ID", Throw).then(() => {
        this.loading = !this.loading;
        item.uploadingShippingAddress = false;
      });
    },
  },
  created() {},
};
</script>