<template>
    <v-dialog
        v-model="show"
        max-width="700px"
    >
        <v-card
            class="text-center"
        >
            <v-card-title
                class="justify-center"
            >
                У Вас истекает срок подписки
            </v-card-title>
            <v-card-text>
                <v-list
                    class="text-left"
                >
                    <h4>Срок действия следующих комплектов заканчивается:</h4>
                    <template v-for="(template, index) in templates">
                        <v-list-item
                            :key="template.id"
                        >
                            <v-list-item-action>
                                <v-icon
                                >mdi-information-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div>
                                        <span
                                        >{{ template.accessTemplate.name }},  </span>
                                        действителен до: 
                                        <v-chip
                                            class="mr-3"
                                        >
                                            {{template.dateEnd.substr(0, 10) | toDate}}
                                        </v-chip>
                                        Осталось дней: 
                                        <v-chip
                                            class="warning"
                                        >
                                        {{template.remainingdays}}
                                        </v-chip>
                                        
                                    </div>
                                </v-list-item-title>
                                
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            :key="index"
                        ></v-divider>
                    </template>
                </v-list>
                
                <div>Для продления срока подписки обращайтесь к менеджерам ТОО "PharmIT".</div>
                <v-list>
                    <template
                        v-for="(manager, index) in managers"
                    >
                        <v-list-item
                            :key="index"
                            v-if="[2,5].every(i => manager.userPhones.map(x => x.phoneTypeId).includes(i))"
                    >
                        <v-list-item-content 
                        >
                            <v-list-item-title class="text-h8">
                                {{manager.firstName + " " + manager.lastName}}, +7 {{manager.userPhones.filter(x => x.phoneTypeId == 5)[0].phone}}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-if="manager.userPhones.filter(x => x.phoneTypeId == 2).length > 0"
                            >
                                <a 
                                    :href="`https://api.whatsapp.com/send?phone=+7${manager.userPhones.filter(x => x.phoneTypeId == 2)[0].phone}`" 
                                    target="_blank">+7 {{ manager.userPhones.filter(x => x.phoneTypeId == 2)[0].phone }}
                                </a>
                                <v-icon
                                    class="ml-2 pb-2"
                                    color="green"
                                >
                                    mdi-whatsapp
                                </v-icon>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                        
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="show = false"
                >Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: ()=> ({
        managers: [],
    }),
    methods: {
    },
    computed:{
        show: {
            get: function(){
                return this.$store.getters.GET_SHOW_EXP_NOTICE;
            },
            set: function(){
                this.$store.commit('SET_SHOW_EXP_NOTICE', false);
            }
        },
        templates:{
            get: function (){
                let newarr = this.$store.getters.GET_EXPIRING_TEMPLATES;
                newarr.forEach(element => {
                    element.remainingdays = new Date(element.dateEnd).getDate() - new Date().getDate();
                });
                return newarr;
            }
        },
        
    },
    mounted(){
        this.$store
            .dispatch("GET_MANAGERS")
            .then((data)=>{
                this.managers = data;
            })
            .catch()
            
    }
}
</script>

<style>

</style>