<template>
  <v-row>
    <v-col cols="3">
      <!-- Фильтр -->
      <v-card
        style="position: -webkit-sticky; position: sticky; top: 100px"
        shaped
      >
        <v-card-title>
          <v-icon large left color="primary">mdi-filter</v-icon>
          <span class="title font-weight-light">Фильтр</span>
        </v-card-title>
        <v-form ref="form">
          <v-col class="d-flex">
            <v-autocomplete
              label="Фильтр поставщиков"
              item-text="name"
              item-value="id"
              :loading="loading.getDistributors"
              :items="distributors"
              v-model="distributor"
              @change="GetDistributorFilials()"
              persistent-hint
              hint="Выберите поставщика"
              return-object
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col class="d-flex">
            <v-autocomplete
              label="Фильтр филиалов поставщика"
              item-text="name"
              item-value="id"
              :items="distributorFilials"
              v-model="distributorFilial"
              :rules="[rules.distributor.required]"
              persistent-hint
              hint="Выберите филиала поставщика"
              return-object
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col class="d-flex">
            <v-autocomplete
              label="Фильтр для сравнения"
              item-text="name"
              item-value="id"
              :items="filials"
              v-model="selectedFilials"
              :rules="[rules.filials.required]"
              persistent-hint
              hint="Выберите поставщика (поставщиков) для сравнения"
              return-object
              chips
              clearable
              deletable-chips
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col class="d-flex">
            <v-btn
              :loading="loading.getAnalyzes"
              :disabled="loading.getAnalyzes"
              @click="GetPriceAnalysisReport()"
              block
              color="primary"
            >
              Сформировать отчет
              <template v-slot:loader>
                <span>Подождите немного...</span>
              </template>
            </v-btn>
          </v-col>
          <v-col class="d-flex">
            <v-btn
              :loading="loading.getExcelFile"
              :disabled="
                loading.getExcelFile ||
                analyzes.length === 0 ||
                headers.length === 0
              "
              @click="DownloadExcelFile()"
              block
              color="primary"
            >
              Скачать отчет
              <template v-slot:loader>
                <span>Подождите немного...</span>
              </template>
            </v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-alert v-if="total == 0" border="bottom" colored-border elevation="2"
        >Отчеты не найдены, попробуйте изменить фильтр.</v-alert
      >
      <v-row justify="center" v-if="total != 0">
        <v-col>
          <!-- {{headers}} -->
          <v-card-title> Товары </v-card-title>
          <v-card>
            <v-form ref="listForm" lazy-validation>
              <v-data-table
                :headers="headers"
                :items="analyzesPerPage"
                :options.sync="options"
                :server-items-length="total"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 50, 100, 200, 300, 400],
                  showFirstLastPage: true,
                }"
                :loading="loading.getAnalyzes"
                multi-sort
                class="elevation-3"
                dense
              >
              </v-data-table>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: {
      getDistributors: true,
      getAnalyzes: false,
      getExcelFile: false,
    },
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: ["id"],
      sortDesc: [false],
    },
    headersStart: [
      { text: "№", align: "center", sortable: true, value: "id", show: true },
      {
        text: "Наименование",
        align: "left",
        sortable: true,
        value: "goodFullName",
        show: true,
      },
      {
        text: "ID",
        align: "left",
        sortable: true,
        value: "pricesDistributorGoodsId",
        show: true,
      },
      {
        text: "Производитель",
        align: "left",
        sortable: true,
        value: "producersName",
        show: true,
      },
      {
        text: "Годен",
        align: "left",
        sortable: true,
        value: "pricesShelfLife",
        show: true,
      },
      {
        text: "Максимальная розничная цена",
        align: "left",
        sortable: true,
        value: "priceLimitationsRetail",
        show: true,
      },
    ],
    headersEnd: [
      {
        text: "Валюта",
        align: "left",
        sortable: true,
        value: "currency",
        show: true,
      },
      {
        text: "Мин. цена",
        align: "left",
        sortable: true,
        value: "minCost",
        show: true,
      },
      {
        text: "Вторая цена",
        align: "left",
        sortable: true,
        value: "secondMinCost",
        show: true,
      },
      {
        text: "Место Дистр 1 по цене",
        align: "left",
        sortable: true,
        value: "minCostDistrIndex",
        show: true,
      },
      {
        text: "Разница м/у Дистр 1 и мин ценой",
        align: "left",
        sortable: true,
        value: "diffDistrCostAndMinCost",
        show: true,
      },
    ],
    headers: [],
    total: 0,

    rules: {
      distributor: {
        required: (value) => !!value || "Поле обязательно для заполнения.",
      },
      filials: {
        required: (value) =>
          !(value.length == 0) || "Поле обязательно для заполнения.",
      },
    },

    distributor: null,
    distributors: [],
    distributorFilial: null,
    distributorFilials: [],
    filials: [],
    selectedFilials: [],
    analyzes: [],
    analyzesPerPage: [],

    fileName: "",
  }),
  async mounted() {
    this.GetDistributors();
    this.GetFilials();
  },
  methods: {
    GetDistributors() {
      this.$store
        .dispatch("DISTRIBUTORS_BY_USER_COUNTRY")
        .then(() => {
          this.distributors = this.$store.getters.DISTRIBUTORS_BY_USER_COUNTRY;
          this.loading.getDistributors = false;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetDistributorFilials() {
      if (this.distributor == null) {
        this.distributorFilial = null;
        this.distributorFilials = [];
      } else {
        this.distributorFilials = this.distributor?.filials;
      }
    },
    GetFilials() {
      this.$store
        .dispatch("FILIALS")
        .then(() => {
          this.filials = this.$store.getters.FILIALS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetPriceAnalysisReport() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading.getAnalyzes = true;
      this.total = 0;
      this.headers = [];
      this.analyzes = [];

      let ReportsSearch = {
        Pagination: {
          Take: `${this.options.itemsPerPage}`,
          Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
        },
        SearchProperty: {
          AnalyzesId: this.selectedFilials.map((i) => i.id) ?? [],
          DistributorFilialId: this.distributorFilial?.id ?? -1,
        },
      };

      this.$store
        .dispatch("ANALYZES", ReportsSearch)
        .then((data) => {
          this.analyzes = data;
          this.total = this.analyzes.length;
          for (let i = 0; i < this.analyzes.length; i++) {
            this.analyzes[i].id = i + 1;
          }
          this.SetAnalysisHeaders();
          this.GetPriceAnalysisReportPerPage();
          this.loading.getAnalyzes = false;
        })
        .catch(() => {
          this.total = 0;
          this.headers = [];
          this.loading.getAnalyzes = false;
        });
    },
    SetAnalysisHeaders() {
      let headersDynamic = [];
      headersDynamic.push({
        text: this.distributorFilial.name,
        align: "left",
        sortable: true,
        value: "pricesGoodsPrice",
        show: true,
      });
      headersDynamic.push({
        text: "Надбавка",
        align: "left",
        sortable: true,
        value: "allowance",
        show: true,
      });
      for (let i = 0; i < this.selectedFilials.length; i++) {
        headersDynamic.push({
          text: this.selectedFilials[i].name,
          align: "left",
          sortable: true,
          value: "pricesGoodsPrices[" + i + "]",
          show: true,
        });
        headersDynamic.push({
          text: this.selectedFilials[i].name + " надбавка",
          align: "left",
          sortable: true,
          value: "allowances[" + i + "]",
          show: true,
        });
      }
      this.headers = this.headersStart.concat(headersDynamic, this.headersEnd);
    },
    DownloadExcelFile() {
      this.loading.getExcelFile = true;
      let ExcelData = {
        Headers: this.headers,
        Analyzes: this.analyzes,
      };
      this.$store
        .dispatch("DOWNLOAD_EXCEL_FILE", ExcelData)
        .then(() => {
          this.loading.getExcelFile = false;
        })
        .catch((err) => {
          window.console.log(err);
        });
    },
    GetPriceAnalysisReportPerPage() {
      let take = this.options.itemsPerPage;
      let skip = (this.options.page - 1) * this.options.itemsPerPage;
      this.analyzesPerPage = this.analyzes.slice(skip, skip + take);
    },
  },
  watch: {
    options: {
      handler() {
        this.GetPriceAnalysisReportPerPage();
      },
      deep: true,
    },
  },
};
</script>
