<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto" elevation="4">
          <v-card-text>
            <v-row align="center">
              <v-col cols="1">
                <v-icon x-large color="primary">mdi-credit-card</v-icon>
              </v-col>
              <v-col cols="11">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Последний заказ:
                      {{ lastOrderSentDate.substr(0, 10) | toDate }}
                      {{ lastOrderSentDate.substr(11, 5) }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="headline mb-1">{{
                      sumOrder | toCurrency
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{yourPurhasesStr}}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" elevation="4">
          <v-card-text>
            <v-row align="center">
              <v-col cols="1">
                <v-icon color="primary" x-large>mdi-medical-bag</v-icon>
              </v-col>
              <v-col cols="11">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >{{ quantityDistinctGoods }} уникальных
                      товаров</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1"
                      >{{ sumQuantityGoods }} штук</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Количество купленных товаров</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" elevation="4">
          <v-card-text>
            <v-row align="center">
              <v-col cols="1">
                <v-icon color="primary" x-large>mdi-monitor-eye</v-icon>
              </v-col>
              <v-col cols="11">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Вы сэкономили
                      {{
                        (sumPriceDiscount / sumOrder) | toPercentage(2)
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">{{
                      sumPriceDiscount | toCurrency
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Комиссия</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="3"
        ><v-btn
          @click="GetOrdersGroupedBy()"
          :loading="loadingOrdersGroupedBy"
          block
        >
          Применить фильтр
          <template v-slot:loader>
            <span>Ведётся поиск...</span>
          </template>
        </v-btn></v-col
      > -->
      <v-col cols="2"
        ><v-radio-group
          v-model="groupByDateVariant"
          row
          @change="GetOrdersGroupedBy()"
        >
          <v-radio label="По дням" value="dayly"></v-radio>
          <v-radio label="По месяцам" value="monthly"></v-radio> </v-radio-group
      ></v-col>
      <v-col cols="3">
        <v-menu
          ref="menu.sentDateFrom"
          v-model="menu.sentDateFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="sentDateFromFormatted"
              label="Дата с"
              persistent-hint
              prepend-icon="mdi-clock-time-eight-outline"
              v-bind="attrs"
              v-on="on"
              hint="Выберите дату доставки заказа"
              clearable
              @click:clear="sentDateFrom = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="sentDateFrom"
            no-title
            @input="menu.sentDateFrom = false"
            @change="GetOrdersGroupedBy()"
          ></v-date-picker> </v-menu
      ></v-col>
      <v-col cols="3"
        ><v-menu
          ref="menu.sentDateTo"
          v-model="menu.sentDateTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="sentDateToFormatted"
              label="Дата по (включительно)"
              persistent-hint
              prepend-icon="mdi-clock-time-eight-outline"
              v-bind="attrs"
              v-on="on"
              hint="Выберите дату доставки заказа"
              clearable
              @click:clear="sentDateTo = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="sentDateTo"
            no-title
            @input="menu.sentDateTo = false"
            @change="GetOrdersGroupedBy()"
          ></v-date-picker> </v-menu
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="mx-auto" elevation="4">
          <v-card-text>
            <line-chart :chart-data="lineData" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mx-auto" elevation="4">
          <v-card-text>
            <pie-chart :chart-data="pieData" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LineChart from "../../charts/LineChart";
import PieChart from "../../charts/PieChart";

export default {
  name: "ChartContainer",
  components: { LineChart, PieChart },
  data: (vm) => ({
    yourPurhasesStr: 'Ваши покупки за весь период',
    loadingOrdersGroupedBy: false,
    lineData: {},
    pieData: {},
    sumOrder: 0,
    quantityDistinctGoods: 0,
    sumQuantityGoods: 0,
    lastOrderSentDate: "",
    sumPriceDiscount: 0,

    sentDateFrom: null,
    sentDateTo: null,
    sentDateFromFormatted: vm.$options.filters.toDate(),
    sentDateToFormatted: vm.$options.filters.toDate(),
    menu: { sentDateFrom: false, sentDateTo: false },
    groupByDateVariant: "dayly",
  }),

  methods: {
    async GetOrdersGroupedBy() {
      this.loadingOrdersGroupedBy = true;
      let SearchProperty = {
        SentDateFrom: `${this.sentDateFrom ?? ""}`,
        SentDateTo: `${this.sentDateTo ?? ""}`,
        GroupByDateVariant: this.groupByDateVariant,
      };

      let OrdersSearch = {
        SearchProperty,
      };

      this.ResetData();

      this.$store
        .dispatch("ORDERS_GROUPED_BY", OrdersSearch)
        .then((data) => {
          this.lineData = {
            labels: [],
            datasets: [],
          };

          this.lineData.datasets.push({
            label: "Покупка",
            backgroundColor: this.GetRandomColor(),
            data: [],
          });

          data.ordersGroupBySentDate.forEach((order) => {
            this.lineData.labels.push(
              this.$options.filters.toDate(order.date.substr(0, 10))
            );

            this.lineData.datasets[0].data.push(order.sum.toFixed(2));
          });

          this.pieData = {
            labels: [],
            datasets: [
              {
                backgroundColor: [],
                data: [],
              },
            ],
          };
          var colors = this.GenerateColor(
            "#BBDEFB",
            "#0D47A1",
            data.ordersGroupByFilial.length
          );
          data.ordersGroupByFilial.forEach((order, index) => {
            this.pieData.labels.push(order.filial.name);
            this.pieData.datasets[0].backgroundColor.push("#" + colors[index]);
            this.pieData.datasets[0].data.push(order.sum.toFixed(2));
          });

          let userData = data.ordersGroupByUser[0];
          this.sumOrder = userData.sumOrder;
          this.quantityDistinctGoods = userData.quantityDistinctGoods;
          this.sumQuantityGoods = userData.sumQuantityGoods;
          this.lastOrderSentDate = userData.lastOrderSentDate;
          this.sumPriceDiscount = userData.sumPriceDiscount;

          this.loadingOrdersGroupedBy = false;
        })
        .catch(() => {
          this.loadingOrdersGroupedBy = false;
        });
    },

    ResetData() {
      this.sumOrder = 0;
      this.quantityDistinctGoods = 0;
      this.sumQuantityGoods = 0;
      this.lastOrderSentDate = "";
      this.sumPriceDiscount = 0;

      this.lineData = {
        labels: [],
        datasets: [],
      };

      this.pieData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      };
    },

    GetRandomColor() {
      var letters = "0123456789ABCDEF";
      var color =
        "#0D47A1" +
        letters[Math.floor(Math.random() * 16)] +
        letters[Math.floor(Math.random() * 16)];
      return color;
    },

    Hex(c) {
      var s = "0123456789abcdef";
      var i = parseInt(c);
      if (i == 0 || isNaN(c)) return "00";
      i = Math.round(Math.min(Math.max(0, i), 255));
      return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
    },

    ConvertToHex(rgb) {
      return this.Hex(rgb[0]) + this.Hex(rgb[1]) + this.Hex(rgb[2]);
    },

    Trim(s) {
      return s.charAt(0) == "#" ? s.substring(1, 7) : s;
    },

    ConvertToRGB(hex) {
      var color = [];
      color[0] = parseInt(this.Trim(hex).substring(0, 2), 16);
      color[1] = parseInt(this.Trim(hex).substring(2, 4), 16);
      color[2] = parseInt(this.Trim(hex).substring(4, 6), 16);
      return color;
    },

    GenerateColor(colorStart, colorEnd, colorCount) {
      // The beginning of your gradient
      var start = this.ConvertToRGB(colorStart);

      // The end of your gradient
      var end = this.ConvertToRGB(colorEnd);

      // The number of colors to compute
      var len = colorCount;

      //Alpha blending amount
      var alpha = 0.0;

      var data = [];

      for (let i = 0; i < len; i++) {
        var c = [];
        alpha += 1.0 / len;

        c[0] = start[0] * alpha + (1 - alpha) * end[0];
        c[1] = start[1] * alpha + (1 - alpha) * end[1];
        c[2] = start[2] * alpha + (1 - alpha) * end[2];

        data.push(this.ConvertToHex(c));
      }
      return data;
    },
  },

  created() {
    this.GetOrdersGroupedBy();
  },

  watch: {
    sentDateFrom() {
      window.console.log("this.sentDateFrom", this.sentDateFrom);
      this.sentDateFromFormatted = this.$options.filters.toDate(
        this.sentDateFrom
      );
      this.yourPurhasesStr = 'Ваши покупки за выбранный период';
    },
    sentDateTo() {
      this.sentDateToFormatted = this.$options.filters.toDate(this.sentDateTo);
      this.yourPurhasesStr = 'Ваши покупки за выбранный период'
      //
    },
  },
};
</script>
