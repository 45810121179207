import Vue from 'vue'
// import {
//     HubConnectionBuilder,
//     LogLevel
// } from "@aspnet/signalr";
import * as signalR from "@aspnet/signalr";
Vue.use(signalR)
// Vue.use(HubConnectionBuilder)
// Vue.use(LogLevel)

export default signalR