import mrcAxios from "../../config/mrcAxios"
import store from './../index'

export default {
    state: {
        companyUserGroups: [],
        companyUserGroupCompanyGoodsGroups: [],
        selectedCompanyUserGroupId: null,
        selectedCompanyUserGroupUserId: null,
        companyUserGroupUserPharms: []
    },
    getters: {
        COMPANY_USER_GROUPS: state => {
            return state.companyUserGroups;
        },
        COMPANY_USER_GROUP_COMPANY_GOODS_GROUPS: state => {
            return state.companyUserGroupCompanyGoodsGroups;
        },
        SELECTED_COMPANY_USER_GROUP: state => {
            return state.selectedCompanyUserGroupId;
        },
        SELECTED_COMPANY_USER_GROUP_USER: state => {
            return state.selectedCompanyUserGroupUserId;
        },
        COMPANY_USER_GROUP_USER_PHARMS: state => {
            return state.companyUserGroupUserPharms;
        },
    },
    mutations: {
        SET_COMPANY_USER_GROUPS: (state, payload) => {
            state.companyUserGroups = payload;
        },
        PUSH_TO_COMPANY_USER_GROUPS: (state, payload) => {
            state.companyUserGroups.push(payload);
        },
        SET_COMPANY_USER_GROUP_COMPANY_GOODS_GROUPS: (state, payload) => {
            state.companyUserGroupCompanyGoodsGroups = payload;
        },
        SET_SELECTED_COMPANY_USER_GROUP: (state, payload) => {
            state.selectedCompanyUserGroupId = payload;
        },
        SET_SELECTED_COMPANY_USER_GROUP_USER: (state, payload) => {
            state.selectedCompanyUserGroupUserId = payload;
        },
        SET_COMPANY_USER_GROUP_USER_PHARMS: (state, payload) => {
            state.companyUserGroupUserPharms = payload;
        },
    },
    actions: {
        GET_COMPANY_USER_GROUPS: () => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/CompanyUserGroup/Get`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_USER_GROUPS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        ADD_RANGE_COMPANY_USER_GROUP_COMPANY_GOODS_GROUPS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .post(`/CompanyUserGroupCompanyGoodsGroup/AddRange`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_USER_GROUP_COMPANY_GOODS_GROUPS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMMIT_SELECTED_COMPANY_USER_GROUP: (commit, payload) => {
            store.commit('SET_SELECTED_COMPANY_USER_GROUP', payload);
        },
        ADD_COMPANY_USER_GROUP: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .post(`/CompanyUserGroup/Add`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('PUSH_TO_COMPANY_USER_GROUPS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMMIT_SELECTED_COMPANY_USER_GROUP_USER: (commit, payload) => {
            store.commit('SET_SELECTED_COMPANY_USER_GROUP_USER', payload);
        },
        GET_COMPANY_USER_GROUP_USER_PHARMS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/CompanyUserGroupUserPharm/GetByCompanyUserGroupUserId?companyUserGroupUserId=${payload}`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_USER_GROUP_USER_PHARMS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        GET_COMPANY_USER_GROUP_USER_PHARMS_BY_USER: () => {
            return new Promise((resolve, reject) => {
                mrcAxios
                    .get(`/CompanyUserGroupUserPharm/Get`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            if (data.data != null) {
                                store.commit('SET_COMPANY_USER_GROUP_USER_PHARMS', data.data);
                                resolve(true);
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};