<template>
  <v-row>
    <v-col cols="2">
      <!-- Фильтр -->
      <v-card
        style="position: -webkit-sticky; position: sticky; top: 100px"
        shaped
      >
        <v-card-title>
          <v-icon large left color="primary">mdi-filter</v-icon>
          <span class="title font-weight-light">Фильтр</span>
        </v-card-title>
        <v-col class="d-flex">
          <v-autocomplete
            label="Фильтр поставщиков"
            item-text="name"
            item-value="id"
            :items="filials"
            persistent-hint
            v-model="filial"
            hint="Выберите поставщика"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex">
          <v-text-field
            label="Логин пользователя"
            persistent-hint
            v-model="login"
            hint="Веедите полностью"
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex">
          <v-text-field
            label="Фильтр заказов"
            persistent-hint
            v-model="orderId"
            hint="Номер заказа"
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex">
          <v-autocomplete
            label="Фильтр типа запросов"
            item-text="type"
            item-value="id"
            :items="requestTypes"
            v-model="requestType"
            persistent-hint
            hint="Выберите типа запроса"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex">
          <v-menu
            ref="menu.requestDateFrom"
            v-model="menu.requestDateFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="requestDateFromFormatted"
                label="Дата с"
                persistent-hint
                prepend-icon="mdi-clock-time-eight-outline"
                v-bind="attrs"
                v-on="on"
                hint="Выберите дату запроса"
                clearable
                @click:clear="requestDateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="requestDateFrom"
              no-title
              @input="menu.requestDateFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex">
          <v-menu
            ref="menu.requestDateTo"
            v-model="menu.requestDateTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="requestDateToFormatted"
                label="Дата по (включительно)"
                persistent-hint
                prepend-icon="mdi-clock-time-eight-outline"
                v-bind="attrs"
                v-on="on"
                hint="Выберите дату запроса"
                clearable
                @click:clear="requestDateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="requestDateTo"
              no-title
              @input="menu.requestDateTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex">
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="GetAPIRequests()"
            block
            color="primary"
          >
            Поиск запросов
            <template v-slot:loader>
              <span>Ведётся поиск...</span>
            </template>
          </v-btn>
        </v-col>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата запроса</th>
              <th class="text-left">Пользователь</th>
              <th class="text-left">Тип запроса</th>
              <th class="text-left">Филиал</th>
              <th class="text-left">Номер заказа</th>
              <th class="text-left">Запрос</th>
              <th class="text-left">Тело Запроса</th>
              <th class="text-left">Ответ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="request in requests" :key="request.id">
              <td class="caption">
                {{ new Date(request.requestDateTime).toLocaleString() }}
              </td>
              <td>{{ request.user.login }}</td>
              <td class="caption">
                {{ requestTypes.find((x) => x.id == request.requestType).type }}
              </td>
              <td class="caption">
                <span v-if="filials.find((x) => x.id == request.filialId) != null">{{
                  filials.find((x) => x.id == request.filialId).name
                }}</span>
              </td>
              <td class="caption">{{ request.orderId }}</td>
              <td class="caption">{{ request.request }}</td>
              <td class="caption">
                <v-btn
                  small
                  download="response"
                  :href="'data:,' + request.requestBody"
                  color="primary"
                  v-if="request.requestBody"
                >
                  Тело</v-btn
                >
                <span v-else>пусто</span>
              </td>

              <td class="caption">
                <v-btn
                  v-if="request.response"
                  small
                  color="primary"
                  @click="DownloadAPIRequestsOutgoingResponse(request.response)"
                >
                  Ответ</v-btn
                >
                <span v-else>пусто</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 50,
      },

      filial: null,
      filials: [],
      orderId: null,
      login: null,

      requests: [],

      requestType: null,
      requestTypes: [],

      loading: false,

      requestDateFrom: null,
      requestDateTo: null,
      requestDateFromFormatted: this.$options.filters.toDate(),
      requestDateToFormatted: this.$options.filters.toDate(),
      menu: { requestDateFrom: false, requestDateTo: false },
    };
  },
  methods: {
    GetFilials() {
      this.$store
        .dispatch("FILIALS")
        .then(() => {
          this.filials = this.$store.getters.FILIALS;
        })
        .catch(() => {});
    },
    GetAPIRequestTypes() {
      this.$store
        .dispatch("API_REQUEST_TYPES")
        .then((response) => {
          this.requestTypes = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAPIRequests() {
      this.requests = [];
      this.loading = true;
      // let Pagination = {
      //   Take: `${this.options.itemsPerPage}`,
      //   Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      // };

      let SearchProperty = {
        OrderId: `${this.orderId ?? ""}`,
        FilialId: `${this.filial?.id ?? ""}`,
        RequestTypeId: `${this.requestType?.id ?? ""}`,
        RequestDateFrom: `${this.requestDateFrom ?? ""}`,
        RequestDateTo: `${this.requestDateTo ?? ""}`,
        Login: `${this.login ?? ""}`
      };

      // let RequestSearch = {
      //   Pagination,
      //   SearchProperty,
      // };
      this.$store
        .dispatch("API_REQUESTS", SearchProperty)
        .then((data) => {
          this.loading = false;
          this.requests = data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    DownloadAPIRequestsOutgoingResponse(id) {
      this.$store
        .dispatch("API_REQUEST_DOWNLOAD_RESPONSE", id)
        .then(() => {})
        .catch(() => {});
    },
  },

  created() {
    this.GetFilials();
    this.GetAPIRequestTypes();
    this.GetAPIRequests();
  },

  watch: {
    requestDateFrom() {
      this.requestDateFromFormatted = this.$options.filters.toDate(
        this.requestDateFrom
      );
    },
    requestDateTo() {
      this.requestDateToFormatted = this.$options.filters.toDate(
        this.requestDateTo
      );
      //
    },
  },
};
</script>

<style>
</style>