<template>
    <div class="mb=1">
        <v-card
            class="mb-3"
        >
            <v-card-title>
                Контроллер
            </v-card-title>
            <v-row
                class="pa-4"
                justify="space-between"
            >
                <v-col cols="3">
                    <v-list
                        v-if="users.length"
                    >
                        <v-list-item-group
                            color="primary"
                        >
                            <v-list-item v-for="item in users" :key="item.id" @click="GetUserApprovals(item)" >
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{item.login}}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>

                    </v-list>
                </v-col>
                <v-col
                    class="text-center pl-12"
                    v-if="userApprovals.length" 
                    cols="9"
                >
                    <template
                        class="pt-6 mx-auto text-center"
                    >
                        <v-card
                            :key="selectedUser.id"
                            flat
                        >
                            <v-card-text
                            >
                                <h3 class="text-h5 mb-2 text-center">
                                    {{ selectedUser.firstName + " " + selectedUser.lastName}}
                                </h3>
                                <div class="blue--text mb-2">
                                    {{ selectedUser.login }}
                                </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-row
                                class="text-left"
                                tag="v-card-text"
                            >
                                <v-col
                                    class="text-right mr-4 mb-2"
                                    tag="strong"
                                    cols="5"
                                >
                                    Компания:
                                </v-col>
                                <v-col>{{ selectedUser.company.name }}</v-col>
                            </v-row>
                        </v-card>
                    </template>
                    <v-expansion-panels
                        v-for="(userApproval, index) in userApprovals"
                        :key="`userApproval-${index}`"
                        multiple
                        v-model="panel"
                        class="mt-5 text-left"
                    >
                        <v-expansion-panel
                            v-if="userApprovals.length"
                            open-all
                        >
                            <v-expansion-panel-header
                            >
                                Менеджер: {{userApproval.managerUser.login}}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                            >
                                <h5>Дата запроса:
                                    {{
                                        new Date(userApproval.dateRequest).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        })
                                    }}
                                </h5>
                                <h5
                                    class="mb-1"
                                >Комментарий: {{userApproval.comment}}</h5>
                                <v-divider class="mb-2"></v-divider>
                                <div
                                    v-for="(accessApproval, indx) in userApproval.accessApprovals"
                                    :key="`accessTemplate-${indx}-${index}`"
                                >
                                   <v-btn
                                        block
                                        class="mt-2"
                                        @click="SelectItem(accessApproval)"
                                        :style="{backgroundColor : selectedTemplates.indexOf(accessApproval) == -1? '':'rgba(112, 176, 255, 0.31)'}"
                                        max-width="200"
                                   >
                                        Шаблон:
                                        <code
                                            class="mr-1"
                                        >
                                            {{accessApproval.accessTemplate.name}}
                                        </code>
                                        Дата начала:
                                        <code>
                                            {{accessApproval.dateStart.substr(0, 10) | toDate}}
                                        </code>
                                        Дата окончания:
                                        <code>
                                             {{accessApproval.dateEnd.substr(0, 10) | toDate}}
                                        </code>
                                   </v-btn>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        class="mt-5 mr-2 error"
                        @click="ConfirmUserTemplates(3)"
                    >
                        Отклонить
                    </v-btn>
                    <v-btn
                        small
                        class="mt-5 success"
                        @click="ConfirmUserTemplates(2)"
                    >
                        Подтвердить
                    </v-btn>
                </v-col>
                <v-col
                    v-else-if="users.length"
                    class="text-center"
                >Выберите пользователя</v-col>
                <v-col v-else class="text-center">
                    Нет запросов на подтверждение 
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
    data: () => ({
        active: [],
        open:[],
        items: [],
        panel:[0],
        users:[],
        selectedUser: {},
        userApprovals: [],
        selectedTemplates: [],
    }),
    mounted() {
        this.GetUnconfirmedUsers();
    },
    methods: {
        GetUnconfirmedUsers(){
            this.$store
                .dispatch("GET_UNCONFIRMED_USERS")
                .then(() => {
                    this.users = this.$store.getters.GET_UNCONFIRMED_USERS;
                })
                .catch((error)=> {
                    window.console.log(error);
                })
        },
        GetUserApprovals(item) {
            this.selectedUser = item;
            this.selectedTemplates = [];
            this.$store
                .dispatch("GET_USER_APPROVALS", item.id)
                .then((data)=> {
                    this.userApprovals = data;
                })
                .catch((error)=> {
                    window.console.log(error);
                });
        },
        SelectItem(item){
            if (this.selectedTemplates.indexOf(item) === -1){
                this.selectedTemplates.push(item);
            }
            else {
                let index = this.selectedTemplates.indexOf(item);
                this.selectedTemplates.splice(index, 1);
            }
        },
        ConfirmUserTemplates(approvalStatus){
            if (!this.selectedTemplates.length)
            {
                confirm('Выберите шаблоны доступов');
                return true;
            }
            if (confirm('Подтвердите действие')){
                let payload = {
                    userId: this.selectedUser.id,
                    accessApprovals: this.selectedTemplates,
                    approvalStatusId: approvalStatus
                };
                this.$store
                    .dispatch("CONFIRM_USER_TEMPLATES", payload)
                    .then((data)=>{
                        if (data){
                            this.userApprovals.forEach((item) => {
                                item.accessApprovals = item.accessApprovals.filter(x => this.selectedTemplates.indexOf(x) == -1);
                            })
                            this.userApprovals = this.userApprovals.filter(x => x.accessApprovals.length > 0);
                            this.selectedTemplates = [];
                            this.GetUnconfirmedUsers();
                        }
                        this.panel = [0];
                    })
                    .catch((error)=> {
                        window.console.log(error);
                    });
            }
        },
    },
    computed: {
    },
    created: function () {
    },
};
</script>