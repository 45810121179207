import axios from "axios";


export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        GET_SCHEDULERS_BY_CONTEXT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Scheduler/GetByContext`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CREATE_SCHEDULER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Scheduler/CreateAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_SCHEDULER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/Scheduler/UpdateAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        DELETE_SCHEDULER: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Scheduler/DeleteById?id=` + payload.id)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },

    }
};