<template>
  <div>
    <v-card-title>
      Поставщики
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchDistributors"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-sheet :loading="loading" v-if="firstLoad" class="px-3 pt-3 pb-3">
      <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        type="table-row-divider@5"
      ></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
    </v-sheet>

    <v-data-table
      :headers="distributorHeaders"
      v-show="!firstLoad"
      :items="distributors"
      :items-per-page="10"
      class="elevation-2"
      :loading="loading"
      :search="searchDistributors"
    >
      <template v-slot:item.filial="{ item }">
        <v-btn
          small
          @click="
            dialog = !dialog;
            GetFilialsByDistributorId(item);
          "
          color="primary"
          >Филиалы</v-btn
        >
      </template>
    </v-data-table>

    <!-- Поставщики -->
    <v-dialog
      v-model="dialog"
      hide-overlay
      class="elevation-20"
      transition="scale-transition"
      width="1800px"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="dialog = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          Филиалы поставщика
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFilials"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="filialHeaders"
            :items="filials"
            :search="searchFilials"
            :items-per-page="500"
          >
           
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,

    APIRequestTypes: [],
    APIRequestProtocols: [],
    APIDataFormats: [],

    filialRequestAbilitiesDialog: false,
    shippingAddressConfigDialog: false,
    sendOrderConfigDialog: false,
    priceConfigDialog: false,
    contractConfigDialog: false,

    distributors: [],
    filials: [],
    currentFilial: {},
    expanded: [],
    loading: true,
    firstLoad: true,
    searchDistributors: "",
    searchFilials: "",
    distributorHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Название", value: "name" },
      { text: "", value: "filial", sortable: false },
    ],
    filialHeaders: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Название", value: "name" },
      { text: "Телефон", value: "phone" },
      { text: "Почта", value: "email" },
    ],
  }),
  async mounted() {
    this.GetDistributors();
    this.GetAPIRequestTypes();
    this.GetAPIRequestProtocols();
    this.GetAPIDataFormats();
  },
  methods: {
   
    GetDistributors() {
      this.$store
        .dispatch("DISTRIBUTORS_BY_USER_COUNTRY")
        .then(() => {
          this.Loading();
          this.distributors = this.$store.getters.DISTRIBUTORS_BY_USER_COUNTRY;
        })
        .catch((error) => {
          this.Loading();
          window.console.log(error);
        });
    },
    GetFilialsByDistributorId(filial) {
      this.filials = this.$store.getters.FILIALS_BY_DISTRIBUTOR_ID(
        filial.id
      ).filials;
    },
    Loading() {
      this.firstLoad = !this.firstLoad;
      this.loading = !this.loading;
    },

    GetAPIRequestTypes() {
      this.$store
        .dispatch("API_REQUEST_TYPES")
        .then((response) => {
          this.$store.commit("SET_API_REQUEST_TYPES", response);
          this.APIRequestTypes = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAPIRequestProtocols() {
      this.$store
        .dispatch("API_REQUEST_PROTOCOLS")
        .then((response) => {
          this.$store.commit("SET_API_REQUEST_PROTOCOLS", response);
          this.APIRequestProtocols = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAPIDataFormats() {
      this.$store
        .dispatch("API_REQUEST_DATA_FORMATS")
        .then((response) => {
          this.$store.commit("SET_API_REQUEST_DATA_FORMATS", response);
          this.APIDataFormats = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  computed: {
    userActions: {
      get: function () {
        return this.$store.getters.CURRENT_USER_ACTIONS;
      },
    },
  },
};
</script>