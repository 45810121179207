import axios from "axios";
import store from './../index'

export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        DISCOUNTS: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Discount/GetAll`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        CREATE_DISCOUNT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Discount/Create`, payload)
                    .then(({
                        data,
                        status
                    }) => {

                        if (status === 200) {
                            let Success = {
                                text: 'Скидка добавлена',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);
                            data.filial = payload.filial
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        DELETE_DISCOUNT: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/Discount/Delete`, {
                        data: payload
                    })
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
};