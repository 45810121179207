<template>
  <v-card>
    <v-card-title>
      <span>
        <code>Настройка возможностей исходящих запросов</code>
        {{this.filial.name}}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <v-col>
            <v-select
              v-model="filialRequestAbilities"
              :items="selectAPIRequestTypes"
              item-text="apiRequestType.type"
              item-value="apiRequestType.id"
              label="Выбрать"
              persistent-hint
              return-object
              multiple
              chips
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('on-filial-request-abilities-dialog', false)">Закрыть</v-btn>
      <v-btn color="primary" @click="UpdateFilialRequestAbilities();">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    filial: Object,
  },
  data: () => ({
    filialRequestAbilities: [],
    selectAPIRequestTypes: [],
    loading: false,
  }),
  methods: {
    UpdateFilialRequestAbilities() {
      this.$store
        .dispatch("UPDATE_FILIAL_REQUEST_ABILITIES", {
          filialRequestAbilities: this.filialRequestAbilities,
          filialId: this.filial.id,
        })
        .then(() => {
          this.$emit("on-filial-request-abilities-dialog", false);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetFilialRequestAbilities() {
      this.loading = true;
      this.selectAPIRequestTypes = [];
      this.filialRequestAbilities = [];
      this.$store
        .dispatch("FILIAL_REQUEST_ABILITIES", this.filial.id)
        .then((response) => {
          this.filialRequestAbilities = response;
          for (let index = 0; index < this.APIRequestTypes.length; index++) {
            const element = {
              filialId: this.filial.id,
              apiRequestTypeId: this.APIRequestTypes[index].id,
              apiRequestType: this.APIRequestTypes[index],
            };
            this.selectAPIRequestTypes.push(element);
          }

          this.loading = false;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  async mounted() {},

  computed: {
    APIRequestTypes: {
      get: function () {
        return this.$store.getters.API_REQUEST_TYPES;
      },
    },
    APIRequestProtocols: {
      get: function () {
        return this.$store.getters.API_REQUEST_PROTOCOLS;
      },
    },
    APIDataFormats: {
      get: function () {
        return this.$store.getters.API_REQUEST_DATA_FORMATS;
      },
    },
  },
};
</script>

<style>
</style>