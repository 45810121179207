import axios from "axios";

export default {
    state: {
        shippingAddressConfig: null,
    },
    getters: {
        SHIPPING_ADDRESS_CONFIG: state => {
            return state.shippingAddressConfig;
        },
    },
    mutations: {
        SET_SHIPPING_ADDRESS_CONFIG: (state, payload) => {
            state.shippingAddressConfig = payload;
        },
    },
    actions: {
        SHIPPING_ADDRESS_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ObjectConfig/GetShippingAddressConfig?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_SHIPPING_ADDRESS_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/ObjectConfig/UpdateShippingAddressConfig`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        CONTRACT_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ObjectConfig/GetContractConfig?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_CONTRACT_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/ObjectConfig/UpdateContractConfig`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        ORDER_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ObjectConfig/GetOrderConfig?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_ORDER_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/ObjectConfig/UpdateOrderConfig`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        PRICE_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ObjectConfig/GetPriceConfig?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_PRICE_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/ObjectConfig/UpdatePriceConfig`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        INVOICE_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/ObjectConfig/GetInvoiceConfig?filialId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        UPDATE_INVOICE_CONFIG: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/ObjectConfig/UpdateInvoiceConfig`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};