<template>
  <v-col lg="6" md="6" sm="12" cols="12">
    <v-tabs v-model="activeOrderTab">
      <v-tab v-for="item in items" :key="item.tab" :href="`#${item.tab}`">{{
        item.tab
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeOrderTab">
      <v-tab-item v-for="item in items" :key="item.tab" :value="item.tab">
        <div v-if="item.tab == 'Новинки'">
          <AdTemplate :adLocation="3"></AdTemplate>
        </div>
        <div v-if="item.tab == 'Цены'">
          <OrderPrices></OrderPrices>
        </div>
        <div v-if="item.tab == 'Аналоги'">
          <OrderAnalogs></OrderAnalogs>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    items: [
      { tab: "Новинки", content: "" },
      { tab: "Цены", content: "" },
      { tab: "Аналоги", content: "" },
    ],
  }),
  computed: {
    activeOrderTab: {
      get: function () {
        return this.$store.getters.ORDER_RIGHT_TAB;
      },
      set: function (newValue) {
        this.$store.commit("SET_ORDER_RIGHT_TAB", newValue);
      },
    },
  },
};
</script>