import axios from "axios";
import store from './../index'
import router from '../../config/router'

export default {
  state: {
    access: localStorage.getItem('PHC_accT'),
    refresh: localStorage.getItem('PHC_refT'),
  },
  getters: {
    ACCESS_TOKEN: state => {
      return state.access;
    },
    REFRESH_TOKEN: state => {
      return state.refresh;
    },
  },
  mutations: {
    SET_TOKENS: (state, payload) => {
      state.access = payload.access
      state.refresh = payload.refresh
      localStorage.setItem('PHC_accT', state.access);
      localStorage.setItem('PHC_refT', state.refresh);
    },
    CLEAR_TOKENS: (state) => {
      state.access = ''
      state.refresh = ''
      localStorage.removeItem('PHC_accT');
      localStorage.removeItem('PHC_refT');
    },
  },
  actions: {
    CREATE_TOKENS: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`/Token/CreateAll`, payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200 && data.accessToken && data.refreshToken) {

              let Tokens = {
                access: data.accessToken,
                refresh: data.refreshToken
              }
              store.commit('SET_TOKENS', Tokens);
              store.commit('SET_USER', data.user);

              resolve(true);

            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    CLEAR_TOKENS: () => {
      return new Promise((resolve) => {
        store.commit('CLEAR_TOKENS');
        localStorage.clear();
        router.push({
          name: 'login'
        })
        resolve(true);
      });
    },
    UPDATE_TOKENS: (commit, payload) => {
      return new Promise((resolve, reject) => {

        axios
          .post(`/Token/Update`, payload)
          .then(({
            data,
            status
          }) => {

            if (status === 200 && data.accessToken && data.refreshToken) {
              let Tokens = {
                access: data.accessToken,
                refresh: data.refreshToken
              }

              store.commit('SET_TOKENS', Tokens);

              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          })

      });

    }
  }
};