import axios from "axios";
import store from '../index'

export default {
    state: {
        bankCardsDialog: false,
        bankCards: [],
        payOrderDialog: false,
        payOrderInvoiceDialog: false
    },
    getters: {
        HALYK_CARDS_DIALOG: state => {
            return state.bankCardsDialog
        },
        HALYK_CARDS: state => {
            return state.bankCards
        },
        PAY_ORDER_DIALOG: state => {
            return state.payOrderDialog
        },
        PAY_ORDER_INVOICE_DIALOG: state => {
            return state.payOrderInvoiceDialog
        },
    },
    mutations: {
        SET_HALYK_CARDS_DIALOG: (state, payload) => {
            state.bankCardsDialog = payload;
        },
        SET_HALYK_CARDS: (state, payload) => {
            state.bankCards = payload;
        },
        SET_PAY_ORDER_DIALOG: (state, payload) => {
            state.payOrderDialog = payload;
        },
        SET_PAY_ORDER_INVOICE_DIALOG: (state, payload) => {
            state.payOrderInvoiceDialog = payload;
        },
    },
    actions: {
        HALYK_CARDS: (commit, payload) => {
            store.commit("SET_HALYK_CARDS", []);
            return new Promise((resolve, reject) => {
                axios
                    .get(`/HalykPay/GetCardsAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_HALYK_CARDS", data);
                            resolve(data);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        HALYK_CARDS_CONTEXT: (commit, payload) => {
            store.commit("SET_HALYK_CARDS", []);
            return new Promise((resolve, reject) => {
                axios
                    .get(`/HalykPay/GetCardsByContextAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("SET_HALYK_CARDS", data);
                            resolve(true);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CARD_REG_REQUEST: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/HalykPay/CardRegRequest`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {

                            resolve(data);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        SET_WORKING_CARD: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/HalykPay/SetWorkingCard`, payload)
                    .then(({

                        status
                    }) => {

                        if (status === 200) {
                            let Success = {
                                text: 'Рабочая карта изменена',
                                timeout: 5000,
                                color: 'success',
                                show: true
                            }
                            store.commit('SET_SNACKBAR', Success);

                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        TRANSFER_P2P: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/HalykPay/TransferP2PAsync?orderId=` + payload.orderId + `&balance=` + payload.balance + `&commission=` + payload.commission)
                    .then(({
                        data,
                        status
                    }) => {
                        window.console.log(data)
                        if (status === 200) {



                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        UPDATE_HALYK_CARD: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/HalykPay/UpdateCard?cardId=` + payload.cardId + ((payload?.filial?.id === undefined) ? '' : `&filialId=` + payload?.filial?.id))
                    .then(({

                        status
                    }) => {

                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        TRANSFERS_BY_CARDID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/HalykPay/TransfersByCardId?cardId=` + payload)
                    .then(({
                        data,
                        status
                    }) => {
                        window.console.log(data)
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        HALYK_FILIAL_BALANCE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/HalykPay/GetBalanceAsync?filialId=`+ payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {

                            resolve(data);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
};