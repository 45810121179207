import axios from "axios";
import store from './../index'


export default{
    state: {
        clientBonus: 0,
        selectedClientBonus: 0
    },
    getters: {
        CLIENT_BONUS: state => {
            return state.clientBonus;
        },
        SELECTED_CLIENT_BONUS: state => {
            return state.selectedClientBonus;
        },
    },
    mutations: {
        SET_CLIENT_BONUS: (state, payload) => {
            state.clientBonus = payload;
        },
        SET_SELECTED_CLIENT_BONUS: (state, payload) => {
            state.selectedClientBonus = payload;
        }
    },
    actions:{
        GET_CLIENT_BONUS_FROM_API: (commit, payload) => {
            return new Promise((resolve, reject) => {
               axios
                    .get(`/ClientBonus/UnloadAsync?filialId=` + payload.filialId + "&accessUserBIN=" + payload.accessUserBIN + "&orderId=" + payload.orderId)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            store.commit('SET_CLIENT_BONUS', data);
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        COMMIT_SELECTED_PAYMENT_TYPES: (commit, payload) => {
            store.commit('SET_SELECTED_CLIENT_BONUS', payload);
        }
    }
}