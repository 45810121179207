<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Выберите аптеку"
                  class="rounded-0"
                  :items="pharms"
                  item-text="companyName"
                  item-value="id"
                  v-model="selectedPharm"
                  outlined
                  dense
                  return-object
                  hide-details
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.companyName"
                      ></v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.cityName
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="primary--text">{{
                        data.item.login
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Средние продажи"
                  placeholder="Введите средние продажи"
                  outlined
                  class="rounded-0"
                  dense
                  hide-details=""
                  type="number"
                  v-model="quantity"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="green" small outlined tile @click="add()"
                  >Добавить</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "BindPharms",
  data() {
    return {
      pharms: [],
      selectedPharm: null,
      quantity: null,
    };
  },
  methods: {
    add() {
      if (this.selectedPharm == null || this.quantity == null) return;

      this.$store
        .dispatch("GET_COMPANY_GOODS_GROUP_PHARM", {
          companyGoodsGroupId:
            this.$store.getters.SELECTED_COMPANY_GOODS_GROUP_ID,
          pharmUserId: this.selectedPharm.id,
          quantity: this.quantity,
        })
        .then((data) => {
          if (data.statusCode == 1) {
            let Success = {
              text: "Аптека успешно привязана",
              timeout: 3000,
              color: "success",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Success);
          } else {
            let Error = {
              text: "Ошибка при привязке аптеки!",
              timeout: 3000,
              color: "danger",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Error);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  mounted() {
    this.$store
      .dispatch("GET_PHARMS")
      .then(() => {
        this.pharms = this.$store.getters.PHARMS;
      })
      .catch((error) => {
        window.console.log(error);
      });
  },
};
</script>