<template>
  <v-col>
    <v-tabs 
      v-model="activePoint"
      fixed-tabs
    >
      <v-tab v-for="item in items" :key="item.userId" :href="`#${item.userId}`" @click="GetOrdersByUser(item.userId)"
        :disabled="tabDisabled"
      >{{
        item.login
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activePoint">
      <v-tab-item v-for="item in items" :key="item.userId" :value="item.userId">
        <div>
          <OrderList></OrderList>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    items: [
    ],
    tabDisabled: false,
  }),
  computed: {
    getOrderFromAPI: {
      get: function () {
        return this.$store.getters.GET_ORDER_FROM_API;
      }
    },
    activePoint: {
      get: function () {
        return this.$store.getters.ACTIVE_POINT;
      },
      set: function (value) {
        this.$store.commit("SET_ACTIVE_POINT", value);
      },
    },
  },
  methods: {
    GetOrdersByUser(item) {
      this.tabDisabled = true;
      this.$store.commit("SET_ORDER_FROM_API", true);
      this.$store
        .dispatch("ORDERS_BY_USER", item)
        .then(() => {
          this.tabDisabled = false;
          this.$store.commit("SET_ORDER_NAVIGATION", 0);
          this.$store.commit("SET_ORDER_FROM_API", false);
        })
        .catch(() => { 
          this.tabDisabled = false;
        });
    },
    GetPoints() {
      this.$store
        .dispatch("GET_PHARMACY_POINTS")
        .then(() => {
          this.items = this.$store.getters.GET_PHARMACY_POINTS;
        })
        .catch(() => { });
    },
  },
  async created() {
    this.GetPoints();
  },
};
</script>