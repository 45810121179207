<template>
  <v-dialog
    class="elevation-20"
    transition="scale-transition"
    max-width="400px"
    persistent
    scrollable
    v-model="payOrderDialog"
  >
    <v-card class="mx-auto" max-width="400" :loading="payLoading">
      <v-toolbar dense>
        <v-btn text color="primary" icon @click="payOrderDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Оплата заказа</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn @click="payOrderDialog = false" text color="primary"
            >Закрыть</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>

      <br />

      <v-card-text>
        <h3>Номер заказа: {{ order.id }}</h3>
        <div>Поставщик: {{ order.filial.name }}</div>
        <div v-if="order.filialOrderId">
          Номер заказа [Поставщик]: {{ order.filialOrderId }}
        </div>
        <div>Номер заказа [Фармцентр]: {{ order.id }}</div>
        <div>Количество товаров: {{ order.orderDetails.length }}</div>
        <div>Статус заказа: {{ order.status }}</div>
        <div v-if="order.sentDate">
          Дата отправки: {{ new Date(order.sentDate).toLocaleString() }}
        </div>
        <div v-if="order.createdDate">
          Дата создания: {{ new Date(order.createdDate).toLocaleString() }}
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
      <p class="subtitle-2 pl-6 mt-3">Сумма заказа со скидкой: {{ paySum }}</p>      <v-divider class="mx-4"></v-divider>
      <p class="subtitle-2 pl-6 mt-3">Остаток баланса: {{ order.balance }}</p><v-divider class="mx-4"></v-divider>
      <p class="subtitle-2 pl-6 mt-3">Комиссия: {{ commission }}</p>

      <v-card-actions>
        <v-btn
          block
          class="white--text"
          color="primary"
          :disabled="payLoading"
          @click="TransferP2P(order.id)"
        >
          Оплатить
          {{ Number(paySum) + Number(-order.balance) + Number(commission) }}
          KZT</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  data: () => ({
    payLoading: false,
    commission: 250,
  }),
  computed: {
    payOrderDialog: {
      get: function () {
        return this.$store.getters.PAY_ORDER_DIALOG;
      },
      set: function () {
        this.$store.commit("SET_PAY_ORDER_DIALOG", false);
      },
    },
    paySum: {
      get: function () {
        return this.order?.orderDetails
          .map((x) => x.quantity * x.price * (1 - x.discount / 100))
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          )
          .toFixed(2);
      },
    },
  },
  methods: {
    TransferP2P(orderId) {
      this.payLoading = true;
      this.$store
        .dispatch("TRANSFER_P2P", {
          orderId: orderId,
          balance: this.order.balance ?? 0,
          commission: this.commission,
        })
        .then((response) => {
          let Result;

          switch (response.result) {
            case "00":
              this.$emit("get-archive-orders");
              Result = {
                text: "Заказ успешно оплачен",
                timeout: 5000,
                color: "success",
                show: true,
              };
              break;
            case "NO":
              Result = {
                text:
                  "Возникли ошибки при оплате.<br> Пожалуйста свяжитесь с менеджерами Фармцентра. КОД ОТВЕТА: " +
                  response.id,
                timeout: 0,
                color: "warning",
                show: true,
              };
              break;
            default:
              Result = {
                text:
                  "Карта отправителя или получателя не найдено.<br> Пожалуйста свяжитесь с менеджерами Фармцентра.",
                timeout: 0,
                color: "error",
                show: true,
              };
              break;
          }

          this.$store.commit("SET_SNACKBAR", Result);

          this.payLoading = false;

          this.payOrderDialog = false;
        })
        .catch(() => {
          this.payLoading = false;
        });
    },
  },
};
</script>

<style></style>
