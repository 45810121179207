<template>
  <v-card>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('onCloseDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="$emit('onCloseDialog')"> Закрыть </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <v-list-item>
          <!-- order -->
          <v-card
            class="mb-3"
            :key="orderFullScreen.id"
            :id="`order${orderFullScreen.id}`"
          >
            <!-- header -->
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="9" class="text-truncate">
                    <v-list-item>
                      <v-list-item-avatar> </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Номер заказа:
                          <code class="subtitle-2">{{ orderFullScreen.id }}</code>
                          Дата создания:
                          {{
                            new Date(orderFullScreen.createdDate).toLocaleString([], {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-title>{{
                          orderFullScreen.filial.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="3" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          v-on="on"
                          @click="$emit('onCloseDialog')"
                        >
                          <v-icon>mdi-fullscreen</v-icon>
                        </v-btn>
                      </template>
                      <span>Увидеть в полном экране</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          v-on="on"
                          @click="
                            currentOrder = orderFullScreen;
                            dialog.shareOnChat = true;
                          "
                        >
                          <v-icon>mdi-share-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>Поделиться в чате</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- body -->
            <v-row>
              <!-- details operation -->
              <v-col cols="1" class="text-right">
                <v-card
                  elevation="0"
                  style="position: -webkit-sticky; position: sticky; top: 60px"
                  class="pt-4"
                >
                  <div class="mb-1">
                    <v-menu top offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-btn v-on="{ ...onTooltip, ...onMenu }" fab x-small>
                              <v-icon>mdi-content-cut</v-icon>
                            </v-btn>
                          </template>
                          <span>Переместить в хранилище</span>
                        </v-tooltip>
                      </template>
                      <v-list dense max-width="230px">
                        <v-subheader v-if="storages.length == 0"
                          >У вас нет активных хранилище</v-subheader
                        >
                        <template v-for="(accessStorage, index) in storages">
                          <v-divider :key="index" v-if="index !== 0"></v-divider>
                          <v-list-item :key="-index" @click="0">
                            <v-list-item-content
                              @click="
                                MoveDetailsToStorage(
                                  orderFullScreen.selectedDetails,
                                  accessStorage.storage.id
                                )
                              "
                            >
                              <code class="subtitle-2">{{
                                accessStorage.storage.name
                              }}</code>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </div>
                  <div class="mb-1">
                    <v-menu top offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-btn v-on="{ ...onTooltip, ...onMenu }" fab x-small>
                              <v-icon>mdi-box-cutter</v-icon>
                            </v-btn>
                          </template>
                          <span>Переместить к заказам у того же поставщика</span>
                        </v-tooltip>
                      </template>
                      <v-list dense two-line max-width="200px">
                        <template
                          v-if="
                            orders.filter(
                              (x) =>
                                x.filialId == orderFullScreen.filialId &&
                                x.id !== orderFullScreen.id
                            ).length < 1
                          "
                        >
                          <v-subheader>Нет других заказов у поставщика</v-subheader>
                        </template>
                        <template
                          v-for="(orderItem, index) in orders.filter(
                            (x) =>
                              x.filialId == orderFullScreen.filialId &&
                              x.id !== orderFullScreen.id
                          )"
                        >
                          <v-divider :key="index" v-if="index !== 0"></v-divider>
                          <v-list-item :key="-index" @click="0">
                            <v-list-item-content
                              @click="
                                MoveDetailsToOrder(
                                  orderFullScreen.selectedDetails,
                                  orderItem.id
                                )
                              "
                            >
                              <v-list-item-title>
                                К заказу
                                <code class="subtitle-2">{{ orderItem.id }}</code>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                v-html="orderItem.createdDate"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </div>
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          @click="SplitDetails(orderFullScreen.selectedDetails)"
                        >
                          <v-icon>mdi-call-split</v-icon>
                        </v-btn>
                      </template>
                      <span>Разделить заказ</span>
                    </v-tooltip>
                  </div>
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          @click="DeleteDetails(orderFullScreen.selectedDetails)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Удалить позиции</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>

              <!-- details -->
              <v-col cols="10">
                <v-data-table
                  filterable
                  v-model="orderFullScreen.selectedDetails"
                  :headers="headers"
                  :items="orderFullScreen.orderDetails"
                  dense
                  show-select
                  item-key="id"
                  :items-per-page="100"
                  disable-pagination
                  hide-default-footer
                > <!--eslint-disable-next-line -->
                  <template v-slot:item.id="{ item }">
                    <v-chip>
                      {{
                        orderFullScreen.orderDetails
                          .map(function (x) {
                            return x.id;
                          })
                          .indexOf(item.id) + 1
                      }}
                    </v-chip>
                  </template>

                  <!-- TODO переписать  -->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.quantity="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.quantity"
                      large
                      lazy
                      persistent
                      @save="SaveQuantity(props.item)"
                      @cancel="CancelQuantity"
                      @open="OpenQuantity"
                      @close="CloseQuantity"
                      saveText="Сохранить"
                      cancelText="Отмена"
                    >
                      <div class="text-no-wrap">
                        {{ props.item.quantity }}

                        <v-icon small>mdi-pencil</v-icon>
                      </div>
                      <v-badge
                        v-if="props.item.quantityChanged"
                        color="warning"
                        bordered
                        :content="`${props.item.oldQuantity}`"
                      ></v-badge>
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.quantity"
                          single-line
                          counter
                          autofocus
                          type="number"
                          :rules="[quantityRules]"
                          label="Введите количество"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.price="{ item }">
                    <div class="text-no-wrap">
                      <span v-bind:style="[ IsMinPrice(item) ? {}: {'color':'red'}]">
                        {{(item.price * (1 - item.discount / 100)).toFixed(2)}}
                      </span>
                      <v-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            v-on="on"
                            @click="GetPriceByGoodsIdAndCityId(item)"
                            >mdi-redo</v-icon
                          >
                        </template>
                        <v-simple-table fixed-header max-height="300px" dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Поставщик</th>
                                <th class="text-left">Цена</th>
                                <th class="text-left">Срок годности</th>
                                <th class="text-left">Остатки</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="price in goodsPrices"
                                :key="price.id"
                                @click="SelectFilial(price, item.quantity)"
                              >
                                <td>
                                  {{ price.filial.name }}
                                  <code v-if="price.userDiscount !== 0"
                                    >{{ price.userDiscount }}%</code
                                  >
                                  <v-spacer></v-spacer>
                                  <code v-if="price.isntDiscount">без скидки</code>
                                  <v-tooltip top v-if="price.isPromotion">
                                    <template v-slot:activator="{ on }">
                                      <code v-on="on">акция</code>
                                    </template>
                                    <span> {{ price.promotionDescription }}</span>
                                  </v-tooltip>
                                </td>

                                <td>
                                  <code v-if="price.userDiscount !== 0">{{
                                    (
                                      price.goodsPrice -
                                      (price.userDiscount * price.goodsPrice) / 100
                                    ).toFixed(2)
                                  }}</code>
                                  {{ price.goodsPrice }}
                                </td>
                                <td>
                                  <span>{{
                                    price.shelfLife.substr(0, 10) | toDate
                                  }}</span>
                                </td>
                                <td>
                                  <span v-if="price.stored > 0">{{ price.stored }}</span>
                                  <span v-else>н/д</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-menu>
                    </div>
                    <v-badge
                      v-if="item.priceChanged"
                      color="warning"
                      bordered
                      :content="`${item.oldPrice}`"
                    ></v-badge>
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.shelfLife="{ item }">{{
                    item.shelfLife.substr(0, 10) | toDate
                  }}</template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.sum="{ item }">{{
                    (item.quantity * item.price).toFixed(2)
                  }}</template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.discount="{ item }"
                    >{{ item.discount }}

                    <v-spacer></v-spacer>
                    <v-badge
                      v-if="item.discountChanged"
                      color="warning"
                      bordered
                      :content="`${item.oldDiscount}`"
                    ></v-badge>
                    <v-spacer></v-spacer>
                    <code v-if="item.isntDiscount">без скидки</code></template
                  >

                  <template v-slot:footer>
                    <v-divider></v-divider>
                    <v-layout justify-end>
                      <v-chip class="ma-2" label justify-end>
                        Общая сумма:
                        {{
                          orderFullScreen.orderDetails
                            .map((x) => x.quantity * x.price)
                            .reduce(function (previousValue, currentValue) {
                              return previousValue + currentValue;
                            })
                            .toFixed(2)
                        }}
                      </v-chip>
                    </v-layout>
                  </template>
                </v-data-table>
              </v-col>

              <!-- order operation -->
              <v-col cols="1" class="text-left">
                <v-card
                  elevation="0"
                  style="position: -webkit-sticky; position: sticky; top: 60px"
                  class="pt-4"
                >
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          small
                          @click="
                            currentOrder = orderFullScreen;
                            dialog.orderSend = true;
                          "
                          :loading="
                            loading.orderSend && orderFullScreen.id == currentOrder.id
                          "
                          :disabled="
                            loading.orderSend && orderFullScreen.id == currentOrder.id
                          "
                        >
                          <v-icon>mdi-send</v-icon>
                        </v-btn>
                      </template>
                      <span>Отправить заказ</span>
                    </v-tooltip>
                  </div>
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          small
                          @click="
                            DeleteOrder(orderFullScreen.id);
                            currentOrder.id = orderFullScreen.id;
                            loading.deleteOrder = true;
                          "
                          :loading="
                            loading.deleteOrder && orderFullScreen.id == currentOrder.id
                          "
                          :disabled="
                            loading.deleteOrder && orderFullScreen.id == currentOrder.id
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Удалить заказ</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-list-item>
      </v-list>
    </v-card>
    <!-- Окно отправки заказов -->
    <v-dialog v-model="dialog.orderSend" persistent max-width="1000" scrollable>
      <OrderSend
        :order="currentOrder"
        @onCloseDialog="dialog.orderSend = false"
      ></OrderSend>
    </v-dialog>

    <!-- Окно поделится заказом в чате -->
    <v-dialog v-model="dialog.shareOnChat" persistent max-width="800">
      <Share :order="currentOrder" @onCloseDialog="dialog.shareOnChat = false"></Share>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  props: {
    orderFullScreen: Object,
  },
  data: () => ({
    goodsPrices: [],
    quantityRules: (v) => v > 0 || "не должно быть меньше 1",
    modelState: {},

    loading: {
      deleteOrder: false,
      orderSend: false,
    },

    dialog: {
      orderFullScreen: false,
      orderSend: false,
      shareOnChat: false,
    },

    currentOrder: {
      // id: -1,
      // filial: {
      //   name: "",
      // },
      // createdDate: "",
      // comment: "",
    },

    headers: [
      // { text: "", value: "id" },
      { text: "Название", value: "goods.fullName" },
      { text: "Форма", value: "goods.dosageForm.name" },
      { text: "Номер", value: "goods.number" },
      { text: "Кол-во", value: "quantity" },
      { text: "Цена /Мин.", value: "price" },
      { text: "Макс.розн.цена", value: "price2" },
      { text: "Сумма", value: "sum" },
      { text: "Скидка", value: "discount" },
      { text: "Срок годности", value: "shelfLife" },
    ],
  }),
  methods: {
    IsMinPrice(item){
      if (item.minPrice){
        return ((item.price - (item.price * item.discount / 100)).toFixed(2) > item.minPrice) ? false: true;
      }
      return true;
    },    
    ParseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;
    },
    NotSelectedDetails(details) {
      if (details.length == 0) {
        let Snackbar = {
          text: "Выберите позиции",
          timeout: 3000,
          color: "warning",
          show: true,
        };
        this.$store.commit("SET_SNACKBAR", Snackbar);
        return true;
      }
    },
    MoveDetailsToOrder(details, toOrderId) {
      if (this.NotSelectedDetails(details)) return;

      let payload = {
        toOrderId,
        details,
      };
      this.$store
        .dispatch("MOVE_DETAILS_TO_ORDER", payload)
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
    MoveDetailsToStorage(details, toStorageId) {
      if (this.NotSelectedDetails(details)) return;
      let payload = {
        toStorageId,
        details,
      };
      this.$store
        .dispatch("MOVE_DETAILS_TO_STORAGE", payload)
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
    SaveQuantity(detail) {
      let UpdatedDetail = {
        Id: detail.id,
        OrderId: detail.orderId,
        Quantity: detail.quantity,
        Discount: detail.discount,
        Price: detail.price,
        DistributorGoodsId: detail.distributorGoodsId,
      };

      this.$store
        .dispatch("UPDATE_DETAIL", UpdatedDetail)
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
          // if (error.response.data.errors) {
          //   this.modelState = error.response.data.errors;
          // }
          // this.error = true;
        });
    },
    CancelQuantity() {
      window.console.log("cancel");
    },
    OpenQuantity() {
      // this.modelState = {};
      window.console.log("open");
    },
    CloseQuantity() {
      window.console.log("close");
    },
    DeleteOrder(id) {
      this.$store
        .dispatch("DELETE_ORDER", id)
        .then(() => {})
        .catch(() => {
          this.loading.deleteOrder = false;
        });
    },
    DeleteDetails(details) {
      if (this.NotSelectedDetails(details)) return;
      this.$store
        .dispatch("DELETE_DETAILS", details)
        .then(() => {})
        .catch(() => {});
    },
    SplitDetails(details) {
      if (this.NotSelectedDetails(details)) return;
      this.$store
        .dispatch("SPLIT_DETAILS", details)
        .then(() => {})
        .catch(() => {});
    },
    GetPriceByGoodsId(goods, filialId) {
      this.goodsPrices = [];

      let payload = {
        goodsId: goods.goodsId,
        goods: goods,
      };

      this.$store
        .dispatch("PRICE_BY_GOODS_ID", payload)
        .then(() => {
          this.goodsPrices = this.$store.getters.PRICE_BY_GOODS_ID.prices
            .filter((x) => x.filialId !== filialId)
            .sort(function (a, b) {
              return a.goodsPriceWithUserDiscount - b.goodsPriceWithUserDiscount;
            });
        })
        .catch(() => {});
    },
    GetPriceByGoodsIdAndCityId(item) {
      this.goodsPrices = [];
      let cityId = (this.$store.getters.ORDERS.filter((x) => x.id == item.orderId))[0]?.filial?.cityId;
      let payload = {
        goodsId: item.goodsId,
        goods: item,
      };
      if (cityId) {
        this.$store
          .dispatch("PRICE_BY_GOODS_ID", payload)
          .then(() => {
            this.goodsPrices = this.$store.getters.PRICE_BY_GOODS_ID.prices
              .filter((x) => x.filial.cityId == cityId)
              .sort(function (a, b) {
                return a.goodsPriceWithUserDiscount - b.goodsPriceWithUserDiscount;
              });
        })
          .catch(() => {});
      }
    },
    SelectFilial(value, quantity) {
      let OrderDialog = {
        goodsQuantity: quantity,
        show: true,
        price: Object.assign({}, value),
      };
      this.$store.commit("SET_ORDER_DIALOG", OrderDialog);
    },
  },
  created() {
    window.console.log("orderFullScreen", this.orderFullScreen);
  },
  computed: {
    orders: {
      get: function () {
        window.console.log("GET_ORDERS");
        return this.$store.getters.ORDERS;
      },
    },
    ordersNav: {
      get: function () {
        let orderNav = this.$store.getters.ORDER_NAVIGATION;
        if (orderNav == 0) return this.$store.getters.ORDERS;
        return this.$store.getters.ORDERS.slice(orderNav - 1, orderNav);
      },
    },
    storages: {
      get: function () {
        return this.$store.getters.ACCESS_STORAGES;
      },
    },
  },
};
</script>
