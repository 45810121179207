<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-card tile>
          <v-card-title>
            <span class="text-subtitle-2 font-weight-bold">Группы</span>
            <v-spacer />
            <v-btn x-small color="green" outlined tile @click="openAddDialog(1)"
              >Добавить</v-btn
            >
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 py-0">
            <v-list
              v-if="companyUserGroups != null && companyUserGroups.length > 0"
              dense
              height="70vh"
              class="overflow-y-auto"
            >
              <v-list-item-group v-model="selectedGroup" color="primary">
                <template v-for="groupItem in companyUserGroups">
                  <v-list-item :key="groupItem.id" :value="groupItem.id">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="groupItem.name"
                        class="text-wrap"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="'dvd_g' + groupItem.id" />
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card tile>
          <v-card-title>
            <span class="text-subtitle-2 font-weight-bold">Позиции</span>
            <v-spacer />
            <v-btn
              x-small
              color="green"
              outlined
              tile
              @click="openAddDialog(2)"
              :disabled="selectedGroup == null"
              >Добавить</v-btn
            >
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 py-0">
            <v-list
              v-if="selectedGroup != null"
              dense
              height="70vh"
              class="overflow-y-auto"
            >
              <v-list-item-group
                v-model="selectedPositionGroup"
                color="primary"
              >
                <template
                  v-for="positionGroupItem in companyUserGroups.find(
                    (x) => x.id == selectedGroup
                  ).companyUserGroupGoodsGroups"
                >
                  <v-list-item :key="positionGroupItem.id">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="positionGroupItem.companyGoodsGroup.name"
                        class="text-wrap"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="'dvd_pg' + positionGroupItem.id" />
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-card tile>
          <v-card-title>
            <span class="text-subtitle-2 font-weight-bold"
              >Медицинские представители</span
            >
            <v-spacer />
            <v-btn
              x-small
              color="green"
              outlined
              tile
              @click="openAddDialog(3)"
              :disabled="selectedGroup == null"
              >Добавить</v-btn
            >
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 py-0">
            <v-list
              v-if="selectedGroup != null"
              dense
              height="70vh"
              class="overflow-y-auto"
              two-line
            >
              <v-list-item-group v-model="selectedMr" color="primary">
                <template
                  v-for="mrItem in companyUserGroups.find(
                    (x) => x.id == selectedGroup
                  ).companyUserGroupUsers"
                >
                  <v-list-item :key="mrItem.id" :value="mrItem.id">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          mrItem.user.lastName + ' ' + mrItem.user.firstName
                        "
                        class="text-wrap"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="'[' + mrItem.user.login + ']'"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn
                        text
                        fab
                        small
                        class="my-auto"
                        color="primary"
                        @click="openPharmDialog(4, mrItem.id)"
                      >
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider :key="'dvd_mr_' + mrItem.id" />
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card tile>
        <v-card-title>{{dialogTitle}}</v-card-title>
        <v-divider />
        <v-card-text>
          <CompanyUserGroup v-if="selectedActionId == 1" />
          <CompanyUserGroupCompanyGoodsGroup
            v-else-if="selectedActionId == 2"
          />
          <CompanyUserGroupUserPharms v-else-if="selectedActionId == 4" />
          <div v-else></div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="closeDialog()">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import CompanyUserGroupCompanyGoodsGroup from "./CompanyUserGroupCompanyGoodsGroup.vue";
import CompanyUserGroup from "./CompanyUserGroup.vue";
import CompanyUserGroupUserPharms from "./CompanyUserGroupUserPharms.vue"
export default {
  name: "Groups",
  components: {
    CompanyUserGroupCompanyGoodsGroup,
    CompanyUserGroup,
    CompanyUserGroupUserPharms
  },
  computed: {
    companyUserGroups: {
      get: function () {
        return this.$store.getters.COMPANY_USER_GROUPS;
      },
    },
  },
  data() {
    return {
      selectedGroup: null,
      selectedMr: null,
      selectedPositionGroup: null,
      dialog: false,
      selectedActionId: null,
      dialogTitle: 'Диалоговое окно'
    };
  },
  methods: {
    getCompanyUserGroups() {
      this.$store
        .dispatch("GET_COMPANY_USER_GROUPS")
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
    openAddDialog(actionId) {
      switch(actionId){
        case 1: this.dialogTitle = 'Добавление группы'; break;
        case 2: this.dialogTitle = 'Добавление позиций'; break;
        default: this.dialogTitle = 'Диалоговое окно'; break;
      }
      this.selectedActionId = actionId;
      this.dialog = true;
    },
    openPharmDialog(actionId, id) {
      this.dialogTitle = 'Аптеки'
      this.selectedActionId = actionId;
      this.dialog = true;

      this.$store
        .dispatch("COMMIT_SELECTED_COMPANY_USER_GROUP_USER", id)
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  watch: {
    selectedGroup: function () {
      this.$store
        .dispatch("COMMIT_SELECTED_COMPANY_USER_GROUP", this.selectedGroup)
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  mounted() {
    this.getCompanyUserGroups();
  },
};
</script>