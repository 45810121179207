import axios from "axios";
import store from '../index'

export default {
    state: {
        source: []
    },
    getters: {
        REQUEST: state => {
            return state.request;
        },
        CANCEL_TOKEN: state => (id) => {
            return state.source.find(x => x.id === id)
                .cancelTokenSource.token;
        }
    },
    mutations: {
        PUSH_REQUEST: (state, payload) => {
            let Request = {
                id: payload.id,
                cancelTokenSource: payload.cancelTokenSource
            }
            state.source.push(Request);
        },
        THROW_CANCEL: (state, payload) => {
 

            state.source.find(x => x.id === payload.id)
                .cancelTokenSource.cancel(payload.message);


            state.source = state.source.filter(function (obj) {
                return obj.id !== payload.id;
            });
        }
    },
    actions: {
        CANCEL_REQUEST_BY_ID: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/CancelRequest/CancelById?id=` + payload.id)
                    .then(({
                        status
                    }) => {
                        if (status === 200) {
                            store.commit("THROW_CANCEL", payload);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        CANCEL_REQUESTS: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/CancelRequest/GetAll`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            window.console.log(data)
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
};