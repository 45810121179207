import axios from 'axios';
import store from './../index'
import signalr from './../../config/signalr'

export default {
    state: {
        hubConnection: null,
        contentUpdate: false
    },
    getters: {
        HUB_CONNECTION: state => {
            return state.hubConnection
        },
        CONTENT_UPDATE: state => {
            return state.contentUpdate
        },
    },
    mutations: {
        SET_CONTENT_UPDATE: (state, payload) => {
            state.contentUpdate = payload;
        },
        SET_HUB_CONNECTION: (state, payload) => { 

            if (state.hubConnection){
                state.hubConnection.stop();
                state.hubConnection.disconnect();
            }
            let url = `${axios.defaults.baseURL}` + "/" + payload

            state.hubConnection = new signalr.HubConnectionBuilder()
                .withUrl(url, {
                    accessTokenFactory: () => store.getters.ACCESS_TOKEN
                }).configureLogging(signalr.LogLevel.Information)
                .build();

        },
    },
    actions: {
        CONNECT_HUB: () => {

            store.commit('SET_HUB_CONNECTION', "chat-hub");

            let startedPromise = null

            function start() {
                startedPromise = store.getters.HUB_CONNECTION.start().catch(err => {
                    window.console.error('Failed to connect with hub', err)
                    return new Promise((resolve, reject) =>
                        setTimeout(() => start().then(resolve).catch(reject), 6000))
                })

                return startedPromise
            }

            store.getters.HUB_CONNECTION.onclose(() => start())

            start()

        }
    }
}