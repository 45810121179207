<template>
<div>
    <v-row>
        <v-col cols="12">
            <v-data-table :headers="headers" :items="companyGoodsGroups" item-key="id" class="elevation-0">
                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small fab text v-bind="attrs" v-on="on">
                                <v-icon color="primary">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group>
                                <v-list-item @click="openAddDialog(1, item.id)">
                                    <v-list-item-title>Привязать аптеку</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openDiscountDialog(2, item.id)">
                                    <v-list-item-title>Настроить скидочные условия</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="openDiscountDialog(3, item.id)">
                                    <v-list-item-title>Настроить дефолтные скидочные условия</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable max-width="600" persistent>
        <v-card tile>
            <v-card-title></v-card-title>
            <v-divider />
            <v-card-text>
                <BindPharms v-if="selectedActionId == 1" />
                <DiscountConditions v-else-if="selectedActionId == 2" />
                <DefaultDiscountConditions v-else-if="selectedActionId == 3" />
                <div v-else></div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="red" @click="closeDialog()">Закрыть</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import BindPharms from "../discounts/intelligence/BindPharms.vue"
import DiscountConditions from "../discounts/intelligence/DiscountConditions.vue"
import DefaultDiscountConditions from "../discounts/intelligence/DefaultDiscountCondifitions.vue"
export default {
    components: {
        BindPharms,
        DiscountConditions,
        DefaultDiscountConditions
    },
    data() {
        return {
            headers: [{
                    text: "Идентификатор",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Наименование",
                    value: "name",
                    align: "center"
                },
                {
                    text: "Действия",
                    value: "actions",
                    align: "center"
                },
            ],
            companyGoodsGroups: [],
            dialog: false,
            selectedActionId: null,
        };
    },
    methods: {
        getCompanyGoodsGroups() {
            this.$store
                .dispatch("GET_COMPANY_GOODS_GROUPS")
                .then(() => {
                    this.companyGoodsGroups = this.$store.getters.COMPANY_GOODS_GROUPS;
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },
        openAddDialog(actionId, companyGoodsGroupId) {

            this.$store
                .dispatch("COMMIT_SELECTED_COMPANY_GOODS_GROUP_ID", companyGoodsGroupId)
                .then(() => {})
                .catch((error) => {
                    window.console.log(error);
                });

            this.selectedActionId = actionId;
            this.dialog = true;
        },
        openDiscountDialog(actionId, companyGoodsGroupId) {
            this.$store
                .dispatch("COMMIT_SELECTED_COMPANY_GOODS_GROUP_ID", companyGoodsGroupId)
                .then(() => {})
                .catch((error) => {
                    window.console.log(error);
                });

            this.selectedActionId = actionId;
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
    },
    mounted() {
        this.getCompanyGoodsGroups();
    },
};
</script>
