<template>
  <v-row justify="center">
    <v-window
      v-model="window"
      :vertical="vertical"
      :show-arrows="showArrows"
      :reverse="reverse"
      show-arrows-on-hover
      dark
      continuous
      style="max-width:825px"
    >
      <template v-for="adItem in adItems.filter(x=>x.adIdContains.includes(adLocation))">
        <v-window-item :key="adItem.id">
          <v-row>
            <template v-for="(content, index) in adItem.adItemContents">
              <v-col :cols="content.frontColumn" :key="index">
                <v-card
                  color="grey"
                  :height="GetDimension(content.frontColumn,  IsVertical(adItem.adItemContents)).height"
                  :width="GetDimension(content.frontColumn,   IsVertical(adItem.adItemContents)).width"
                >
                  <h3>ВЫСОТА: {{GetDimension(content.frontColumn, IsVertical(adItem.adItemContents)).height}} px</h3>
                  <h3>ШИРИНА: {{GetDimension(content.frontColumn, IsVertical(adItem.adItemContents)).width}} px</h3>
                  <code>COL: {{content.frontColumn}}</code>
                </v-card>
              </v-col>
            </template>
          </v-row>

          <v-btn color="primary" class="ml-4" small @click="CreateAdItem(adItem)">Добавить</v-btn>
        </v-window-item>
      </template>
    </v-window>
  </v-row>
</template>
<script>
export default {
  props: {
    adLocation: Number,
    goodsId: Number,
  },
  data: () => ({
    dialog: false,
    window: 0,
    windowContent: 0,

    showArrows: true,
    vertical: true,
    reverse: true,
    autorun: true,
  }),

  methods: {
    GetDimension(col, isVertical) {
      var dimension = this.$store.getters.AD_ITEM_CONTENT_DIMENSIONS(
        this.adLocation,
        col,
        isVertical
      );
      return dimension;
    },

    IsVertical(contents) {
      var result =
        contents
          .map((x) => x.frontColumn)
          .reduce(function (previousValue, currentValue) {
            return previousValue + currentValue;
          }) == 12;

      return result;
    },

    CreateAdItem(adItem) {
      this.$store
        .dispatch("CREATE_AD_ITEM", adItem)
        .then(() => {
          this.$emit("ad-template-new-dialog-close");
        })
        .catch(() => {});
    },
  },

  computed: {
    ad: {
      get: function () {
        var result = this.$store.getters.ADS_CONSTRUCTOR(this.adLocation);
        return result;
      },
    },
    adItems: {
      get: function () {
        var result = this.ad?.adItems?.filter((x) => x.goodsId == this.goodsId);
        return result;
      },
    },
  },
};
</script>

