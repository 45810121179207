<template>
  <div>
    <v-card class="elevation-0 rounded-0">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              label="Выберите корпорацию"
              class="rounded-0"
              :items="corporations"
              item-text="name"
              item-value="id"
              v-model="selectedCorporation"
              outlined
              dense
              return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12">
            <v-card class="elevation-0 rounded-0">
              <v-card-title>
                Товары
                <v-spacer />
                <v-text-field
                  v-model="search"
                  label="Поиск"
                  class="rounded-0"
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                ></v-text-field>
                <v-spacer />
                <v-btn color="primary" small outlined tile @click="add()">
                  Применить
                </v-btn>
              </v-card-title>
              <v-data-table
                v-model="selectedGoods"
                :headers="headers"
                :items="goods"
                item-key="id"
                :single-select="singleSelect"
                :search="search"
                show-select
                :loading="loading"
                loading-text="Идет загрузка..."
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      companyCorporations: [],
      corporations: [],
      goods: [],
      selectedCorporation: null,
      headers: [
        { text: "Идентификатор", value: "id", align: "center" },
        { text: "Наименование", value: "name", align: "center" },
      ],
      search: "",
      singleSelect: false,
      selectedGoods: [],
      loading: false,
      companyGoodsGroups: [],
    };
  },
  methods: {
    getCompanyCorporations() {
      this.$store
        .dispatch("GET_COMPANY_CORPORATIONS")
        .then(() => {
          this.companyCorporations = this.$store.getters.COMPANY_CORPORATIONS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getCorporationByIdList(idList) {
      this.$store
        .dispatch("GET_CORPORATIONS", idList)
        .then(() => {
          this.corporations = this.$store.getters.CORPORATIONS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getGoodsByCorporationId(corporationId) {
      this.$store
        .dispatch("GET_GOODS_BY_CORPORATION_ID", corporationId)
        .then(() => {
          this.goods = this.$store.getters.CORPORATION_GOODS;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    add() {
      let param = {
        list: this.selectedGoods.map((x) => ({
          name: x.name,
          goodsId: x.id,
        })),
      };

      this.$store
        .dispatch("ADD_COMPANY_GOODS_GROUPS", param)
        .then(() => {
          this.companyGoodsGroups = this.$store.getters.COMPANY_GOODS_GROUPS;
          window.console.log(this.companyGoodsGroups);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  watch: {
    companyCorporations: function () {
      this.loading = true;
      this.getCorporationByIdList(
        this.companyCorporations
          .map((n) => `idList=${n.corporationId}`)
          .join("&")
      );
      this.loading = false;
    },
    selectedCorporation: function () {
      this.loading = true;
      this.getGoodsByCorporationId(this.selectedCorporation.id);
      this.loading = false;
    },
  },
  mounted() {
    this.getCompanyCorporations();
  },
};
</script>