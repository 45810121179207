<template>
  <div>
    <div v-if="goods">
      <v-row align="center">
        <v-col cols="12" class="text-center">
          <v-icon slot="icon" color="error" size="30"
            >mdi-cloud-off-outline</v-icon
          >
          <h3>
            К сожалению данного товара нет в прайсе, вы можете использовать ниже
            варианты
          </h3>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="text-center">
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Поиск по аналогам</v-card-title>

          <v-card-text>
            <v-chip-group v-model="selection" column>
              <v-chip @click="SetSearch('inn')" color="primary" outlined>
                МНН:
                <span class="font-weight-bold">{{ goods.inn.name }}</span>
              </v-chip>
              <v-chip @click="SetSearch('brand')" color="primary" outlined>
                Бренд:
                <span class="font-weight-bold">{{ goods.brand.name }}</span>
              </v-chip>
              <v-chip @click="SetSearch('atc')" color="primary" outlined>
                АТХ:
                <span class="font-weight-bold">{{ goods.atc.name }}</span>
              </v-chip>
              <v-chip @click="SetSearch('producer')" color="primary" outlined>
                Производитель:
                <span class="font-weight-bold">{{ goods.producer.name }}</span>
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-col>

        <v-col cols="12" class="text-center" v-if="!notification">
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="headline"
            >Уведомить меня в случае появления товара в прайсе</v-card-title
          >
          <v-card-subtitle
            >(В данный момент доступен уведомление по Фарм-чат)</v-card-subtitle
          >

          <v-card-text>
            <v-btn
              @click="CreateNotification()"
              :disabled="notificationButton"
              color="primary"
              >Уведомить</v-btn
            >
          </v-card-text>
        </v-col>
        
        <v-col cols="12" class="text-center" v-else>
          <v-divider class="mx-4"></v-divider>
          <v-card-subtitle
            >Вы подписаны на уведомление. Когда товар появится в прайсе вы
            будете в курсе.</v-card-subtitle
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-col cols="12" class="text-center">
        <v-card-subtitle class="headline"
          >Выберите товар, чтобы увидеть цены поставщиков.</v-card-subtitle
        >
      </v-col>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    selection: -1,
    notification: null,
    notificationButton: false,
  }),
  computed: {
    goods: {
      get: function () {

        let goods = this.$store.getters.PRICE_BY_GOODS_ID.goods;
        return goods;
      },
    },
  },
  methods: {
    CreateNotification() {
      this.notificationButton = true;
      this.$store
        .dispatch("CREATE_NOTIFICATION", { goodsId: this.goods?.id })
        .then(() => {
          this.notification = {};
          this.notificationButton = false;
        })
        .catch((error) => {
          window.console.log(error);
          this.notificationButton = false;
        });
    },
    GetNotificationByGoodsId() {
      this.$store
        .dispatch("NOTIFICATION_BY_GOODS_ID", this.goods?.id)
        .then((response) => {
          this.notification = response;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    SetSearch(type) {
      let Search = {
        fullName: "",
        beInThePrices: false,
        advanced: {
          INN: type == "inn" ? this.goods?.inn?.name : "",
          brand: type == "brand" ? this.goods?.brand?.name : "",
          ATC: type == "atc" ? this.goods?.atc?.name : "",
          producer: type == "producer" ? this.goods?.producer?.name : "",
          show: true,
        },
      };
      this.$store.commit("SET_GOODS_SEARCH", Search);
    },
  },
  created() {
    this.GetNotificationByGoodsId();
  },
};
</script>
