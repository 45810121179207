<template>
  <div>
    <v-row>
      <!-- Создание хранилище -->
      <v-col cols="6">
        <span class="subheading mx-1">Выберите хранилище</span> или
        <v-menu
          v-model="createStorageMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-small class="mx-1"
              >создайте</v-btn
            >
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="storageName"
                    :rules="storageRules"
                    :counter="10"
                    maxlength="10"
                    label="Имя"
                    required
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn small text @click="createStorageMenu = false"
                >Отмена</v-btn
              >
              <v-btn small text @click="CreateStorage()" color="primary"
                >Создать</v-btn
              >
            </v-card-actions>
          </v-card> </v-menu
        >новое
      </v-col>

      <!-- Доступ к хранилище пользователей-->
      <v-col cols="5" class="d-flex align-center">
        <v-select
          v-model="accessUsers"
          :items="companyUsers"
          label="Пользователи"
          multiple
          hint="Выбрать доступ к хранилищу"
          persistent-hint
          return-object
          item-text="firstName"
          item-value="accessUserId"
          :disabled="accessStorages.length == 0 || !enableAccessUsers"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="1">
        <v-btn
          small
          fab
          @click="CreateAccessUsers"
          :disabled="accessStorages.length == 0 || !enableAccessUsers"
          class="primary--text"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Выбор хранилище -->
    <v-chip-group v-model="storageIndex" active-class mandatory>
      <v-chip
        v-for="accessStorage in accessStorages"
        :key="accessStorage.storage.id"
        @click.native="
          GetStorageGoods();
          GetAccessUsers();
        "
        filter
        outlined
        :close="currentUser.id == accessStorage.storage.userCreatedId"
        close-icon="mdi-close-thick"
        @click:close="DeleteStorage(accessStorage.storage.id)"
      >
        {{ accessStorage.storage.name }}
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn small fab text v-on="on">
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          Создано
          <span v-if="currentUser.id == accessStorage.storage.userCreatedId"
            >Вами</span
          >
          <span v-else
            >пользователем
            {{ accessStorage.storage.userCreated.firstName }}</span
          >
        </v-tooltip>
      </v-chip>
    </v-chip-group>

    <!-- Товары хранилище -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="searchGoods"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ma-2 primary--text"
              v-on="on"
              fab
              small
              :disabled="selectedGoods.length < 1"
              @click="Optimized"
              v-if="userRoleId != 11"
            >
              <v-icon>mdi-flare</v-icon>
            </v-btn>
          </template>
          <span>Оптимизированный заказ</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ma-2 primary--text"
              v-on="on"
              fab
              small
              :disabled="selectedGoods.length < 1"
              @click="DeleteStorageGoods()"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Удалить из хранилища</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        v-model="selectedGoods"
        :headers="headers"
        :items="storageGoods"
        :search="searchGoods"
        hide-default-footer
        fixed-header
        class="elevation-3"
        @click:row="GetPriceByGoodsId"
        :loading="loadingGoods"
        dense
        item-key="goodsId"
        filterable
        show-select
        height="400"
        :item-class="getRowColor"
        disable-pagination
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    createStorageMenu: false,
    storageName: "",
    storageRules: [
      (v) => !!v || "Имя обязательно",
      (v) => v.length <= 10 || "Имя должно быть не более 10 символов",
    ],
    companyUsers: [],
    accessUsers: [],
    storageIndex: 0,
    searchGoods: "",
    selectedGoods: [],
    loadingGoods: false,
    headers: [
      {
        text: "",
        value: "data-table-select",
        class: "primary white--text",
        sortable: false,
      },
      {
        text: "Полное название",
        value: "goods.fullName",
        class: "primary white--text",
        sortable: false,
      },
      {
        text: "Количество",
        value: "quantity",
        class: "primary white--text",
        sortable: false,
      },
      {
        text: "Пользователь",
        value: "userCreated.firstName",
        class: "primary white--text",
        sortable: false,
      },
    ],
    storageUserId: null,
  }),
  methods: {
    CreateStorage() {
      let Storage = {
        name: this.storageName,
        userCreatedId: this.currentUser.id,
      };
      this.$store
        .dispatch("CREATE_STORAGE", Storage)
        .then(() => {
          this.createStorageMenu = false;
        })
        .catch(() => {
          this.createStorageMenu = false;
        });
    },
    DeleteStorage(id) {
      this.accessUsers = [];
      this.$store
        .dispatch("DELETE_STORAGE", id)
        .then(() => {
          this.GetStorageGoods();
          this.GetAccessUsers();
        })
        .catch(() => {});
    },
    async DeleteStorageGoods() {
      await this.$store.dispatch("DELETE_STORAGE_GOODS", {
        goodsIds: this.selectedGoods.map((x) => x.goodsId),
        storageId: this.currentStorage.id,
      });
      if (this.$store.getters.DELETE_STORAGE_GOODS_STATUS) {
        let Success = {
          text: "Позиции успешно удалены!",
          timeout: 3000,
          color: "success",
          show: true,
        };
        this.$store.commit("SET_SNACKBAR", Success);
        this.GetStorageGoods();
        this.selectedGoods = [];
      } else {
        let Error = {
          text: "Ошибка при удалении позиций!",
          timeout: 3000,
          color: "danger",
          show: true,
        };
        this.$store.commit("SET_SNACKBAR", Error);
      }
    },
    GetCompanyUsers() {
      this.$store
        .dispatch("USERS_INSIDE_COMPANY")
        .then(() => {
          this.companyUsers = this.$store.getters.USERS;
        })
        .catch(() => {});
    },
    GetAccessUsers() {
      this.accessUsers = [];
      this.$store
        .dispatch("ACCESS_USERS_BY_STORAGE_ID", this.currentStorage?.id)
        .then((response) => {
          this.accessUsers = response.map((x) => x.accessUser);
        })
        .catch(() => {});
    },
    GetStorageGoods() {
      this.selectedGoods = [];
      this.loadingGoods = true;
      this.$store.commit("SET_STORAGE_GOODS", []);
      if (!this.currentStorage) this.loadingGoods = false;
      this.$store
        .dispatch("STORAGE_GOODS", this.currentStorage?.id)
        .then(() => {
          this.loadingGoods = false;
        })
        .catch(() => {
          this.loadingGoods = false;
        });
    },
    CreateAccessUsers() {
      let Storage = {
        StorageId: this.currentStorage?.id,
        AccessUsers: this.accessUsers.map((x) => x.id),
      };
      this.$store
        .dispatch("CREATE_ACCESS_USERS_STORAGE", Storage)
        .then(() => {})
        .catch(() => {});
    },
    GetPriceByGoodsId(value) {
      if (this.userRoleId == 11)
        return;
      this.storageUserId = value.userCreatedId;
      this.$store.commit("SET_LOADING_ORDER_PRICE", true);
      // row.select(true);

      let payload = {
        goodsId: value.goodsId,
        goods: value.goods,
      };

      this.$store
        .dispatch("PRICE_BY_GOODS_ID", payload)
        .then(() => {
          this.$store.commit("SET_LOADING_ORDER_PRICE", false);
          this.$store.commit("SET_ORDER_RIGHT_TAB", "Цены");
        })
        .catch(() => {});
    },
    Optimized() {
      this.$store
        .dispatch("OPTIMIZED_ORDER", this.selectedGoods)
        .then(() => {
          this.GetStorageGoods();
          this.GetOrders();
        })
        .catch(() => {});
    },
    getRowColor(item) {
      let currentIndex = this.storageGoods
        .map((x) => x.goodsId)
        .indexOf(item.goodsId);

      if (currentIndex % 2 == 0) {
        return "gray-row-color";
      } else {
        return "white-row-color";
      }
    },
    GetOrders() {
      this.$store.commit("SET_ORDER_NAVIGATION", 0);
      this.$store.commit("SET_ORDER_FROM_API", true);
      if (this.$store.getters.ACTIVE_POINT != null) {
        this.$store
          .dispatch("ORDERS_BY_USER", this.$store.getters.ACTIVE_POINT)
          .then(() => {
            this.$store.commit("SET_ORDER_FROM_API", false);
          })
          .catch(() => {
          });
      } else {
        this.$store
          .dispatch("ORDERS")
          .then(() => {
            this.$store.commit("SET_ORDER_FROM_API", false);
          })
          .catch(() => { });
      }
    },
  },
  computed: {
    accessStorages: {
      get: function () {
        return this.$store.getters.ACCESS_STORAGES;
      },
    },
    currentStorage: {
      get: function () {
        return this.$store.getters.ACCESS_STORAGES[this.storageIndex]?.storage;
      },
    },
    currentUser: {
      get: function () {
        return this.$store.getters.USER;
      },
    },
    storageGoods: {
      get: function () {
        return this.$store.getters.STORAGE_GOODS.filter(
          (x) => x.storageId == this.currentStorage?.id
        );
      },
    },
    enableAccessUsers: {
      get: function () {
        return (
          this.currentUser.id ==
          this.$store.getters.ACCESS_STORAGES[this.storageIndex]?.storage
            ?.userCreatedId
        );
      },
    },
    userRoleId: {
      get: function () {
        return this.$store.getters.USER.userRoleId;
      },
    },
  },
  mounted() {
    this.GetCompanyUsers();
    this.GetStorageGoods();
    this.GetAccessUsers();
  },
};
</script>
<style lang="css">
.white-row-color {
  background-color: #ffffff;
}
.gray-row-color {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>